<div class="sucess-popup fail">
  <div class="text-center mt-30">
    <img src="../../../../../assets/images/icons/sucess.png" />
  </div>
  <div class="f-20 fw-600 text-darkgrey text-center mt-30">
    Oops, Something went wrong!<br />
    <ng-container *ngIf="showAborted">
      Your payment transaction was Aborted. Please try again.
    </ng-container>
    <ng-container *ngIf="!showAborted">
      Please try again or check with your bank with reference no: {{ data }}.
    </ng-container>
  </div>
  <div class="row justify-center mt-30">
    <div class="col-md-3">
      <button
        *ngIf="showAborted && this.data !== 'aborteds'"
        (click)="subscribe(true)"
        class="theme-btn primary-btn mb-20 f-12 w-100"
      >
        Subscribe
      </button>
      <button
        *ngIf="!showAborted"
        (click)="close(true)"
        class="theme-btn primary-btn mb-20 f-12 w-100"
      >
        Continue
      </button>
    </div>
  </div>
</div>
