<!-- <div class="create-universal-component">
  <div class="container">
    <div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <h1 class="mb-20">Create new Universe</h1>
        <div class="row mt-20 label px-0">
        <div class="col-md-3 ">
          <div class="f-12 text-grey mb-05">Universe name</div>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput formControlName="universeName" autocomplete="off" />
            <mat-error>
              <mat-error *ngIf="errorHandling('universeName', 'required')">
                Universe name required
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
            <div class="f-12 text-grey mb-05">Description</div>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput formControlName="description" autocomplete="off" />
              <mat-error>
                <mat-error *ngIf="errorHandling('description', 'required')">
                  Description required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="label-2 mt-20 f-16">Add stocks to new universe using</div>
        <div class="justify-content-between mt-10 mb-30">
          <div class="col-lg-10 col-md-12">
            <div class="row add-pd no-margin">
              <div class="col-lg-2 col-md-5 px-0 label f-12">
                <p class="f-12 text-grey mb-05">Existing Universe
                  <span class="px-1" style="vertical-align: middle" [matMenuTriggerFor]="menu"><img
                      src="../../../../assets/images/icons/info.svg" alt="" /></span>
                </p>
                <mat-menu #menu="matMenu" class="p-3">
                  <div class="row justify-content-center">
                    <div class="col text-center">
                      You can edit existing universe by <br>removing or adding stocks to it .
                    </div>
                  </div>
                </mat-menu>
                <mat-form-field appearance="outline">
                  <mat-select [multiple]="true" formControlName="universes"
                    (openedChange)="closeDropdown($event,'universe')"
                    (selectionChange)="selectionChange($event,'universe')">
                    <mat-option *ngFor="let universe of universes" [value]="universe.name">{{universe.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              
              <div class="col-md-2 px-0 label f-12 text-grey addMr">
                <p class="f-12 text-grey mb-05">Sector</p>
                <mat-form-field appearance="outline">
                  <mat-select [multiple]="true" formControlName="sectors"
                    (openedChange)="closeDropdown($event,'sector')"
                    (selectionChange)="selectionChange($event,'sector')">
                    <mat-option *ngFor="let sector of sectors" [value]="sector.name">{{sector.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-1  text-center align-self-center">
                <img class="or-icon" src="../../../../../assets/images/icons/or.svg" />
              </div>
              <div class="col-lg-3 col-md-5 px-0 label f-12 text-grey">
                <div>
                  <p class="f-12 text-grey mb-05">Search stock by name</p>
                </div>
                <div class="searchBox">
                  <mat-form-field appearance="outline" class="w-100">

                    <input matInput formControlName="search" [matAutocomplete]="auto" [formControl]="searchControl"
                      (input)="searchHandler($event)" />
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                      (optionSelected)="optionSelected($event)">
                      <mat-option *ngFor="let stock of searchResults" [value]="stock">
                        {{stock.stock_name}}
                      </mat-option>
                    </mat-autocomplete>


                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-1  text-center align-self-center">
                <img class="or-icon" src="../../../../../assets/images/icons/or.svg" />
              </div>
              <div class="col-md-2 px-0 align-self-center">
                <button mat-button class="theme-btn border-btn-grey mt-20 fw-400">Bulk upload</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row table_container" style="margin: 0%;">
          <div class="table-con">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>
                  <img class="pb-2 " src="../../../../assets/images/icons/add.svg" alt="">
                </th>
                <td mat-cell *matCellDef="let element; let i=index;"> {{(i+1)}} </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>

                  Stock Name </th>
                <td mat-cell *matCellDef="let element"> {{element.stock_name}} </td>
              </ng-container>


              <ng-container matColumnDef="marketCap">
                <th mat-header-cell *matHeaderCellDef> Market cap (Cr) </th>
                <td mat-cell *matCellDef="let element"> {{element.market_cap}} </td>
              </ng-container>


              <ng-container matColumnDef="currentValue">
                <th mat-header-cell *matHeaderCellDef> Current Value </th>
                <td mat-cell *matCellDef="let element"> {{element.share_price}} </td>
              </ng-container>

              <ng-container matColumnDef="peRatio">
                <th mat-header-cell *matHeaderCellDef> PE Ratio </th>
                <td mat-cell *matCellDef="let element"> {{element.pe_ratio}} </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                  <img src="../../../../assets/images/icons/delete.svg" (click)="deleteRow($event,element)">
                </td>
              </ng-container>




              <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height: 40px;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

        </div>
        <div class="row flex-wrap bottom_text" style="padding: 15px 15px 0;">
          <div class="col-sm-8 px-0 align-self-center text-grey sm-text-center sm-mb-20">
            Market cap and current value updated every once in a day, eveyday at evening 7:00 PM

          </div>
          <div class="col-sm-4 px-0 justify-content-end text-right sm-text-center">
            <div class="btn-group">
              <button mat-button class="theme-btn border-btn" type="button" (click)="onCancelClick()">Cancel</button>
              <button mat-button type="submit" class="theme-btn primary-btn ">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</div> -->

<div class="create-universal-component ui-update">
  <div class="container">
    <div class="container-lg white-bg public-bg-wrapper mt-60 mb-60 pd-30">
      <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <h1 class="mb-20">Create new Universe</h1>
          <div class="row mt-20 label px-0">
            <div class="col-md-3">
              <div class="f-12 text-grey mb-05">Universe name</div>
              <mat-form-field appearance="outline" class="w-100">
                <input
                  matInput
                  formControlName="universeName"
                  autocomplete="off"
                />
                <mat-error>
                  <mat-error *ngIf="errorHandling('universeName', 'required')">
                    Universe name required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <div class="f-12 text-grey mb-05">Description</div>
              <mat-form-field appearance="outline" class="w-100">
                <input
                  matInput
                  formControlName="description"
                  autocomplete="off"
                />
                <mat-error>
                  <mat-error *ngIf="errorHandling('description', 'required')">
                    Description required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-1 retail">
              <mat-checkbox
                [hidden]="loggedInUserRole !== 'admin'"
                [disabled]="hiddenCheck"
                formControlName="show_retail"
                (change)="retail($event)"
                >Retail</mat-checkbox
              >
              <mat-checkbox
                class="mx-2"
                [disabled]="retailCheck"
                (change)="hiddenCheckEvent($event)"
                >Hidden</mat-checkbox
              >
            </div>
          </div>
          <div class="label-2 mt-20 f-16">Add stocks to new universe using</div>
          <div class="justify-content-between mt-10 mb-30">
            <div class="col-lg-10 col-md-12">
              <div class="row add-pd no-margin">
                <div class="col-lg-2 col-md-5 px-0 label f-12">
                  <p class="f-12 text-grey mb-05">
                    Existing Universe
                    <span
                      class="px-1"
                      style="vertical-align: middle"
                      [matMenuTriggerFor]="menu"
                      ><img
                        src="../../../../assets/images/icons/info.svg"
                        alt=""
                    /></span>
                  </p>
                  <mat-menu #menu="matMenu" class="p-3">
                    <div class="row justify-content-center">
                      <div class="col text-center">
                        You can edit existing universe by <br />removing or
                        adding stocks to it .
                      </div>
                    </div>
                  </mat-menu>
                  <mat-form-field appearance="outline">
                    <mat-select
                      [multiple]="true"
                      formControlName="universes"
                      (openedChange)="closeDropdown($event, 'universe')"
                      (selectionChange)="selectionChange($event, 'universe')"
                    >
                      <mat-option
                        *ngFor="let universe of universes"
                        [value]="universe.name"
                        >{{ universe.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <div class="col-md-1  text-center align-self-center">
                  <img class="or-icon" src="../../../../../assets/images/icons/or.svg" />
                </div> -->
                <div class="col-md-2 px-0 label f-12 text-grey addMr">
                  <p class="f-12 text-grey mb-05">Sector</p>
                  <mat-form-field appearance="outline">
                    <mat-select
                      [multiple]="true"
                      formControlName="sectors"
                      (openedChange)="closeDropdown($event, 'sector')"
                      (selectionChange)="selectionChange($event, 'sector')"
                    >
                      <mat-option
                        *ngFor="let sector of sectors"
                        [value]="sector.name"
                        >{{ sector.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-1 text-center align-self-center">
                  <img
                    class="or-icon"
                    src="../../../../../assets/images/icons/or.svg"
                  />
                </div>
                <div class="col-lg-3 col-md-5 px-0 label f-12 text-grey">
                  <div>
                    <p class="f-12 text-grey mb-05">Search stock by name</p>
                  </div>
                  <div class="searchBox">
                    <mat-form-field appearance="outline" class="w-100">
                      <input
                        matInput
                        formControlName="search"
                        [matAutocomplete]="auto"
                        [formControl]="searchControl"
                        (input)="searchHandler($event)"
                      />
                      <mat-icon matSuffix>search</mat-icon>
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="displayFn"
                        (optionSelected)="optionSelected($event)"
                      >
                        <mat-option
                          *ngFor="let stock of searchResults"
                          [value]="stock"
                        >
                          {{ stock.stock_name }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-1 text-center align-self-center">
                  <img
                    class="or-icon"
                    src="../../../../../assets/images/icons/or.svg"
                  />
                </div>
                <div class="col-md-2 px-0 align-self-center">
                  <button
                    mat-button
                    class="theme-btn border-btn-grey mt-20 fw-400"
                  >
                    Bulk upload
                  </button>
                </div>
              </div>
            </div>
          </div>
          <mat-error *ngIf="showErr"
            >Please select more then 10 stocks to create your
            universe.</mat-error
          >
          <div class="row table_container" style="margin: 0%">
            <div class="table-con">
              <table
                mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef>
                    <img
                      class="pb-2"
                      src="../../../../assets/images/icons/add.svg"
                      alt=""
                    />
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{ i + 1 }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Stock Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.stock_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="marketCap">
                  <th mat-header-cell *matHeaderCellDef>Market cap (Cr)</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.market_cap }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="currentValue">
                  <th mat-header-cell *matHeaderCellDef>Current Value</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.share_price }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="peRatio">
                  <th mat-header-cell *matHeaderCellDef>PE Ratio</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.pe_ratio }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <img
                      src="../../../../assets/images/icons/delete.svg"
                      (click)="deleteRow($event, element)"
                    />
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns"
                  style="height: 40px"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
          <div class="row flex-wrap bottom_text" style="padding: 15px 15px 0">
            <div
              class="col-sm-8 px-0 align-self-center text-grey sm-text-center sm-mb-20"
            >
              Market cap and current value updated every once in a day, eveyday
              at evening 7:00 PM
            </div>
            <div
              class="col-sm-4 px-0 justify-content-end text-right sm-text-center"
            >
              <div class="btn-group">
                <button
                  mat-button
                  class="theme-btn border-btn"
                  type="button"
                  (click)="onCancelClick()"
                >
                  Cancel
                </button>
                <button mat-button type="submit" class="theme-btn primary-btn">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
