import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-register-popup',
  templateUrl: './register-popup.component.html',
  styleUrls: ['./register-popup.component.scss']
})
export class RegisterPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<RegisterPopupComponent>,
   ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  

  

}
