<div class="sucess-popup">
  <div class="text-center mt-30">
    <img src="../../../../../assets/images/icons/sucess.png" />
  </div>
  <div class="f-16 fw-400 text-darkgrey text-center mt-30">
    Great, you have successfully subscribed to Smart Stocks.

  </div>
<!--
  <div class="f-16 fw-400 text-darkgrey text-center mt-30">
    Your bank
    reference no. is <span class="fw-600"> {{ data?.bank_ref_no }} </span> and amount paid is <span class="fw-600">{{data?.amount}}</span> against order number <span class="fw-600"> {{data?.order_id}}</span><br />
    Let's start discovering Smart Stocks
  </div> -->
  <div class="f-17 fw-600 text-darkgrey text-start mt-30 col-md-10 offset-md-1 offfset-sm-0">
    Payment summary:
  </div>
  <div class="f-16 text-darkgrey text-start mt-10">

    <div class="row " style="align-items: baseline;">

      <div class="col-md-3 offset-md-1 offfset-sm-0  fw-600 ">Order Number</div>
      <div class="col-md-8">{{data.order_id}}</div>
      <div class="col-md-3 offset-md-1 offfset-sm-0  fw-600">Bank reference no</div>
      <div class="col-md-8">{{ data.bank_ref_no }}</div>
      <div class="col-md-3 offset-md-1 offfset-sm-0  fw-600">Amount paid</div>
      <div class="col-md-8">Rs. {{data.amount |number:"1.0-0"}}</div>
    </div>
  </div>

  <div class="row justify-center mt-30">
    <button (click)="close(true)" class="theme-btn primary-btn mb-20 f-12 w-50">
      Discover Smart Stocks
    </button>
  </div>
</div>
