import { SharedService } from 'src/app/common/services/shared.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpComponent } from '../otp/otp.component';
import { OnBoardingService } from '../../services/onboarding.service';
import { NotificationService } from 'src/app/services/notification.services';
import { PublicService } from 'src/app/modules/public/services/public.service';
import { environment } from 'src/environments/environment';
import 'intl-tel-input/build/css/intlTelInput.css';
import * as intlTelInput from 'intl-tel-input';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { RegisterPopupComponent } from '../register-popup/register-popup.component';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { error } from 'console';
import { PlayVideoPopupComponent } from 'src/app/modules/shared/play-video-popup/play-video-popup.component';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RegisterComponent implements OnInit, OnDestroy, DoCheck {
  registerForm: FormGroup;
  betaId = '';
  checked = false;
  showRequired = false;
  emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  mobileValidation = '^((\\+91-?)|0)?[0-9]{10}$';
  panValidation = '[A-Z]{5}[0-9]{4}[A-Z]{1}';
  product: any;
  public: any;
  isReferer: any = {
    status: false,
    referrer_id: null,
    lead_id: null,
  };
  hashedKey: string;
  @ViewChild('phoneNumber') phoneNumber: ElementRef;
  intlTelInput: any;
  exmobileNo = '';
  productSmartbetaAGdetails: any;
  selectedCountryCode: any;
  constructor(
    public _router: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private onBoardingService: OnBoardingService,
    private notifyService: NotificationService,
    private publicService: PublicService,
    private sharedService: SharedService,
    private fb: FormBuilder
  ) {
    if (sessionStorage.getItem('agreementSmartBeta')) {
      sessionStorage.removeItem('agreementSmartBeta')
    }
    if (sessionStorage.getItem('aID')) {
      sessionStorage.removeItem('aID')
    }
    if (sessionStorage.getItem('data')) {
      sessionStorage.removeItem('data')
    }
    this.patchform$.subscribe((res: any) => {
      console.log('res');
    });

  }
  ngDoCheck(): void {
  }
  passwordHasLowercase: boolean;
  passwordHasUppercase: boolean;
  passwordHasNumber: boolean;
  passwordHasSpecialChar: boolean;
  passwordIsLongEnough: boolean;
  passwordHasNoSequentialNumbers: boolean;
  passwordIsNotUsername: boolean;
  passwordIsNotPreviouslyUsed: boolean;
  checkvalidation(e:any){
    let password = e.target.value;
    this.passwordHasLowercase = /[a-z]/.test(password);
    this.passwordHasUppercase = /[A-Z]/.test(password);
    this.passwordHasNumber = /\d/.test(password);
    this.passwordHasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    this.passwordIsLongEnough = password.length >= 8;
    this.passwordHasNoSequentialNumbers = !/(012|123|234|345|456|567|678|789)/.test(password);
    // this.passwordIsNotUsername = password !== this.username;
    // this.passwordIsNotPreviouslyUsed = !this.previousPasswords?.includes(password);
  }
  openVideo(){
    
    let videoLink = 'https://www.youtube.com/embed/cFPk-WsjL0c?autoplay=1'
    this.dialog.open(PlayVideoPopupComponent, {
      data: { videoLink: videoLink },
      width:'800px',
      minHeight:'460px',
      height:'100%',
      maxHeight:'460px',
      panelClass:'videoPopupContainer'
    })
  }
  isSubDomain() {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    if (subdomain === 'richmee') {
      return true
    } else {
      return false
    }
  }
  domain = () => {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    if (subdomain === 'richmee') {
     

    } else {

    }
  }
  ngOnInit(): void {
    this.intialize();

  }

  smartBetaReffererID: any = null;

  subData: any;

  intialize() {
    this._router.paramMap.subscribe((parameter: any) => {
      this.product = parameter.params.product
        ? parameter.params.product
        : 'smartvalues';
    });
    if (sessionStorage.getItem('userType')) {
      sessionStorage.removeItem('userType')
    }
    if (this.product === 'smartbeta') {
      sessionStorage.setItem('userType', 'smartbeta')
    }
    this.registerFormControls();
  }

  patchForm(formData: any) {

    let mob: any = formData.subscriber_mobile.split('-');
    this.registerForm.patchValue({
      fname: formData.subscriber_first_name,
      mname: formData?.subscriber_middle_name || '',
      lname: formData.subscriber_last_name,
      email: formData.subscriber_email,
      pan: formData.subscriber_pan,
      amount: formData?.public___subscriber_products[0]?.investment_amount,
      fee_amount: formData?.public___subscriber_products[0]?.fee_amount,
    });
    console.log(this.registerForm.value)
    this.productSmartbetaAGdetails = formData?.public___subscriber_products[0];
    this.registerForm.controls['fname'].disable();
    this.registerForm.controls['email'].disable();
    this.registerForm.controls['lname'].disable();
    this.registerForm.controls['mname'].disable();
    this.registerForm.controls['mobile'].disable();
    this.registerForm.controls['fee_amount'].disable();
    this.registerForm.controls['amount'].disable();

    if (this.isVerified) {
      this.registerForm.controls['pan'].disable();
    }
    setTimeout(() => {
      this.manualLoadMobileForm();
      setTimeout(() => {
        this.setMobileInput(mob);
      }, 500);
    }, 1000);

  }

  setMobileInput(mob: any) {
    console.log(mob)
    let dialcode: any = this.intlTelInput.countries.find(
      (ele: any) => mob[0].replace('+', '') === ele.dialCode
    ).iso2;
    this.intlTelInput.setCountry(dialcode);
    this.registerForm.patchValue(
      {
        mobile: mob[1],
      }
    );
  }
  registerLead(params: any) {
    this.isReferer.status = true;
    this.isReferer.referrer_id = params.referrer_id;
    this.onBoardingService
      .registerLead({
        docs: {
          referrer_id: params.referrer_id,
          product: this.product,
        },
      })
      .subscribe(
        (res: any) => {
          let response = res[0]?.leads;
          if (response.err === '-') {
            this.isReferer.lead_id = response.lead[0]['lead_id'];
          } else {
            this.notifyService.showError(`Fail :Try Again`, response.err);
          }
        },
        (e: any) => {
          this.notifyService.showError(`Fail :Try Again`, '');
        }
      );
  }

  registerFormControls() {
    this.registerForm = this.fb.group(
      {
        fname: new FormControl('', [Validators.required]),
        lname: new FormControl('', [Validators.required]),
        mname: new FormControl('', [Validators.required]),
        email: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.pattern(this.emailValidation),
          ],
        }),
        mobile: new FormControl('', [Validators.required]),
        pan: new FormControl('', [
          Validators.required,
          Validators.pattern(this.panValidation),
        ]),
        amount: new FormControl(
          '',
          this.product === 'smartbeta' ? [Validators.required] : []
        ),
        fee_amount: new FormControl(
          '',
          this.product === 'smartbeta' ? [Validators.required] : []
        ),
        password: new FormControl('', {
          validators:
            this.product !== 'smartbeta'
              ? [(Validators.required, Validators.minLength(6))]
              : [],
        }),
        cpassword: new FormControl('', {
          validators:
            this.product !== 'smartbeta'
              ? [(Validators.required, Validators.minLength(6))]
              : [],
        }),
      },
      {
        validator: ConfirmedValidator('password', 'cpassword'),
      }
    );
  }

  patchform$ = new Subject<any>();

  ngAfterViewInit() {
    this.public = this.publicService.isPublic;
    this._router.queryParams.subscribe((params: any) => {
      if (this.product === 'smartbeta') {
        this.onBoardingService
          .get_smartbeta_subscriber(params.id)
          .subscribe((res: any) => {
            this.smartBetaReffererID = params.id;
            this.subData = res[0].subscriber[0];

            setTimeout(() => {
              if (this.subData.subscriber_pan) {
                this.isVerified = true
                this.patchForm(this.subData);

              } else {
                this.patchForm(this.subData);
              }

            }, 1500);
          });
      }

      if (params.referrer_id) {
        this.registerLead(params);
      } else if (this.isSubDomain()) {
        const host = window.location.host;
        const subdomain = host.split('.')[0];
        if (subdomain === 'richmee') {
          this.publicService.get_referrer_subdomain({
            "referrer_id": subdomain
          }).subscribe((res:any) => {
           let  refId=  res?.[0]?.referrers?.[0]?.referrer_id||''
            this.registerLead({referrer_id:window.btoa(refId)});
          })
    
        } else {
    
        }
       
       
      }
    });
  }
  manualLoadMobileForm() {
    this.public = this.publicService.isPublic;
    this.intlTelInput = intlTelInput(this.phoneNumber?.nativeElement, {
      utilsScript: 'assets/scripts/intltelinpututils.js',
      initialCountry: 'IN',
      nationalMode: false,
      formatOnDisplay: true,
      separateDialCode: true,
      autoHideDialCode: true,
      preferredCountries: ['in'],
      allowDropdown: false,

      // autoPlaceholder:'aggressive'
      /*  customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
      return "e.g. " + selectedCountryPlaceholder;
    }, */
    });
    this.selectedCountryCode =
      this.intlTelInput.getSelectedCountryData().dialCode;

    this.registerForm.controls.mobile.setValidators([
      internationalMobileValidator(this.intlTelInput),
    ]);
    console.log(218);

  }

  get f() {
    return this.registerForm.controls;
  }

  public errorHandling = (control: string, error: string) => {
    return this.registerForm.controls[control].hasError(error);
  };

  onSubmit() {
    if (this.registerForm.valid) {
      this.createFormData();
      if (this.product === 'smartbeta') {
        this.onBoardingService
          .generateOTP(this.registerForm.controls.mobile.value)
          .subscribe((res: any) => {
            if (!environment.production) {
              if (res.status === 'success') {
                this.openOtpDialog('old');
              } else {
                this.notifyService.showError(`Fail : ${res.message}`, '');
              }
            } else {
              this.openOtpDialog('old');
            }
          });
      } else {
        if(!this.passwordHasLowercase || !this.passwordHasUppercase || !this.passwordHasNumber || !this.passwordHasSpecialChar || !this.passwordIsLongEnough || !this.passwordHasNoSequentialNumbers){
       
          return
        }
        this.onBoardingService
          .checkSubscriber({
            email: this.registerForm.controls.email.value,
            mobile: this.transformMobileNumber(),
          })
          .subscribe(
            (resp: any) => {
              if (resp[0].subscriber[0].subscriber_count === 0) {
                this.onBoardingService
                  .generateOTP(this.registerForm.controls.mobile.value)
                  .subscribe((res: any) => {
                    if (!environment.production) {
                      if (res.status === 'success') {
                        this.openOtpDialog('new');
                      } else {
                        this.notifyService.showError(
                          `Fail : ${res.message}`,
                          ''
                        );
                      }
                    } else {
                      this.openOtpDialog('new');
                    }
                  });
              } else {
                if (this.product === 'smartbeta') {
                  this.onBoardingService
                    .generateOTP(this.registerForm.controls.mobile.value)
                    .subscribe((res: any) => {
                      if (!environment.production) {
                        if (res.status === 'success') {
                          this.openOtpDialog('old');
                        } else {
                          this.notifyService.showError(
                            `Fail : ${res.message}`,
                            ''
                          );
                        }
                      } else {
                        this.openOtpDialog('old');
                      }
                    });
                } else {
                  this.notifyService.showError(
                    `Fail :Subscriber already exists`,
                    ''
                  );
                }
              }
            },
            (e: any) => {
              this.notifyService.showError(`Fail :Try Again`, '');
            }
          );
      }
    }
  }
  openOtpDialog(type: any) {
    let data: any = {
      product: this.product,
      mobile: this.transformMobileNumber().replace('+', ''),
      type: type,
    };
    if (this.product === 'smartbeta') {
      data.detail = {
        subscriber_email: this.registerForm.controls.email.value,
        subscriber_pan: this.registerForm.controls.pan.value,
        subscriber_first_name: this.registerForm.controls.fname,
        subscriber_middle_name: this.registerForm.controls.mname,
        subscriber_last_name: this.registerForm.controls.lname,

        id: this.smartBetaReffererID,
      };
      console.log(this.productSmartbetaAGdetails);
      data.agreementValues = this.productSmartbetaAGdetails;
    }
    const dialogRef = this.dialog.open(OtpComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => { });
  }
  transformMobileNumber() {
    let dialCode = `+${this.intlTelInput.getSelectedCountryData().dialCode}`;
    let mobileNumber = this.registerForm.controls.mobile.value;
    return [dialCode, mobileNumber].join('-');
  }
  createFormData() {
    let docs: any = this.onBoardingService.createSubscriberData.docs[0];
    this.onBoardingService.betaInvestment = this.registerForm.value.amount;
    let registerFormControls = this.registerForm.controls;

    docs.subscriber_email = registerFormControls.email.value;
    docs.subscriber_first_name = registerFormControls.fname.value;
    docs.subscriber_middle_name = registerFormControls.mname.value;
    docs.subscriber_last_name = registerFormControls.lname.value;
    docs.subscriber_pan = registerFormControls.pan.value;
    docs.subscriber_mobile = this.transformMobileNumber();
    docs['public.subscriber_products'][0].product_name = this.product;

    // docs.subscriber_mobile = this.registerForm.controls.mobile.value.e164Number;
    docs.joining_date = new Date().toISOString().split('T')[0];
    if (this.product !== 'smartbeta') {
      docs.password = this.createHashedKey();
    }

    if (this.isReferer.status) {
      docs.lead_id = this.isReferer.lead_id;
      docs.referrer_id = this.isReferer.referrer_id;
    } else {
      docs.lead_id = 0;
      docs.referrer_id = '';
    }
  }

  createHashedKey() {
    let secretKey = this.registerForm.controls.password.value;

    this.hashedKey = window.btoa(secretKey);
    return this.hashedKey;
  }

  login() {
    this.router.navigateByUrl('/auth/login');
  }

  changePreferredCountries() {
    // this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  goLandingPage() {
    let userLogged = localStorage.getItem('user');
    if (!userLogged) {
      const host = window.location.host;
      const subdomain = host.split('.')[0];

      if (subdomain === 'richmee') {

        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl('/')
      }

    }
  }
  ngOnDestroy() {
    this.intlTelInput?.destroy();
  }

  tnc() {
    this.dialog.open(RegisterPopupComponent, {
      width: '500px',
    });
  }

  isVerified = false
  verifyPan(e: any) {
    e.preventDefault()
    const panform: any = this.registerForm.controls['pan']
    this.registerForm.controls['pan'].setErrors(null)
    if (panform.value && panform.valid) {
      this.onBoardingService.validatePan({
        "pan": panform.value
      }).subscribe({
        next: (res: any) => {
          console.log(res)
          const { name } = res
          this.isVerified = true
          let splitName = name.split(' ')
          this.registerForm.controls['fname'].patchValue(splitName[0])
          this.registerForm.controls['mname'].patchValue(splitName.slice(1, splitName.length - 1).join(" "));
          this.registerForm.controls['lname'].patchValue(splitName[splitName.length - 1])
          this.registerForm.controls['pan'].setErrors(null)
          this.registerForm.controls['pan'].disable()
          this.registerForm.controls['fname'].disable();
          this.registerForm.controls['lname'].disable();
          this.registerForm.controls['mname'].disable();
          setTimeout(() => {
            this.registerForm.controls['pan'].setErrors(null)
            this.manualLoadMobileForm();
            setTimeout(() => {
              this.setMobileInput(this.subData.subscriber_mobile?.split('-'))

            }, 1000);
          }, 500)


        },
        error: (e: any) => {
          this.registerForm.controls['pan'].setErrors(null)
          this.registerForm.controls['pan'].setErrors({ verify: e?.error?.error || '' })
        }
      })

    } else {
      this.registerForm.controls['pan'].setErrors({ required: true })
    }

  }
  convertToLowercase(event: any) {
    event.target.value = event.target.value.toLowerCase();
  }
}
export function ConfirmedValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };


}
export function internationalMobileValidator(
  internationalInput: any
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return internationalInput.isValidNumber() ? null : { invalidNumber: true };
  };
}
