import { Component } from '@angular/core';

export interface PeriodicElement {
  stocks: string;
  weight: string;
  // pouch: number;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { stocks: 'Adani Ports and Special Economic..', weight: '18%' },
  { stocks: 'Sun Pharmaceutical Industries Ltd', weight: '13%' },
  { stocks: 'Oil and Natural gas Corporation Ltd', weight: '11%' },
  { stocks: 'Housing Development Finance Co', weight: '10%' },
  { stocks: 'Power Grid Corporation of India', weight: '10%' },
  { stocks: 'Grasim Industries Ltd', weight: '09%' },
  { stocks: 'Ather Auto pvt ltd', weight: '06%' }
];

@Component({
  selector: 'app-review-history',
  templateUrl: './review-history.component.html',
  styleUrls: ['./review-history.component.scss']
})
export class ReviewHistoryComponent {

  displayedColumns: string[] = ['stocks', 'weight'];
  dataSource = ELEMENT_DATA;

  // constructor() { }

  // ngOnInit(): void {
  // }

}
