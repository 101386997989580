import { WarningKycRpPopupComponent } from './../../../../warning-kyc-rp-popup/warning-kyc-rp-popup.component';
import { SharedService } from 'src/app/common/services/shared.service';
import { PublicService } from 'src/app/modules/public/services/public.service';
import { Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.services';
import { PlayVideoPopupComponent } from 'src/app/modules/shared/play-video-popup/play-video-popup.component';
import { PlansService } from 'src/app/modules/investment-pouches/services/plans.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-create-pouch-popup',
  templateUrl: './create-pouch-popup.component.html',
  styleUrls: ['./create-pouch-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePouchPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private mdDialogRef: MatDialogRef<CreatePouchPopupComponent>,
    public publicService: PublicService,
    private shs: SharedService,
    public dialog: MatDialog,
    private notifyService: NotificationService,
    private plansService: PlansService,
    private matDialogRef: MatDialogRef<CreatePouchPopupComponent>,
  ) {
    this.paymentUrl = environment.paymentGatewayUrl;
  }

  paymentUrl: any;
  paymentData: any = {
    access_code: '',
    encRequest: '',
    order_id: '',
  };
  code: any = null;
  subscriptionList: any = [];
  subscriptionListC: any = [];
  selected: any;
  error: boolean = false;
  errortext: any = '';
  disabled = false;
  userInfo: any;
  expiredDate:any;
  agreementSigned:any;
  getCountry: any;
  getStateName: any;
  form!: FormGroup;
  getDetails: any;

  @ViewChild('myForm', { static: true }) public ngForm: ElementRef =
    {} as ElementRef;
    ShowRiskPopup:boolean = false;

  ngOnInit(): void {
    this.agreementSigned=this.data?.agreementSign
    this.userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    this.subscriptionList = this.data.data;
    this.subscriptionListC = this.data.data;
    this.selected = 0;
    this.expiredDate=this.data.expiredDates
    console.log(this.data)
    if(this.agreementSigned==='Yes'){
setTimeout(()=>{
  this.makePAyment('makePayment')
},2000)
    }

    this.form = new FormGroup({
      address: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required, Validators.pattern('^[1-9][0-9]{5}$')]),
    });

    const data = {
      "subscriber_id":  JSON.parse(localStorage.getItem('user') || '{}').id
    }
    this.plansService.getBillingDetails(data).subscribe((res: any) => {
      this.getDetails = res[0].sub_bill_addrs;
      console.log(this.getDetails[0].billing_address)
      this.form.patchValue({
        "address": this.getDetails[0].billing_address,
        "country": this.getDetails[0].billing_country,
        "state": this.getDetails[0].billing_state,
        "city": this.getDetails[0].billing_city,
        "pincode": this.getDetails[0].billing_pincode,
      });
    })
    this.plansService.getCountry().subscribe((res: any) => {
      this.getCountry = res[0].country_name;
    })
    this.plansService.getState().subscribe((res: any) => {
      this.getStateName = res[0].country_name;
    })
  }
  public close(value: string) {
    if (value === 'subscribe') {
      if (this.shs.checkSubscriptionActive()) {
        this.startPouchCreation();
      } else {
        const dialog = this.dialog.open(WarningKycRpPopupComponent);
        this.mdDialogRef.close();
      }
    } else {
      this.mdDialogRef.close(value);
    }
  }
  openVideo(){
    let videoLink = 'https://www.youtube.com/embed/GiSYIj4Jomw?autoplay=1'
    this.dialog.open(PlayVideoPopupComponent, {
      data: { videoLink: videoLink },
      width:'800px',
      minHeight:'460px',
      height:'100%',
      maxHeight:'460px',
      panelClass:'videoPopupContainer'
    })
  }
  getText(d: any) {
    if (d === 'HALF-YEARLY') {
      return '6 months';
    }
    if (d === 'YEARLY') {
      return 'year';
    }
    if (d === 'MONTHLY') {
      return 'month';
    }
    return '-';
  }
  validatePromoCode(value: any) {
    let body = {
      // subscription_amount:
      //   this.subscriptionList[this.selected].subscription_amount,
      // promo_code: value,
      promo_code: value,
      sv_feature_name: 'create_pouch',
      subscription_freq: this.subscriptionList[this.selected].subscription_freq,
    };
    if (value != '' && !this.disabled) {
      this.shs.showLoader$.next(true);
      this.publicService.verifyPromoCodessvf(body).subscribe(
        (result: any) => {
          let res = result[0].Results[0];

          if (res.promo_status === 'Active') {
            this.error = false;
            this.errortext = '';
            this.changeAmount(res.discount_amount, res.promo_code);
            this.disabled = true;
            this.shs.showLoader$.next(false);
          }
          //  else {
          //   this.error = true;
          //   this.errortext = 'Promo code is expired';
          //   this.changeAmount(
          //     this.subscriptionList[this.selected].subscription_amount,
          //     null
          //   );
          // }
        },
        (e) => {
          this.shs.showLoader$.next(false);
          this.notifyService.showError('', e.error.error.message);
        }
      );
    }
  }
  allowNumber(event: any) {
    const input = event.key;
    const isDigit = /^\d$/.test(input);
    if (!isDigit) {
      event.preventDefault();
    }
  }
  
  onPasteAllowNumber(event: any) {
    const isAllDigits = /^\d+$/.test(event.clipboardData.getData('Text'));
    if (!isAllDigits) {
      event.preventDefault();
    }
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  changeAmount(amount: any, promo: any) {
    let discountedAmount: any = [];
    this.subscriptionList.map((ele: any) => {
      let E = ele;
      E.subscription_amount = E.subscription_amount - amount;
      E.promo = promo || null;
      discountedAmount.push(E);
    });
    this.subscriptionList = discountedAmount;
    for (let i of this.subscriptionList) {
      let r = document
        .getElementById(`radio-sub${i.rowIndex}`)
        ?.getElementsByClassName('mat-radio-label-content')[0] as HTMLElement;
      r.innerText = `Subscribe to this feature at Rs ${
        i.subscription_amount
      } for ${this.getText(i.subscription_freq)}`;
    }
  }
  clear() {
    this.code = '';
    this.disabled = false;
  }
  redirectToPayment(data: any) {
    let encRequest = document.getElementById('encRequest') as HTMLInputElement;
    let access_code = document.getElementById(
      'access_code'
    ) as HTMLInputElement;
    encRequest.value = data.encRequest;
    access_code.value = data.access_code;
    this.paymentData = data;

    const interval = setInterval(() => {
      if (this.paymentData.access_code && this.paymentData.encRequest) {
        console.log(JSON.stringify(this.publicService.agreementDetailsNew));
        sessionStorage.setItem(
          'pouch',
          JSON.stringify(this.publicService.agreementDetailsNew)
        );
        this.ngForm.nativeElement.submit();

        clearInterval(interval);
      }
    }, 100);
  }

  startPouchCreation() {
    // this.publicService.agreementFrom = 'from-create';
    this.publicService.agreementDetailsNew = {
      new: this.subscriptionList[this.selected],
      orginal: this.subscriptionListC[this.selected],
    };
    console.log(this.subscriptionList[this.selected].subscription_freq);
    // this.mdDialogRef.close(value);

    this.publicService
      .intiatePaymentGatewaysvf({
        subscriber_id: this.userInfo.id,
        redirect_url: `${window.location.host}/pouch-creation`,
        subscription_freq:
          this.subscriptionList[this.selected].subscription_freq,
        sv_feature_name: 'create_pouch',
        promo_code: this.code,
      })
      .subscribe((res: any) => {
        if (res) {
          this.redirectToPayment(res);
        }
      });
  }
  subscribe() {
    this.form.markAllAsTouched();
    if(this.form.value != '') {
      let stateCode = this.getStateName.find((e:any)=>e.state_name === this.form.controls['state'].value).state_code
      const data = {
        "subscriber_id": JSON.parse(localStorage.getItem('user') || '{}')?.id,
        "docs": {
          "billing_address": this.form.controls['address'].value,
          "billing_country": this.form.controls['country'].value,
          "billing_state": this.form.controls['state'].value,
          "billing_city": this.form.controls['city'].value,
          "billing_pincode": this.form.controls['pincode'].value,
          'billing_state_code':stateCode,
        }
       }
       if(this.form.valid){
        this.plansService.updateBillingDetails(data).subscribe((res: any) => {

          this.openAgreement('subscriber',data.docs);
        })
       }

  }
}
  openAgreement(value: string,data?:any) {

    let pouchCreation ={
      subscription_amount:this.subscriptionList[this.selected].subscription_amount,
      subscription_freq : this.subscriptionList[this.selected].subscription_freq,
      sv_feature_id: this.subscriptionList[this.selected].sv_feature_id,
      sv_feature_name: this.subscriptionList[this.selected].sv_feature_name,
    }
    sessionStorage.setItem('pouchCreation', JSON.stringify(pouchCreation))
    sessionStorage.setItem('billings', JSON.stringify(data))
 const selectedOption =this.subscriptionList.map((s: { subscription_amount: number, subscription_freq: string }) => s.subscription_amount + ' for ' + this.getText(s.subscription_freq))[this.selected]
  this.publicService.mapSubscriberData.subscription_freq =selectedOption
  this.publicService.mapSubscriberData.subscription = data
    this.mdDialogRef.close(value)
  }
  makePAyment(value: string){
    this.mdDialogRef.close(value)
  }
  closed(){
    this.mdDialogRef.close()
  }
  renewNow(value: string){
    this.form.markAllAsTouched();
    let pouchCreation ={
      subscriber_id: this.userInfo.id,
      subscription_amount:this.subscriptionList[this.selected].subscription_amount,
      subscription_freq : this.subscriptionList[this.selected].subscription_freq,
      sv_feature_id: this.subscriptionList[this.selected].sv_feature_id,
      sv_feature_name: this.subscriptionList[this.selected].sv_feature_name,

    }
    let stateCode = this.getStateName.find((e:any)=>e.state_name === this.form.controls['state'].value).state_code
    const data = {
      "subscriber_id": JSON.parse(localStorage.getItem('user') || '{}')?.id,
      "docs": {
        "billing_address": this.form.controls['address'].value,
        "billing_country": this.form.controls['country'].value,
        "billing_state": this.form.controls['state'].value,
        "billing_city": this.form.controls['city'].value,
        "billing_pincode": this.form.controls['pincode'].value,
        'billing_state_code':stateCode,
      }
     }
     if(this.form?.valid){
      this.plansService.updateBillingDetails(data).subscribe((res: any) => {
        sessionStorage.setItem('pouchCreation', JSON.stringify(pouchCreation))
        this.mdDialogRef.close(value);
        console.log(pouchCreation)
      })
     }



  }
}
