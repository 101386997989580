import { WarningKycRpPopupComponent } from './../../../warning-kyc-rp-popup/warning-kyc-rp-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.services';
import { environment } from 'src/environments/environment';
import { PouchCompositionComponent } from '../../investment-pouches/pouch-composition/pouch-composition.component';
import { PouchService } from '../../investment-pouches/services/pouch.service';
import { CommonDialogWaitingComponent } from '../common-dialog/common-dialog.component';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { SharedService } from 'src/app/common/services/shared.service';
import { RenewSubscriptionPopupComponent } from '../renew-subscription-popup/renew-subscription-popup.component';
import { PublicService } from '../../public/services/public.service';
import { PaymentLoaderComponent } from '../../navigation/payment-loader/payment-loader.component';
import * as _moment from 'moment';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { SubscriptionRenewPopupComponent } from '../subscription-renew-popup/subscription-renew-popup.component';
import { DecimalPipe } from '@angular/common';
import { AmountUpdatePopupComponent } from './amount-update-popup/amount-update-popup.component';
import { SucessPopupComponent } from '../../public/components/sucess-popup/sucess-popup.component';
import { MapSubscriberComponent } from '../../investment-pouches/map-subscriber/map-subscriber.component';
import { RiskProfileComponent } from '../risk-profile/risk-profile.component';
import { PlayVideoPopupComponent } from '../play-video-popup/play-video-popup.component';

export interface AmountUpdatePopupData {
  pouchId: string | number;
  pouchFrequency: string;
  subscriberInvestmentAmount: string;
  subscriberID: string;
  min_investment:any

}
@Component({
  selector: 'app-banner-subscription-details',
  templateUrl: './banner-subscription-details.component.html',
  styleUrls: ['./banner-subscription-details.component.scss'],
  providers: [DecimalPipe]
})
export class BannerSubscriptionDetailsComponent implements OnInit {
  showRpWarning = false
  @Input() calculateSubFee: boolean;
  @Input() agreementStatus: any;
  @Input() PouchSubscriberStocksReturns: any;
  @Input() calculatedMinAmount: any;
  @Input() pouchDetail: any;
  @Input() tabIndex: any;
  @Input() byPassToPayment: any;
  @Input() subscriptionStatus: any;
  @Input() inputBannerType: any;
  @Output() updateTabeleHandler = new EventEmitter();
  @Output() bypassToPaymentHandler = new EventEmitter();
  @Output() tabIndexChanger = new EventEmitter();
  @Input() investmentChange: boolean;
  @Input()holdStockLength:any;
  gatewayInstance = new window.scDK({
    // ! Use valid gateway name here
    gateway: 'smartvalues',
    /*
     * ! Use valid auth token
     * Details - https://developers.gateway.smallcase.com/docs/getting-started#broker-identification
     */
    smallcaseAuthToken: environment.smallcaseAuthToken,
    config: {
      // Should allow after market orders?
      amo: true,
    },
  });
  bannerType: any = 0;
  sub_fee: any;
  isPending = false;
  user_notSubscribed = true;
  user_Subscribed_notInvested = false;
  user_subscribed_andInvested = false;
  subscriptionsList: any;
  minimumInvestmentAmountCopy: any;
  min_investment: number = 0;
  subscriberSubscription: any;
  intialLoad = true;
  stockLength:any;
  constructor(
    public parentcomp: MapSubscriberComponent,
    public router: Router,
    public pouchService: PouchService,
    private notifyService: NotificationService,
    private http: HttpClient,
    public dialogRef: MatDialog,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private publicService: PublicService,
    private subscriberService: SubsciberService,
    private decimalpipe: DecimalPipe
  ) {
    this.stockLength = subscriberService
  }
  ngOnChanges(changes: any): void {
    if (this.intialLoad) {
      this.intialLoad = false;
    } else {
      this.intiate();
    }
  }
  ngOnInit(): void {
    this.intiate();
  }
  intiate() {
    this.min_investment = this.pouchDetail.min_amt;
    this.minimumInvestmentAmountCopy = this.pouchDetail.min_amt;
    this.getSubscriptionPlanList();
    if (this.pouchDetail.subscribed === 'Y') {
      this.bannerType = this.inputBannerType;
      if (this.bannerType === 1) {
        this.subscriberSubscription =
          this.inputBannerType == 1 ? this.subscriptionStatus : [];
      } else if (this.bannerType === 2) {
        this.subscriberSubscription =
          this.inputBannerType == 2 ? this.subscriptionStatus : [];
      }

      this.daysLeftForRenewal();
    } else {
      this.bannerType = 0;
    }

    this.getSubscriptionPlanList();
  }
  openVideo(){
    let videoLink = 'https://www.youtube.com/embed/iBLkEldjdgU?autoplay=1'
    this.dialog.open(PlayVideoPopupComponent, {
      data: { videoLink: videoLink },
      width:'800px',
      minHeight:'460px',
      height:'100%',
      maxHeight:'460px',
      panelClass:'videoPopupContainer'
    })
  }
  getminInvestmentAmount() {
    let data: any = {
      pouch_id: this.pouchDetail.id,
      stock_price_date: localStorage
        .getItem('stock_price_uploaded_date')
        ?.split('T')[0],
    };
    this.pouchService.getPouchStocksReturns(data).subscribe(
      (response: any) => {
        let returns: any =
          response[0].Results[0].return_data.pouch_stocks_returns;
        let sumArr: number[] = [];
        if (returns) {
          for (let data of returns) {
            if (!data.sell_date) {
              sumArr.push(data.close_price * data.stock_tf_count);
            }
          }
          let sum = 0;
          for (let i = 0; i < sumArr.length; i++) {
            sum += sumArr[i];
          }

          this.min_investment = Number(Math.round(sum).toFixed(2));
        }
        this.getSubscriptionPlanList();
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );

    // this.getSubscriptionPlanList();
  }
  getSubscriptionPlanList() {

    this.subscriptionsList = this.pouchDetail.public___pouch_subscriptions[0]
      .subscription_freq
      ? this.pouchDetail.public___pouch_subscriptions
      : [];
    let type = this.subscriptionsList.find((ele: any) => ele.rowIndex == 1);


    if (Object.keys(this.agreementStatus).length > 0) {
      this.sub_fee = `${this.decimalpipe.transform(this.agreementStatus[0].public___subscriber_investments[0].subscription_amount_final, '1.0-0')}/${this.agreementStatus[0].public___subscriber_investments[0].subscription_freq}`
    } else {
      if (this.calculateSubFee) {
        this.sub_fee = `${Math.round(
          this.pouchDetail.public___pouch_subscriptions[0]
            .subscription_amount_final
        ).toFixed(0)} / ${type?.subscription_freq} `;
        return;
      } else {
        this.sub_fee = type?.subscription_freq
          ? `${this.getamount(type?.subscription_freq)} / ${type?.subscription_freq
          } `
          : '-';
      }
    }
  }
  getamount(type: any) {
    let getsubscriptiondata = this.subscriptionsList?.find(
      (ele: any) => ele.subscription_freq === type
    );
    if (getsubscriptiondata?.subscription_type === 'MAX') {
      let value = Math.max(
        (this.min_investment * getsubscriptiondata?.subscription_perc) /
        (100 * Number(type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)),
        getsubscriptiondata?.subscription_amount
      );

      return Math.round(value).toFixed(2) || 0;
    }
    if (getsubscriptiondata?.subscription_type === 'MIN') {
      let value = Math.min(
        (this.min_investment * getsubscriptiondata?.subscription_perc) /
        (100 * Number(type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)),
        getsubscriptiondata?.subscription_amount
      );

      return Math.round(value).toFixed(2) || 0;
    }
    if (getsubscriptiondata?.subscription_type === 'BOTH') {
      let value =
        (this.min_investment * getsubscriptiondata?.subscription_perc) /
        (100 *
          Number(type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)) +
        getsubscriptiondata?.subscription_amount;
      return Math.round(value).toFixed(2) || 0;
    }
    return this.minimumInvestmentAmountCopy;
  }
  checkSubscriptionActive() {
    let user = JSON.parse(localStorage.getItem('user') || '{}').attributes;
    if (user.kyc_date !== '' && user.risk_profile !== 'PENDING') {
      return true;
    } else {
      return false;
    }
  }
  checkSubscriptionEligibility(userRP: string, pouchRP: string) {
    // Define risk profile levels
    const riskProfiles = ["low", "moderate", "high"];

    // Check if the provided risk profiles are valid
    if (!riskProfiles.includes(userRP.toLowerCase()) || !riskProfiles.includes(pouchRP.toLowerCase())) {
      return 'invalid';
    }

    // Assign numerical values to risk profiles for comparison
    const userRPValue = userRP.toLowerCase();
    const pouchRPValue = pouchRP.toLowerCase();

    // Check eligibility based on risk profiles
    if (userRPValue === pouchRPValue) {
      return 'same';
    } else {
      return "notSame";
    }
  }
  subscribe() {
    //check for user risk
    //  this.dialog.open(RiskProfileComponent)
    let user: any = localStorage.getItem('user')
    let parsedUser: any = JSON.parse(user || '{}')

    let rp = this.checkSubscriptionEligibility(parsedUser.attributes.risk_profile, this.pouchDetail.risk_profile)
    if (!this.showRpWarning && rp == 'notSame') {
      const dialog = this.dialog.open(RiskProfileComponent, { data: [parsedUser.attributes.risk_profile, this.pouchDetail.risk_profile, this.pouchDetail.heading_text] })
      dialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.showRpWarning = true
          this.continueSubscribe()
        } else {
          // this.router.navigateByUrl('/investment')
        }
      })

    } else {
      this.continueSubscribe()
    }


  }
  continueSubscribe() {
    if (this.checkSubscriptionActive()) {
      if (localStorage.getItem('user')) {
        if (this.tabIndex == 0) {

          this.tabIndexChanger.emit(1);
        } else {
          if (this.byPassToPayment) {
            this.bypassToPaymentHandler.emit(this.byPassToPayment);
          } else {
            this.router.navigateByUrl(
              `/subscription-plan/${this.pouchDetail.id}`
            );
          }
        }
      } else {
        this.router.navigateByUrl(`/auth/login`);
      }
    } else {
      const dialog: any = this.dialog.open(WarningKycRpPopupComponent, {
        data: {
          message: 'Error in upload KYC.. Please upload',
          title: 'Error',
          status: 'Fail',
        },
        height: '18rem',
        width: '50rem',
      });
    }
  }
investBtnDisable =false
  investSmallcase() {

    if (this.tabIndex == 0) {

      this.tabIndexChanger.emit(1);
      this.investBtnDisable=false
    } else {
      this.investBtnDisable=true
      this.pouchService
        .getTransactionId({
          pouch_id: this.pouchDetail.id,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
        })
        .subscribe(
          (response: any) => {
            let id = response.data.transactionId;
            let error = response.errors;

            if (!error) {
              this.gatewayInstance
                .triggerTransaction({
                  transactionId: id,
                })
                .then(
                  (txnResponse: any) => {
                    let body = {
                      pouch_id: this.pouchDetail.id,
                      txn_id: id,
                      from_date: new Date(
                        new Date(
                          localStorage
                            .getItem('stock_price_uploaded_date')
                            ?.split('T')[0] || ''
                        ).setDate(
                          new Date(
                            localStorage
                              .getItem('stock_price_uploaded_date')
                              ?.split('T')[0] || ''
                          ).getDate() - 7
                        )
                      )
                        .toISOString()
                        .split('T')[0],
                      to_date: localStorage
                        .getItem('stock_price_uploaded_date')
                        ?.split('T')[0],
                      subscriber_id: JSON.parse(
                        localStorage.getItem('user') || '{}'
                      ).id,
                      api_name: 'sc_txn_resp',
                      api_response_data: {
                        api_name: 'sc_txn_resp',
                        api_id: id,
                        api_response: txnResponse,
                      },
                    };
                    const dialogRef = this.dialogRef.open(
                      CommonDialogWaitingComponent,
                      { disableClose: true }
                    );
                    this.http
                      .post(
                        environment.baseUrl_8083 +
                        `smartvalues/func/saveapiresponse`,
                        body
                      )
                      .subscribe((res: any) => {
                        if (
                          res[0].update_pouch_subscriber_trade_status.err ===
                          '-' &&
                          res[1].insert_pouch_subscriber_trade_status.err ===
                          '-' &&
                          res[2].insert_pouch_subscriber_trades.err === '-'
                        ) {
                          // this.pouchComposition.getSubscriberReturns();
                          // dialogRef.close();
                          this.updateTabeleHandler.emit({ dialog: dialogRef });
                        } else {
                          this.notifyService.showError('Error: Invalid', '');
                        }
                      });
                  },
                  (error: any) => {
                    let body = {
                      pouch_id: this.pouchDetail.id,
                      txn_id: id,
                      subscriber_id: this.pouchDetail.id,
                      api_name: 'sc_txn_resp',
                      api_response_data: {
                        api_name: 'sc_txn_resp',
                        api_id: id,
                        api_response: {
                          error: error.message,
                        },
                      },
                    };

                    this.http
                      .post(
                        environment.baseUrl_8083 +
                        `smartvalues/func/saveapiresponseerr`,
                        body
                      )
                      .subscribe((res: any) => {
                        if (res[0].update_pouch_subscriber_trades.err === '-') {
                          // this.pouchComposition.getSubscriberReturns();
                          this.updateTabeleHandler.emit();
                        }
                        this.notifyService.showError(
                          `Error : ${error.message}`,
                          ''
                        );
                      });
                  }
                );
            } else {
              this.notifyService.showError('Error: Invalid', '');
            }
            this.investBtnDisable=false
          },
          (error: any) => {
            //Error callback
            console.error('error caught in component', error);

            this.notifyService.showError(`Error:${error.error.errors[0]}`, '');
            this.investBtnDisable=false

          }
        );
    }
  }
  subscriptionMonth: any;
  getSubscriptionTypeText(val?: any) {
    this.subscriptionMonth = val;

    if (val === 'MONTHLY') {
      return 'Monthly subscription';
    }
    if (val === 'QUARTERLY') {
      return 'Quarterly subscription';
    }
    if (val === 'HALF-YEARLY') {
      return 'Half yearly subscription';
    }

    return '-';
  }

  getRound(val: any) {
    if (val) {
      let num: any = Math.round(Number(val));
      if (isNaN(num)) {
        return val;
      } else {
        return num;
      }
      return;
    } else {
      return val;
    }
  }

  pouchEndDate: any;
  expiredPouch: boolean = false;
  pouchExpireToday: boolean = false;
  switchDateLeft: boolean = true;
  totalDaysLeft: any;
  SubscriptionEndDate: any;
  daysLeftForRenewal() {
    if (this.subscriptionStatus?.[0]?.end_date) {
      const endDateMoment = _moment(this.subscriptionStatus?.[0]?.end_date).endOf('day');
      // Uncomment the below line if you want to hardcode the end date for testing
      // const endDateMoment = _moment('2023-08-20T00:00:00Z').endOf('day');
      this.pouchEndDate = endDateMoment;
      const todayMoment = _moment();
      let daysLeft = endDateMoment.diff(todayMoment, 'days');
      this.totalDaysLeft = daysLeft;
      if (daysLeft <= 0 && endDateMoment.isSame(todayMoment, 'day')) {
        this.pouchExpireToday = true
      } else if (daysLeft <= 0 && !endDateMoment.isSame(todayMoment, 'day')) {
        this.expiredPouch = true
      } else if (daysLeft <= 7) {
        this.switchDateLeft = false;
        this.expiredPouch = false;
        this.pouchExpireToday = false;
      } else {
        this.switchDateLeft = true;
        this.expiredPouch = false;
        this.pouchExpireToday = false;
      }
    }
  }
  openRenew() {
// stock_price_date: this.pouchEndDate.clone().add(1, 'days'),
    let subscriberPayload: any = {
      stock_price_date: this.pouchEndDate.isAfter(_moment()) ? localStorage
        .getItem('stock_price_uploaded_date')
        ?.split('T')[0] : this.pouchEndDate.clone().add(1, 'days').format('YYYY-MM-DD'),
      pouch_id: this.pouchDetail.id,
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
    };
    this.subscriberService
      .getPouchSubscriberReturns(subscriberPayload)
      .subscribe((res) => {
        if (res) {
          let data = {
            pouchId: this.pouchDetail.id,
            pouchReturn: res,
            invAmnt: this.subscriberSubscription[0]?.investment_amount
          };

          let popup = this.dialog.open(SubscriptionRenewPopupComponent, {
            data,
          });
        }
      });
  }

  makePayment() {
    let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    this.publicService
      .intiatePaymentGatewaysvf({
        subscriber_id: d.subscriber_id,
        redirect_url: `${window.location.host}/investment`,
        subscription_freq: d.subscription_freq,
        sv_feature_name: 'create_pouch',
        promo_code: '',
      })
      .subscribe((res: any) => {
        if (res) {
          this.redirectToPayment(res);
        }
      });
  }
  redirectToPayment(data: any) {
    this.dialog.open(PaymentLoaderComponent, {
      data: data,
      panelClass: 'custom-modalbox',
    });
  }

  getAgreement() {
    if (Object.keys(this.agreementStatus).length > 0) {
      return true
    } else {
      return false
    }

  }

  // update subscriber investment amount
  updateSubscriberInvestmentAmount(): void {
    console.log(this.subscriptionStatus);

    if (!this.subscriptionStatus) {
      console.error('Subscription status is not available.');
      return;
    }

    const {
      investment_amount,
      pouch_id,
      public___pouch_subscriber_subscriptions,
    } = this.subscriptionStatus[0];

    if (!public___pouch_subscriber_subscriptions || public___pouch_subscriber_subscriptions.length === 0) {
      console.error('No subscriptions available.');
      return;
    }

    const { subscription_freq } = public___pouch_subscriber_subscriptions[0];

    const dialog: MatDialogRef<AmountUpdatePopupComponent, AmountUpdatePopupData> = this.dialog.open(AmountUpdatePopupComponent, {
      data: {
        pouchId: pouch_id as string,
        pouchFrequency: subscription_freq as string,
        subscriberInvestmentAmount: investment_amount as string,
        min_investment:this.min_investment,
        subscriberID: JSON.parse(localStorage.getItem('user') || '{}').id,

      },
    });
    dialog.afterClosed().subscribe((res: any) => {
      if (res.sellDownValue === 'Sell Down' || res.sellDownValue === 'Top Up') {

        let payload = {
          "docs": {
            "investment_amount": res.data.investment_amount
          },
          "docsi": [
            {
              "pouch_id": res.data.pouch_id,
              "subscriber_id": JSON.parse(localStorage.getItem('user') || '{}').id,
              "investment_amount": res.data.investment_amount,
              "change_amount": res.data.change_amount,
              "from_date": _moment(localStorage.getItem('stock_price_uploaded_date')).format('YYYY-MM-DD')
            }
          ],
          "pouch_id": res.data.pouch_id,
          "subscriber_id": JSON.parse(localStorage.getItem('user') || '{}').id,
          "change_date": _moment(localStorage.getItem('stock_price_uploaded_date')).format('YYYY-MM-DD'),
          "review_date": _moment(localStorage.getItem('stock_price_uploaded_date')).format('YYYY-MM-DD')
        }
        this.subscriberService.changeInvestment(payload).subscribe((res: any) => {
          if (res) {
            let popup = this.dialog.open(SucessPopupComponent, {
              data: {
                isRenew: 'sellAmount',
                order: '',
                amount: '',
                no: '',
                id: payload.pouch_id,
              },

            });
            popup.afterClosed().subscribe((res) => {
              this.parentcomp.tabIndex = 1;
              this.parentcomp.intialLoad(true)
              this.parentcomp.composition.refreshPendingTable()
              this.parentcomp.refreshBanner()
            })
          }
        })
      }

    })
  }
}
