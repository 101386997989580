<div class="investment_expiration_banner">
  <ng-container *ngIf="bannerType === 0">
    <div class="p-3 text-start">
      <span class="d-block text_h f-12">Subscription fees</span>
      <span class="f-12 --black3 mb-5">
        Rs. {{ getRound(this.sub_fee) || "-" }}
      </span>
      <!-- <br /> -->
      <!-- <br /> -->
      <div class="mt-10">
        <span class="d-block text_h f-12" *ngIf="!investmentChange ">Minimum Investment</span>
        <span class="d-block text_h f-12" *ngIf="investmentChange ">Investment Amount</span>
        <span *ngIf="!getAgreement()" class="f-12 --black3">Rs. {{getRound (min_investment)|number: '1.0-0' }}</span>
        <span *ngIf="getAgreement()" class="f-12 --black3">Rs.
          {{(agreementStatus?.[0]?.public___subscriber_investments?.[0]?.investment_amount||0) | number
          :"1.0-0"}}</span>
      </div>
      <!-- <br /> -->
      <div class="mt-10">
        <button mat-button class="theme-btn primary-btn f-12" type="button" (click)="subscribe()">
          Subscribe & Invest
        </button>
      </div>
      <div class="row mt-10">
        <div class="col-12 d-flex f-12 justify-content-center">
          <div><img src="../../../../../assets/images/icons/watchVideoIcon.svg" > want some help ? <span (click)="openVideo()" class="cPointer text-dark-blue f-13"> watch this video</span>  on how to subscribe </div>

          </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="bannerType === 1 || bannerType === 2 ">
    <div class="p-3">
      <div class="text-center mb-10">
        <img src="../../../../../assets/images/icons/tick-circle-outline.png" alt="" />
      </div>


      <span class="f-12 --black3" *ngIf="subscriberSubscription?.length > 0">
        {{
        getSubscriptionTypeText(
        subscriberSubscription[0]
        ?.public___pouch_subscriber_subscriptions[0].subscription_freq
        )
        }}
        <br />
        <span *ngIf="switchDateLeft && !expiredPouch && !pouchExpireToday">expires on {{pouchEndDate | date: "dd MMM
          yyyy"}}</span>
        <ng-container >

      <span *ngIf="!switchDateLeft && !expiredPouch && !pouchExpireToday" class="increaseZindex pRelative">expiring in
          {{totalDaysLeft}} days. <a class="text-blue cursor"  *ngIf="!pouchDetail.stop_sub && pouchDetail.subscribed=='Y'" (click)="openRenew()"> Renew</a></span>
        <span *ngIf="pouchExpireToday" class="increaseZindex pRelative">expires today. <a class="text-blue cursor" *ngIf="!pouchDetail.stop_sub && pouchDetail.subscribed=='Y'"
            (click)="openRenew()"> Renew</a></span>
        <span *ngIf="!pouchExpireToday&&expiredPouch" class="increaseZindex pRelative">expired on {{pouchEndDate | date:
          "dd MMM yyyy"}} <a class="text-blue cursor" *ngIf="!pouchDetail.stop_sub && pouchDetail.subscribed=='Y'" (click)="openRenew()"> Renew</a></span></ng-container>
        </span>
      <br />

      <div *ngIf="subscriberSubscription?.length > 0">
        <span class="d-block text_h f-12 mt-10">Investment Amount</span>
        <span class="f-12 --black3">Rs. {{
          getRound(subscriberSubscription[0]?.investment_amount )| number:'1.0-0'
          }}</span>
      </div>
      <ng-container  *ngIf="!pouchDetail.stop_sub && pouchDetail.subscribed=='Y'">
        <div class="f-12 fw-600 updateText increaseZindex pRelative" *ngIf="!(!pouchExpireToday&&expiredPouch)"
        style="cursor: pointer;" (click)="updateSubscriberInvestmentAmount()">Update Amount</div>

        </ng-container>


      <div *ngIf="subscriberSubscription?.stop_loss > 0">
        <span class="d-block text_h f-12 mt-10">Stop Loss</span>
        <span class="f-12 --black3">{{ subscriberSubscription[0].stop_loss }} %</span>
      </div>

      <div class="mt-10" *ngIf="bannerType === 1">

        <button mat-button  class="theme-btn primary-btn f-12 increaseZindex spinnerBtn" type="button" [disabled]="investBtnDisable"  (click)="investSmallcase()">
          <div *ngIf="!investBtnDisable">{{(holdStockLength >=1 && stockLength?.holdStockLength !==0)||stockLength?.holdStockLength !==0 ? 'Execute Trades':'Execute Trades'}}</div>
          <ng-container *ngIf="investBtnDisable" >
            <mat-icon ><mat-spinner color="primary" diameter="20">
              </mat-spinner></mat-icon>
          </ng-container>
        </button>

      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="bannerType === 2">
    <!-- Remove liqudite pouch -->
    <!-- <div class="p-3">
      <div class="text-center">
        <img
          src="../../../../../assets/images/icons/tick-circle-outline.png"
          alt=""
        />
      </div>
      <br />

      <span class="f-12 --black3">
        Subscribed for 3 months <br />
        on 21 Aug 2022
      </span>
      <br />
      <br />
      <div>
        <span class="f-12 --black3">
          Invested 75,000.00 <br />
          on 25 Aug
        </span>
      </div>
      <br />
      <div class="">
        <button mat-button class="theme-btn primary-btn f-12" type="button">
          Liquidate pouch
        </button>
      </div>
    </div> -->

  </ng-container>
</div>
