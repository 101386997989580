import { PublicFacadeService } from './../public/state/public.facade.services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Elements } from '../../common/interfaces/table';
import { DashboardService } from './services/dashboard.service';
import { DashboardState, selectDashboardState } from './store/dashboard.state';
import { Store } from '@ngrx/store';
import { Load } from './store/actions/dashboard.action';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { PublicService } from '../public/services/public.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { NotificationService } from 'src/app/services/notification.services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public isPublic: boolean = false;
  pouchList: any;
  subscriberList: any;
  pouchRebalanceList: any;
  topGainersLosers: any;
  pouchesSub: Subscription;
  uploadedDate: any;

  pouches = {
    border: true,
    box: true,
    heading: true,
    content: true,
    subscriber: true,
    pouch: false,
    lastReview: false,
    nextReview: false,
  };

  subscribers = {
    box: false,
    border: true,
    heading: true,
    content: true,
    subscriber: false,
    pouch: true,
    lastReview: false,
    nextReview: false,
  };

  pouchesRebalances = {
    box: false,
    border: false,
    heading: true,
    content: false,
    subscriber: false,
    pouch: false,
    lastReview: true,
    nextReview: true,
  };

  showFilter = true;

  pouchesFilter = [
    { name: 'Investments', value: -23 },
    { name: 'Annualized Returns', value: -22 },
    { name: 'Subscribers', value: -17 },
  ];
  subscribersFilter = [
    { name: 'Pouches', value: -9 },
    { name: 'Days Subscribed', value: 7 },
    { name: 'Total Returns', value: -11 },
    { name: 'Total Investments', value: -10 },
  ];

  PouchesData: Elements[];

  getState: Observable<any>;
  dashboardKpis: any;
  totalPouch: any;
  totalSubscriber = '';
  totalInvestment: any;
  totalReturn: any;
  logginUser: any;
  defaultBody: any;
  defaultSubscriberSort: number = -23;
  defaultPouchSort: number = -9;
  public url: string;
  hideElement = false;
  isPubic: boolean = false;
  admin_update: boolean = false;
  loadedKPI: boolean = false;
  loadedPOUCH: boolean = false;
  isLoading: boolean;

  constructor(
    private pouchService: PouchService,
    private subscriberService: SubsciberService,
    private store: Store<DashboardState>,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
    private notifyService: NotificationService,
    private publicFacadeService: PublicFacadeService
  ) {
    this.getState = this.store.select(selectDashboardState);

    this.router.events.subscribe((event) => {
      let routes = ['/public/dashboard'];
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.hideElement = routes.includes(event.url) ? true : false;
        if (this.hideElement == true) {
          this.isPublic = true;
        }
        // if(this.url === '/dashboard') {
        //   this.admin_update = true;
        // }
        // else {
        //   this.admin_update = true;
        // }
      }
    });
  }

  ngOnInit() {
    this.authService.onUserUpdate.subscribe((data) => {
      if (!localStorage.getItem('stock_price_uploaded_date')) {
        this.publicFacadeService.setUploadDateToStore();
      }
      localStorage.setItem('user', JSON.stringify(data));

      this.logginUser = data.attributes;
    });

    if (!this.logginUser) {
      const storageUser: any = localStorage.getItem('user');
      const user = JSON.parse(storageUser);
      this.logginUser = user?.attributes;
    }
    this.defaultBody = {
      // pouchPayload : { "limit": 5, "sort": this.defaultSubscriberSort, "current_date":"2021-11-29" },
      // subscriberPayload : { "limit": 5, "sort": this.defaultPouchSort },
      rebalancePayload: { limit: 8 },
    };

    if (
      this.logginUser?.role === 'admin' ||
      this.logginUser?.role === 'partner' ||
      this.logginUser?.role === 'ria'
    ) {
      this.getState.subscribe((state) => {
        if (!this.dashboardKpis) {
          this.dashboardKpis = state.dashboard;
          if (this.dashboardKpis && this.dashboardKpis.length > 0) {
            this.totalPouch =
(              this.dashboardKpis[0][0]?.kpi1[0]?.no_of_pouches.toLocaleString(
                'en-IN',
                {
                  useGrouping: true,
                }
              ) ) || '0';
            this.totalSubscriber =
              this.dashboardKpis[0][1].kpi2[0].no_of_subscribers.toLocaleString(
                'en-IN',
                {
                  useGrouping: true,
                }
              ) || '0';
            this.totalInvestment =
              this.dashboardKpis[0][2].kpi3[0].total_investments || '0';
            this.totalReturn =
              this.dashboardKpis[0][3].kpi4[0].total_returns || '0';
            this.uploadedDate =
              this.dashboardKpis[0][4].kpi5[0].last_uploaded_on || '-';
            //this.pouchList = this.dashboardKpis[1];
            //this.subscriberList = this.dashboardKpis[2];
            this.pouchRebalanceList = this.dashboardKpis[1];
            this.topGainersLosers = this.dashboardKpis[2];

            localStorage.setItem(
              'stock_price_uploaded_date',
              `${this.uploadedDate}`
            );
            this.getSubscriberList();
            this.getPouchList();
          }
        }
      });

      if (!this.dashboardKpis) {
        this.store.dispatch(new Load(this.defaultBody));
      }
    }
  }
  //
  getSubscriberList() {
    let subscriberPayload = { limit: 5, sort: this.defaultPouchSort };
    this.subscriberService.getSubscriberList(subscriberPayload).subscribe(
      (res: any) => {
        if (res && res.length > 0 && res[0].subscribers) {
          let subscriberList: any = [];
          res[0].subscribers.map((element: any) => {
            subscriberList.push({
              heading: `${element['first_name']} ${element['last_name']}`,
              joiningDate: element['joined_since'],
              count: element['pouch_count'],
            });
          });

          this.subscriberList = [...subscriberList];
        }
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      },
      () => { }
    );
  }

  getPouchList() {
    let pouchPayload = {
      limit: 5,
      sort: this.defaultSubscriberSort,
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      current_date: this.uploadedDate.split('T')[0],
    };
    this.pouchService.getPouchList(pouchPayload).subscribe(
      (res: any) => {
        if (res.length > 0 && res[0].pouches) {
          let pouchList: any = [];
          res[0].pouches.map((element: any) => {
            pouchList.push({
              heading: element['name'],
              content: element['description'],
              count: element['subscriber_count'],
            });
          });

          this.pouchList = [...pouchList];
        }
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      },
      () => { }
    );
  }
  //
  ngOnDestroy() { }

  goSubscriber() {
    this.router.navigateByUrl('/more/subscriber');
  }

  goInvestment() {
    this.router.navigateByUrl('/investment');
  }

  sortData(event: any) {
    if (event.subscriber) {
      this.defaultSubscriberSort = event.sortValue;
      this.getPouchList();
    } else {
      this.defaultPouchSort = event.sortValue;
      if (this.logginUser.role != 'subscriber') {
        this.getSubscriberList();
      }
    }
  }

  goStockList() {
    this.router.navigateByUrl('/more/stock');
  }
}
