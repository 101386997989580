import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import * as moment from 'moment';

@Component({
  selector: 'app-map-subscriber-popup',
  templateUrl: './map-subscriber-popup.component.html',
  styleUrls: ['./map-subscriber-popup.component.scss'],
})
export class MapSubscriberPopupComponent implements OnInit {
  subscriberList: any = [];
  mapSubscriberForm: FormGroup;
  errorMessage: any = '';
  isDisabled: boolean;
  uploadedDate: any;
  subscriptionsList: any = [];
  minimumInvestmentAmountCopy: any = 0;
  selected: any = 'MONTHLY';
  investment_id: any;
  subscriber_id: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MapSubscriberPopupComponent>,
    private subscriberService: SubsciberService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.isDisabled = this.data.id ? true : false;
    // this.getSubscriptionPlanList();

    this.subscriberService
      .get_smartbeta_subscriberlist({ active: true })
      .subscribe((res: any) => {
        this.subscriberList = res[0].subscriber_investments;
      });
    this.createForm();
    this.minimumInvestmentAmountCopy = this.data.min_investment;

    if (this.data.id) {
      this.mapSubscriberForm.controls['subscriber_id'].setValue(this.data.id);
      this.mapSubscriberForm.controls['start_date'].setValue(
        new Date(this.data.subscribed)
      );
      this.mapSubscriberForm.controls['end_date'].setValue(
        new Date(this.data.end_date)
      );
      this.mapSubscriberForm.controls['stoploss_stock'].setValue(
        this.data.stoploss_stock
      );
      this.mapSubscriberForm.controls['stoploss_portfolio'].setValue(
        this.data.stoploss_portfolio
      );
      this.mapSubscriberForm.controls['investment_amount'].setValue(
        this.data.pouch.min_amt
      );
    }
    this.mapSubscriberForm.controls['investment_amount'].setValue(
      this.data.pouch.min_amt
    );
  }

  createForm() {
    this.mapSubscriberForm = new FormGroup({
      investment_id: new FormControl('', { validators: [Validators.required] }),
      start_date: new FormControl(
        new Date(localStorage.getItem('stock_price_uploaded_date') || ''),
        {
          validators: [Validators.required],
        }
      ),
      end_date: new FormControl(new Date('2099-12-31'), {
        validators: [Validators.required],
      }),
      stoploss_stock: new FormControl(0, {
        validators: [Validators.required],
      }),
      stoploss_portfolio: new FormControl(0, {
        validators: [Validators.required],
      }),
      investment_amount: new FormControl('', {
        validators: [Validators.required],
      }),
      joinDate: new FormControl('', { validators: [] }),
    });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  mapSubscriber(event: any) {
    if (
      this.mapSubscriberForm.controls['investment_amount']?.value >=
      this.data.pouch.min_amt
    ) {
      this.errorMessage = '';
      if (this.mapSubscriberForm.valid && this.selected) {
        const formValue = this.mapSubscriberForm.value;
        if (formValue.start_date > formValue.end_date) {
          this.errorMessage = 'End date cannot be greater than start date.';
          return;
        }
        const start_date = moment(new Date(formValue.start_date)).format(
          'YYYY-MM-DD'
        );
        const end_date = moment(new Date(formValue.end_date)).format(
          'YYYY-MM-DD'
        );
        //formValue.start_date.setDate(formValue.start_date.getDate() + 1);
        // formValue.end_date.setDate(formValue.end_date.getDate() + 1);

        if (!this.data.id) {
          const data = {
            subscriberObj: [
              {
                pouch_id: this.data.pouch_id,
                subscriber_id: formValue.investment_id,
                subscription_type: this.selected,
                start_date: start_date,
                end_date: end_date,
                stoploss_stock: formValue.stoploss_stock,
                stoploss_portfolio: formValue.stoploss_portfolio,
                investment_amount: formValue.investment_amount,
                is_active: 'Y',
                // investment_id: formValue.investment_id,
              },
            ],
            docsi: [
              {
                pouch_id: this.data.pouch_id,
                subscriber_id: formValue.investment_id,
                investment_amount: formValue.investment_amount,
                change_amount: formValue.investment_amount,
                from_date: start_date,
              },
            ],
            pouch_id: this.data.pouch_id.toString(),
            subscriber_id: formValue.investment_id,
            review_date: start_date,
            investment_amount: formValue.investment_amount,
            minimum_investment: this.data.min_investment,
          };
          this.subscriberService.mapSubscriberToPouch(data).subscribe(
            (res: any) => {
              if (
              res.event_logs.err == '-'
              ) {                
                this.dialogRef.close(true);
                const dialog: any = this.dialog.open(CommonDialogComponent, {
                  data: {
                    message: 'Subscriber mapped successfully.',
                    title: 'Success',
                    status: 'Success',
                  },
                });
                setTimeout(() => {
                  dialog.close({ success: true });
                }, 5000);
                // const data: any = {
                //   start_date: formValue.start_date.toISOString().split('T')[0],
                //   stock_upload_date: this.uploadedDate.split('T')[0],
                //   pouch_id: Number(this.data.pouch_id),
                //   subscriber_id: formValue.subscriber_id,
                // };
                // this.subscriberService
                //   .mapSubscriberToPouchRebalance(data)
                //   .subscribe((res: any) => {
                //     const dialog: any = this.dialog.open(
                //       CommonDialogComponent,
                //       {
                //         data: {
                //           message: 'Subscriber mapped successfully.',
                //           title: 'Success',
                //           status: 'Success',
                //         },
                //       }
                //     );
                //     setTimeout(() => {
                //       dialog.close({ success: true });
                //     }, 5000);
                //   });
              } else {
                this.dialogRef.close(false);
                const dialog: any = this.dialog.open(CommonDialogComponent, {
                  data: {
                    message: 'Error on mapping.',
                    title: 'Error',
                    status: 'Fail',
                  },
                });
                setTimeout(() => {
                  dialog.close({ success: false });
                }, 1000);
              }
            },
            (e: any) => {              
              this.dialogRef.close(false);
              const dialog: any = this.dialog.open(CommonDialogComponent, {
                data: {
                  message: 'Subscriber mapped Failed.',
                  title: 'Fail',
                  status: 'Fail',
                },
              });
              setTimeout(() => {
                dialog.close({ success: false });
              }, 5000);
            }
          );
        } else {
          const data = {
            subscriberObj: {
              subscription_type: this.selected,
              start_date: start_date,
              end_date: end_date,
              stoploss_stock: formValue.stoploss_stock,
              stoploss_portfolio: formValue.stoploss_portfolio,
              investment_amount: this.data.invest,
              is_active: 'Y',
            },
            docsi: [
              {
                pouch_id: Number(this.data.pouch_id),
                subscriber_id: this.subscriber_id,
                investment_amount: this.data.invest,
                change_amount: this.data.invest,
                from_date: start_date,
              },
            ],
            pouch_id: this.data.pouch_id,
            subscriber_id: this.subscriber_id,
            start_date: start_date,
            review_date: start_date,
            investment_amount: this.data.invest,
            minimum_investment: this.data.min_investment,
          };

          this.subscriberService
            .editMapSubscriberToPouch(data)
            .subscribe((res: any) => {
              this.dialogRef.close();
              if (
                res[0].deletepouchsubscriberstocks &&
                res[0].deletepouchsubscriberstocks.err == '-' &&
                res[1].del_pouch_subscriber_investments &&
                res[1].del_pouch_subscriber_investments.err == '-' &&
                res[2].pouch_subscriber_stocks &&
                res[2].pouch_subscriber_stocks.err == '-' &&
                res[3].pouch_subscribers &&
                res[3].pouch_subscribers.err == '-' &&
                res[4].pouch_subscriber_investments &&
                res[4].pouch_subscriber_investments.err == '-'
              ) {
                const dialog: any = this.dialog.open(CommonDialogComponent, {
                  data: {
                    message: 'Subscriber mapped successfully.',
                    title: 'Success',
                    status: 'Success',
                  },
                });
                setTimeout(() => {
                  dialog.close({ success: true });
                }, 5000);
                // const data: any = {
                //   start_date: formValue.start_date.toISOString().split('T')[0],
                //   stock_upload_date: this.uploadedDate.split('T')[0],
                //   pouch_id: Number(this.data.pouch_id),
                //   subscriber_id: formValue.subscriber_id,
                // };
                // this.subscriberService
                //   .mapSubscriberToPouchRebalance(data)
                //   .subscribe((res: any) => {
                //     const dialog: any = this.dialog.open(
                //       CommonDialogComponent,
                //       {
                //         data: {
                //           message: 'Subscriber mapped successfully.',
                //           title: 'Success',
                //           status: 'Success',
                //         },
                //       }
                //     );
                //     setTimeout(() => {
                //       dialog.close({ success: true });
                //     }, 5000);
                //   });
              } else {
                const dialog: any = this.dialog.open(CommonDialogComponent, {
                  data: {
                    message: 'Error on mapping.',
                    title: 'Error',
                    status: 'Fail',
                  },
                });
                setTimeout(() => {
                  dialog.close({ success: false });
                }, 1000);
              }
            });
        }
      }
    } else {
      this.errorMessage = 'Investment amount is lesser minimum investment';
    }
  }

  // getSubscriptionPlanList() {
  //   console.log(
  //     this.data.pouch?.public___pouch_subscriptions,
  //     'check subscriptions'
  //   );
  //   this.subscriptionsList = this.data.pouch.public___pouch_subscriptions[0]
  //     .subscription_freq
  //     ? this.data.pouch.public___pouch_subscriptions
  //     : [];
  //   this.selected = this.subscriptionsList[0].subscription_freq;
  // }
  // getOptionsText(type: string) {
  //   if (type === 'MONTHLY') {
  //     return 'Monthly subscription : ' + this.getamount(type);
  //   } else if (type === 'QUATERLY') {
  //     return '3 months subscription : ' + this.getamount(type);
  //   } else {
  //     return '6 months subscription : ' + this.getamount(type);
  //   }
  // }
  // getamount(type: any) {
  //   let getsubscriptiondata = this.subscriptionsList?.find(
  //     (ele: any) => ele.subscription_freq === type
  //   );
  //   if (getsubscriptiondata?.subscription_type === 'MAX') {
  //     let value = Math.max(
  //       (this.minimumInvestmentAmountCopy *
  //         getsubscriptiondata?.subscription_perc) /
  //         (100 * (type === 'MONTHLY' ? 12 : type === 'QUATERLY' ? 4 : 2)),
  //       getsubscriptiondata?.subscription_amount
  //     );
  //     return value.toFixed(2) || 0;
  //   }
  //   if (getsubscriptiondata?.subscription_type === 'MIN') {
  //     let value = Math.min(
  //       (this.minimumInvestmentAmountCopy *
  //         getsubscriptiondata?.subscription_perc) /
  //         (100 * (type === 'MONTHLY' ? 12 : type === 'QUATERLY' ? 4 : 2)),
  //       getsubscriptiondata?.subscription_amount
  //     );

  //     return value.toFixed(2) || 0;
  //   }
  //   if (getsubscriptiondata?.subscription_type === 'BOTH') {
  //     let value =
  //       (this.minimumInvestmentAmountCopy *
  //         getsubscriptiondata?.subscription_perc) /
  //         (100 * (type === 'MONTHLY' ? 12 : type === 'QUATERLY' ? 4 : 2)) +
  //       getsubscriptiondata?.subscription_amount;
  //     return value.toFixed(2) || 0;
  //   }
  //   return this.minimumInvestmentAmountCopy;
  // }
  // getFrequencyPlan() {
  //   let value = '';
  //   switch (this.selected) {
  //     case 'MONTHLY':
  //       value = '1 month';

  //       break;
  //     case 'QUATERLY':
  //       value = '3 month';

  //       break;
  //     case 'HALF-YEARLY':
  //       value = '6 month';

  //       break;
  //   }
  //   return value;
  // }
  radioChanged(e: any) {
    this.selected = e.value;
  }
  investmentAmountChange(e: any) {
    this.minimumInvestmentAmountCopy = Number(e.target.value);
  }
  changeSub(e: any) {
    console.log(e);
    let sub = this.subscriberList.find((ele: any) => {
      return ele.subscriber_id === e.value;
    });

    // this.mapSubscriberForm.patchValue({
    //   investment_amount: sub.investment_amount,
    // });

    // this.mapSubscriberForm.controls['investment_amount'].setValue(
    //   sub?.investment_amount
    // );
    // (this.minimumInvestmentAmountCopy = sub.investment_amount),
    this.subscriber_id = sub.subscriber_id;
    this.investment_id = sub.investment_id;
  }

  
}
