<ng-container>
  <div class="pouch-component ui-update mt-20">
    <div class="container">
      <div class="container-lg" style="width: 100%;">
        <ng-container>
          <div class="container-lg" style="width: 100%;margin-top: -5px;">
            <div class="f-24 fw-600 investment-title white text-center">Our <span class="f-green bold">Investment
                Pouches</span>
            </div>
            <p class="f-16 fw-400 mt-20 white text-center">SMART Pouches carefully tailored to your risk appetite
              and
              investment budget.
            </p>
          </div>
        </ng-container>
      </div>
      <div class="container-lg white-bg public-bg-wrapper mt-20 mb-60 pd-30">
        <div class="row mb-20">
          <div class="col-md-4 sm-mb-20">
            <p class="f-12 text-grey mb-05">Search</p>
            <div class="searchBox1 w-100">
              <input matInput [(ngModel)]="searchValue" (keyup.enter)="searchHandler($event)"
                placeholder="Type atleast 3 characters to search" autocomplete="off" />
              <img src="../../../../../assets/images/icons/search_public.svg" alt="" srcset=""
                (click)="searchHandler($event)" class="cursor">
            </div>
          </div>
          <div class="col-md-3 col-10">
            <p class="f-12 text-grey mb-05">Sort by</p>
            <div class="outline-border">
              <mat-form-field appearance="outline" class="w-100">
                <mat-select [(ngModel)]="selectedSort" (selectionChange)="sortOnChange($event)">
                  <mat-option *ngFor="let sd of sortData" [value]="sd.value">{{sd.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-2 col-2">
            <div class="filterStyle cursor" (click)="isshowFilter()"> <img src="assets/images/icons/filter.png" /></div>
            <span matBadge="" matBadgeSize="small"></span>
          </div>
        </div>
        <div class="fiterbox border" *ngIf="showFilter">
          <ng-container>
            <form [formGroup]="pouchFilterForm" (ngSubmit)="applyFilters()">
              <div class="fiterbox-row" style="width: 105em;">
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Min Investment</p>
                  <div class="input-flex">
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="amountOperatorInvestment">
                        <mat-option *ngFor="let op of operators" [value]="op.value">{{op.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <input matInput type="number" min="0" autocomplete="off" formControlName="min_investment" />
                  </div>
                </div>
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Annualized Returns</p>
                  <div class="input-flex">
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="amountOperatorAnnualized">
                        <mat-option *ngFor="let op of operators" [value]="op.value">{{op.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <input matInput type="number" min="0" autocomplete="off" formControlName="annualized_returns" />
                  </div>
                </div>
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Risk Profile</p>
                  <div>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select [multiple]="true" formControlName="riskProfiles"
                        (selectionChange)="selectionChange($event,'risk_profile')">
                        <mat-option *ngFor="let rp of risk_profiles" [value]="rp.value">{{rp.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>


                <div class="fiterbox-card btn-group">
                  <button mat-button color="primary" class="theme-btn border-btn"
                    (click)="cancelFilters()">Cancel</button>
                  <button mat-button color="primary" class="theme-btn primary-btn" type="submit">Apply</button>
                </div>
              </div>
            </form>
          </ng-container>
        </div>
        <app-details [userType]="'public'" [pouches]='pouches' [showGhost]="showGhost"></app-details>
      </div>
    </div>
  </div>
</ng-container>
