<nav class='navigationText navContainer'  mat-tab-nav-bar>
  <ng-container *ngFor="let link of navLinks">
    <a [ngClass]="isPublic ? 'navigationText_public' : ''" mat-tab-link *ngIf="showNavOption(link.label)"
    [routerLink]="link.link" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
    <span (click)="clickingOnRoute()" >{{link.label}}</span>
  </a>

  </ng-container>
  <a [ngClass]="isPublic ? 'navigationText_public' : ''" mat-tab-link
  *ngIf="this.role === 'subscriber'" (click)="openSmartstock()"
  >
  <span> Smart Stocks</span>
</a>
</nav>

