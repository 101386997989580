<section class="table" appPouchesTable>
  <div class="filterdrpdown-row" *ngIf="sortingFilter">
    <div *ngIf="sortingFilter && tableStructure.subscriber" style="text-align: right;">
      Top 5 Pouches
    </div>
    <div *ngIf="sortingFilter && tableStructure.pouch" style="text-align: right;">
      Top 5 Subscribers
    </div>
    <div class="category">
      <mat-form-field *ngIf="dashboardFilter.length > 0" appearance="outline">
        <mat-label>Select Category</mat-label>
        <mat-select (selectionChange)="sortData($event, tableStructure)" [(ngModel)]="selectedSort">
          <mat-option [value]="option.value" *ngFor="let option of dashboardFilter">{{ option.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="!sortingFilter" class="table-header">
    <div></div>
    <div>Last review</div>
    <div>Next review</div>
  </div>
  <div class="table-body ">
    <div *ngIf="!isLoading">
      <div *ngFor="let data of definedData ; let i = index" class="table-row" (click)='onPouchClick(i)'>
        <div appPouchesTable *ngIf="tableStructure.box" class="icon"><img _ngcontent-mbk-c125=""
            src="/assets/images/icons/electric_mobility.png" ></div>
        <div class="tabletitle">
          <h3 appPouchesTable  [heading]="true" *ngIf="tableStructure.heading">{{data.heading}}</h3>
          <p *ngIf="tableStructure.content && !tableStructure.pouch">{{data.content}}</p>
          <p *ngIf="tableStructure.pouch">{{data.joiningDate | date : 'mediumDate'}}</p>
        </div>
        <div appPouchesTable [border-v]="true" *ngIf="tableStructure.border"></div>
        <div *ngIf="tableStructure.subscriber" class="subscribers-num">{{data.count}} Subscribers
        </div>
        <div *ngIf="tableStructure.pouch" class="subscribers-num pouches-subscribers-num">{{data.count}} Pouches</div>
        <div *ngIf="tableStructure.lastReview" class="subscribers-num lastDate w45">{{data.lastReviewDate | date :
          'mediumDate'}}</div>
        <div *ngIf="tableStructure.nextReview" class="subscribers-num nextDate w45">
          {{data.nextReviewDate | date :
          'mediumDate'}}</div>
      </div>
    </div>

    <!--  -->
    <section *ngIf="isLoading">
      <ng-container *ngFor="let i of [1, 2, 3,4,5]">
        <div class="table-row">
          <div *ngIf="tableStructure.box" class="icon">
            <div style="height: 50px;width: 50px" class="ghost"></div>
          </div>
          <div class="tabletitle">
            <h3  *ngIf="tableStructure.heading" class="ghost" style="width: 30%; height: 20px;margin-top: 10px;" ></h3>
            <p *ngIf="tableStructure.content && !tableStructure.pouch" class="ghost" style="width: 20%;height: 20px;">
            </p>
            <p *ngIf="tableStructure.pouch" class="ghost" style="width: 20%; height: 20px;margin-top: 10px;"></p>
          </div>
          <div *ngIf="tableStructure.border"
            style="border-right: 1px solid rgb(184, 184, 184); padding: 0; height: 38px;"></div>
          <div *ngIf="tableStructure.subscriber" class="subscribers-num ghost" style="width: 10%;height: 20px;"></div>
          <div *ngIf="tableStructure.pouch" class="subscribers-num pouches-subscribers-num ghost" style="width: 20%; height: 20px;margin-top: 10px;"></div>
          <div *ngIf="tableStructure.lastReview" class="subscribers-num ghost lastDate "
            style="width: 10%;height: 20px;"></div>
          <div *ngIf="tableStructure.nextReview" class="subscribers-num ghost nextDate "
            style="width: 10%;height: 20px;"></div>
        </div>
      </ng-container>
    </section>
    <!--  -->
  </div>
</section>
