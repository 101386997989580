import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Elements } from "../../../common/interfaces/table";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StockService {
  private GET_STOCK_EXCHANGES = `store/smartvalues/myquery/execute/get_stock_exchanges`;
  private GET_STOCKS = `store/smartvalues/myquery/execute/get_stocks`;

  constructor(private http: HttpClient) {}

  public getStockExchanges(data: any) {
    return this.http.post(environment.baseUrl + this.GET_STOCK_EXCHANGES, data);
  }
  public getStocks(data: any) {
    return this.http.post(environment.baseUrl + this.GET_STOCKS, data);
  }
}
