<div class="more-container container">
  <div class="more-css">
    <ul>
      <li (click)="goStock()">Stock Prices</li>
      <li (click)="universe()">Universe</li>
      <li (click)="subscriber()">Subscribers</li>
      <li (click)="holiday()">Holiday Lists</li>
      <li (click)="promocode()">Promo Codes</li>
      <li (click)="referral()">Referrers</li>
      <li (click)="refUserList()">Referrer Users</li>
      <li (click)="adminUser()">Admin Users</li>
      <li (click)="pouchpayments()">Pouch Payments</li>
      <li (click)="bonusSplit()">Bonus & Split</li>
    </ul>
  </div>
</div>
<!-- <div class="more-container ui-update">
    <div>
        <ul>
            <li (click)="goStock()">Stock Prices</li>
            <li (click)="universe()">Universe</li>
            <li (click)="subscriber()">Subscribers</li>
            <li (click)="holiday()">Holiday List</li>
            <li (click)="promocode()">Promo Code</li>
        </ul>
    </div>
</div> -->
