import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ContactUsDialogComponent } from '../contact-us-dialog/contact-us-dialog.component';

@Component({
  selector: 'app-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss'],
})

export class PublicFooterComponent {
  
currentApplicationVersion : any
  constructor(
    public router: Router,
    private dialog:MatDialog
    ) {
    this.currentApplicationVersion = environment.appVersion;
  }

  goTo(url: any) {
    if (url === 'terms') {
      location.href = '/public/terms-and-conditions';
    }
    if (url === 'policy') {
      location.href = '/public/privacy-policy';
    }
  }
  openContactDialog(){
    const dialog = this.dialog.open(ContactUsDialogComponent)
  }
}
