<div class="component_container">
  <div class="component_title text-center">
    <span class="f-28 fw-600" style="color: white"
      >My <span class="f-green">Profile</span></span
    >
  </div>
  <div class="component_content_container resetHeight">
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        *ngFor="let link of links"
        (click)="goTo(link)"
        [routerLink]="link.path"
        routerLinkActive
        #RouterLinkActive="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        [active]="RouterLinkActive.isActive"
      >
        {{ link.name }}
      </a>
    </nav>
    <ng-container>
      <router-outlet></router-outlet>
    </ng-container>
  </div>
</div>
