import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PouchService {
  private GET_TIMEFRAMES = `get_timeframes`;
  private GET_UNIVERSE_LIST = `store/smartvalues/myquery/execute/get_universe_list`;
  private GET_POUCH_LIST = `store/smartvalues/myquery/execute/get_pouchlist`;
  private GET_STOCKS_BY_UNIVERSE = `store/smartvalues/myquery/execute/get_stocks_by_universe`;
  private CREATE_POUCH = `store/smartvalues/myquery/execute/create_pouch`;
  private EDIT_POUCH = `store/smartvalues/myquery/execute/edit_pouch`;
  private GET_POUCH_STOCKS_RETURN = `store/smartvalues/myquery/execute/get_pouch_stocks_returns`;
  private COMPARE_POUCH_UNIVERSE_PERFORMANCE = `store/smartvalues/myquery/execute/compare_pouch_universe_performance`;
  private GET_POUCH_INDEX_RATIO = `store/smartvalues/myquery/execute/get_pouch_index_ratios`;
  private GET_POUCH_SUBSCRIBER_STOCKS_RETURNS = `store/smartvalues/myquery/execute/get_pouch_subscriber_stocks_returns`;
  private COMPARE_POUCH_SUBSCRIBER_UNIVERSE_PERFORMANCE = `store/smartvalues/myquery/execute/compare_pouch_subscriber_universe_performance`;
  private STOP_LOSS=`store/smartvalues/myquery/execute/fetch_stop_loss`;

  constructor(private http: HttpClient) {}

  gatewayInstance =this.getSmallcase()
  getSmallcase(){
    const host = window.location.host;
    const subdomain = host.split('.')[0];

    if (subdomain === 'richmee') {
      return {}
    }
   return  new window.scDK({
    // ! Use valid gateway name here
    gateway: 'smartvalues',
    /*
     * ! Use valid auth token
     * Details - https://developers.gateway.smallcase.com/docs/getting-started#broker-identification
     */
    smallcaseAuthToken: environment.smallcaseAuthToken,
    config: {
      // Should allow after market orders?
      amo: true,
    },
  });
  }

  getPouchList(data: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_LIST, data);
  }

  getUniverseList() {
    return this.http.post(environment.baseUrl + this.GET_UNIVERSE_LIST, {});
  }
  getUniverseListNew(payload: any) {
    return this.http.post(
      environment.baseUrl + this.GET_UNIVERSE_LIST,
      payload
    );
  }

  generateTimeframe(data: any) {
    return this.http
      .post(environment.baseUrl_8081 + this.GET_TIMEFRAMES, data)
      .toPromise();
  }

  getStockListByUniverse(data: any) {
    return this.http.post(
      environment.baseUrl + this.GET_STOCKS_BY_UNIVERSE,
      data
    );
  }

  createPouch(data: any) {
    return this.http.post(environment.baseUrl + this.CREATE_POUCH, data);
  }

  editPouch(data: any) {
    return this.http.post(environment.baseUrl + this.EDIT_POUCH, data);
  }

  getPouchStocksReturns(data: any) {
    // data.pouch_id =277
    // data.stock_price_date="2022-07-01"
    return this.http.post(
      environment.baseUrl + this.GET_POUCH_STOCKS_RETURN,
      data
    );
  }

  getComparePouchUniversePerformance(data: any) {
    return this.http.post(
      environment.baseUrl + this.COMPARE_POUCH_UNIVERSE_PERFORMANCE,
      data
    );
  }

  getPouchIndexRatio(data: any) {
    return this.http.post(
      environment.baseUrl + this.GET_POUCH_INDEX_RATIO,
      data
    );
  }

  getPouchSubscriberStocksReturns(data: any) {
    return this.http.post(
      environment.baseUrl + this.GET_POUCH_SUBSCRIBER_STOCKS_RETURNS,
      data
    );
  }

  getComparePouchSubscriberUniversePerformance(data: any) {
    return this.http.post(
      environment.baseUrl + this.COMPARE_POUCH_SUBSCRIBER_UNIVERSE_PERFORMANCE,
      data
    );
  }
  getTransactionId(body: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/get_sc_txnid`,
      body
    );
  }
  selectedSubscriber: any;
  getSubscriberSubscription(body: any) {
    return this.http.post(
      environment.baseUrl +
        `store/smartvalues/myquery/execute/get_subscriber_subscription`,
      body
    );
  }

  deletePouch(payload: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/delete_pouch`,
      payload
    );
  }
  updateManualTxn(payload: any) {
    return this.http.post(
      environment.baseUrl +
        'store/smartvalues/myquery/execute/insert_manual_txn',
      payload
    );
  }
  downloadRebalance(payload: any,type:any) {
    return this.http.post(
      environment.baseUrl +
        `store/smartvalues/myquery/execute/${type==='AVERAGE'?'download_rebalance_details_avg' :'download_rebalance_details'}/csv`,
      payload,
      { responseType: 'text' }
    );
  }
  downloadReturnSummary(payload: any) {
    return this.http.post(
      environment.baseUrl +
        'store/smartvalues/myquery/execute/download_return_summary/csv',
      payload,
      { responseType: 'text' }
    );
  }
  downloadReturnDetails(payload: any) {
    return this.http.post(
      environment.baseUrl +
        'store/smartvalues/myquery/execute/download_return_details/csv',
      payload,
      { responseType: 'text' }
    );
  }

  get_pouch_subscriber_status(payload: any) {
    return this.http.post(
      environment.baseUrl +
        'store/smartvalues/myquery/execute/get_pouch_subscriber_status',
      payload
    );
  }
  deleteTxn(payload: any) {
    return this.http.post(
      environment.baseUrl +
        'store/smartvalues/myquery/execute/delete_txn',
      payload
    );
  }
  getPouchCreationId(body: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/get_sc_txnid_np/`,
      body
    );
  }
  postStopLoss(data: any) {
    return this.http.post(environment.baseUrl + this.STOP_LOSS, data);
  }
}
