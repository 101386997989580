<ng-container>
  <div class="pouch-component ui-update mt-20">
    <div class="container">
      <div class="container-lg" style="width: 100%">
        <ng-container *ngIf="userInfo.role !== 'subscriber'">
          <div class="f-24 fw-600 investment-title white text-center">
            <span class="f-green bold">Investment Pouches</span>
          </div>
        </ng-container>
        <ng-container *ngIf="userInfo.role === 'subscriber'">
          <div class="container-lg" style="width: 100%; margin-top: -5px">
            <div class="f-24 fw-600 investment-title white text-center">
              Our <span class="f-green bold">Investment Pouches</span>
            </div>
            <p class="f-16 fw-400 mt-20 white text-center">
              SMART Pouches carefully tailored to your risk appetite and
              investment budget.
            </p>
          </div>
        </ng-container>
      </div>
      <div class="container-lg white-bg public-bg-wrapper mt-20 mb-60 pd-30">
        <div class="row mb-20">
          <div class="col-md-4 sm-mb-20">
            <p class="f-12 text-grey mb-05">Search</p>
            <!-- <div class="searchBox">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput [(ngModel)]="searchValue" (keyup.enter)="searchHandler($event)"
                                    placeholder="Type atleast 3 characters to search" autocomplete="off"/>
                                <mat-icon (click)="searchHandler($event)">search</mat-icon>
                            </mat-form-field>
                        </div> -->
            <div class="searchBox1 w-100">
              <input
                matInput
                [(ngModel)]="searchValue"
                (keyup.enter)="searchHandler($event)"
                (input)="searchHandler($event)"
                placeholder="Type atleast 3 characters to search"
                autocomplete="off"
              />
              <img
                src="../../../../../assets/images/icons/search_public.svg"
                alt=""
                srcset=""
                (click)="searchHandler($event)"
                class="cursor"
              />
            </div>
          </div>
          <div class="col-md-3 col-10">
            <p class="f-12 text-grey mb-05">Sort by</p>
            <div class="outline-border">
              <mat-form-field appearance="outline" class="w-100">
                <mat-select
                  [(ngModel)]="selectedSort"
                  (selectionChange)="sortOnChange($event)"
                >
                  <mat-option *ngFor="let sd of sortData" [value]="sd.value">{{
                    sd.name
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-2 col-2">
            <div class="filterStyle cursor" (click)="isshowFilter()">
              <img src="assets/images/icons/filter.png" />
            </div>
            <span matBadge="" matBadgeSize="small"></span>
          </div>

          <div class="col-md-3 text-right">
            <button
              mat-button
              color="primary"
              *ngIf="userInfo.role !== 'subscriber' "
              (click)="createButtonClick()"
              class="theme-btn primary-btn mt-20 sm-mt-15 float-btn-botton"
            >
              <!-- Create New -->
              {{ userInfo.role === 'subscriber' ? 'Create Pouch' : 'Create New' }}
            </button>
          </div>
        </div>
        <div class="fiterbox border" *ngIf="showFilter">
          <ng-container *ngIf="userInfo.role === 'subscriber'">
            <form [formGroup]="pouchFilterForm" (ngSubmit)="applyFilters()">
              <div class="fiterbox-row">
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Min Investment</p>
                  <div class="input-flex">
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="amountOperatorInvestment">
                        <mat-option
                          *ngFor="let op of operators"
                          [value]="op.value"
                          >{{ op.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <input
                      matInput
                      type="number"
                      min="0"
                      autocomplete="off"
                      formControlName="min_investment"
                    />
                  </div>
                </div>
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Annualised Returns</p>
                  <div class="input-flex">
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="amountOperatorAnnualized">
                        <mat-option
                          *ngFor="let op of operators"
                          [value]="op.value"
                          >{{ op.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <input
                      matInput
                      type="number"
                      min="0"
                      autocomplete="off"
                      formControlName="annualized_returns"
                    />
                  </div>
                </div>
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Risk Profile</p>
                  <div>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select
                        [multiple]="true"
                        formControlName="riskProfiles"
                        (selectionChange)="
                          selectionChange($event, 'risk_profile')
                        "
                      >
                        <mat-option
                          *ngFor="let rp of risk_profiles"
                          [value]="rp.value"
                          >{{ rp.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="fiterbox-card btn-group">
                  <button
                    mat-button
                    color="primary"
                    class="theme-btn border-btn"
                    (click)="cancelFilters()"
                  >
                    Cancel
                  </button>
                  <button
                    mat-button
                    color="primary"
                    class="theme-btn primary-btn"
                    type="submit"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="userInfo.role !== 'subscriber'">
            <form [formGroup]="pouchFilterForm" (ngSubmit)="applyFilters()">
              <div class="fiterbox-row">
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Investment Amount</p>
                  <div class="input-flex">
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="amountOperator">
                        <mat-option
                          *ngFor="let op of operators"
                          [value]="op.value"
                          >{{ op.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <input
                      matInput
                      type="number"
                      min="0"
                      autocomplete="off"
                      formControlName="amount"
                    />
                  </div>
                </div>
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Risk Profile</p>
                  <div>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select
                        [multiple]="true"
                        formControlName="riskProfiles"
                        (selectionChange)="
                          selectionChange($event, 'risk_profile')
                        "
                      >
                        <mat-option
                          *ngFor="let rp of risk_profiles"
                          [value]="rp.value"
                          >{{ rp.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">CAGR</p>
                  <div class="input-flex">
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="cagrOperator">
                        <mat-option
                          *ngFor="let op of operators"
                          [value]="op.value"
                          >{{ op.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <input
                      matInput
                      type="number"
                      min="0"
                      autocomplete="off"
                      formControlName="cagr"
                    />
                  </div>
                </div>
                <div class="fiterbox-card">
                  <p class="f-12 text-grey mb-05">Investment Strategy</p>
                  <div>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select
                        [multiple]="true"
                        formControlName="strategies"
                        (selectionChange)="
                          selectionChange($event, 'invstment_strategy')
                        "
                      >
                        <mat-option
                          *ngFor="let inv of investment_strategies"
                          [value]="inv.value"
                        >
                          {{ inv.name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="fiterbox-card btn-group">
                  <button
                    mat-button
                    color="primary"
                    class="theme-btn border-btn"
                    (click)="cancelFilters()"
                  >
                    Cancel
                  </button>
                  <button
                    mat-button
                    color="primary"
                    class="theme-btn primary-btn"
                    type="submit"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </form>
          </ng-container>
        </div>
        <app-details
        *ngIf="pouches"
          [search]="searchValue"
          [sortId]="selectedSort"
          [userType]="userInfo.role"
          [pouches]="pouches"
          [showGhost]="showGhost"
        ></app-details>
      </div>
    </div>
  </div>
  <form #myForm method="post" [action]="paymentUrl" ngNoForm  hidden>
    <input id="encRequest" name="encRequest" [(ngModel)]="paymentData.encRequest" />
    <input id="access_code" name="access_code" [(ngModel)]="paymentData.access_code" />
    <button type="submit"></button>
  </form>
</ng-container>
