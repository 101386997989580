import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublicService } from '../../services/public.service';
import { ContactSuccessPopupComponent } from './contact-success-popup/contact-success-popup.component';
import { NotificationService } from 'src/app/services/notification.services';

@Component({
  selector: 'app-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss'],
})
export class ContactUsDialogComponent implements OnInit {
  contactUsForm!: FormGroup;
  emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  constructor(private dialogRef: MatDialogRef<ContactUsDialogComponent>,
    private notifyService: NotificationService,
    private _publicService:PublicService,private _dialog:MatDialog,
    ) {}

  ngOnInit(): void {
    this.createForm();
  }
  convertToLowercase(event: any) {
    event.target.value = event.target.value.toLowerCase();
  }
  createForm() {
    this.contactUsForm = new FormGroup({
      name: new FormControl('', { validators: [Validators.required] }),
      email: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(this.emailValidation),
        ],
      }),
      contactNumber: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern('^[0-9]+$'),
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      }),
      comment: new FormControl('', { validators: [Validators.required] }),
    });
  }
  submit() {  
    if(this.contactUsForm.valid){
      let contactUsFormValue = this.contactUsForm.value;
      let data = {
        docs: [
          {
            contact_name: contactUsFormValue.name,
            email_id: contactUsFormValue.email,
            contact_no: contactUsFormValue.contactNumber,
            description: contactUsFormValue.comment,
          },
        ],
      };
      this._publicService.contactUs(data).subscribe((res)=>{
       if(res){
      this.dialogRef.close()
        this._dialog.open(ContactSuccessPopupComponent)
       }
      },(e)=>{
      this.dialogRef.close()
        this.notifyService.showError('',  'Oops, Something went wrong!');
      })
      
    }

  }
  close() {
    this.dialogRef.close();
  }
  public errorHandling = (control: string, error: string) => {
    return this.contactUsForm.controls[control].hasError(error);
  };
  isNumberKey(evt:any) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
}
