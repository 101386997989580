import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StockPopupComponent } from '../more/components/stock-popup/stock-popup.component';


@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss'],
})
export class MoreComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goStock() {
    this.router.navigateByUrl("more/stock");
  }
  universe() {
    this.router.navigateByUrl("more/universe");
  }
  subscriber() {
    this.router.navigateByUrl("more/subscriber");
  }
  holiday() {
    this.router.navigateByUrl("more/holiday");
  }
  promocode() {
    this.router.navigateByUrl("more/promocode");
  }
  referral() {
    this.router.navigateByUrl("more/referral");
  }
  adminUser() {
    this.router.navigateByUrl("more/admin-user");
  }
  refUserList() {
    this.router.navigateByUrl("more/referrer-user-list");
  }
  pouchpayments(){
    this.router.navigateByUrl("more/pouch-payments");
  }
  bonusSplit(){
    this.router.navigateByUrl("more/bonus-split");
  }
}
