<div class="row popup-container">
  <div class="row f-16 fw-600 l-24 border-bottom pb-10">Update Amount</div>
  <!-- Type of update amount -->
  <div class="row mt-20">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="radioSelected"
      (ngModelChange)="radioChange(radioSelected)">
      <mat-radio-button value="Top Up">Top Up</mat-radio-button>
      <mat-radio-button class="ml-20" value="Sell Down">Sell Down</mat-radio-button>
    </mat-radio-group>
  </div>
  <!--  -->
  <form [formGroup]="updateAmount" (ngSubmit)="onSubmit()">
    <div class="row align-items-center mt-20">
      <div class="col-md-6 mb-15">
        <div>
          <p class="f-12 form-text mb-05 fw-400">{{ radioSelected }} Amount</p>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-100">
            <input #amnt matInput type="number" formControlName="Amount" (input)="onInputChange($event)" />
          </mat-form-field>
          <mat-error *ngIf="
              updateAmount.controls.Amount.invalid &&
              (updateAmount.controls.Amount.dirty ||
                updateAmount.controls.Amount.touched)
            ">
            <span *ngIf="
                updateAmount.controls.Amount.hasError('required') ||
                !this.updateAmount.valid
              " class="position-absolute">Please enter an amount</span>
          </mat-error>
        </div>
      </div>
      <div class="col-md-2 mt-10">
        <button mat-button class="theme-btn primary-btn" [disabled]="amnt.value && buttonDisable">
          <ng-container *ngIf="buttonDisable">
            <mat-icon><mat-spinner color="primary" diameter="20"> </mat-spinner></mat-icon>
          </ng-container>
          <ng-container *ngIf="!buttonDisable">
            <img src="../../../../../assets/images/icons/arrow-right.png" alt="" />
          </ng-container>
        </button>
      </div>
    </div>

  </form>
  <div class="row" style="max-height: 150px;overflow-y: auto;">
    <div class="row f-14 mat-error" *ngIf="
    updateAmount?.get('Amount')?.value < math.round(minInverstment) &&
    minInverstmentError && radioSelected === 'Top Up'
  ">
      Your top up balance is less than the minimum amount required for this
      pouch. If you proceed, your pending transaction may not contain all
      the stocks in the pouch. If you wish to top up and include all the stocks
      in the pouch, please top up with Rs {{ minInverstment | number : "1.0-0"}}. which is the
      minimum amount required for the pouch.
    </div>
    <div class="row">
      <div class="col-md-12 mt-20 fw-400 f-14" *ngIf="investmentAmount">
        Revised Investment amount :
        <span class="f-16 fw-600" *ngIf="radioSelected === 'Top Up'">Rs {{ revisedInverstmentAmount | number : "1.0-0"
          }}</span>
        <span class="f-16 fw-600" *ngIf="radioSelected === 'Sell Down'">
          <span *ngIf="revisedInverstmentAmount > 0">Rs {{ revisedInverstmentAmount | number : "1.0-0" || 0 }}</span>
          <span *ngIf="revisedInverstmentAmount == 0">Rs {{ revisedInverstmentAmount | number : "1.0-0" || 0 }}</span>
          <span *ngIf="0 > revisedInverstmentAmount" style="color: red">(Rs
            {{
            math.abs(revisedInverstmentAmount) | number : "1.0-0" || 0
            }})</span>
        </span>
      </div>
      <div class="col-md-12 mt-20 fw-400 f-14" *ngIf="revisedSubscription">
        Your revised Subscription fee is as under
      </div>
  
      <div class="row align-items-center">
        <div class="col-sm-6 mt-20 fw-400 f-14 offset-sm-1" *ngIf="revisedSubscription">
          Revised Subscription Amount :
        </div>
        <div *ngIf="revisedSubscription" class="f-16 fw-600 mt-20 col-sm-5 marginChange-10">
          Rs {{ reviseSubsCriptionFee | number : "1.0-0" || 0 }}
        </div>
        <div class="col-sm-6 mt-20 fw-400 f-14 offset-sm-1" *ngIf="revisedSubscription">
          Subscription Amount Paid :
        </div>
        <div *ngIf="revisedSubscription" class="f-16 fw-600 mt-20 col-sm-5 marginChange-10">
          Rs {{ subscriptionAmountPaid | number : "1.0-0" || 0 }}
        </div>
        <div class="col-sm-6 mt-20 fw-400 f-14 offset-sm-1"
          *ngIf="balanceSubscriptionAmountPaid > 0 && revisedSubscription">
          Balance Subscription Amount :
        </div>
        <div *ngIf="balanceSubscriptionAmountPaid > 0 && revisedSubscription"
          class="f-16 fw-600 mt-20 col-sm-5 marginChange-10">
          Rs {{ balanceSubscriptionAmountPaid | number : "1.0-0" || 0 }}
          <span (click)="goToPayment(balanceSubscriptionAmountPaid)" class="text-blue pointer"
            style="text-decoration: underline">pay</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 justify-end mt-20 border-top button-container d-flex">
    <div class="">
      <button mat-dialog-close mat-button class="theme-btn border-btn f-14 fw-500 mt-10">
        Cancel
      </button>
    </div>
    <div class="ml-20">
      <button mat-button class="theme-btn primary-btn f-12 fw-600 mt-10" [disabled]="showPay"
        (click)="saveAmount(radioSelected)">
        Save
      </button>
    </div>
  </div>
</div>