import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent implements OnInit {
  public bgColor: boolean;
  public hideHeader: boolean;
  richmeeHeader:boolean = false
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      let routes = [
        '/',
        '/public/landing-page',
        '/public/dashboard',
        'public/privacy-policy',
        '/public/terms-and-conditions',
      ];

      if (event instanceof NavigationEnd) {
        if (
          event.url === '/' ||
          event.url === '/public/landing-page' ||
          event.url === '/public' ||
          event.url === 'public/privacy-policy' ||
          event.url === '/public/terms-and-conditions'
        ) {
          this.bgColor = true;
        } else {
          this.bgColor = false;
        }
        if (
          event.url === '/public/dashboard' ||
          event.url === '/public/pouch-creation' ||
          event.url === 'public/privacy-policy' ||
          event.url === '/public/terms-and-conditions'
        ) {
          this.hideHeader = true;
          this.bgColor = false;
        } else {
          this.hideHeader = false;
          this.bgColor = true;
        }
      }
    });
  }
  ngOnInit(): void {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
  
    if (subdomain === 'richmee') {
      this.richmeeHeader = true
      return
    }else{
      this.richmeeHeader = false
    }
  }

  onLogin() {
    this.router.navigateByUrl('/auth/login');
  }
  onRegister() {
    this.router.navigateByUrl('/register');
  }
  goToHome() {
    // this.router.navigateByUrl('/')
    location.href = '/';
  }
}
