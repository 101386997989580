<div id="paymentcontainer">
  <ng-container >
    <form #myForm method="post" [action]="paymentUrl" ngNoForm  hidden>
      <input id="encRequest" name="encRequest" [(ngModel)]="paymentData.encRequest" />
      <input id="access_code" name="access_code" [(ngModel)]="paymentData.access_code" />
      <button type="submit"></button>
    </form>
  </ng-container>

</div>
