import {  
  Directive,  
  HostListener,  
  EventEmitter,  
  Output,  
  HostBinding,  
} from '@angular/core';  
import {  
  FileHandle  
} from '../common/interfaces/file-handle';  
import {  
  DomSanitizer  
} from '@angular/platform-browser'; 

@Directive({
  selector: '[appImageDrag]'
})
export class ImageDragDirective {  
  @Output() file: EventEmitter <FileHandle> = new EventEmitter();  
  @HostBinding('style.background') public background = '#eee';  
  constructor(private sanitizer: DomSanitizer) {}  
  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {  
      evt.preventDefault();  
      evt.stopPropagation();  
      this.background = '#999';  
  }  
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {  
      evt.preventDefault();  
      evt.stopPropagation();  
      this.background = '#eee';  
  }  
  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {  
      evt.preventDefault();  
      evt.stopPropagation();  
      this.background = '#eee';  
      let fileHandle : FileHandle;    
      const file : any = evt.dataTransfer ?  evt.dataTransfer.files[0] : '';  
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));  
      fileHandle = {
        file : file,
        url : url
      }       
      if (fileHandle) {  
          this.file.emit(fileHandle);  
      }  
  }  
}   
