<div class="popup-section pouch-width" >
      <div class="popup-header d-flex">
        <p class="f-16 fw-600">Smart Stocks</p>
        <mat-icon mat-dialog-close>close</mat-icon>
      </div>
      <div class="popup-body">
        <p>
          Your Smart Stock subscription will expire on {{expiredDate| date :'dd MMMM yyyy'}}
        </p>
        <form #myForm method="post" action="{{ paymentUrl }}" ngNoForm hidden>
          <input name="encRequest" id="encRequest" [(ngModel)]="paymentData.encRequest" />
          <input name="access_code" id="access_code" [(ngModel)]="paymentData.access_code" />
        </form>
        <div class="text-greyf-14 fw-500 sb-text">
          <div class="row">
            <div class="col-md-6">
              <div>
                <p class="f-16 fw-600" style="margin-bottom: 0 !important;">Billing Details</p>
                <div class="address">
                  <form [formGroup]="form">
                    <div>
                      <mat-label>Address</mat-label>
                      <mat-form-field appearance="outline" class="w-100">         
                        <textarea [ngClass]="{'border_red':form.get('address')?.invalid}" matInput placeholder="Address" formControlName="address" ></textarea>
                      </mat-form-field>
                      <mat-error class="f-12" *ngIf="form.get('address')?.invalid">
                        Invalid Address
                    </mat-error>
                    </div>
                    <div class="row"  style="margin-top: 7px;">
                      <div class="col-md-6">
                        <div>
                          <mat-label>Country</mat-label>
                          <mat-form-field appearance="outline" class="w-100">          
                            <mat-select [ngClass]="{'select_error':form.get('country')?.invalid}" placeholder="Country" formControlName="country">
                              <mat-option *ngFor="let c of getCountry" [value]="c.country_name">{{c.country_name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error class="f-12" *ngIf="form.get('country')?.invalid">
                            Invalid Country
                        </mat-error>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <mat-label>State</mat-label>
                          <mat-form-field appearance="outline" class="w-100">          
                            <mat-select [ngClass]="{'select_error':form.get('state')?.invalid}" placeholder="Select" formControlName="state">
                              <mat-option *ngFor="let s of getStateName" [value]="s.state_name">{{s.state_name}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error class="f-12" *ngIf="form.get('state')?.invalid">
                            Invalid State
                        </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="row"  style="margin-top: 5px;">
                      <div class="col-md-6">
                        <div>
                          <mat-label>City</mat-label>
                          <mat-form-field appearance="outline" class="w-100">          
                            <input [ngClass]="{'border_red':form.get('city')?.invalid}" matInput placeholder="City" formControlName="city">
                          </mat-form-field>
                          <mat-error class="f-12" *ngIf="form.get('city')?.invalid">
                            Invalid City
                        </mat-error>
                        </div>
                      </div>
                    <div class="col-md-6">
                      <div>
                        <mat-label>Pincode</mat-label>
                        <mat-form-field appearance="outline" class="w-100">        
                          <input [ngClass]="{'select_error':form.get('pincode')?.invalid}" (keypress)="numberOnly($event);allowNumber($event)" matInput placeholder="Pincode" formControlName="pincode"  maxlength="6" type="tel" (paste)="onPasteAllowNumber($event)" (input)="allowNumber($event)">
                        </mat-form-field>
                        <mat-error class="f-12" *ngIf="form.get('pincode')?.invalid">
                          Invalid Pincode
                      </mat-error>
                      </div>
                    </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6" style="padding-left: 15px;">
              <div class="text-start">
                <div>Renew this feature at Rs: </div>
                <div class="py-1" *ngFor="let s of subscriptionList; let i = index">
                  <mat-radio-group aria-label="Select an option" [(ngModel)]="selected">
                    <mat-radio-button id="{{ 'radio-sub' + s.rowIndex }}" (change)="selected = s.rowIndex"
                      [value]="s.rowIndex - 1">
                      {{ s.subscription_amount|number }} {{ s.subscription_freq === 'MONTHLY' ? 'for a' : 'for' }}
                      {{ getText(s.subscription_freq) }}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        <!-- <div class="text-greyf-14 fw-500 sb-text">
          <div class="text-start">
            <div>Renew this feature at Rs: </div>
            <div class="py-1" style="margin-left: 38%" *ngFor="let s of subscriptionList; let i = index">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="selected">
                <mat-radio-button id="{{ 'radio-sub' + s.rowIndex }}" (change)="selected = s.rowIndex"
                  [value]="s.rowIndex - 1">
                  {{ s.subscription_amount|number }} {{ s.subscription_freq === 'MONTHLY' ? 'for a' : 'for' }}
                  {{ getText(s.subscription_freq) }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div> -->
      </div>  
      <div class="popup-footer text-center d-flex justify-center">
        <div class="col-md-3" style="margin-left: 10px">
          <button (click)="renewNow('renewNow')" mat-button class="theme-btn primary-btn w-100">
            Renew Now
          </button>
        </div>
      </div>
    
  </div>