<div class="dashboard-details">
  <div class="welcome_dashboard">
    <div class="container">
      <h1 class="f_white">
        Welcome
        <span class="f-l_green">{{ loggedinUser?.name.first }} {{ loggedinUser?.name.last }}</span>
      </h1>
      <div style="margin-top: 14px">
        <div class="welcome_hud">
          <div class="row hud_details_mobView addPD">
            <div class="col-md-3 col-6 hud_details">
              <div class="img-icon">
                <img src="../../../../../assets/images/icons/pouches_ico.svg" alt="" />
              </div>
              <h3>{{ dashboardHudData.totalPouches || 00 }}</h3>
              <span class="sub-text">Your Pouches</span><br />
              <span class="f-12 f-l_green view-text cursor" (click)="goTo(0)">VIEW ALL</span>
            </div>
            <div class="col-md-3 col-6 hud_details">
              <div class="img-icon">
                <img src="../../../../../assets/images/icons/investment_ico.svg" alt="" />
              </div>
              <h3>{{ dashboardHudData.totalInvestments || 00.0 }}L</h3>
              <span class="sub-text">Total Investments</span><br />
              <span class="f-12 f-l_green view-text cursor" (click)="goTo(0)">VIEW ALL</span>
            </div>
            <div class="col-md-3 col-6 hud_details">
              <div class="img-icon">
                <img src="../../../../../assets/images/icons/returns_ico.svg" alt="" />
              </div>
              <h3>{{ dashboardHudData.totalReturns || 00|number: "1.2-2" }}%</h3>
              <span class="sub-text">Total Returns</span><br />
              <span class="f-12 f-l_green view-text cursor" (click)="goTo(0)">VIEW ALL</span>
            </div>
            <div class="col-md-3 col-6 align-self-center">
              <button mat-flat-button class="button_explore" (click)="goTo(0)">
                Explore Pouches
              </button>
            </div>
          </div>
        </div>
        <div style="width: 90%; color: white" class="container dashboard-stock-price">
          <div class="row">
            <div class="col-md-3">
              <div class="text-left">
                <span style="font-size: 12px">
                  Stock price last uploaded on
                </span>
                <br />
                <span style="padding-top: 6px">
                  <strong>{{ date | date: "longDate" }}</strong>
                </span>
              </div>
            </div>
            <div class="col-md-9 text-left">
              <span style="font-size: 12px">
                Stocks with major price change</span>
              <br />
              <ul style="padding-top: 6px" class="p-0 ulStyle f-14">
                <li *ngIf="this.dashboardHudData.topGainers?.length > 0" class="border_right">
                  <div class="d-flexM">
                    <div class="amw">
                      <strong>{{
                        this.dashboardHudData.topGainers[0].stock_code
                        }}</strong>
                    </div>
                    <div style="padding-left: 5px; padding-right: 5px">
                      <img src="../../../../../assets/images/icons/up.png" alt="" />
                    </div>
                    <div>{{ this.dashboardHudData.topGainers[0].roc }}%</div>
                  </div>
                </li>
                <li *ngIf="this.dashboardHudData.topGainers?.length > 0" class="border_right">
                  <div class="d-flexM">
                    <div class="amw">
                      <strong>{{
                        this.dashboardHudData.topGainers[1].stock_code
                        }}</strong>
                    </div>
                    <div style="padding-left: 5px; padding-right: 5px">
                      <img src="../../../../../assets/images/icons/up.png" alt="" />
                    </div>
                    <div>{{ this.dashboardHudData.topGainers[1].roc }}%</div>
                  </div>
                </li>
                <li *ngIf="this.dashboardHudData.topLosers?.length > 0" class="border_right">
                  <div class="d-flexM">
                    <div class="amw">
                      <strong>
                        {{
                        this.dashboardHudData.topLosers[0].stock_code
                        }}</strong>
                    </div>
                    <div style="padding-left: 5px; padding-right: 5px">
                      <img src="../../../../../assets/images/icons/down.png" alt="" />
                    </div>
                    <div>{{ this.dashboardHudData.topLosers[0].roc }}%</div>
                  </div>
                </li>
                <li *ngIf="this.dashboardHudData.topLosers?.length > 0">
                  <div class="d-flexM">
                    <div class="amw">
                      <strong>
                        {{
                        this.dashboardHudData.topLosers[1].stock_code
                        }}</strong>
                    </div>
                    <div style="padding-left: 5px; padding-right: 5px">
                      <img src="../../../../../assets/images/icons/down.png" alt="" />
                    </div>
                    <div>{{ this.dashboardHudData.topLosers[1].roc }}%</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-lg public-bg-wrapper mt-10 mb-60 pd-30" style="background-color: #f9fbff">
      <ng-container>
        <div class="row" style="position: relative">
          <p class="f-18 fw-600" style="text-align: left">Your Pouches</p>

          <div class="row" style="position: relative" *ngIf="yourPouch?.length === 0">
            <div class="yPouch col-md-10">
              <div style="width: 40% !important">
                <p style="text-align: left">
                  You have not subscribed to any pouches. Please
                  <span class="text-blue" style="cursor: pointer" (click)="subscribe()">subscribe</span>
                  to pouches or
                  <span class="text-blue" (click)="create()" style="cursor: pointer">create</span>
                  your own pouch.
                </p>
              </div>
              <div class="bb">
                <span> </span>
              </div>
            </div>

            <div class="col-md-2 text-right imgcheck">
              <img src="../../../../../assets/images/pouch_bg.svg" />
            </div>
          </div>
          <div class="d-flex cart cart-mobile" *ngIf="yourPouch?.length !== 0">
            <div class="col-md-4 bd cursor" *ngFor="let item of filterPouches(yourPouch)" (click)="goToPouch(item.id)">
              <div style="padding: 20px 30px 0px">
                <div>
                  <img [src]="item.src || '/assets/images/icons/cart-img.svg'" />
                </div>
                <div class="f-16 fw-600 text-darkgrey mt-10">
                  {{ item.name }}
                </div>
                <div class="row cart-wrapper">
                  <div class="col-4 col-sm-4 col-md-4">
                    <div>
                      <div class="fs-12 fw-400 text-grey">Subscribed</div>
                      <div class="text-darkgrey fw-500 f-14">
                        {{ item.subscription_date | date: "dd MMM yyyy" }}
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-4 col-md-4">
                    <div>
                      <div class="fs-12 fw-400 text-grey">Subscription</div>
                      <div class="text-darkgrey fw-500 f-14">
                        {{ item.public___pouches__review_freq }}
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-4 col-md-4">
                    <div>
                      <div class="fs-12 fw-400 text-grey">Your Returns</div>
                      <div class="text-darkgrey fw-500 f-14">
                        {{ item.return || 0 }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div class="fs-12 fw-600 f-black mt-10">
                  Annualised Return
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 fs-12 fw-400 text-grey">
                      This Pouch
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 img-check">
                      <img src="../../../../../assets/images/icons/cart_rect.png" />
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 fs-12 fw-400 text-grey">
                      {{ item.pouch || 0 }}%
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 fs-12 fw-400 text-grey">
                      NIFY50
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 img-check">
                      <img src="../../../../../assets/images/icons/cart_rect2.png" />
                    </div>
                    <div class="col-1 col-sm-1 col-md-1"></div>
                    <div class="col-4 col-sm-4 col-md-4 fs-12 fw-400 text-grey">
                      {{ item.nify50 || 0 }}%
                    </div>
                  </div>
                </div>
                <div style="text-align: right !important" class="text-grey mt-30 fs-12 text-grey fw-400">
                  *since subscription
                </div>
              </div>
            </div>
          </div>
          <div class="f-14 fw-400 text-blue mt-10 pointer" *ngIf="yourPouch?.length !== 0" (click)="goTo(1)">
            View All
          </div>
        </div>
      </ng-container>
      <div class="my-2">
        <mat-divider></mat-divider>
      </div>

      <div>
        <p class="f-18 fw-600 mt-1" style="text-align: left">
          Pouches Matching Your Risk Tolerance
        </p>
        <div class="d-flex row">
          <ng-container *ngIf="tolerance?.length > 0; else noPouch">
            <div class="col-md-12 tolerance cursor" (click)="goToPouch(item.id)"
              *ngFor="let item of filterPouches(tolerance).slice(0, 2)">
              <div class="row d-flex align-center tolerance-style">
                <div class="col-2 col-sm-2 col-md-1" style="text-align: center; padding-left: 0">
                  <img [src]="item.src || '/assets/images/icons/cart-img.svg'" />
                </div>
                <div class="col-7 col-sm-7 col-md-7">
                  <div class="f-16 fw-600 text-darkgrey heading">
                    {{ item.name || "-" }}
                  </div>
                  <div class="f-14 fw-400 text-grey">
                    {{ item.description || "-" }}
                  </div>
                </div>
                <div class="col-5 col-sm-5 col-md-2 test" *ngIf="this.userInfo.role === 'subscriber'">

                </div>
                <div class="col-5 col-sm-5 col-md-2 test" *ngIf="this.userInfo.role !== 'subscriber'">
                  <li class="f-12 fw-400 text-grey">Subscribers:</li>
                  <li class="f-14 fw-400 text-black" style="margin-top: 5px">
                    {{ item.subscriber_count || "-" }}
                  </li>
                </div>
                <div class="col-7 col-sm-7 col-md-2 test">
                  <li class="f-12 fw-400 text-grey">Returns:</li>
                  <li class="f-14 fw-400 text-black" style="margin-top: 5px">
                    {{ item.return_perc }}% since
                    {{ item.start_date | date: "MMM yyyy" }}
                  </li>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noPouch>
            <span class="f-14 fw-400 text-black" style="margin-top: 5px">
              We couldn't find any pouch matching your risk profile
            </span>
          </ng-template>
        </div>
        <div *ngIf="tolerance?.length > 0" class="f-14 fw-400 text-blue mt-10 pointer" style="text-align: right"
          (click)="goTo(0)">
          View All
        </div>
      </div>

      <div class="mt-10">
        <p class="f-18 fw-600" style="text-align: left">
          Pouches Giving Most Returns
        </p>
        <div class="d-flex row">
          <div class="col-md-12 tolerance" *ngFor="let item of filterPouches(returns).slice(0, 2)"
            (click)="goToPouch(item.id)">
            <div class="row d-flex align-center cursor tolerance-style">
              <div class="col-2 col-sm-2 col-md-1" style="text-align: center; padding-left: 0">
                <img [src]="item.src || '/assets/images/icons/cart-img.svg'" />
              </div>
              <div class="col-7 col-sm-7 col-md-7">
                <div class="f-16 fw-600 text-darkgrey heading">
                  {{ item.name || "-" }}
                </div>
                <div class="f-14 fw-400 text-grey">
                  {{ item.description || "-" }}
                </div>
              </div>
              <div class="col-5 col-sm-5 col-md-2 test" *ngIf="this.userInfo.role === 'subscriber'">

              </div>
              <div class="col-5 col-sm-5 col-md-2 test" *ngIf="this.userInfo.role !== 'subscriber'">
                <li class="f-12 fw-400 text-grey">Subscribers:</li>
                <li class="f-14 fw-400 text-black" style="margin-top: 5px">
                  {{ item.subscriber_count || "-" }}
                </li>
              </div>
              <div class="col-7 col-sm-7 col-md-2 test">
                <li class="f-12 fw-400 text-grey">Returns:</li>
                <li class="f-14 fw-400 text-black" style="margin-top: 5px">
                  {{ item.return_perc|| "-" }}% since
                  {{ item.start_date | date: "MMM yyyy" }}
                </li>
              </div>
            </div>
          </div>
          <div class="f-14 fw-400 text-blue mt-10 pointer" style="text-align: right" (click)="goTo(0)">
            View All
          </div>
        </div>
      </div>
    </div>
  </div>
</div>