import { OnBoardingService } from './../modules/onbording/services/onboarding.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { AppActionTypes, Logout } from '../store/actions/logout.action';
import { Store } from '@ngrx/store';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  logginUser: string = '';
  isPublic: any = false;
  hideElement = true;
  role: any;
  isSideBarActive: boolean = false;
  istoggleActive: boolean = false;
  url: any;
  onlyPublic: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store,
    private OnBoardingService: OnBoardingService
  ) {}
  ngOnInit() {
    this.role = '';
    this.router.events.subscribe((event) => {
      let routes = [
        '/',
        '/login',
        '/auth',
        '/auth/login',
        '/auth/new-password',
        // '/onboarding',
        // '/onboarding/questionaire',
        // '/onboarding/multiplechoice',
        '/onboarding/declaration',
        '/auth/register',
        '/auth/otp',
        '/register',
        // '/onboarding/questionaire/smartbeta',
        // '/onboarding/questionaire/smartvalue',
        '/register/smartbeta',
        '/public',
        '/public/landing-page',
        '/public/privacy-policy',
        '/public/terms-and-conditions',
        // '/public/pouchlist-details',
        // '/public/subscription-plan',
      ];
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.hideElement = routes.includes(event.url) ? true : false;
      }
    });
    this.authService.onUserUpdate.subscribe((data) => {
      this.logginUser = `${data.attributes.name.first} ${data.attributes.name.last}`;
      this.role = data.attributes.role;
    });

    if (!this.logginUser) {
      const storageUser: any = localStorage.getItem('user');
      const user = JSON.parse(storageUser);
      if (user) {
        this.logginUser = `${user.attributes.name.first} ${user.attributes.name.last}`;
        this.role = user.attributes.role;
      }
    }
  }
  togglesideBar() {
    this.isSideBarActive = !this.isSideBarActive;
    this.istoggleActive = !this.istoggleActive;
  }
  onLogout(): void {
    const refreshToken: any = localStorage.getItem('refreshToken');
    const id: any = localStorage.getItem('id');
    this.authService.getTokens(refreshToken,id).subscribe((response) => {
      this.authService.setTokens(response);
      this.authService.logoutUser().subscribe((res: any) => {
        localStorage.clear();
        this.store.dispatch(new Logout());
        if (this.OnBoardingService.questionsData) {
          this.OnBoardingService.questionsData = null;
        }
        this.router.navigateByUrl('/auth/login');
      });
    });

    localStorage.clear();
    this.router.navigateByUrl('/auth/login');
  }
  changePassword(): void {
    this.router.navigateByUrl('/auth/change-password');
  }
  closeMenu1(e: any) {
    //need to call
    // this.togglesideBar();
  }
}
