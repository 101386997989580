import { Action } from "@ngrx/store";
export enum UniverseActionTypes {
  LOAD = "[Universe] Load",
  ADDEDIT = "[Universe] AddEdit",
  ADDEDIT_SUCCESS = "[Universe] AddEdit Success",
  ADDEDIT_FAILURE = "[Universe] AddEdit Failure",

  LOAD_STOCK = "[Stock] Load",
  ADD_SELECTED_STOCKS = "[Stock] Add Selected",
  UPDATE_STOCKS = "[Stock] Update",
  LOAD_STOCK_SUCCESS = "[Stock] Load Success",
  LOAD_STOCK_FAILURE = "[Stock] Load Failure",

  CREATE_UNIVERSE = "[Universe] Create",
  CREATE_UNIVERSE_SUCCESS = "[Universe] Create Universe Success",
  CREATE_UNIVERSE_FAILURE = "[Universe] Create Universe Failure",

  LOAD_SEARCH_RESULTS = "[Search] Load ",
  LOAD_SEARCH_RESULTS_SUCCESS = "[Search] Load Success",
  LOAD_SEARCH_RESULTS_FAILURE = "[Search] Load Failure",

  NAVIGATED_UNIVERSE = "[Universe] Selected Universe",
  NAVIGATED_UNIVERSE_SUCCESS = "[Universe] Selected Universe Success",
  RESET = "[Universe] Reset",
}
export class Load implements Action {
  readonly type = UniverseActionTypes.LOAD;
  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = UniverseActionTypes.RESET;
  constructor(public payload: any) {}
}
 
export class AddEdit implements Action {
  readonly type = UniverseActionTypes.ADDEDIT;
  constructor(public payload: any) {}
}

export class AddEditSuccess implements Action {
  readonly type = UniverseActionTypes.ADDEDIT_SUCCESS;
  constructor(public payload: any) {}
}

export class AddEditFailure implements Action {
  readonly type = UniverseActionTypes.ADDEDIT_FAILURE;
  constructor(public payload: any) {}
}


export class LoadStocks implements Action {
  readonly type = UniverseActionTypes.LOAD_STOCK;
  constructor(public payload: any) {}
}

export class UpdateStocks implements Action {
  readonly type = UniverseActionTypes.UPDATE_STOCKS;
  constructor(public payload: any) {}
}

export class AddSelectedStocks implements Action {
  readonly type = UniverseActionTypes.ADD_SELECTED_STOCKS;
  constructor(public payload: any) {}
}

export class LoadStockSuccess implements Action {
  readonly type = UniverseActionTypes.LOAD_STOCK_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadStockFailure implements Action {
  readonly type = UniverseActionTypes.LOAD_STOCK_FAILURE;
  constructor(public payload: any) {}
}


export class CreateUniverse implements Action {
  readonly type = UniverseActionTypes.CREATE_UNIVERSE;
  constructor(public payload: any) {}
}

export class CreateUniverseSuccess implements Action {
  readonly type = UniverseActionTypes.CREATE_UNIVERSE_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateUniverseFailure implements Action {
  readonly type = UniverseActionTypes.CREATE_UNIVERSE_FAILURE;
  constructor(public payload: any) {}
}

export class LoadSearchResult implements Action {
  readonly type = UniverseActionTypes.LOAD_SEARCH_RESULTS;
  constructor(public payload: any) {}
}

export class LoadSearchResultSuccess implements Action {
  readonly type = UniverseActionTypes.LOAD_SEARCH_RESULTS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadSearchResultFailure implements Action {
  readonly type = UniverseActionTypes.LOAD_SEARCH_RESULTS_FAILURE;
  constructor(public payload: any) {}
}

export class NavigateUniverse implements Action {
  readonly type = UniverseActionTypes.NAVIGATED_UNIVERSE;
  constructor(public payload: any) {}
}

export class NavigateUniverseSuccess implements Action {
  readonly type = UniverseActionTypes.NAVIGATED_UNIVERSE_SUCCESS;
  constructor(public payload: any) {}
}

export type All = AddEdit | AddEditSuccess | AddEditFailure 
| LoadStocks | UpdateStocks | AddSelectedStocks | LoadStockSuccess| LoadStockFailure | CreateUniverse|
 CreateUniverseSuccess| CreateUniverseFailure |
 LoadSearchResult | LoadSearchResultSuccess | LoadSearchResultFailure | NavigateUniverse |
 NavigateUniverseSuccess | Reset ;