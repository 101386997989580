<div class="containers mt-20" style="padding-bottom: 20px;">
  <div class=""  *ngIf="showUpdate">
  <div *ngIf="userRole === 'subscriber'"   class="d-flex align-items-center justify-content-end px-3" style="padding-right: 61px!important;">
    <button mat-button class="theme-btn primary-btn "  (click)="showUpdates()">
      Update
    </button>
  </div>
  </div>
<div class="mx-4  d-flex flex-directions" *ngIf="showUpdate" >  
<div class="d-flex row">
  <div *ngIf="aadhar && loaded"  class="mt-30 col-lg-5 ">
    <div class="mx-3 px-3">
      <div *ngIf="aadhar && loaded">
        <div class="w-50">
          <div class="mb-2"  *ngIf="userRole === 'admin' || userRole === 'subscriber'">AADHAR (Front Side)</div>
          <div>
            <embed
              *ngIf="aadhar && !reupload.aadhar && (userRole === 'admin' || userRole === 'subscriber')"
              [src]="aadhar.file"
              height="200px"
              width="330px"
              #aadharframe
            />

            <!-- <ngx-dropzone
              (change)="onSelect($event)"
              *ngIf="reupload.aadhar"
              accept="image/png, image/jpeg,application/pdf"
              class="dnd-container"
              id="AADHAR"
            >
              <ngx-dropzone-label class="mt-14">
                <p class="dnd_text">Drag and drop file here</p>
                <p class="dnd_text">OR</p>
                <p class="mt-11 browse-btn">Browse</p>
                <p class="font-grey mt-7 pb-16">
                  (Supported files : jpeg, png, .pdf)
                </p>
              </ngx-dropzone-label>
            </ngx-dropzone>
            <ng-container>
              <div>
                <div *ngIf="uploadFileProcess.aadhar.showProgressForAadhar">
                  <div class="p-flex pt-1 mt-3">
                    <mat-progress-bar
                      class="mat-progress-bar"
                      mode="determinate"
                      [value]="uploadFileProcess.aadhar.progressValueForAadhar"
                    >
                    </mat-progress-bar>

                    <img
                      [id]="uploadFileProcess.aadhar.aadharFileName"
                      class="close_icon"
                      src="../../../../../../assets/images/icons/icons8_cancel.svg"
                      alt=""
                    />
                  </div>
                  <p class="fileName_text">
                    {{ uploadFileProcess?.aadhar?.aadharFileName }}
                  </p>
                </div>
              </div>
            </ng-container> -->
          </div>
          <div class="mt-2" *ngIf="!reupload.aadhar && (userRole === 'admin'|| userRole === 'subscriber')">
            Uploaded on {{ aadhar?.created_date | date : "dd MMM yyyy" }}
            <!-- <span
              (click)="reuploadfn('aadhar')"
              *ngIf="!from_subTable"
              style="
                color: #065dbc;
                margin-left: auto;
                float: right;
                margin-right: 12%;
                cursor: pointer;
              "
            >
              Reupload</span
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!aadhar && (userRole === 'admin' || userRole === 'subscriber')"  class="mt-30 col-lg-5">
    <div class="mx-3 px-3">
      <div class="w-50">
        <div class="mb-2">Aadhar (Front Side)</div>
        <div style="height: 200px;width: 330px;" class="dnd-container  d-flex justify-content-center align-items-center">
          <p class="dnd_text"> Aadhar has not been uploaded</p>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="aadhar_b && loaded"  class="mt-30 col-lg-5 ">
    <div class="mx-3 px-3">
      <div *ngIf="aadhar_b && loaded">
        <div class="w-50">
          <div class="mb-2"  *ngIf="userRole === 'admin' || userRole === 'subscriber'">AADHAR (Back Side)</div>
          <div>
            <embed
              *ngIf="aadhar_b && !reupload.aadhar_b && (userRole === 'admin' || userRole === 'subscriber')"
              [src]="aadhar_b.file"
              height="200px"
              width="330px"
              #aadhar_bframe
            />
          </div>
          <div class="mt-2" *ngIf="!reupload.aadhar_b && (userRole === 'admin'|| userRole === 'subscriber')">
            Uploaded on {{ aadhar_b?.created_date | date : "dd MMM yyyy" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!aadhar_b && (userRole === 'admin' || userRole === 'subscriber')"  class="mt-30 col-lg-5">
    <div class="mx-3 px-3">
      <div class="w-50">
        <div class="mb-2">Aadhar (Back Side)</div>
        <div style="height: 200px;width: 330px;" class="dnd-container  d-flex justify-content-center align-items-center">
          <p class="dnd_text"> Aadhar back has not been uploaded</p>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="pan  && loaded"  class="mt-30 col-lg-5 ">
    <div class="mx-3 px-3">
      <div class="divcheck" *ngIf="pan && loaded">
        <div class="w-50">
          <div class="mb-2" *ngIf="userRole === 'admin' || userRole === 'subscriber'">PAN</div>
          <div>
            <embed
              *ngIf="pan && !reupload.pan && (userRole === 'admin'|| userRole === 'subscriber')"
              [src]="pan?.file"
              frameborder="0"
              height="200px"
              width="330px"
              #panframe
            />
           
          </div>
          <div class="mt-2" *ngIf="!reupload.pan && (userRole === 'admin' || userRole === 'subscriber')">
            Uploaded on {{ pan?.created_date | date : "dd MMM yyyy" }}
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!pan && (userRole === 'admin' || userRole === 'subscriber' )"  class="mt-30 col-lg-5">
    <div class="mx-3 px-3">
      <div class="w-50">
        <div class="mb-2">Pan</div>
        <div style="height: 200px;width: 330px;" class="dnd-container  d-flex justify-content-center align-items-center">
          <p class="dnd_text"> Pan has not been uploaded</p>
        </div>
      </div>
    </div>

  </div>
</div>
</div>
  <div *ngIf="!showUpdate">
    <ng-container *ngIf="(kycDocUpload && loaded && !showNodocs)||userRole === 'subscriber'">
      <app-fileUploads
        #fileUpload
        [from]="'profile'"
        [type]="'subscriber'"
        [buttonShow]="true"
        [pan]="pan"
        [aadhar]="aadhar"
        [aadhar_b]="aadhar_b"
        (fileSaved)="FileSaved($event)"
      ></app-fileUploads>
    </ng-container>
  
  </div>
</div>
