import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<SuccessComponent>,@Inject(MAT_DIALOG_DATA)public data :any) {}

  ngOnInit(): void {}
  close(val: any) {
    this.dialogRef.close(true);
  }
}
