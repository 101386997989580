import { Observable } from 'rxjs';
import { AuthService } from '../../modules/auth/services/auth.service';
import { Router } from '@angular/router';
import { PublicFacadeService } from 'src/app/modules/public/state/public.facade.services';
import { inject } from '@angular/core';
export function appInitializer(authService: AuthService, facadeService: PublicFacadeService) {
  const refresh_token: any = localStorage.getItem('refreshToken');
  const id:any = localStorage.getItem('id');

  const url: any = window.location.href;
  return () =>
    new Promise((resolve: any) => {

      /*authService.getTokens(refresh_token).subscribe(response => {
            authService.setTokens(response);

            if (url) {
                resolve(true);
            }
        })*/
      facadeService.setUploadDateToStore();
      if (
        refresh_token &&
        refresh_token != '' &&
        refresh_token != 'undefined'
      ) {
        authService
          .getTokens(refresh_token,id)
          .subscribe((response) => {
            // const token = {
            //     IdToken: response.id_token,
            //     RefreshToken: response.RefreshToken
            // }
            authService.setTokens(response);
            authService.startTokenUpdateTimer(response.expires_in / 60 - 5);
            if (url) {
              resolve(true);
            }
          })
          .add(resolve);
      } else {
        setTimeout(() => {
          resolve('foo');
        }, 300);
      }
    });
}
