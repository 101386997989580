<div class="register-wrapper">
  <div class="bg-image"></div>
  <div class="container">
    <div class="logo-wrapper" style="cursor: pointer" routerLink="/">
      <img
        (click)="goLandingPage()"
        style="cursor: pointer; z-index: 99"
        *ngIf="product !== 'smartbeta'"
        src="../../../../../assets/images/logo-update.svg"
      />
      <img
        (click)="goLandingPage()"
        style="cursor: pointer; z-index: 99"
        *ngIf="product === 'smartbeta'"
        src="../../../../../assets/images/smartbeta_update.svg"
      />
    </div>
  </div>
  <div class="container conatiner-wrapper">
    <p class="f-16 text-center fw-600">
      You are on the right track for SMART investments.<br />
      Let's get you registered.
    </p>
    <div class="register">
      <form
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
        autocomplete="new-form"
      >
      <div class="">
        <span class="f-12 fw-500">PAN</span>
        <mat-form-field appearance="outline" class="w100 panBtn">
          <input
            matInput
            placeholder="PAN"
            required
            formControlName="pan"
            name="pan"
            ng-model="pan"
            maxlength="10"
            autocomplete="off"
          />
          <di matSuffix *ngIf="!isVerified"> <button  (click)="verifyPan($event)"  style="color: white; font-size:10px;height: 35px;" mat-button class="btn-primary textBtn">Verify</button></di>
          <mat-error>
            <mat-error *ngIf="errorHandling('pan', 'required')">
              PAN required
            </mat-error>
            <mat-error *ngIf="!errorHandling('pan', 'required') &&!registerForm.controls['pan'].hasError('verify')&& registerForm.get('pan')?.invalid">
              Invalid PAN
            </mat-error>
            <mat-error *ngIf="registerForm.controls['pan'].hasError('verify')">
             {{registerForm.controls['pan'].getError('verify')}}
            </mat-error>
          </mat-error>
        </mat-form-field>

      </div>
        <ng-container *ngIf="isVerified">
          <div class="mt-20">
            <span class="f-12 fw-500">First Name</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                placeholder="First Name"
                formControlName="fname"
                required
              />
              <mat-error>
                <mat-error *ngIf="errorHandling('fname', 'required')">
                  First Name required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mt-20">
            <span class="f-12 fw-500">Middle Name</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                placeholder="Middle Name"
                formControlName="mname"
                required
              />
              <mat-error>
                <mat-error *ngIf="errorHandling('fname', 'required')">
                  First Name required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mt-20">
            <span class="f-12 fw-500">Last Name</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                placeholder="Last Name"
                formControlName="lname"
                required
              />
              <mat-error>
                <mat-error *ngIf="errorHandling('lname', 'required')">
                  Last Name required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mt-20">
            <span class="f-12 fw-500">Email</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                placeholder="pat@example.com"
                formControlName="email"
                required
                oninput="this.value = this.value.replace(/[A-Z]/g, '');"
                (input)="convertToLowercase($event)"

              />
              <mat-error>
                <mat-error *ngIf="errorHandling('email', 'required')">
                  Email Id required
                </mat-error>
                <mat-error *ngIf="registerForm.get('email')?.invalid">
                  Invalid Email
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mt-20">
            <span class="f-12 fw-500">Mobile Number</span>

            <mat-form-field appearance="outline" class="w100">
              <input
                #phoneNumber
                matInput
                placeholder="Number"
                required
                type="number"
                formControlName="mobile"

              />
              <mat-error>
                <mat-error *ngIf="errorHandling('mobile', 'required')">
                  Mobile required
                </mat-error>
                <mat-error
                  *ngIf="registerForm.get('mobile')?.errors?.invalidNumber"
                >
                  Invalid Mobile
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="mt-20">
            <span class="f-12 fw-500">PAN</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                placeholder="PAN"
                required
                formControlName="pan"
                name="pan"
                ng-model="pan"
                maxlength="10"
                autocomplete="off"
              />
              <mat-error>
                <mat-error *ngIf="errorHandling('pan', 'required')">
                  PAN required
                </mat-error>
                <mat-error *ngIf="registerForm.get('pan')?.invalid">
                  Invalid PAN
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div> -->
          <div class="mt-20" *ngIf="product === 'smartbeta'">
            <span class="f-12 fw-500">Investment Amount</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                type="number"
                placeholder="Amount"
                required
                formControlName="amount"
              />
              <mat-error style="font-size: 12px">
                <mat-error *ngIf="errorHandling('amount', 'required')">
                  Amount required
                </mat-error>
                <mat-error *ngIf="registerForm.get('amount')?.invalid">
                  Invalid Amount
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mt-20" *ngIf="product === 'smartbeta'">
            <span class="f-12 fw-500">Advance Fee (6 months)</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                type="number"
                placeholder="Amount"
                required
                formControlName="fee_amount"
              />
              <!-- <mat-hint>plus 18% GST if applicable</mat-hint> -->
              <mat-error style="font-size: 12px">
                <mat-error *ngIf="errorHandling('fee_amount', 'required')">
                  Amount required
                </mat-error>
                <mat-error *ngIf="registerForm.get('fee_amount')?.invalid">
                  Invalid Amount
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mt-20" *ngIf="product !== 'smartbeta'">
            <span class="f-12 fw-500">Password</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                type="password"
                formControlName="password"
                autocomplete="new-password"
                required
                placeholder="Password"
                value=""
                (input)="checkvalidation($event)"
              />
              <!-- <mat-error>
                <mat-error *ngIf="errorHandling('password', 'required')">
                  Password is required
                </mat-error>
                <mat-error *ngIf="registerForm.get('password')?.invalid">
                  Password should be minimum 6 charachter
                </mat-error>
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="mt-20" *ngIf="product !== 'smartbeta'">
            <span class="f-12 fw-500">Confirm Password</span>
            <mat-form-field appearance="outline" class="w100">
              <input
                matInput
                type="password"
                formControlName="cpassword"
                placeholder="Confirm Password"
                required
                value=""
                autocomplete="new-password"
              />
              <!-- <mat-error *ngIf="errorHandling('cpassword', 'required')">
                Confirm Password is required
              </mat-error>
              <mat-error *ngIf="f.cpassword.errors?.confirmedValidator"
                >Password and Retype Password must be match.
              </mat-error> -->
            </mat-form-field>
          </div>

          <div class="col-md-12" style="margin-top: 35px;" *ngIf="product !== 'smartbeta'">
            <div class="f-12 fw-500">Password must:</div>
            <div style="margin-top: 10px;">
              <ul>
                <li [style.color]="passwordHasLowercase ? '' : 'red'">Have at least one lower case character</li>
                <li [style.color]="passwordHasUppercase ? '' : 'red'">Have at least one upper case character</li>
                <li [style.color]="passwordHasNumber ? '' : 'red'">Have at least one number</li>
                <li [style.color]="passwordHasSpecialChar ? '' : 'red'">Have at least one special character</li>
                <li [style.color]="passwordIsLongEnough ? '' : 'red'">Must be minimum 8 characters long</li>
                <li [style.color]="passwordHasNoSequentialNumbers ? '' : 'red'">Must not contain sequential numbers like 123</li>
                <li *ngIf="registerForm.get('cpassword')?.value?.length>0" [style.color]="registerForm.get('cpassword')?.value !== registerForm.get('password')?.value ? 'red' : ''">Password do not match.</li>
                <mat-divider class="mt-20"></mat-divider>
                <li class="mt-10 removeBullet" [style.color]="passwordIsNotUsername ? '' : ''">password should not be too similar to username</li>
                <li class='removeBullet' [style.color]="passwordIsNotPreviouslyUsed ? '' : ''">Password should not be previously used for this account</li>
              </ul>
            </div>
          </div>
          <div class="mt-20">
            <mat-checkbox class="example-margin" #checkbox
              >I agree with</mat-checkbox
            >
            <span (click)="tnc()" style="cursor: pointer">
              terms and conditions</span
            >
          </div>
          <div class="mt-30 w-100" style="height: 40px">
            <button
              class="btn btn-primary w-100"
              type="submit"
              [disabled]="!checkbox.checked"
            >
              Register
            </button>
          </div>
        </ng-container>

        <div class="mt-30 text-center">
          Already have account ?
          <span (click)="login()" class="text-dark-blue login">Login</span>
        </div>
        <div class="row mt-20 d-flex">
          <div class="col-12 d-flex f-12 text-center justify-content-center">
          <div><img src="../../../../../assets/images/icons/watchVideoIcon.svg" > want some help ? <span (click)="openVideo()" class="cPointer text-dark-blue f-13"> watch this video</span>  on how to register</div> 

          </div>
        </div>
      </form>
    </div>
  </div>
</div>
