<div class="subscriber-container">
  <div class="f-16 fw-500 mt-20 mb-15">
    Overview
  </div>
  <div class="widget_bg">
    <div class="row">
      <div class="col-md-4 col-6 display-grid">
        <span class="f-12 text-grey">Subscribers</span>
        <span class="f-16">{{subscriberCount}}</span>
      </div>
      <div class="col-md-4 col-6 display-grid">
        <span class="f-12 text-grey">Investments</span>
        <span class="f-16">{{totalInvestment | currency:'INR' : '': '1.0-0' : 'en-IN'}}</span>
      </div>
    </div>
  </div>

  <div class="table-con mg-20" #containerRef infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100"
    [infiniteScrollContainer]="containerRef" [fromRoot]="true" (scrolled)="onScrollDown()" (scrolledUp)="onUp()">
    <!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="profitloss">
        <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
          Profit / Loss
        </th>
      </ng-container>


      <ng-container matColumnDef="joined">
        <th mat-header-cell *matHeaderCellDef> Date Joined </th>
        <td mat-cell *matCellDef="let element"> {{element.joined | date : 'mediumDate'}} </td>
      </ng-container>

      <ng-container matColumnDef="subscribed">
        <th mat-header-cell *matHeaderCellDef> Date Subscribed </th>
        <td mat-cell *matCellDef="let element"> {{element.subscribed | date : 'mediumDate'}} </td>
      </ng-container>

      <ng-container matColumnDef="invest">
        <th mat-header-cell *matHeaderCellDef> Amount Invested </th>
        <td mat-cell *matCellDef="let element">
          {{element.invest | currency:'INR' : '': '1.0-0' : 'en-IN'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="return">
        <th mat-header-cell *matHeaderCellDef >Profit/Loss Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.return}} </td>
      </ng-container>
      <ng-container matColumnDef="retper">
        <th mat-header-cell *matHeaderCellDef  > Profit/Loss% </th>
        <td mat-cell *matCellDef="let element"> {{element.retper}} </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef  colspan="4" >  </th>

      </ng-container>


      <tr mat-header-row *matHeaderRowDef="['title','profitloss']"></tr> -->
      <!-- <tr mat-header-row *matHeaderRowDef="['name','joined','subscribed','invest', 'return','retper']"></tr> -->
      <!-- <tr class="f-14" mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->

    <!-- </table> -->
 <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>




        <ng-container matColumnDef="joined">
            <th mat-header-cell *matHeaderCellDef> Date Joined </th>
            <td mat-cell *matCellDef="let element"> {{element.joined | date : 'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="subscribed">
            <th mat-header-cell *matHeaderCellDef> Date Subscribed </th>
            <td mat-cell *matCellDef="let element"> {{element.subscribed | date : 'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="invest">
            <th mat-header-cell *matHeaderCellDef> Amount Invested </th>
            <td mat-cell *matCellDef="let element">
                {{element.invest | currency:'INR' : '': '1.0-0' : 'en-IN'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="return">
          <th mat-header-cell *matHeaderCellDef > Profit/Loss Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.return}} </td>
      </ng-container>
      <ng-container matColumnDef="retper">
        <th mat-header-cell *matHeaderCellDef> Profit/Loss% </th>
        <td mat-cell *matCellDef="let element" style="position: relative;"> {{element.retper}}
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                class="btn-style">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="unmapSubscriber(element)">
                    <span>Unmap</span>
                </button>
                <!-- <button mat-menu-item (click)="editSubscriber(element)">
                    <span>Edit Mapping</span>
                </button> -->
                <button mat-menu-item (click)="changeInvestment(element)">
                    <span>Change Investment</span>
                </button>
            </mat-menu>
        </td>
    </ng-container>




        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
