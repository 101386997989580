import { SubsciberService } from 'src/app/services/subscriber.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/common/services/shared.service';
import { NotificationService } from 'src/app/services/notification.services';
import { environment } from 'src/environments/environment';
import { PublicService } from '../../services/public.service';
import { AgreementGeneratorService } from './agreement-generator.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ThankyouComponent } from '../thankyou/thankyou.component';
import { v4 as uuidv4 } from 'uuid';
declare const pdfjsLib: any;

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
})
export class AgreementComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  @ViewChild('agreement', { static: false }) public agreement: ElementRef;
  @ViewChild('myForm', { static: true }) public ngForm: ElementRef =
    {} as ElementRef;
  agreementInfo: any;
  logo: any;
  showAgreement: boolean = false;
  description_sub: any;
  from: any = '';
  paymentData: any = {
    access_code: '',
    encRequest: '',
    order_id: '',
  };
  amount: any = 0;
  paymentUrl: string = '';
  htmlPage: any;
  questions: any = [];
  user: any;
  eSign$: any;
  today = new Date();
  hasRedirectUrl = false;
  redirect = '';
  callback = false;
  pending: number;
  subscriptionFee:any
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _router: ActivatedRoute,
    private sharedService: SharedService,
    private notifyService: NotificationService,
    public publicService: PublicService,
    public ags: AgreementGeneratorService,
    private subService: SubsciberService,
    public sanitizer: DomSanitizer
  ) {
    // this.logo = '/assets/images/icons/Logo.png';
    this.paymentUrl = environment.paymentGatewayUrl;
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }
  ngOnInit(): void {

    if (sessionStorage.getItem('agreementSmartBeta')) {
      this.publicService.agreementDetailsNew = JSON.parse(
        sessionStorage.getItem('agreementSmartBeta') || '{}'
      );
    }
    this._router.paramMap.subscribe((parameter: any) => {
      this.product = parameter.params?.product;
    });
    this._router.queryParams.subscribe((res: any) => {
      if (res.aid === JSON.parse(sessionStorage.getItem('aID') || '{}')) {
        if (res.aid) {
          this.callback = true;
          this.aid = res.aid;
        }
        this.publicService.check_agreement_status(res.aid).subscribe(
          (res: any) => {
            if (res[0].Results[0].status == true) {
              this.checkAID();
            } else if (environment.bypassEsign) {
              this.checkAID();
            } else {
              this.notifyService.showError(
                '',
                'Something went wrong ,Try Again'
              );
              sessionStorage.removeItem('data');

              if (this.product == 'smartbeta') {
                this.router.navigateByUrl('/').then(() => {});
              } else {
                this.intiateFromPlans();
              }
            }
          },
          (e) => {
            console.log(e.error.error);
            this.notifyService.showError('', e.error.error);
            this.router.navigateByUrl('/');
            if (sessionStorage.getItem('aID')) {
              sessionStorage.removeItem('aID');
            }
          }
        );
      } else {
      }
    });
    this.description_sub =
      this.publicService.mapSubscriberData.agreementDetails?.public___pouch_subscriptions.find(
        (ele: any) =>
          ele.subscription_freq ===
          this.publicService.mapSubscriberData.subscription.subscription_freq
      );
      //

      this.getSubscritionAmount()

    this.setUserInfo();
    switch (this.publicService.agreementFrom) {
      case 'from-plans':
        this.intiateFromPlans();
        break;
      case 'from-create':
        this.intiateFromRegisteration();
        break;
    }
  }
  ngOnDestroy(): void {
    this.aid = '';
    if (this.eSign$) {
      this.eSign$.unsubscribe();
    }
  }
  getSubscritionAmount(){
    let description_subData = this.description_sub?.subscription_desc;
    const smartbetaDescriptionData =  this.publicService.agreementDetailsNew?.agreementValues?.subscription_desc;
    if(description_subData){
      this.subscriptionFee = description_subData?.split('or')[0]?.split('Rs. ')[1];
    }
    if(smartbetaDescriptionData){
      this.subscriptionFee = smartbetaDescriptionData?.split('or')[0]?.split('Rs. ')[1]
    }
  }
  ngAfterViewInit(): void {
    this.onIframeLoad(this.product);
  }
  userInfo: any;
  subscriptionData: any;
  agreementData: any;
  setUserInfo() {
    this.userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    this.agreementInfo = this.publicService.mapSubscriberData.agreementDetails;
    this.subscriptionData = this.publicService.mapSubscriberData.data;
  }

  getMonths(type: any) {
    return this.ags.getMonths(type);
  }

  intiateFromPlans() {
    let payload = {
      name: `${this.userInfo.attributes.name.first} ${this.userInfo.attributes.name.last}`,
      email: this.userInfo.attributes.email,
      risk: this.userInfo?.attributes.risk_profile,
      amount: this.publicService?.mapSubscriberData?.data?.investment_amount,
      title: this.agreementInfo?.heading_text,
    };
    this.agreementData = payload;
    this.from = 'from-plans';
  }
  intiateFromRegisteration() {
    let payload = {
      name: `${this.userInfo.attributes.name.first} ${this.userInfo.attributes.name.last}`,
      email: this.userInfo.attributes.email,
      risk: this.userInfo?.attributes.risk_profile,
      amount: this.publicService?.agreementDetailsNew.investment_amount,
      title: this.agreementInfo?.heading_text,
    };
    this.agreementData = payload;
    this.from = 'from-create';
  }
  aid: any;
  product: any;
  count = 0;
  submit() {
    if (!this.eSign$?.closed) {
      this.sharedService.showLoader();
    }
    if (environment.bypassEsign) {
      this.router.navigateByUrl(
        `/agreement?aid=${JSON.parse(sessionStorage.getItem('aID') || '')}`
      );
    } else {
      const interval = setInterval(() => {
        if (this.hasRedirectUrl) {
          window.location.href = this.redirect;
          clearInterval(interval);
          this.count == 0;
        } else {
          //how to check if api is pending in browser
          if (this.count == 500000) {
            clearInterval(interval);
          } else {
            this.count++;
          }
        }
      }, 500);
    }
  }
  ConvertStringToHTML(str: any) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  }
  generatePDF() {}
  isLoding = false;

  eSignIntiate(encodedData: any) {
    let payload: any = {
      id : uuidv4(),
      file: encodedData,
      filename: 'agreeement.pdf',
      signees: [
        {
          name: `${this.userInfo.attributes.name.first} ${this.userInfo.attributes.name.last}`,
          email: this.userInfo.attributes.email,
          mobile: this.userInfo.attributes.phone.replace('-', ''),
          signtype: 'uidai_sign',
          duedate: new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .split('T')[0],
        },
      ],
      callbackurl:
        this.product === 'smartbeta'
          ? `${window.location.host}/agreement/smartbeta`
          : `${window.location.host}/agreement`,
    };
    if (this.product === 'smartbeta') {
      console.log(this.publicService.agreementDetailsNew);
      sessionStorage.setItem(
        'data',
        JSON.stringify({
          mapSubscriberData: this.publicService.agreementDetailsNew,
        })
      );
    } else {
      sessionStorage.setItem(
        'data',
        JSON.stringify({
          mapSubscriberData: this.publicService.mapSubscriberData,
        })
      );
    }
    if (environment.bypassEsign) {
      sessionStorage.setItem(
        'aID',
        JSON.stringify(this.generateRandomString())
      );
    } else {
      this.eSign$ = this.publicService?.intiateesign(payload).subscribe(
        (res: any) => {
          let response = res?.data?.signing_url;
          sessionStorage.setItem('aID', JSON.stringify(res.data.agreement_id));
          Object.keys(response).forEach((e: any) => {
            this.redirect = response[e];
          });
          this.hasRedirectUrl = true;
        },
        (e: any) => {
          this.notifyService.showError('', e.error.error);
          this.retryCount++;
        }
      );
    }
  }
  retryCount = 1;
  openpopup() {
    console.log('this.from', this.from);

    if (this.product !== 'smartbeta') {
      this.publicService
        .saveAgreement({
          docs: {
            subscriber_id: this.userInfo.id,
            investment_amount:
              this.publicService.mapSubscriberData.data.investment_amount,
            registration_date: new Date().toISOString().split('T')[0],
            product_name: 'smartvalues',
            agreement_id: this.aid,
            subscription_type:
              this.publicService.mapSubscriberData.subscription
                .subscription_type,
            subscription_perc:
              this.publicService.mapSubscriberData.subscription
                .subscription_perc,
            subscription_amount:
              this.publicService.mapSubscriberData.subscription
                .subscription_amount,
            subscription_amount_final:
              this.publicService.mapSubscriberData.subscription
                .subscription_amount_final,
            subscription_freq:
              this.publicService.mapSubscriberData.subscription
                .subscription_freq,
            promo_code:
              this.publicService.mapSubscriberData.subscription.promo_code,
            discount_amount:
              this.publicService.mapSubscriberData.subscription.discount_amount,
            pouch_id:
              this.publicService.mapSubscriberData.subscription.pouch_id,
            stoploss:
              this.publicService.mapSubscriberData.data.subscriberObj[0]
                .stoploss_portfolio,
          },
        })
        .subscribe((res: any) => {
          if (res.subscriber_investment.error == '-') {
            this.publicService
              .intiatePaymentGateway({
                investment_amount: `${this.publicService.mapSubscriberData.data.investment_amount}`,
                pouch_id: this.publicService.mapSubscriberData.data.pouch_id,
                frequency:
                  this.publicService.mapSubscriberData.subscription
                    .subscription_freq,
                subscription_amount: `${this.amount}`,
                subscriber_id: this.userInfo.id,
                redirect_url: `${window.location.host}/investment/map-subscriber/${this.publicService.mapSubscriberData.data.pouch_id}`,
              })
              .subscribe(
                (res: any) => {
                  this.redirectToPayment(res);
                },
                (e) => {
                  this.notifyService.showError('', e.error.error.message);
                }
              );
          }
        });
    } else {
      this.publicService
        .saveAgreement({
          docs: {
            subscriber_id: this.userInfo.id,
            investment_amount:
              this.publicService.mapSubscriberData?.agreementValues
                .investment_amount,
            registration_date: new Date().toISOString().split('T')[0],
            product_name: 'smartbeta',
            agreement_id: this.aid,
          },
        })
        .subscribe(
          (res: any) => {
            if (res.subscriber_investment.error === '-') {
              const dialog = this.dialog.open(ThankyouComponent, {
                width: '621px',
                height: '293px',

                disableClose: false,
              });
              setTimeout(() => {
                dialog.close();
                this.router.navigate(['/public/landing-page']);
                if (sessionStorage.getItem('aID')) {
                  sessionStorage.removeItem('aID');
                }
              }, 5000);
            }
          },
          (e) => {
            this.router.navigateByUrl('/');
            this.notifyService.showError('', e.error.error.message);
          }
        );
    }
  }

  redirectToPayment(data: any) {
    this.paymentData = data;
    console.log(this.paymentData, '<[[ payment data');

    const interval = setTimeout(() => {
      if (this.paymentData.access_code && this.paymentData.encRequest) {
        if (this.product !== 'smartbeta') {
          this.publicService.mapSubscriberData.returnToComposition = true;
          sessionStorage.setItem(
            'pouch',
            JSON.stringify(this.publicService.mapSubscriberData)
          );
          this.ngForm.nativeElement.submit();
        } else {
          console.log(JSON.stringify(this.publicService.agreementDetailsNew));
          sessionStorage.setItem(
            'pouch',
            JSON.stringify(this.publicService.agreementDetailsNew)
          );
          this.ngForm.nativeElement.submit();
        }
        clearInterval(interval);
      }
    }, 2000);
  }

  checkAID() {
    let data = JSON.parse(
      sessionStorage.getItem('data') || '{}'
    ).mapSubscriberData;
    if (data) {
      this.publicService.mapSubscriberData = data;
      if (!this.userInfo) {
        this.setUserInfo();
      }
      this.from = 'from-plans';
      console.log(this.publicService.mapSubscriberData);
      this.amount = data.subscription?.subscription_amount_final;
      sessionStorage.removeItem('data');
      if (this.product == 'smartbeta') {
        // this.intiateFromRegisteration()
      } else {
        this.intiateFromPlans();
      }
      this.openpopup();
    }
  }

  innerHtml: any;
  onIframeLoad(product?: any) {
    this.subService
      .fetchRiskProfile({
        subscriber_id: this.user.id,
      })
      .subscribe(async (res: any) => {
        if (res[0]?.subscriber_response) {
          let response = res[0].subscriber_response[0].public___rp_subscribers;
          this.questions = [];
          for (let qn of response) {
            this.questions.push({
              qn: qn.rp_question,
              ans: qn.rp_answer,
            });
          }
          if (product === 'smartbeta') {
            let base64: any;
            let pdf: any = await this.ags.generateSmartBetaPDF({
              user: this.user.attributes,
              value: this.publicService.agreementDetailsNew,
              qns: this.questions,
              subscriptionAmount: this.subscriptionFee,
              aggText:this.description_sub?.agg_text
            });

            // pdfMake.createPdf(pdf).download();
            pdfMake.createPdf(pdf).getBase64((data) => {
              base64 = data;
              if (!this.callback) {
                this.eSignIntiate(base64);
              }
            });
          } else {
            let base64: any;
            let pdf: any = await this.ags.generateSmartValuePDF({
              user: this.user.attributes,
              value: this.publicService.mapSubscriberData,
              qns: this.questions,
              desc: this.description_sub,
              subscriptionAmount: this.subscriptionFee,
              aggText:this.description_sub?.agg_text
            });
            // pdfMake.createPdf(pdf).download();
            pdfMake.createPdf(pdf)?.getBase64((data) => {
              base64 = data;
              if (!this.callback) {
                this.eSignIntiate(base64);
              }
            });
          }
        }
      });
  }
  private generateReport(report: any) {
    const reportUrl = this.convertBase64toFile(report);
    return this.sanitizer.bypassSecurityTrustResourceUrl(reportUrl);
  }
  convertBase64toFile(base64Data: any): string {
    const byteArray = new Uint8Array(
      atob(base64Data)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }
  generateRandomString() {
    // Generate two random 3-digit numbers
    const number1 = Math.floor(Math.random() * 900) + 100;
    const number2 = Math.floor(Math.random() * 900) + 100;

    // Generate two random 3-character strings
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let string = '';
    for (let i = 0; i < 3; i++) {
      string += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const string1 = string;
    string = '';
    for (let i = 0; i < 3; i++) {
      string += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const string2 = string;

    // Concatenate the two numbers and two strings together
    const result = number1.toString() + number2.toString() + string1 + string2;

    return result;
  }
}
