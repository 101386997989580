import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import {
  AuthActionTypes,
  Login,
  LoginSuccess,
  LogInFailure,
  SetPassword,
} from '../actions/auth.action';
import { ButtonSwitchService } from 'src/app/services/button-switch.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    private router: Router,
    private displaybtn: ButtonSwitchService
  ) {}

  @Effect()
  LogIn: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN),
    map((action: Login) => action.payload),
    switchMap((payload) => {
      return this.authService.loginUser(payload.email, payload.password).pipe(
        map((response: any) => {
          if (response != null && response.id_token ) {
            this.authService
              .fetchUser(response.access_token , response.id_token )
              .subscribe((res) => {
                localStorage.setItem('user', JSON.stringify(res));
                this.displaybtn.setButton(false)
                this.authService.setUser(res);
              },(e)=>{
                this.displaybtn.setButton(false)

              });
            return new LoginSuccess(response);
          } else if (response != null && response.ChallengeName) {
            this.displaybtn.setButton(false)
            localStorage.setItem('newuser', JSON.stringify(response));
            return new LoginSuccess(response);
          } else {
            this.displaybtn.setButton(false)
            return new LogInFailure({
              error: 'Incorrect email and/or password.',
            });
          }
        }),
        catchError((error) => {
          this.displaybtn.setButton(false)
          return observableOf(new LogInFailure({ error: error }));
        })
      );
    })
  );

  @Effect()
  SetNewPassword: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SET_PASSWORD),
    map((action: SetPassword) => action.payload),
    switchMap((payload) => {
      return this.authService
        .setNewUserPassword(payload.email, payload.password, payload.session)
        .pipe(
          map((response: any) => {
            if (response != null && response.id_token ) {
              this.authService
                .fetchUser(payload.email, response.id_token )
                .subscribe((res) => {
                  localStorage.setItem('user', JSON.stringify(res));
                  this.authService.setUser(res);
                  localStorage.removeItem('newuser');
                });
              return new LoginSuccess(response);
            } else {
              return new LogInFailure({
                error: response.message,
              });
            }
          }),
          catchError((error) => {
            return observableOf(new LogInFailure({ error: error }));
          })
        );
    })
  );

  @Effect({ dispatch: false })
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((response: any) => {
      if (
        response.payload &&
        response.payload.id_token  &&
        response.payload.refresh_token 
      ) {
        this.authService.setTokens(response.payload);
        localStorage.setItem('refreshToken', response.payload.refresh_token );
        this.authService.startTokenUpdateTimer(
          response.payload.expires_in / 60 - 5
        );
      } else if (
        response.payload.ChallengeName === undefined ||
        response.payload.ChallengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        this.router.navigate(['auth/new-password']);
      }
    })
  );

  @Effect({ dispatch: false })
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE),
    tap((error) => { this.displaybtn.setButton(false)})
  );
}
