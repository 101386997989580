import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, DoCheck } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, update } from 'lodash';
import { Observable } from 'rxjs';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { TimeFrameDialogComponent } from 'src/app/modules/investment-pouches/create-pouch/components/timeframe-dialog/timeframe-dialog.component';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import {
  AddEdit,
  EmptyStateLoad,
  Load,
} from 'src/app/modules/investment-pouches/store/actions/pouch.action';
import {
  PouchState,
  selectPouchState,
} from 'src/app/modules/investment-pouches/store/pouch.state';
import { CommonDialogWaitingComponent } from 'src/app/modules/shared/common-dialog/common-dialog.component';
import { PublicService } from '../../services/public.service';
import * as moment from 'moment';
import { FailureComponent } from 'src/app/modules/investment-pouches/failure/failure.component';
import { SucessPopupComponent } from '../sucess-popup/sucess-popup.component';
import { CreatePouchPopupComponent } from '../create-pouch-popup/create-pouch-popup.component';
import { SharedService } from 'src/app/common/services/shared.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'src/app/services/notification.services';
import { RenewSubscriptionPopupComponent } from 'src/app/modules/shared/renew-subscription-popup/renew-subscription-popup.component';
import { PaymentLoaderComponent } from 'src/app/modules/navigation/payment-loader/payment-loader.component';
import * as _moment from 'moment';
@Component({
  selector: 'app-pouch-creation',
  templateUrl: './pouch-creation.component.html',
  styleUrls: ['./pouch-creation.component.scss'],
})
export class PouchCreationComponent implements OnInit {
  selectedBenchMarkIndex = 'Smartvalues Main Large Cap';
  today: any = new Date();
  pouchDATA: any;
  noOfStocks: number = 2;
  TabIndex = 0;
  pouchState: any;
  getState: Observable<any>;
  currentqnGroup: number = 1;
  index: number = 0;
  blur: boolean = true;
  universeList: any = [];
  displayColumns: any = ['name', 'stopLoss'];
  selectedUniverse: any;
  newPouch: any;
  tableDataSource: any;
  showOptionForStocksnumber = false;
  disableshowOptionForStocksnumberBtn=false
  showRenewLater = false;
  since: any = new Date(
    localStorage.getItem('stock_price_uploaded_date') || ''
  );
  editNewPouch: any = {
    pouch_name: '',
    investment_amount: '',
    pouch_description: '',
    icon: '',
    end_date: '',
    stop_loss: 0,
    minimum_investment: '',
    error: {
      pouch_name: false,
      end_date: false,
      stop_loss: false,
      investment_amount: false,
    },
  };

  questionSets: any = {
    universe: {
      q1: false,
      q2: false,
      q3: false,
      a1: false,
      a2: false,
    },
    pouch_info: {
      q1: false,
      q2: false,
      q3: false,
      a1: false,
    },
    pouch_configuration: {
      q1: false,
      q2: false,
      q3: false,
      a1: false,
    },
    pouch_description: {
      q1: false,
      a1: false,
    },
    pouch_icon: {
      q1: false,
      a1: false,
    },

    pouch_stoploss: {
      q1: false,
      a1: false,
    },
  };
  iconArr1: any = [1, 2, 3, 4, 5, 6, 7];
  iconArr2: any = [8, 9, 10, 11, 12, 13, 14];
  totalDaysLeft: any;
  SubscriptionEndDate: any;
  switchDateLeft: boolean = true;
  constructor(
    @Inject(DOCUMENT) document: Document,
    private store: Store<PouchState>,
    public publicService: PublicService,
    public pouchService: PouchService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public sharedService: SharedService,
    private http: HttpClient,
    private notifyService: NotificationService
  ) {
    this.getState = this.store.select(selectPouchState);
    // if(!localStorage.getItem('stock_price_uploaded_date')){
    //   this.publicService.stock_upload_date
    // }
  }
  usedCount = 0;
  allowedCount = 0;
  chart: any;
  dates: any;
  pouchName: any;
  indexName: any;
  maxPercent: any;
  minPercent: any;
  selectedDuration: any = '3y';
  userInfo: any;
  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    this.daysLeftForRenewal();
    if (this.userInfo.role !== 'subscriber') {
      this.route.queryParams.subscribe((result: any) => {
        if (result.order_id) {
          // this.router.navigateByUrl('/investment');
          this.publicService
            .checkTransactionStatus({ order_id: result.order_id })
            .subscribe((res: any) => {
              let status = res[0].Results[0].order_status;

              if (status === 'Success') {
                let d = JSON.parse(
                  sessionStorage.getItem('pouchCreation') || '{}'
                );
                let u = JSON.parse(localStorage.getItem('user') || '{}');
                // u.attributes.createpouch = true;
                // u.attributes.createpouchexpiry = new Date(
                //   this.getEndDateSubscription(d.subscription_freq)
                // )
                //   .toISOString()
                //   .split('T')[0];
                // localStorage.setItem('user', JSON.stringify(u));
                // let d = JSON.parse(sessionStorage.getItem('pouch') || '{}');
                let ba =   JSON.parse(sessionStorage.getItem('billings') || '{}')
                let payLoad = {
                  subscription: {
                    sv_feature_id: d.sv_feature_id || 1,
                    sv_feature_name: d.sv_feature_name,
                    subscriber_id: this.userInfo.id,
                    subscription_amount: d.subscription_amount,
                    subscription_amount_final: Number(d.subscription_amount),
                    discount_amount: 0,
                    promo_code: null,
                    subscription_freq: d.subscription_freq,
                    start_date: new Date().toISOString().split('T')[0],
                    order_id: result.order_id,
                    bank_ref_no: res[0].Results[0].bank_ref_no,
                    end_date: new Date(
                      this.getEndDateSubscription(d.subscription_freq)
                    )
                      .toISOString()
                      .split('T')[0],
                      ...ba
                  },
                  
                };
                this.publicService.saveSubscriptionPouch(payLoad).subscribe(
                  (res: any) => {
                    if (
                      res[0].old_sv_feature_subscriptions?.error === '-' &&
                      res[1].sv_feature_subscriptions?.error === '-'
                    ) {
                      this.updateLocalStorage(this.userInfo, d);
                      if (sessionStorage.getItem('pouchData')) {
                        this.newPouch = JSON.parse(
                          sessionStorage.getItem('pouchData') || '{}'
                        );
                        if (this.newPouch) {
                          this.index = 1;
                          this.TabIndex = 1;
                          this.calculateMinimumInvestment(this.newPouch);
                          this.tableDataSource = new MatTableDataSource(
                            this.newPouch.public___pouch_stocks
                          );
                        } else {
                          this.index = 0;
                          this.TabIndex = 0;
                        }

                        // sessionStorage.removeItem('pouchData');
                      }
                    }
                  },
                  () => {
                    this.notifyService.showError(
                      'Error',
                      'Something went wrong.'
                    );
                    this.router.navigateByUrl('/investment');
                  },
                  () => {}
                );
                // this.index = 1;
                // this.TabIndex = 1;
                // this.calculateMinimumInvestment(this.newPouch);
                // this.tableDataSource = new MatTableDataSource(
                //   this.newPouch.public___pouch_stocks
                // );
              }
              if (status === 'Failure') {
                this.router.navigateByUrl(`/investment`);
                const dialogRef = this.dialog.open(FailureComponent, {
                  data: res[0].Results[0].bank_ref_no,
                });
                dialogRef.afterClosed().subscribe((res: any) => {
                  if (res) {
                    this.router.navigateByUrl(`/investment`);
                  }
                  if (sessionStorage.getItem('pouch')) {
                    sessionStorage.removeItem('pouch');
                  }
                });
              }
              if (status === 'Aborted') {
                this.router.navigateByUrl(`/investment`);
                const dialogRef = this.dialog.open(FailureComponent, {
                  data: 'aborteds',
                });
                dialogRef.afterClosed().subscribe((res: any) => {
                  this.router.navigateByUrl(`/investment`);
                });
              }
            });
        } else {
          if (sessionStorage.getItem('pouch')) {
            sessionStorage.removeItem('pouch');
          }
        }
      });
    }
    this.publicService.postStockRun({}).subscribe((res: any) => {
      const response = res[0].Results[0];
      if (response.allow_run) {
        this.usedCount = response.used_count;
        this.allowedCount = response.allowed_count;
      } else {
        this.router.navigateByUrl('/investment');
      }
    });
    this.getListofIndices();

    //
    if (this.switchDateLeft === true) {
      this.startBot('universe', ['q1', 'q2', 'q3', 'a1']);
    } else {
      this.startBot('universe', ['q1', 'a2']);
    }
  }
  renewLater() {
    this.startBot('universe', ['q2', 'q3', 'a1']);
  }
  getListofIndices() {
    this.publicService
      .fetchUniverseList({
        limit: 1000,
        // universe_type: 'SYSTEM',
        show_retail: 'Y',
      })
      .subscribe(
        (response: any) => (this.universeList = response[0].universes)
      );
    this.getState.subscribe((state) => {
      this.pouchState = state;
    });
    if (!this.pouchState.pouch) {
      this.store.dispatch(new EmptyStateLoad({}));
    }
  }
  getDates(date: any) {
    let dates = date;
    dates = new Date(dates.setFullYear(dates.getFullYear() - 3));
    let whichDay = dates.getDay();

    if (whichDay === 6) {
      dates.setDate(dates.getDate() - 1);
      return new Date(dates).toISOString();
    } else if (whichDay === 0) {
      dates.setDate(dates.getDate() - 2);
      return new Date(dates).toISOString();
    } else {
      return new Date(dates).toISOString();
    }
  }
  generateTimeFrame() {

    // let dialogRef = this.dialog.open(TimeFrameDialogComponent);
    this.sharedService.showLoader();
    let stock_price_date: any =
      localStorage.getItem('stock_price_uploaded_date') || '';
    let previousDate: any = this.getDates(new Date(stock_price_date));
    let data: any = {
      universe_name: this.selectedUniverse,
      start_date: stock_price_date,
      review_date: stock_price_date,
      timeframe_size: this.noOfStocks, //need to change
      lookback_periods: '9',
      lookback_type: 'FIXED_START_DATE',
      rank_tolerance: 14,
      reset_years: 3,
      retain_stocks: 'Y',
      pouch_type: 'PRICE',
      stock_price_date: stock_price_date,
      review_freq: 'MONTHLY',
      review_freq_day: new Date(stock_price_date).getDate(),
      tf_wt: 'DISTINCT',
      exclude_non_fno: 'N',
    };

    const promise = this.pouchService.generateTimeframe(data);
    this.disableshowOptionForStocksnumberBtn =true
    promise
      .then((response: any) => {
        if (response) {
          this.pouchService
            .postStopLoss({ pouch_id: response.pouch_id })
            .subscribe((res: any) => {
              const stopLossData = res[0].Results;
              const stockData = response.selected_stocks_price.map(
                (stock: any) => ({
                  stock_id: stock.stock_code,
                  stock_name: stock.stock_name,
                  stop_loss:
                    stopLossData.find(
                      (data: any) => data.stock_code === stock.stock_code
                    )?.stoploss || '',
                })
              );
              this.tableDataSource = new MatTableDataSource(stockData);

            });
          this.newPouch = {
            pouch_id: response.pouch_id,
            start_date: response.start_date,
            stock_price_date: response.stock_price_date,
            selected_stocks_price: response['selected_stocks_price'],
            public___pouch_stocks: response.selected_stocks_price,
          };
          let pouch: any = {
            pouch_id: response.pouch_id,
            pouch_type: response.pouch_type,
            universe_name: this.selectedUniverse,
            start_date: response.start_date,
            timeframe_size: response.timeframe_size,
            rank_tolerance: response.rank_tolerance,
            lookback_type: response.lookback_type,
            // subscribed: 'Y',
            public___pouch_timeframe_stocks: response.timeframe_stocks,
          };

          pouch.public___pouch_stocks = response.selected_stocks_price;
          const statePouch = cloneDeep(this.pouchState);
          statePouch.pouch = pouch;
          this.pouchDATA = pouch;
          this.store.dispatch(new AddEdit(statePouch));
          this.tableDataSource = new MatTableDataSource(
            pouch.public___pouch_stocks
          );
          this.index = this.tableDataSource ? 1 : 0;
          this.calculateMinimumInvestment(this.newPouch);
          // dialogRef.close();
          this.sharedService.hideLoader();
          this.disableshowOptionForStocksnumberBtn =false
        }
      })
      .catch((err: any) => {
        this.sharedService.hideLoader();
        this.disableshowOptionForStocksnumberBtn =false
        // dialogRef.close();
      });
  }
  calculateMinimumInvestment(pouches: any) {
    this.pouchService
      .getPouchStocksReturns({
        pouch_id: pouches.pouch_id,
        stock_price_date: this.newPouch.stock_price_date.split('T')[0],
      })
      .subscribe((response: any) => {
        let pouch_stocks_returns =
          response[0].Results[0].return_data.pouch_stocks_returns;

        let sumArr = [];
        for (let data of pouch_stocks_returns) {
          if (data.min_amt) {
            sumArr.push(data.min_amt);
          }
        }
        this.editNewPouch.minimum_investment = Number(
          sumArr.reduce((a, b) => a + b, 0)
        ).toFixed(2);
        /*  this.editNewPouch.investment_amount = Number(
          sumArr.reduce((a, b) => a + b, 0)
        ).toFixed(2); */
      });
  }
  checkExpiry() {
    let quoteExpiry = new Date(this.userInfo.attributes.createpouchexpiry);
    let now = new Date();

    if (now.getTime() > quoteExpiry.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  getValueFromUser(value: any) {
    let user: any = localStorage.getItem('user');
    let userInfo: any = JSON.parse(user)?.attributes;
    return userInfo[value] || '';
  }
  subscribe() {
    let data: any = [];
    sessionStorage.setItem('pouchData', JSON.stringify(this.newPouch));
    this.publicService.getSubscriptionList().subscribe(
      (r: any) => {
        data = r[0].subscriptions;
      },
      (e: any) => {},
      () => {
        let dialog = this.dialog.open(CreatePouchPopupComponent, {
          data: { data: data, from: 'chat' },
        });
        dialog.afterClosed().subscribe((val: string) => {
          if (val === 'subscribe') {
            this.router.navigateByUrl('/agreement');
          } else if (val === 'try') {
            this.router.navigateByUrl('/pouch-creation');
          } else {
            this.router.navigateByUrl('/investment');
          }
        });
      }
    );
    // this.publicService.userSubscribed = true;
    // this.blur = false;
  }
  proceed(journey: any) {
    if (journey === 'continue') {
      this.index = 2;
      this.startBot('pouch_info', ['q1', 'q2', 'q3', 'a1']);
    }
    if (journey === 'restart') {
      this.index = 0;
    }
  }

  startBot(group: any, qnsets: any) {
    for (let i = 0; i < qnsets.length; i++) {
      setTimeout(() => {
        this.questionSets[group][qnsets[i]] = true;
      }, i * 1000);
    }
  }
  next(val: any) {
    switch (val) {
      case 2:
        if (this.editNewPouch.pouch_name) {
          if (
            Number(this.editNewPouch.investment_amount) >=
            Number(this.editNewPouch.minimum_investment)
          ) {
            this.currentqnGroup = 2;
            this.startBot('pouch_configuration', ['q1', 'q2', 'q3', 'a1']);
            this.editNewPouch.error.investment_amount = false;
            this.editNewPouch.error.pouch_name = false;
          } else {
            this.editNewPouch.error.investment_amount = true;
          }
        } else {
          this.editNewPouch.error.pouch_name = true;
        }

        break;

      case 'configure':
        this.currentqnGroup = 3;
        this.startBot('pouch_description', ['q1', 'a1']);
        break;
      case 4:
        this.currentqnGroup = 4;
        this.startBot('pouch_icon', ['q1', 'a1']);
        break;

      case 5:
        // if (this.editNewPouch.end_date) {
        this.currentqnGroup = 5;
        this.startBot('pouch_stoploss', ['q1', 'a1']);
        // this.editNewPouch.error.end_date = false;
        // } else {
        //   this.editNewPouch.error.end_date = true;
        // }

        break;

      case 'advanced_save':
        if (
          this.editNewPouch.stop_loss !== null &&
          this.editNewPouch.stop_loss !== undefined &&
          this.editNewPouch.stop_loss !== '' &&
          this.editNewPouch.stop_loss >= 0
        ) {
          this.editNewPouch.error.stop_loss = false;
          this.saveWithConfiguration();
        } else {
          this.editNewPouch.error.stop_loss = true;
        }

        break;
      case 'save':
        this.saveWithoutConfiguration();
        break;
    }
  }

  scrolled = true;
  scrollingDirection = 0;
  lastScroll = 9999;
  scrollIdleTime = 300;
  scroll(e: any) {
    var delta = e.deltaY;
    var timeNow = performance.now();
    if (
      delta > 0 &&
      (this.scrollingDirection != 1 ||
        timeNow > this.lastScroll + this.scrollIdleTime)
    ) {
      this.showNextQn('down');

      this.scrollingDirection = 1;
    } else if (
      delta < 0 &&
      (this.scrollingDirection != 2 ||
        timeNow > this.lastScroll + this.scrollIdleTime)
    ) {
      this.showPrevQns('up');
      this.scrollingDirection = 2;
    }
    this.lastScroll = timeNow;
  }
  showPrevQns(val: any) {
    if (this.currentqnGroup > 1) {
      this.currentqnGroup--;
    }
  }
  showNextQn(val: any) {}

  saveWithConfiguration() {
    let subscriber_id = JSON.parse(localStorage.getItem('user') || '{}')?.id;
    let body: any = {
      pouch_id: this.newPouch.pouch_id,

      docs: {
        icon_base64: '',
        pouch_name: this.editNewPouch.pouch_name,
        investment_strategy: 'CUSTOM',
        risk_profile: 'MODERATE',
        description: this.editNewPouch.pouch_description,
        benchmark_index: this.selectedBenchMarkIndex,
      },
      subscriber_obj: [
        {
          pouch_id: this.newPouch.pouch_id,
          subscriber_id: subscriber_id,
          subscription_type: 'MONTHLY',
          start_date: localStorage
            .getItem('stock_price_uploaded_date')
            ?.split('T')[0],
          stoploss_stock: this.editNewPouch.stop_loss,
          stoploss_portfolio: this.editNewPouch.stop_loss,
          investment_amount: this.editNewPouch.investment_amount,
          is_active: 'Y',
        },
      ],
      docsi: [
        {
          pouch_id: this.newPouch.pouch_id,
          subscriber_id: subscriber_id,
          investment_amount: this.editNewPouch.investment_amount,
          change_amount: this.editNewPouch.investment_amount,
          from_date: localStorage
            .getItem('stock_price_uploaded_date')
            ?.split('T')[0],
        },
      ],
      subscriber_id: subscriber_id,
      review_date: localStorage
        .getItem('stock_price_uploaded_date')
        ?.split('T')[0],
      investment_amount: this.editNewPouch.investment_amount,
      minimum_investment: this.editNewPouch.minimum_investment,
    };
    const dialogBox = this.dialog.open(CommonDialogWaitingComponent);
    this.publicService.editPouchSubscriber(body).subscribe((res: any) => {
      this.savePouch(res, dialogBox);
    });
  }
  saveWithoutConfiguration() {
    let subscriber_id = JSON.parse(localStorage.getItem('user') || '{}')?.id;
    let body: any = {
      pouch_id: this.newPouch.pouch_id,

      docs: {
        icon_base64: '',
        pouch_name: this.editNewPouch.pouch_name,
        investment_strategy: 'SECTORAL',
        risk_profile: 'HIGH',
        description: '',
        benchmark_index: this.selectedBenchMarkIndex,
      },
      subscriber_obj: [
        {
          pouch_id: this.newPouch.pouch_id,
          subscriber_id: subscriber_id,
          subscription_type: 'MONTHLY',
          start_date: localStorage
            .getItem('stock_price_uploaded_date')
            ?.split('T')[0],
          stoploss_stock: 0,
          stoploss_portfolio: 0,
          investment_amount: this.editNewPouch.investment_amount,
          is_active: 'Y',
        },
      ],
      docsi: [
        {
          pouch_id: this.newPouch.pouch_id,
          subscriber_id: subscriber_id,
          investment_amount: this.editNewPouch.investment_amount,
          change_amount: this.editNewPouch.investment_amount,
          from_date: localStorage
            .getItem('stock_price_uploaded_date')
            ?.split('T')[0],
        },
      ],
      subscriber_id: subscriber_id,
      review_date: localStorage
        .getItem('stock_price_uploaded_date')
        ?.split('T')[0],
      investment_amount: this.editNewPouch.investment_amount,
      minimum_investment: this.editNewPouch.minimum_investment,
    };
    const dialogBox = this.dialog.open(CommonDialogWaitingComponent);
    this.publicService.editPouchSubscriber(body).subscribe((res: any) => {
      this.savePouch(res, dialogBox);
      // this.index = 3;
    });
  }

  savePouch(res: any, dialogBox: any) {
    if (res.length >= 0 && res[0].pouch && res[0].pouch.err == '-') {
      dialogBox.close();
      const dialog: any = this.dialog.open(CommonDialogComponent, {
        data: {
          message: 'Pouch saved successfully.',
          title: 'Success',
          status: 'Success',
        },
      });

      const body = {
        sort: -13,
        current_date: localStorage
          .getItem('stock_price_uploaded_date')
          ?.split('T')[0],
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      };
      this.store.dispatch(new Load(body));
      setTimeout(() => {
        dialog.close();
        this.index = 3;
      }, 1000);
    }
  }
  getEndDate() {
    return new Date(new Date().setFullYear(new Date().getFullYear() + 2))
      .toISOString()
      .split('T')[0];
  }
  goTo(val: string) {
    if (val === 'invest') {
      this.router.navigateByUrl(
        `/investment/map-subscriber/${this.newPouch.pouch_id}`
      );
      this.publicService.fromCreatingPouch = true;
    }
    if (val === 'later') {
      this.router.navigateByUrl('/investment');
    }
  }
  getdescription(val: any) {
    let descObj = this.universeList.find((ele: any) => ele.name === val);
    return descObj?.desc || '';
  }
  changeTab(e: any) {
    this.TabIndex = e;
  }

  // for chart comparsion

  //selected duraion 3 yr
  performanceData: any;
  pouchData: any;
  indexData: any;
  pouchSince: any = 0;
  indexSince: any = 0;
  getPerformance() {
    let index = this.universeList.find(
      (ele: any) => ele.name === this.selectedUniverse
    );
    this.selectedBenchMarkIndex =
      index.universe_type === 'SYSTEM' ? index.name : 'Smartvalues Main Large Cap';

    this.showOptionForStocksnumber = false;
    this.chart = {};
    this.getLastYearsDates();
    let data = {
      pouch_id: 0,
      universe_name: this.selectedUniverse,
      stock_price_date: this.datesForComparison,
    };

    this.pouchService
      .getComparePouchUniversePerformance(data)
      .subscribe((res: any) => {
        if (res && res[0].Results) {
          this.performanceData = res[0].Results;

          this.pouchData = this.performanceData.filter(function (item: any) {
            if (item.record_type == 'pouch') return item;
          });
          this.indexData = this.performanceData.filter(function (item: any) {
            if (item.record_type == 'index') return item;
          });

          this.arrangeChartData();
        }
      });
  }
  arrangeChartData() {
    const maxPercent = Math.round(
      Math.max.apply(
        Math,
        this.performanceData.map(function (item: any) {
          return item.change_perc;
        })
      )
    );
    const minPercent = Math.round(
      Math.min.apply(
        Math,
        this.performanceData.map(function (item: any) {
          return item.change_perc;
        })
      )
    );
    this.pouchData = this.performanceData.filter(function (item: any) {
      if (item.record_type == 'pouch') return item;
    });
    this.indexData = this.performanceData.filter(function (item: any) {
      if (item.record_type == 'index') return item;
    });

    this.dates = this.indexData.map(function (item: any) {
      return item.index_date.split('T')[0];
    });
    const pouchChangePercents = this.pouchData.map(function (item: any) {
      return item.change_perc;
    });
    const indexChangePercents = this.indexData.map(function (item: any) {
      return item.change_perc;
    });
    this.chart = {
      chart1: pouchChangePercents,
      chart2: indexChangePercents,
    };
    this.pouchName = this.selectedUniverse;
    this.indexName = this.indexData[0]?.index_name;
    this.pouchSince = Math.round(
      this.pouchData[this.pouchData.length - 1]?.change_perc + 100
    ).toLocaleString('en-IN', {
      useGrouping: true,
    });

    this.indexSince = Math.round(
      this.indexData[this.indexData.length - 1]?.change_perc + 100
    ).toLocaleString('en-IN', {
      useGrouping: true,
    });
    this.minPercent = minPercent - 2;
    this.maxPercent = maxPercent + 2;
  }
  datesForComparison: any;
  getLastYearsDates() {
    let uploadedDate = localStorage.getItem('stock_price_uploaded_date');



    let date1: any = this.getOneYearAgoDate(uploadedDate);
    let date2: any = this.getOneYearAgoDate(date1);
    let date2a: any = this.getOneYearAgoDate(date2);
    date2a =
      new Date(moment(new Date()).format('YYYY-MM-DD')) > new Date(date2a)
        ? moment(new Date()).format('YYYY-MM-DD')
        : date2a;
    this.datesForComparison = `{${this.getSplitDate(
      uploadedDate
    )},${this.getSplitDate(date1)},${this.getSplitDate(
      date2
    )},${this.getSplitDate(date2a)}}`;
  }

  getOneYearAgoDate(uploadedDate: any) {
    const date = new Date(uploadedDate);
    date.setFullYear(date.getFullYear() - 1);
    return date;
  }
  getSplitDate(date: any) {
    date = new Date(date);
    const splitDate = moment(new Date(date)).format('YYYY-MM-DD');
    return splitDate;
  }
  getEndDateSubscription(freq: any) {
    if (freq === 'MONTHLY') {
      var dt = new Date();
      return dt.setMonth(dt.getMonth() + 1);
    }
    if (freq === 'YEARLY') {
      var dt = new Date();
      return dt.setMonth(dt.getMonth() + 12);
    }
    if (freq === 'HALF-YEARLY') {
      var dt = new Date();
      return dt.setMonth(dt.getMonth() + 6);
    }
    return '';
  }
  updateLocalStorage(userInfo: any, d: any) {
    userInfo.attributes.createpouch = true;
    userInfo.attributes.createpouchexpiry = new Date(
      this.getEndDateSubscription(d.subscription_freq)
    )
      .toISOString()
      .split('T')[0];
    localStorage.setItem('user', JSON.stringify(userInfo));
    if (sessionStorage.getItem('pouch')) {
      sessionStorage.removeItem('pouch');
    }
  }
  checkUserSubscription() {
    if (this.userInfo.attributes.createpouch && !this.checkExpiry()) {
      return true;
    } else {
      return true;
    }
  }
  gotoInvestmentPouches() {
    this.router.navigateByUrl('/investment');
  }
  gatewayInstance = new window.scDK({
    // ! Use valid gateway name here
    gateway: 'smartvalues',
    /*
     * ! Use valid auth token
     * Details - https://developers.gateway.smallcase.com/docs/getting-started#broker-identification
     */
    smallcaseAuthToken: environment.smallcaseAuthToken,
    config: {
      // Should allow after market orders?
      amo: true,
    },
  });
  buttonDisable: boolean = false;
  proceedInvest() {
    if (
      Number(this.editNewPouch.investment_amount) >=
      Number(this.editNewPouch.minimum_investment)
    ) {
      this.editNewPouch.error.investment_amount = false;
      let subscriber_id = JSON.parse(localStorage.getItem('user') || '{}')?.id;
      let pouch_id = this.newPouch.pouch_id;
      let investmentAmount = this.editNewPouch.investment_amount;
      //
     this.buttonDisable = true;

      this.pouchService
        .getPouchCreationId({
          pouch_id: pouch_id,
          subscriber_id: subscriber_id,
          investment_amount: investmentAmount,
        })
        .subscribe(
          (response: any) => {
            this.buttonDisable = false;
            //
            let id = response.data.transactionId;
            let error = response.errors;

            if (!error) {
              this.gatewayInstance
                .triggerTransaction({
                  transactionId: id,
                })
                .then(
                  (txnResponse: any) => {
                    let body = {
                      pouch_id: pouch_id,
                      txn_id: id,
                      from_date: new Date(
                        new Date(
                          localStorage
                            .getItem('stock_price_uploaded_date')
                            ?.split('T')[0] || ''
                        ).setDate(
                          new Date(
                            localStorage
                              .getItem('stock_price_uploaded_date')
                              ?.split('T')[0] || ''
                          ).getDate() - 7
                        )
                      )
                        .toISOString()
                        .split('T')[0],
                      to_date: localStorage
                        .getItem('stock_price_uploaded_date')
                        ?.split('T')[0],
                      subscriber_id: JSON.parse(
                        localStorage.getItem('user') || '{}'
                      ).id,
                      api_name: 'sc_txn_resp',
                      api_response_data: {
                        api_name: 'sc_txn_resp',
                        api_id: id,
                        api_response: txnResponse,
                      },
                    };
                    const dialogRef = this.dialog.open(
                      CommonDialogWaitingComponent,
                      { disableClose: true }
                    );
                    this.http
                      .post(
                        environment.baseUrl_8083 +
                          `smartvalues/func/saveapiresponse`,
                        body
                      )
                      .subscribe((res: any) => {
                        this.router.navigateByUrl('/investment');
                        if (
                          res[0].update_pouch_subscriber_trade_status.err ===
                            '-' &&
                          res[1].insert_pouch_subscriber_trade_status.err ===
                            '-' &&
                          res[2].insert_pouch_subscriber_trades.err === '-'
                        ) {
                          // this.updateTabeleHandler.emit({ dialog: dialogRef });
                        } else {
                          this.notifyService.showError('Error: Invalid', '');
                        }
                      });
                  },
                  (error: any) => {
                    let body = {
                      pouch_id: pouch_id,
                      txn_id: id,
                      subscriber_id: subscriber_id,
                      api_name: 'sc_txn_resp',
                      api_response_data: {
                        api_name: 'sc_txn_resp',
                        api_id: id,
                        api_response: {
                          error: error.message,
                        },
                      },
                    };

                    this.http
                      .post(
                        environment.baseUrl_8083 +
                          `smartvalues/func/saveapiresponseerr`,
                        body
                      )
                      .subscribe((res: any) => {
                        if (res[0].update_pouch_subscriber_trades.err === '-') {
                          // this.updateTabeleHandler.emit();
                        }
                        this.notifyService.showError(
                          `Error : ${error.message}`,
                          ''
                        );
                      });
                  }
                );
            } else {
              this.notifyService.showError('Error: Invalid', '');
            }
          },
          (error: any) => {
            //Error callback
            console.error('error caught in component', error);
            this.buttonDisable = false;
            this.notifyService.showError(`Error:${error.error.errors[0]}`, '');
          }
        );

      //
    } else {
      this.editNewPouch.error.investment_amount = true;
    }
  }

  daysLeftForRenewal() {
    const endDateMoment = _moment(
      this.userInfo.attributes.createpouchexpiry
    ).endOf('day');
    // Uncomment the below line if you want to hardcode the end date for testing
    // const endDateMoment = _moment('2023-08-20T00:00:00Z').endOf('day');
    this.SubscriptionEndDate = endDateMoment;
    const todayMoment = _moment(); // current date
    let daysLeft = endDateMoment.diff(todayMoment, 'days');
    if (daysLeft <= 7) {
      this.totalDaysLeft = daysLeft;
      this.switchDateLeft = false;
    } else {
      this.switchDateLeft = true;
    }
  }
  openRenew() {
    let datas: any = [];
    this.publicService.getSubscriptionList().subscribe((res: any) => {
      if (res) {
        datas = res[0].subscriptions;
        let data = {
          endDate: this.SubscriptionEndDate,
          optionValueShow: datas,
        };
        let popup = this.dialog.open(RenewSubscriptionPopupComponent, { data });
        popup.afterClosed().subscribe((res: any) => {
          if (res === 'renewNow') {
            this.makePayment();
          } else {
            this.startBot('universe', ['q2', 'q3', 'a1']);
          }
        });
      }
    });
  }
  makePayment() {
    let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    this.publicService
      .intiatePaymentGatewaysvf({
        subscriber_id: d.subscriber_id,
        redirect_url: `${window.location.host}/investment`,
        subscription_freq: d.subscription_freq,
        sv_feature_name: 'create_pouch',
        promo_code: '',
      })
      .subscribe((res: any) => {
        if (res) {
          this.redirectToPayment(res);
        }
      });
  }
  redirectToPayment(data: any) {
    this.dialog.open(PaymentLoaderComponent, {
      data: data,
      panelClass: 'custom-modalbox',
    });
  }
}
