import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PublicService } from '../../services/public.service';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/common/services/shared.service';
import { environment } from 'src/environments/environment';
import { AgreementGeneratorService } from '../agreement/agreement-generator.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { NotificationService } from 'src/app/services/notification.services';
import { ThankyouComponent } from '../thankyou/thankyou.component';
import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-agreement-subscribe',
  templateUrl: './agreement-subscribe.component.html',
  styleUrls: ['./agreement-subscribe.component.scss'],
})
export class AgreementSubscribeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  today = new Date();
  userInfo: any;
  subscriptionData: any;
  agreementData: any;
  agreementInfo: any;
  user: any;
  product: any;
  questions: any = [];
  aid: any;
  eSign$: any;
  hasRedirectUrl = false;
  redirect = '';
  count = 0;
  description_sub: any;
  callback = false;
  retryCount = 1;
  paymentUrl: string = '';
  paymentData: any = {
    access_code: '',
    encRequest: '',
    order_id: '',
  };
  from: any = '';
  subscriptionFrequency: any;
  amount: any = 0;
  @ViewChild('myForm', { static: true }) public ngForm: ElementRef =
    {} as ElementRef;
    pouchCreationData:any
  constructor(
    public publicService: PublicService,
    private subService: SubsciberService,
    private _router: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    public ags: AgreementGeneratorService,
    private notifyService: NotificationService,
    public dialog: MatDialog
  ) {
    this.paymentUrl = environment.paymentGatewayUrl;
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }

  ngOnInit(): void {
    this.pouchCreationData = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    console.log(this.pouchCreationData,'1266281624')
    this.product = 'smartvalues';
    this._router.queryParams.subscribe((res: any) => {
      if (res.aid === JSON.parse(sessionStorage.getItem('aID') || '{}')) {
        if (res.aid) {
          this.callback = true;
          this.aid = res.aid;
        }
        this.publicService.check_agreement_status(res.aid).subscribe(
          (res: any) => {
            if (res[0].Results[0].status == true) {
              this.checkAID();
            } else if (environment.bypassEsign) {
              this.checkAID();
            } else {
              this.notifyService.showError(
                '',
                'Something went wrong ,Try Again'
              );
              sessionStorage.removeItem('data');

              this.intiateFromPlans();
            }
          },
          (e) => {
            console.log(e.error.error);
            this.notifyService.showError('', e.error.error);
            this.router.navigateByUrl('/');
            if (sessionStorage.getItem('aID')) {
              sessionStorage.removeItem('aID');
            }
          }
        );
      }
    });
    this.description_sub =
      this.publicService.mapSubscriberData.agreementDetails?.public___pouch_subscriptions.find(
        (ele: any) =>
          ele.subscription_freq ===
          this.publicService.mapSubscriberData.subscription.subscription_freq
      );
    this.setUserInfo();
  }
  ngAfterViewInit(): void {
    this.onIframeLoad(this.product);
  }
  ngOnDestroy(): void {
    this.aid = '';
    if (this.eSign$) {
      this.eSign$.unsubscribe();
    }
  }
  getMonths(type: any) {
    return this.ags.getMonths(type);
  }
  iscomback = false;
  checkAID() {
    this.iscomback = true;
    let freqFromSession = JSON.parse(sessionStorage.getItem('data') || '{}');
    let freq = '';
    if (Object.keys(freqFromSession)?.length > 0) {
      let freq1 = freqFromSession.mapSubscriberData.subscription_freq;
      if (freq1.indexOf('6 months') > 0) {
        freq = 'HALF-YEARLY';
      } else if (freq1.indexOf('year') > 0) {
        freq = 'YEARLY';
      } else {
        freq = '';
      }
      let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
      
      freq= d.subscription_freq;
    }
    let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    console.log(d,'1266281624')
    let data = {
      docs: {
        subscriber_id: this.userInfo.id,
        investment_amount: 0,
        registration_date: new Date().toISOString().split('T')[0],
        product_name: 'create_pouch',
        agreement_id: this.aid,
        subscription_type: '',
        subscription_perc: 0,
        subscription_amount: d.subscription_amount,
        subscription_amount_final: d.subscription_amount,
        subscription_freq: d.subscription_freq,
        promo_code: '',
        discount_amount: 0,
        stoploss: 0,
        sv_feature_id:d.sv_feature_id
      },
    };

    this.subService.postSaveSubscriberInvestment(data).subscribe((res: any) => {
      if (res && freq) {
        // if (freq) {
        this.publicService
          .intiatePaymentGatewaysvf({
            subscriber_id: this.userInfo.id,
            redirect_url: `${window.location.host}/investment`,
            subscription_freq: d.subscription_freq,
            sv_feature_name: 'create_pouch',
            promo_code: '',
          })
          .subscribe((res: any) => {
            if (res) {
              this.paymentData = res;
              this.redirectToPayment(res);
            }
          });
        // }
      }
    });
  }
  openpopup() {
    console.log('this.from', this.from);

    if (this.product !== 'smartbeta') {
      this.publicService
        .saveAgreement({
          docs: {
            subscriber_id: this.userInfo.id,
            investment_amount:
              this.publicService.mapSubscriberData.data.investment_amount,
            registration_date: new Date().toISOString().split('T')[0],
            product_name: 'smartvalues',
            agreement_id: this.aid,
            subscription_type:
              this.publicService.mapSubscriberData.subscription
                .subscription_type,
            subscription_perc:
              this.publicService.mapSubscriberData.subscription
                .subscription_perc,
            subscription_amount:
              this.publicService.mapSubscriberData.subscription
                .subscription_amount,
            subscription_amount_final:
              this.publicService.mapSubscriberData.subscription
                .subscription_amount_final,
            subscription_freq:
              this.publicService.mapSubscriberData.subscription
                .subscription_freq,
            promo_code:
              this.publicService.mapSubscriberData.subscription.promo_code,
            discount_amount:
              this.publicService.mapSubscriberData.subscription.discount_amount,
            pouch_id:
              this.publicService.mapSubscriberData.subscription.pouch_id,
            stoploss:
              this.publicService.mapSubscriberData.data.subscriberObj[0]
                .stoploss_portfolio,
          },
        })
        .subscribe((res: any) => {
          if (res.subscriber_investment.error == '-') {
            this.publicService
              .intiatePaymentGateway({
                investment_amount: `${this.publicService.mapSubscriberData.data.investment_amount}`,
                pouch_id: this.publicService.mapSubscriberData.data.pouch_id,
                frequency:
                  this.publicService.mapSubscriberData.subscription
                    .subscription_freq,
                subscription_amount: `${this.amount}`,
                subscriber_id: this.userInfo.id,
                redirect_url: `${window.location.host}/investment/map-subscriber/${this.publicService.mapSubscriberData.data.pouch_id}`,
              })
              .subscribe(
                (res: any) => {
                  this.redirectToPayment(res);
                },
                (e) => {
                  this.notifyService.showError('', e.error.error.message);
                }
              );
          }
        });
    } else {
      this.publicService
        .saveAgreement({
          docs: {
            subscriber_id: this.userInfo.id,
            investment_amount:
              this.publicService.mapSubscriberData?.agreementValues
                .investment_amount,
            registration_date: new Date().toISOString().split('T')[0],
            product_name: 'smartbeta',
            agreement_id: this.aid,
          },
        })
        .subscribe(
          (res: any) => {
            if (res.subscriber_investment.error === '-') {
              const dialog = this.dialog.open(ThankyouComponent, {
                width: '621px',
                height: '293px',

                disableClose: false,
              });
              setTimeout(() => {
                dialog.close();
                this.router.navigate(['/public/landing-page']);
                if (sessionStorage.getItem('aID')) {
                  sessionStorage.removeItem('aID');
                }
              }, 5000);
            }
          },
          (e) => {
            this.router.navigateByUrl('/');
            this.notifyService.showError('', e.error.error.message);
          }
        );
    }
  }
  // redirectToPayment(data: any) {
  //   this.paymentData = data;
  //   console.log(this.paymentData, '<[[ payment data');

  //   const interval = setTimeout(() => {
  //     if (this.paymentData.access_code && this.paymentData.encRequest) {
  //       if (this.product !== 'smartbeta') {
  //         this.publicService.mapSubscriberData.returnToComposition = true;
  //         sessionStorage.setItem(
  //           'pouch',
  //           JSON.stringify(this.publicService.mapSubscriberData)
  //         );
  //         this.ngForm.nativeElement.submit();
  //       } else {
  //         console.log(JSON.stringify(this.publicService.agreementDetailsNew));
  //         sessionStorage.setItem(
  //           'pouch',
  //           JSON.stringify(this.publicService.agreementDetailsNew)
  //         );
  //         this.ngForm.nativeElement.submit();
  //       }
  //       clearInterval(interval);
  //     }
  //   }, 2000);
  // }
  intiateFromPlans() {
    let payload = {
      name: `${this.userInfo.attributes.name.first} ${this.userInfo.attributes.name.last}`,
      email: this.userInfo.attributes.email,
      risk: this.userInfo?.attributes.risk_profile,
      amount: this.publicService?.mapSubscriberData?.data?.investment_amount,
      title: this.agreementInfo?.heading_text,
    };
    this.agreementData = payload;
    this.from = 'from-plans';
  }
  onIframeLoad(product?: any) {
    
    this.subService
      .fetchRiskProfile({
        subscriber_id: this.user.id,
      })
      .subscribe(async (res: any) => {
       
        if (res[0].subscriber_response) {
         
          let response = res[0].subscriber_response[0].public___rp_subscribers;
          console.log(response);
          this.questions = [];
          for (let qn of response) {
            this.questions.push({
              qn: qn.rp_question,
              ans: qn.rp_answer,
            });
          }

          {
            let base64: any;
            let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
            let pdf: any = await this.ags.generateSubscribePDF(
              {
                user: this.user.attributes,
                value: this.publicService.mapSubscriberData,
                qns: this.questions,
                desc: this.description_sub,
                subscriptionFrequency:d.subscription_freq,
                pouchCreationData:this.pouchCreationData
              },
              this.iscomback
            );
           
            // pdfMake.createPdf(pdf).download();
            try {
              pdfMake.createPdf(pdf)?.getBase64((data) => {
                base64 = data;

                if (!this.callback) {
                  this.eSignIntiate(base64);
                }
              });
            } catch (e) {}
          }
        }
      });
  }
  setUserInfo() {
    let subscriptionFrequencys;
    this.userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    this.agreementInfo =
      this.publicService?.mapSubscriberData?.agreementDetails;
    this.subscriptionData = this.publicService?.mapSubscriberData?.data;
    subscriptionFrequencys =
      this.publicService?.mapSubscriberData?.subscription_freq;
    if (subscriptionFrequencys?.indexOf('6 months') > 0) {
      this.subscriptionFrequency = 'HALF-YEARLY';
    } else if (subscriptionFrequencys?.indexOf('year') > 0) {
      this.subscriptionFrequency = 'YEARLY';
    } else {
      this.subscriptionFrequency = '';
    }
    let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    this.subscriptionFrequency=d.subscription_freq || '';
  }
  eSignIntiate(encodedData: any) {
    let payload: any = {
      id : uuidv4(),
      file: encodedData,
      filename: 'agreeement.pdf',
      signees: [
        {
          name: `${this.userInfo.attributes.name.first} ${this.userInfo.attributes.name.last}`,
          email: this.userInfo.attributes.email,
          mobile: this.userInfo.attributes.phone.replace('-', ''),
          signtype: 'uidai_sign',
          duedate: new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .split('T')[0],
        },
      ],
      callbackurl:
        this.product === 'smartbeta'
          ? `${window.location.host}/agreement-subscribe`
          : `${window.location.host}/agreement-subscribe`,
    };
    if (this.product === 'smartbeta') {
      console.log(this.publicService.agreementDetailsNew);
      sessionStorage.setItem(
        'data',
        JSON.stringify({
          mapSubscriberData: this.publicService.agreementDetailsNew,
        })
      );
    } else {
      sessionStorage.setItem(
        'data',
        JSON.stringify({
          mapSubscriberData: this.publicService.mapSubscriberData,
        })
      );
    }
    if (environment.bypassEsign) {
      sessionStorage.setItem(
        'aID',
        JSON.stringify(this.generateRandomString())
      );
    } else {
      this.eSign$ = this.publicService.intiateesign(payload).subscribe(
        (res: any) => {
          let response = res.data.signing_url;
          sessionStorage.setItem('aID', JSON.stringify(res.data.agreement_id));
          Object.keys(response).forEach((e: any) => {
            this.redirect = response[e];
          });
          this.hasRedirectUrl = true;
        },
        (e: any) => {
          this.notifyService.showError('', e.error.error);
          this.retryCount++;
        }
      );
    }
  }
  generateRandomString() {
    // Generate two random 3-digit numbers
    const number1 = Math.floor(Math.random() * 900) + 100;
    const number2 = Math.floor(Math.random() * 900) + 100;

    // Generate two random 3-character strings
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let string = '';
    for (let i = 0; i < 3; i++) {
      string += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const string1 = string;
    string = '';
    for (let i = 0; i < 3; i++) {
      string += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const string2 = string;

    // Concatenate the two numbers and two strings together
    const result = number1.toString() + number2.toString() + string1 + string2;

    return result;
  }
  submit() {
    console.log(this.eSign$);
    if (!this.eSign$?.closed) {
      this.sharedService.showLoader();
    }
    if (environment.bypassEsign) {
      this.router.navigateByUrl(
        `/agreement-subscribe?aid=${JSON.parse(
          sessionStorage.getItem('aID') || ''
        )}`
      );
    } else {
      const interval = setInterval(() => {
        if (this.hasRedirectUrl) {
          window.location.href = this.redirect;
          clearInterval(interval);
          this.count == 0;
        } else {
          //how to check if api is pending in browser
          if (this.count == 500000) {
            clearInterval(interval);
          } else {
            this.count++;
          }
        }
      }, 500);
    }
  }

  redirectToPayment(data: any) {
    let encRequest = document.getElementById('encRequest') as HTMLInputElement;
    let access_code = document.getElementById(
      'access_code'
    ) as HTMLInputElement;
    encRequest.value = data.encRequest;
    access_code.value = data.access_code;
    this.paymentData = data;
    const interval = setInterval(() => {
      if (this.paymentData.access_code && this.paymentData.encRequest) {
        console.log(JSON.stringify(this.publicService.agreementDetailsNew));
        sessionStorage.setItem(
          'pouch',
          JSON.stringify(this.publicService.agreementDetailsNew)
        );
        this.ngForm.nativeElement.submit();

        clearInterval(interval);
      }
    }, 100);
  }
}
