import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { DashboardService } from '../../services/dashboard.service';
import {
  DashboardActionTypes,
  Load,
  AddEdit,
  AddEditSuccess,
  AddEditFailure,
} from '../actions/dashboard.action';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { SubsciberService } from 'src/app/services/subscriber.service';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions: Actions,
    private dashboardService: DashboardService,
    private pouchService: PouchService,
    private subscriberService: SubsciberService,
    private router: Router
  ) {}

  // @Effect()
  // Load: Observable<any> = this.actions.pipe(
  //   ofType(DashboardActionTypes.LOAD),
  //   map((action: Load) => action.payload),
  //   switchMap((payload) => {
  //     return this.dashboardService.getKPIs()
  //     .pipe(
  //       map((response : any) => {
  //         if (response != null) {
  //
  //           return new AddEditSuccess(response);
  //         } else {
  //           return new AddEditFailure({ error: "Error." });
  //         }
  //       }),
  //       catchError((error) => {
  //
  //         return observableOf(new AddEditFailure({ error: error }));
  //       })
  //     );
  //   })
  // );

  @Effect()
  Load: Observable<any> = this.actions.pipe(
    ofType(DashboardActionTypes.LOAD),
    mergeMap((action: Load) => {
      const result$ = forkJoin([
        this.dashboardService.getKPIs(),
        // this.pouchService.getPouchList(action.payload.pouchPayload).pipe(
        //   map((data : any) => {
        //     return data[0].pouches.map((element : any) => {
        //       return { heading: element["name"], content: element["description"],  count : element["subscriber_count"]};
        //     })
        //   }),
        //   catchError((error) => {
        //     return observableOf({ error: error });
        //   })
        // ),
        // this.subscriberService.getSubscriberList(action.payload.subscriberPayload).pipe(
        //   map((data : any) => {
        //     return data[0].subscribers.map((element : any) => {
        //       return { heading: element["name"], joiningDate: element["joined_since"],  count : element["pouch_count"]};
        //     })
        //   }),
        //   catchError((error) => {
        //     return observableOf({ error: error });
        //   })
        // ),
        this.dashboardService
          .getPouchRebalanceList(action.payload.rebalancePayload)
          .pipe(
            map((data: any) => {
              return data[0].pouches.map((element: any) => {
                return {
                  heading: element['name'],
                  lastReviewDate: element['review_date'],
                  nextReviewDate: element['next_review_date'],
                  review_frequency: element['review_freq'],
                };
              });
            }),
            catchError((error) => {
              return observableOf({ error: error });
            })
          ),
        this.dashboardService.getTopGainersLosers(),
      ]).pipe(
        mergeMap((joinResult) => {
          return [new AddEditSuccess(joinResult)];
        })
      );
      return result$;
    }),
    catchError((error) => {
      return observableOf(new AddEditFailure({ error: error }));
    })
  );

  @Effect()
  AddEdit: Observable<any> = this.actions.pipe(
    ofType(DashboardActionTypes.ADDEDIT),
    map((action: AddEdit) => action.payload),
    switchMap((payload) => {
      try {
        return observableOf(new AddEditSuccess(payload));
      } catch (error) {
        return observableOf(new AddEditFailure({ error: error }));
      }
    })
  );

  @Effect({ dispatch: false })
  AddEditSuccess: Observable<any> = this.actions.pipe(
    ofType(DashboardActionTypes.ADDEDIT_SUCCESS),
    tap((response) => {
      const data: any = response; //response.payload;
    })
  );

  @Effect({ dispatch: false })
  AddEditFailure: Observable<any> = this.actions.pipe(
    ofType(DashboardActionTypes.ADDEDIT_FAILURE),
    tap((error) => {})
  );
}
