import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PouchState, selectPouchState } from '../store/pouch.state';
import { Store } from '@ngrx/store';
import { Load } from '../store/actions/pouch.action';
import { Observable } from 'rxjs/internal/Observable';
import { MatDialog } from '@angular/material/dialog';
import { MapSubscriberPopupComponent } from 'src/app/modules/investment-pouches/map-subscriber-popup/map-subscriber-popup.component';
import { PouchService } from '../services/pouch.service';
import { PublicService } from '../../public/services/public.service';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { UtilsService } from 'src/app/common/services/utils.service';
import { TEXTS } from '../../../common/constants/text-constants';
import { DeletePopupComponent } from 'src/app/common/components/delete-popup/delete-popup.component';
import { NotificationService } from 'src/app/services/notification.services';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { SucessPopupComponent } from '../../public/components/sucess-popup/sucess-popup.component';
import { FailureComponent } from '../failure/failure.component';
import * as moment from 'moment';
import { cloneDeep, map } from 'lodash';
import { SharedService } from 'src/app/common/services/shared.service';
import { environment } from 'src/environments/environment';
import { error } from 'console';
import * as _moment from 'moment';
import { SubscriptionRenewPopupComponent } from '../../shared/subscription-renew-popup/subscription-renew-popup.component';
import { InvalidComponent } from '../create-pouch/components/pouch-details/invalid/invalid.component';
import { RiskProfileComponent } from '../../shared/risk-profile/risk-profile.component';
import { BannerSubscriptionDetailsComponent } from '../../shared/banner-subscription-details/banner-subscription-details.component';
import { PlansService } from '../services/plans.service';
import { WarningKycRpPopupComponent } from 'src/app/warning-kyc-rp-popup/warning-kyc-rp-popup.component';
@Component({
  selector: 'app-map-subscriber',
  templateUrl: './map-subscriber.component.html',
  styleUrls: ['./map-subscriber.component.scss'],
})
export class MapSubscriberComponent implements OnInit {
  @ViewChild('performance') performance: any;
  @ViewChild('composition') composition: any;
  @ViewChild('bannerBtn') bannerBtn: any;
  @ViewChild('myForm', { static: true }) public ngForm: ElementRef =
    {} as ElementRef;
  agreementStatus: any = {};
  currentDate = new Date();
  subscriberList: any = [];
  calculateSubFee = false;
  paymentUrl: any = '';
  pouchId: any;
  refresh = true;
  userSubscribed = false;
  getState: Observable<any>;
  pouches: any;
  tabIndex: any = 0;
  uploadedDate: any;
  defaultBody: any;
  pouchDetail: any;
  cagr: any;
  isOwnerOrAdmin: boolean = false;
  showAmountOnSubscriber: boolean = false;
  logginUserRole: any;
  PouchSubscriberStocksReturns: any = [];
  min_investment: any = 0;
  subScriptionData: any = {
    amount: 100000,
    frequency: '1-month',
  };
  subscriptionsList: any;
  bannerDetails: any;
  bannerType = 0;
  subscriptionStatus: any;
  subscribtionBannerType: any;
  count = 0;
  byPassToPayment: any = {
    status: false,
    data: null,
  };

  constructor(
    public planService:PlansService,
    public subscriberService: SubsciberService,
    private route: ActivatedRoute,
    private store: Store<PouchState>,
    private dialog: MatDialog,
    private router: Router,
    public pouchService: PouchService,
    public publicService: PublicService,
    private utilsService: UtilsService,
    private notifyService: NotificationService,
    private sharedService: SharedService,
  ) {
    this.getState = this.store.select(selectPouchState);
    this.paymentUrl = environment.paymentGatewayUrl;
  }
  closeSucessDialog = false;
  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const routeParams = this.route.snapshot.params;
    this.componentLoaded=true
    if (queryParams.order_id) {
      this.transactionalRedirect(queryParams.order_id, routeParams.pouchId);
    } else {
      this.intialLoad(true);
    }
  }
  holdStocksLength:any
  // holdStockLength(event:any){
  //   this.holdStocksLength = event;
  // }
  billingDetails:any={}
  intialLoad(load: boolean) {
    this.refresh = false
    const storageUser: any = localStorage.getItem('user');
    const user = JSON.parse(storageUser);
    let logginUserEmail = user.attributes.email;
    this.logginUserRole = user.attributes.role;
    this.pouchId = this.route.snapshot.paramMap.get('pouchId');
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.defaultBody = {
      current_date: this.uploadedDate?.split('T')[0],
      subscriber_id: user.id,
    };

    this.getState.subscribe((state) => {
      let statePouches = state.pouches;
      if (statePouches) {
        this.pouches = [...statePouches];
        this.pouchDetail = this.pouches.find(
          (item: any) => item.id == this.pouchId
        );

        this.cagr = Number(this.pouchDetail?.cagr * 100).toFixed(2);
        this.isOwnerOrAdmin =
          this.pouchDetail?.created_by == logginUserEmail ||
          this.logginUserRole == 'USER';

        if (this.pouchDetail?.subscribed === 'Y') {
          this.userSubscribed = true;
          this.radioSelected = 0;
          this.disabled = false;
        } else {
          if (
            this.logginUserRole === 'admin' ||
            this.logginUserRole === 'ria' ||
            this.logginUserRole === 'partner'
          ) {
            //check for no of subscribers
            if (parseInt(this.pouchDetail?.subscriber_count) > 0) {
              this.disabled = false;
            } else {
              this.disabled = true;
            }
            this.userSubscribed = true;
            this.radioSelected = 1;
          } else {
            this.userSubscribed = false;
            this.radioSelected = 1;
            this.disabled = true;
          }
        }

        if (this.publicService.fromCreatingPouch) {
          this.tabIndex = 1;
          this.publicService.fromCreatingPouch = false;
        }
        this.getBanner();
      }
      if (this.pouchDetail && load) {
      }
    });
    if (!this.pouches) {
      this.store.dispatch(new Load(this.defaultBody));
    }
    this.refresh = true
  }

  mapSubscriberpopup() {
    let dialogRef = this.dialog.open(MapSubscriberPopupComponent, {
      data: {
        pouch_id: this.pouchId,
        min_investment: this.min_investment,
        pouch: this.pouchDetail,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.sharedService.onSubscriberAdd.next({ result: true });
        this.intialLoad(true);
        this.store.dispatch(new Load(this.defaultBody));
      }
    });
  }

  editPouch() {
    this.router.navigateByUrl(`investment/edit-pouch/${this.pouchId}`);
  }
  getminInvestmentAmount() {
    let data: any = {
      pouch_id: this.pouchId,
      stock_price_date: localStorage
        .getItem('stock_price_uploaded_date')
        ?.split('T')[0],
    };
    this.pouchService.getPouchStocksReturns(data).subscribe(
      (response: any) => {
        let returns: any =
          response[0].Results[0]?.return_data?.pouch_stocks_returns;
        let sumArr: number[] = [];
        if (returns) {
          for (let data of returns) {
            if (!data.sell_date) {
              sumArr.push(data.close_price * data.stock_tf_count);
            }
          }
          let sum = 0;
          for (let i = 0; i < sumArr.length; i++) {
            sum += sumArr[i];
          }
          this.min_investment = Number(sum.toFixed(2));
        }
        this.getSubscriptionPlanList();

        // this.getOptionsText(this.subscriptionsList[0]?.subscription_freq);
        // this.setBanner();
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );
  }
  getSubscriptionPlanList() {
    this.subscriptionsList = this.pouchDetail.public___pouch_subscriptions;
    if (this.subscriptionsList) {
      this.getOptionsText(this.subscriptionsList[0]?.subscription_freq);
      this.setBanner();
    }
  }
  getOptionsText(type: string) {
    if (type === 'MONTHLY') {
      this.subScriptionData = {
        amount: this.getamount(type),
        frequency: '1 month',
      };
    } else if (type === 'QUARTERLY') {
      this.subScriptionData = {
        amount: this.getamount(type),
        frequency: '3 month',
      };
    } else if (type === 'HALF-YEARLY') {
      this.subScriptionData = {
        amount: this.getamount(type),
        frequency: '6 month',
      };
    } else {
      this.subScriptionData = {
        amount: '',
        frequency: '',
      };
    }
  }
  getamount(type: any) {
    let getsubscriptiondata = this.subscriptionsList?.find(
      (ele: any) => ele.subscription_freq === type
    );
    if (getsubscriptiondata?.subscription_type === 'MAX') {
      let value = Math.max(
        (this.min_investment * getsubscriptiondata?.subscription_perc) /
        (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)),
        getsubscriptiondata?.subscription_amount
      );
      return value.toFixed(2) || 0;
    }
    if (getsubscriptiondata?.subscription_type === 'MIN') {
      let value = Math.min(
        (this.min_investment * getsubscriptiondata?.subscription_perc) /
        (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)),
        getsubscriptiondata?.subscription_amount
      );

      return value.toFixed(2) || 0;
    }
    if (getsubscriptiondata?.subscription_type === 'BOTH') {
      let value =
        (this.min_investment * getsubscriptiondata?.subscription_perc) /
        (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)) +
        getsubscriptiondata?.subscription_amount;
      return value.toFixed(2) || 0;
    }
    return this.min_investment;
  }
  setBanner() { }

  tabChanged(e: any) {
    this.tabIndex = e.index;
    console.log(e.index);
    this.getCagr();
  }
  changeBanner(e: any) {
    this.bannerType = e;
  }

  changeTrigger(e: any) { }

  radioSelected: any;
  since: any = this.formatDate(new Date());
  valueChange(e: any) {
    if (this.tabIndex === 0) {
      this.performance.valueChange(e);
    }
    if (this.tabIndex === 1) {
      this.composition.valueChange(e);
    }
    if (!this.pouchService.selectedSubscriber) {
      this.pouchService.selectedSubscriber =
        this.subscriberList[0]?.subscriber_id;
    }

    if (e.value === 1) {
      this.showAmountOnSubscriber = false;
    } else if (e.value === 0) {
      this.showAmountOnSubscriber = true;
    }
  }
  disabled: any = true;
  sinceDateChangeHandler(e: any) {
    if (this.tabIndex === 0) {
      this.performance.sinceDateChangeHandler(e);
    }
    if (this.tabIndex === 1) {
      this.composition.sinceDateChangeHandler(e);
    }
    this.getCagr();
  }
  updateTable(e: any) {
    this.tabIndex = 1;
    this.composition.getSubscriberReturns(e?.dialog);
  }

  checkSubscriptionEligibility(userRP: string, pouchRP: string) {
    // Define risk profile levels
    const riskProfiles = ["low", "moderate", "high"];

    // Check if the provided risk profiles are valid
    if (!riskProfiles.includes(userRP.toLowerCase()) || !riskProfiles.includes(pouchRP.toLowerCase())) {
      return 'invalid';
    }

    // Assign numerical values to risk profiles for comparison
    const userRPValue = userRP.toLowerCase();
    const pouchRPValue = pouchRP.toLowerCase();

    // Check eligibility based on risk profiles
    if (userRPValue == pouchRPValue) {
      return 'same';
    } else {
      return "notSame";
    }
  }
  checkSubscriptionActive() {
    let user = JSON.parse(localStorage.getItem('user') || '{}').attributes;
    if (user.kyc_date !== '' && user.risk_profile !== 'PENDING') {
      return true;
    } else {
      return false;
    }
  }
  goSubscribe() {

    if(this.checkSubscriptionActive()){
    //check for user risk
    //  this.dialog.open(RiskProfileComponent)
    let user: any = localStorage.getItem('user')
    let parsedUser: any = JSON.parse(user || '{}')

    let rp = this.checkSubscriptionEligibility(parsedUser.attributes.risk_profile, this.pouchDetail.risk_profile)
    if (!this.bannerBtn.showRpWarning && rp == 'notSame') {
      const dialog = this.dialog.open(RiskProfileComponent, { data: [parsedUser.attributes.risk_profile, this.pouchDetail.risk_profile] })
      dialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.bannerBtn.showRpWarning = true
          this.router.navigateByUrl(`/subscription-plan/${this.pouchDetail.id}`);

        } else {
          // this.router.navigateByUrl('/investment')
        }
      })

    } else {
      this.router.navigateByUrl(`/subscription-plan/${this.pouchDetail.id}`);




    }
    }else{
      const dialog: any = this.dialog.open(WarningKycRpPopupComponent, {
        data: {
          message: 'Error in upload KYC.. Please upload',
          title: 'Error',
          status: 'Fail',
        },
        height: '18rem',
        width: '50rem',
      });
    }

  
  }
  deletePouch(pouch: any) {
    const dialogRef: any = this.utilsService.openDialog(
      DeletePopupComponent,
      { message: TEXTS.DELETE_TEXT },
      500
    );
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.type === 'yes') {
        this.pouchService
          .deletePouch({
            pouch_id: [pouch.id],
          })
          .subscribe((res: any) => {
            const dialog: any = this.dialog.open(CommonDialogComponent, {
              data: {
                message: 'Pouch deleted successfully.',
                title: 'Success',
                status: 'Success',
              },
            });
            setTimeout(() => {
              dialog.close();
              this.router.navigateByUrl('/investment');
            }, 1000);
          });
      }
    });
  }
  showDelete(pouch: any) {
    //check user is admin , and check user id is same as created id , subscriber 0
    let userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    if (pouch.total_subscribers > 0) {
      return false;
    }
    if (
      pouch?.total_subscribers == 0 &&
      userInfo.id === pouch?.created_by &&
      userInfo.attributes.role === 'admin'
    ) {
      return true;
    }
    if (userInfo?.attributes?.superadmin == 'Y') {
      return true;
    }
    if (userInfo?.attributes?.referrer_id === pouch.created_by) {
      return true;
    }
    return false;
  }

  loaded = true;
  reload = true;
  afterMapSubscriber(result: any) {
    //need to open  success popup

    this.mapSubscriberTopouch(result);
    result.dialog.afterClosed().subscribe((res: any) => {
      this.intialLoad(true);
      this.store.dispatch(new Load(this.defaultBody));
      this.refreshBanner();
      // if (res) {
      // }
      // if (!res) {
      //   this.router.navigateByUrl(`/investment`);
      // }
    });
  }

  mapSubscriberTopouch(result: any) {
    const storageUser: any = localStorage.getItem('user');
    const user = JSON.parse(storageUser);
    if (user.attributes.role === 'subscriber') {
      this.publicService.mapSubscriberData.returnToComposition = true;

      return true;
    } else {
      return false;
    }
  }

  getCagr() {
    if (this.radioSelected == 0) {
    }
    if (this.radioSelected == 1) {
      // this.pouchService
      //   .getPouchList({
      //     sort: -13,
      //     current_date: moment(new Date(this.since)).format('YYYY-MM-DD'),
      //     pouch_id: this.pouchId,
      //   })
      //   .subscribe(
      //     (res: any) => {
      //       this.cagr = (res[0].pouches[0].cagr * 100).toFixed(2);
      //     },
      //     (e) => {
      //       this.notifyService.showError('', e.error.error.message);
      //     }
      //   );
    }
  }
  subscriberChangeEvent(e: any) {
    this.cagr = Number(e * 100).toFixed(2);
  }
  showHold:any;
  holdStockLength(e:any){
    this.showHold = e
  }
  downloadCSV(type: any) {
    switch (type) {
      case 'rebalance':
        this.pouchService
          .downloadRebalance({ pouch_id: this.pouchId }, this.pouchDetail.tf_wt)
          .subscribe(
            (res) => this.saveFile(res, 'rebalance_details.csv'),
            (error: any) => {
              this.notifyService.showError(
                'Fail',
                'Something went wrong,Try again.'
              );
              setTimeout(() => {
                this.sharedService.hideLoader();
              }, 5000);
            }
          );
        break;
      case 'return':
        this.pouchService
          .downloadReturnDetails({ pouch_id: this.pouchId })
          .subscribe(
            (res) => this.saveFile(res, 'return_details.csv'),
            (error: any) =>
              this.notifyService.showError(
                'Fail',
                'Something went wrong,Try again.'
              )
          );
        break;
      case 'summary':
        this.pouchService
          .downloadReturnSummary({ pouch_id: this.pouchId })
          .subscribe(
            (res) => this.saveFile(res, 'report_summary.csv'),
            (error: any) =>
              this.notifyService.showError(
                'Fail',
                'Something went wrong,Try again.'
              )
          );
        break;
    }
  }
  async saveFile(res: any, name: any) {
    let link = document.createElement('a');
    link.href = `data:text/csv;charset=utf-8,${res}`;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // this.notifyService.showSuccess('Success',`${name} download successfully`)
  }
  paymentData: any = {
    encRequest: '',
    access_code: '',
  };
  redirectToPayment(data: any, res: any) {
    if (environment.bypassEsign) {
      this.paymentData = data;
      console.log(this.paymentData, '<[[ payment data');
      console.log(this.publicService.mapSubscriberData);
      const interval = setTimeout(() => {
        if (this.paymentData.access_code && this.paymentData.encRequest) {
          this.publicService.mapSubscriberData.returnToComposition = true;

          let payload: any = {
            subscriberObj: [
              {
                pouch_id: res.agreement_status[0].pouch_id,
                subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')
                  .id,
                subscription_type:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_freq,
                start_date:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .registration_date,
                end_date: this.getEndDate(
                  res.agreement_status[0].public___subscriber_investments[0]
                    .registration_date,
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_freq
                ),
                stoploss_stock:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .stoploss,
                stoploss_portfolio:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .stoploss,
                investment_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .investment_amount,
                is_active: 'Y',
              },
            ],
            docsi: [
              {
                pouch_id: res.agreement_status[0].pouch_id,
                subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')
                  .id,
                investment_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .investment_amount,
                change_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .investment_amount,
                from_date: localStorage.getItem('stock_price_uploaded_date'),
              },
            ],
            pouch_id: res.agreement_status[0].pouch_id,
            subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
            review_date:
              res.agreement_status[0].public___subscriber_investments[0]
                .registration_date,
            investment_amount:
              res.agreement_status[0].public___subscriber_investments[0]
                .investment_amount,
            minimum_investment: this.pouchDetail.min_amt,
            subscription: [
              {
                pouch_id: res.agreement_status[0].pouch_id,
                subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')
                  .id,
                subscription_type:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_type,
                subscription_perc:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_perc,
                subscription_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_amount,
                subscription_amount_final:Number(
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_amount_final),
                discount_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .discount_amount,
                promo_code:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .promo_code,
                subscription_freq:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_freq,
                start_date:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .registration_date,
                end_date: this.getEndDate(
                  res.agreement_status[0].public___subscriber_investments[0]
                    .registration_date,
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_freq
                ),
                order_id: '',
                bank_ref_no: '',
                investment_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .investment_amount,
                    ...this.billingDetails
              },
            ],
          };
          console.log(payload, '<=====');
          this.publicService.mapSubscriberData.data = payload;
          this.publicService.mapSubscriberData.subscription =
            payload.subscription[0];

          sessionStorage.setItem(
            'pouch',
            JSON.stringify(this.publicService.mapSubscriberData)
          );
          this.ngForm?.nativeElement.submit();

          clearInterval(interval);
        }
      }, 2000);
    } else {
      this.paymentData = data;
   console.log()

      const interval = setTimeout(() => {
        if (this.paymentData.access_code && this.paymentData.encRequest) {
          this.publicService.mapSubscriberData.returnToComposition = true;
          let payload: any = {
            subscriberObj: [
              {
                pouch_id: res.agreement_status[0].pouch_id,
                subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')
                  .id,
                subscription_type:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_freq,
                start_date:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .registration_date,
                end_date: this.getEndDate(
                  res.agreement_status[0].public___subscriber_investments[0]
                    .registration_date,
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_freq
                ),
                stoploss_stock:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .stoploss,
                stoploss_portfolio:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .stoploss,
                investment_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .investment_amount,
                is_active: 'Y',
              },
            ],
            docsi: [
              {
                pouch_id: res.agreement_status[0].pouch_id,
                subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')
                  .id,
                investment_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .investment_amount,
                change_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .investment_amount,
                from_date: localStorage.getItem('stock_price_uploaded_date'),
              },
            ],
            pouch_id: res.agreement_status[0].pouch_id,
            subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
            review_date:
              res.agreement_status[0].public___subscriber_investments[0]
                .registration_date,
            investment_amount:
              res.agreement_status[0].public___subscriber_investments[0]
                .investment_amount,
            minimum_investment: this.pouchDetail.min_amt,
            subscription: [
              {
                pouch_id: res.agreement_status[0].pouch_id,
                subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')
                  .id,
                subscription_type:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_type,
                subscription_perc:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_perc,
                subscription_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_amount,
                subscription_amount_final:Number(
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_amount_final),
                discount_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .discount_amount,
                promo_code:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .promo_code,
                subscription_freq:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_freq,
                start_date:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .registration_date,
                end_date: this.getEndDate(
                  res.agreement_status[0].public___subscriber_investments[0]
                    .registration_date,
                  res.agreement_status[0].public___subscriber_investments[0]
                    .subscription_freq
                ),
                order_id: '',
                bank_ref_no: '',
                investment_amount:
                  res.agreement_status[0].public___subscriber_investments[0]
                    .investment_amount,
                    ...this.billingDetails
              },
            ],
          };
          console.log(payload, '<=====');
          this.publicService.mapSubscriberData.data = payload;
          this.publicService.mapSubscriberData.subscription =
            payload.subscription[0];

          sessionStorage.setItem(
            'pouch',
            JSON.stringify(this.publicService.mapSubscriberData)
          );
          console.log(this.ngForm);
          this.ngForm?.nativeElement.submit();

          clearInterval(interval);
        }
      }, 2000);
    }
  }

  getEndDate(startdate: any, period: any) {
    if (period === 'MONTHLY') {
      var dt = new Date(startdate);

      return this.formatDate(dt.setMonth(dt.getMonth() + 1));
    }
    if (period === 'YEARLY') {
      var dt = new Date(startdate);
      return this.formatDate(dt.setMonth(dt.getMonth() + 12));
    }

    if (period === 'HALF-YEARLY') {
      var dt = new Date(startdate);
      return this.formatDate(dt.setMonth(dt.getMonth() + 6));
    }
    if (period === 'QUARTERLY') {
      var dt = new Date(startdate);
      return this.formatDate(dt.setMonth(dt.getMonth() + 3));
    }
    return '';
  }

  formatDate(date: any) {
    const d = new Date(date);
    // This will return an ISO string matching your local time.
    return new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes() - d.getTimezoneOffset()
    )
      .toISOString()
      .split('T')[0];
  }
  goSubscribe1() {
    delete this.billingDetails.subscriber_id
    delete this.billingDetails.rowIndex
    sessionStorage.setItem('billings', JSON.stringify( this.billingDetails))
    this.route.params.subscribe((params: any) => {
      this.pouchId = params.pouchId;
    });
    this.checkSubscriptionStatus();

    if (this.byPassToPayment.status) {
      this.publicService
        .intiatePaymentGateway({
          investment_amount: `${this.byPassToPayment.data[0].agreement_status[0].public___subscriber_investments[0].investment_amount}`,
          pouch_id: this.byPassToPayment.data[0].agreement_status[0].pouch_id,
          frequency:
            this.byPassToPayment.data[0].agreement_status[0]
              .public___subscriber_investments[0].subscription_freq,
          subscription_amount: `${this.byPassToPayment.data[0].agreement_status[0].public___subscriber_investments[0].subscription_amount_final}`,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')?.id,
          redirect_url: `${window.location.host}/investment/map-subscriber/${this.byPassToPayment.data[0].agreement_status[0].pouch_id}`,
        })
        .subscribe(
          (res1: any) => {

             this.redirectToPayment(res1, this.byPassToPayment.data[0]);
          },
          (e) => {
            this.notifyService.showError('', e.error.error.message);
          }
        );
    } else if (environment.bypassEsign) {
      let details = JSON.parse(sessionStorage.getItem('pouch') || '{}');
      console.log(details);
      this.publicService
        .intiatePaymentGateway({
          investment_amount: `${details.data.investment_amount}`,
          pouch_id: details.data.pouch_id,
          frequency: details.subscription.subscription_freq,
          subscription_amount: `${details.subscription.subscription_amount_final}`,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')?.id,
          redirect_url: `${window.location.host}/investment/map-subscriber/${details.data.pouch_id}`,
        })
        .subscribe(
          (res1: any) => {
            this.redirectToPayment(res1, details.data);
          },
          (e) => {
            this.notifyService.showError('', e.error.error.message);
          }
        );
    }


  }
  bypass(e: any) {
    if (e) {
      this.goSubscribe1();
    }
  }

  transactionalRedirect(order_id: string, pid: any) {
    let pouch_Data = sessionStorage.getItem('pouch') || '{}';
    this.publicService.mapSubscriberData = JSON.parse(pouch_Data);
    // getpouch data
    this.pouchService
      .getPouchList({
        pouch_id: pid,
        current_date: localStorage
          .getItem('stock_price_uploaded_date')
          ?.split('T')[0],
      })
      .subscribe({
        next: (response: any) => {
          this.pouchDetail = response[0].pouches[0];
          this.tabIndex = 1;
          this.intialLoad(true);
        },
        complete: () => {
          //  check transaction
          this.publicService
            .checkTransactionStatus({ order_id: order_id })
            .subscribe({
              next: (response: any) => {
                let status = response[0].Results[0].order_status;
                switch (status) {
                  case 'Success': {
                    this.whenSuccess(response[0].Results[0], pid);
                    break;
                  }
                  case 'Failure': {
                    this.whenCanceled(response[0].Results[0], pid);
                    break;
                  }
                  case 'Aborted': {
                    this.whenAborted();
                    break;
                  }
                  default: {
                    this.invalid(response[0].Results[0].order_status, pid);
                  }
                }
              },
            });
        },
      });
  }
  whenCanceled(apiResponse: any, pid: any) {
    const { amount, bank_ref_no, order_id } = apiResponse;
    const dialogRef = this.dialog.open(FailureComponent, {
      data: bank_ref_no,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        // this.router.navigateByUrl(
        //   `/investment/map-subscriber/${this.publicService.mapSubscriberData.data.pouch_id}`
        // );
        this.publicService.mapSubscriberData.returnToComposition = true;
        this.intialLoad(true);
        location.href = `/investment/map-subscriber/${this.publicService.mapSubscriberData.data.pouch_id}`;
      }
      this.refreshBanner();
    });
  }
  whenSuccess(apiResponse: any, pid: any) {
    const { amount, bank_ref_no, order_id } = apiResponse;

    const { subscription, data } = this.publicService.mapSubscriberData;
    if (data.isRenew === 'updateInvestment') {
      this.changeInvestment(apiResponse, data);
    } else {
      // let payload = subscription;
      subscription.order_id = order_id;
      subscription.bank_ref_no = bank_ref_no;
      const body = { ...data, subscription: [subscription] };
      body.review_date = localStorage.getItem('stock_price_uploaded_date');
      if (data.isRenew) {
        delete body.isRenew;
        this.subscriberService.mapSubscriberToPouchRenew(body).subscribe({
          next: (response: any) => {
            const dialogRef = this.dialog.open(SucessPopupComponent, {
              data: {
                isRenew: true,
                order: order_id,
                amount: amount,
                no: bank_ref_no,
                id: pid,
              },
            });
            this.afterMapSubscriber({
              id: this.publicService.mapSubscriberData.data.pouch_id,
              to: 'investment',
              dialog: dialogRef,
            });
          },
          error: (error: any) => {
            this.notifyService.showError('Error: Error Mapping Subscriber', '');
          },
        });
      } else {
        console.log(body,'1111111')
        this.subscriberService.mapSubscriberToPouch(body).subscribe({
          next: (response: any) => {
            // if (
            //   response[0].pouch_subscribers.err === '-' &&
            //   response[1].pouch_subscriber_investments.err === '-' &&
            //   response[2].pouch_subscriber_trades.err === '-'
            // ) {
              const dialogRef = this.dialog.open(SucessPopupComponent, {
                data: {
                  isRenew: false,
                  order: order_id,
                  amount: amount,
                  no: bank_ref_no,
                  id: pid,
                },
              });
              this.afterMapSubscriber({
                id: this.publicService.mapSubscriberData.data.pouch_id,
                to: 'investment',
                dialog: dialogRef,
              });
            // }
          },
          error: (error: any) => {
            this.notifyService.showError('Error: Error Mapping Subscriber', '');
          },
        });
      }
    }
  }
  whenAborted() {
    const dialogRef = this.dialog.open(FailureComponent, {
      data: 'aborted',
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res == 'reIntiate') {
        this.goSubscribe1();
      }
      this.refreshBanner();
    });
  }
  invalid(msg: any, pid: any) {
    const dialogRef = this.dialog.open(InvalidComponent, {
      data: msg,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      // if (res == 'reIntiate') {
      //   this.goSubscribe1();
      // }
      this.refreshBanner();
    });
  }
  investmentChange: boolean;
  componentLoaded=false
  checkSubscriptionStatus() {
    if(!this.componentLoaded){
      return
    }
    this.pouchService
      .get_pouch_subscriber_status({
        pouch_id: this.pouchId,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      })
      .subscribe({
        next: (res: any) => {
          try {
            if (res && res.length > 1 && res?.[0]?.agreement_status) {
              this.agreementStatus = res[0]?.agreement_status;
            }
            if (res && res.length > 1 && res[1]?.subscription_status) {
              this.subscriptionStatus = res[1].subscription_status;

              let data = this.subscriptionStatus[0].public___pouch_subscriber_subscriptions[0];
              let pouchData = {
                subscription_freq: data.subscription_freq,
                start_date: data.start_date,
                end_date: data.end_date,
                subscription_amount_final: Number(data.subscription_amount_final),
                subscription_type: data.subscription_type,
                subscription_perc: data.subscription_perc,
                subscription_amount: data.subscription_amount,
              }
              localStorage.setItem('subscriptionPouchdetails', JSON.stringify(pouchData));
              this.checkSubscriptionValidity();
            }
            console.log(this.pouchDetail);
            if (
              res[0].agreement_status[0].public___subscriber_investments[0]
                .agreement_id
            ) {
              this.byPassToPayment.status = true;
              this.byPassToPayment.data = res;
              console.log(this.pouchDetail);
              this.pouchDetail.public___pouch_subscriptions[0] =
                res[0].agreement_status[0].public___subscriber_investments[0];
              // this.pouchDetail.min_amt =
              //   res[0].agreement_status[0].public___subscriber_investments[0].investment_amount;

              let pouchData = cloneDeep(this.pouchDetail);
              this.pouchDetail = null;
              this.pouchDetail = pouchData;
              this.calculateSubFee = true;
              this.investmentChange = true;
            }
          } catch (e) {
            this.byPassToPayment.status = false;
            this.byPassToPayment.data = null;
            this.calculateSubFee = false;
            this.investmentChange = false;
          }
        },
        error: () => {
          this.byPassToPayment.status = false;
          this.byPassToPayment.data = null;
          this.calculateSubFee = false;
        },
      });
  }
  pouchExpired: boolean = false;
  pochExpiredDate: any;
  checkSubscriptionValidity() {
    const endDateMoment = _moment(this.subscriptionStatus?.[0]?.end_date).endOf(
      'day'
    );
    // Uncomment the below line if you want to hardcode the end date for testing
    // const endDateMoment = _moment('2023-08-17T00:00:00Z').endOf('day');
    this.pochExpiredDate = endDateMoment;
    const todayMoment = _moment();
    let daysLeft = endDateMoment.diff(todayMoment, 'days');
    if (daysLeft <= 0 && !endDateMoment.isSame(todayMoment, 'day')) {
      this.pouchExpired = true;
    } else {
      this.pouchExpired = false;
    }
  }
  renewSubscription() {
    let subscriberPayload: any = {
      stock_price_date: localStorage
        .getItem('stock_price_uploaded_date')
        ?.split('T')[0],
      pouch_id: this.pouchId,
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
    };
    this.subscriberService
      .getPouchSubscriberReturns(subscriberPayload)
      .subscribe((res) => {
        if (res) {
          const navigationExtras = {
            state: {
              Renew: 'false',
              pouchReturn: res,
            },
          };
          let data = {
            pouchId: this.pouchId,
            pouchReturn: res,
            invAmnt: this.subscriptionStatus[0]?.investment_amount,
          };

          let popup = this.dialog.open(SubscriptionRenewPopupComponent, {
            data,
          });
        }
      });
  }
  getSubList(data:any,event:any) {
    console.log(event)
    if (this.logginUserRole !== 'subscriber' && this.router.url !== '/investment') {
      let body:any;
      if(this.logginUserRole === 'admin' || this.logginUserRole === 'partner'){
        body = data
      }else{
        body ={
          pouch_id: this.pouchId
        }
      }
      this.subscriberService
        .get_pouch_subscribers(body)
        .subscribe({
          next: (res: any) => {
            this.subscriberList = res[0]?.subscribers.filter((e:any)=> {
              let  d = ''
              // e.active_flag === event 
              if(event=== 'Y'){
                d = "ACTIVE"
              }else{
                d = "INACTIVE"
              } 
              return e.active_flag === d
            });
          },
        });
    }
  }
  getBanner() {
    let  userInfo = JSON.parse(localStorage.getItem('user') || '{}');

    this.planService.getBillingDetails({subscriber_id
      :
     userInfo.id}).subscribe((res:any)=>{
    this.billingDetails=res[0]?.sub_bill_addrs?.[0]||{}


     })
    if (this.publicService?.mapSubscriberData?.returnToComposition) {
      this.tabIndex = 1;
    }

    if (this.logginUserRole === 'subscriber') {
      if (this.pouchDetail?.subscribed === 'Y') {
        let data = {
          pouch_id: Number(this.pouchDetail.id),
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
          stock_price_date: localStorage
            .getItem('stock_price_uploaded_date')
            ?.split('T')[0],
        };
        this.pouchService
          .getPouchSubscriberStocksReturns(data)
          .subscribe((res: any) => {
            this.getHoldStock(res)










            if (res[1]?.pouch_subscriber_trades?.length > 0) {
              this.subscribtionBannerType = 1;
            } else {
              this.subscribtionBannerType = 2;
            }
            this.PouchSubscriberStocksReturns = res[1]?.pouch_subscriber_trades;
          });
      }
      this.checkSubscriptionStatus();
    } else {
      let data = {
        pouch_id: this.pouchId,
        // "active_flag":"Y"
    }
      this.getSubList(data,'Y');
    }
  }
  refreshBanner() {
    this.refresh = false;
    this.refresh = true;
  }
  changeInvestment(res: any, data: any) {
    const body = { ...data };
    const { amount, bank_ref_no, order_id } = res;
    body.date = _moment(
      localStorage.getItem('stock_price_uploaded_date')
    ).format('YYYY-MM-DD');
    const pouchData = localStorage.getItem('subscriptionPouchdetails');
    const billing=  sessionStorage.getItem('billings')
    if (pouchData !== null) {
      const pouchValue = JSON.parse(pouchData);
      const billingValue =JSON.parse(billing||'{}');
      let payload = {
        docs: {
          investment_amount: body.investment_amount,
        },
        docsi: [
          {
            pouch_id: body.pouch_id,
            subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
            investment_amount: body.investment_amount,
            change_amount: body.change_amount,
            from_date: body.date,
          },
        ],
        pouch_id: body.pouch_id,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
        change_date: body.date,
        review_date: body.date,
        subscription: [
          {
            investment_amount: body.investment_amount,
            pouch_id: body.pouch_id,
            subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
            subscription_type: pouchValue.subscription_type,
            subscription_perc: pouchValue.subscription_perc,
            subscription_amount: Number(amount),
            subscription_amount_final: Number(amount),
            discount_amount: 0,
            promo_code: null,
            subscription_freq: pouchValue.subscription_freq,
            start_date: _moment(pouchValue.start_date).format('YYYY-MM-DD'),
            end_date: _moment(pouchValue.end_date).format('YYYY-MM-DD'),
            order_id: order_id,
            bank_ref_no: bank_ref_no,
            ...billingValue
          },
        ],
      };
      this.subscriberService.changeInvestment(payload).subscribe((res: any) => {
        if (res) {
          let popup = this.dialog.open(SucessPopupComponent, {
            data: {
              isRenew: 'updateAmount',
              order: order_id,
              amount: amount,
              no: bank_ref_no,
              id: data.pouch_id,
            },
          });
          popup.afterClosed().subscribe((res) => {
            this.intialLoad(true);
            this.composition.refreshPendingTable()
            this.refreshBanner();
          });
        }
      });
    }





  }
  gotoPouchVisibility() {
    this.router.navigateByUrl(`/investment/pouch-visibility/${this.pouchId}`)
  }


  getHoldStock(res:any){
    let resArr = res[0].pouch_subscriber_stocks_returns;
    const newHoldArray = resArr?.sort((prev: any, next: any) => {
      return (
        new Date(prev.buy_date).getTime() - new Date(next.buy_date).getTime()
      );
    });
    let holdStock=[]
    newHoldArray?.map((element: any) => {
      if (element['stock_qty'] > 0) {
        holdStock.push(element)
      }
    });
    this.holdStocksLength = holdStock.length;
  }

  radioChangeSubscriber(event:any){
    let data = {
      pouch_id: this.pouchId,
      // "active_flag":event
  }
    this.getSubList(data,event)
  }

  filterPouches(pouchArr:any){
    pouchArr = pouchArr.filter((pouch: any) => !pouch.stop_sub || (pouch.subscribed === 'Y' && pouch.stop_sub));
    return pouchArr
  }
  ngOnDestroy(): void {
    this.componentLoaded=false

  }
}
