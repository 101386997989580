import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { MapSubscriberPopupComponent } from 'src/app/modules/investment-pouches/map-subscriber-popup/map-subscriber-popup.component';
import { ChangeInvestmentPopupComponent } from 'src/app/modules/investment-pouches/change-investment-popup/change-investment-popup.component';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from 'src/app/common/services/shared.service';
import { NotificationService } from 'src/app/services/notification.services';

export interface Element {
  name: string;
  joined: string;
  subscribed: string;
  invest: string;
  return: string;
  retper: string;
}

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss'],
})
export class SubscriberComponent {
  displayedColumns: string[] = [
    'name',
    'joined',
    'subscribed',
    'invest',
    'return',
    'retper',
  ];
  dataSource: any = [];
  uploadedDate: any;
  unfilteredDataSource: any[] = [];
  subscriberCount: any;
  totalInvestment: any;
  sum = 10;
  throttle = 100;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  defaultPayload: any;
  @Input() pouchDetail: any;

  constructor(
    private subscriberService: SubsciberService,
    private sharedService: SharedService,
    private notifyService: NotificationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.sharedService.onSubscriberAdd.subscribe((res : any) => {
      if(res.result == true){
        this.fetchData();
      }
    })
    if (this.pouchDetail) {
      this.fetchData();
    }
  }

  fetchData() {
    this.defaultPayload = {
      limit: 10,
      stock_price_date: this.uploadedDate.split('T')[0],
      pouch_id: this.pouchDetail.id,
    };
    this.fetchPouchSubscribers(this.defaultPayload);

    // this.subscriberService
    //   .getPouchSubscribersTotalReturns({ pouch_id: this.pouchDetail.id })
    //   .subscribe(
    //     (res: any) => {
    //       if (res[0].total_subscribers) {
    //         // this.subscriberCount =
    //         //   res[0]?.total_subscribers[0].subscriber_count;
    //         // this.totalInvestment =
    //         //   res[0].total_subscribers[0]?.total_investment_amount.toLocaleString(
    //         //     'en-IN',
    //         //     {
    //         //       useGrouping: true,
    //         //     }
    //         //   );
    //         this.subscriberCount = res[0]?.total_subscribers.reduce(
    //           (a: any, c: any) => {
    //             return a + c.subscriber_count;
    //           },
    //           0
    //         );
    //         this.totalInvestment = res[0]?.total_subscribers
    //           .reduce((a: any, c: any) => {
    //             console.log(a, c.total_investment_amount);
    //             return a + c.total_investment_amount;
    //           }, 0)
    //           ?.toLocaleString('en-IN', {
    //             useGrouping: true,
    //           });
    //       }
    //     },
    //     (e) => {
    //       this.notifyService.showError('', e.error.error.message);
    //     }
    //   );
  }

  fetchPouchSubscribers(payload: any) {
    this.subscriberService.getPouchSubscriberReturns(payload).subscribe(
      (res: any) => {

        if (res[0] &&res[0].pouch_subscriber_returns) {
          this.subscriberCount =res[0].pouch_subscriber_returns.length
          this.unfilteredDataSource=  res[0]?.pouch_subscriber_returns.map((element: any) => {
            let value =element.public___pouch_subscribers[0]
            if (value['is_active'] == true) {

           return {
            id: value['subscriber_id'],
            name: `${value.public___subscribers[0]['subscriber_first_name']} ${value.public___subscribers[0]['subscriber_last_name']}`,
            joined: value['joined_since'].split('T')[0],
            subscribed: value['start_date'].split('T')[0],
            invest: value['investment_amount'],
            return:
            element['total_returns']?.toLocaleString('en-IN', {
                useGrouping: true,
              }) || 0,
            retper:(element['total_return_perc'] * 100).toFixed(2),
            end_date: value['end_date'],
            is_active: value['is_active'],
            pouch_id: value['pouch_id'],
            pouch_subscriber_id: value['pouch_subscriber_id'],
            stoploss_portfolio: value['stoploss_portfolio'],
            stoploss_stock: value['stoploss_stock'],
            subscription_type: value['subscription_type'],
          }
            }
            return null
          }).filter((e:any)=>e !==null);
        }

        this.dataSource = new MatTableDataSource(this.unfilteredDataSource);
        this.totalInvestment=0
        for(let i of this.unfilteredDataSource ){
          this.totalInvestment =this.totalInvestment+ i['invest']
        }
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );
  }

  unmapSubscriber(item: any) {
    let data = {
      pouch_id: item.pouch_id,
      subscriber_id: item.id,
    };
    this.subscriberService
      .unmapSubscriberToPouch(data)
      .subscribe((res: any) => {
        if (
          res[0].pouch_subscribers.err &&
          res[0].pouch_subscribers.err == '-' &&
          res[1].pouch_subscriber_stocks.err &&
          res[1].pouch_subscriber_stocks.err == '-'
        ) {
          const dialog: any = this.dialog.open(CommonDialogComponent, {
            data: {
              message: 'Subscriber unmapped successfully.',
              title: 'Success',
              status: 'Success',
            },
          });
          setTimeout(() => {
            dialog.close({ success: true });
          }, 5000);
          this.fetchPouchSubscribers(this.defaultPayload);
        } else {
          const dialog: any = this.dialog.open(CommonDialogComponent, {
            data: {
              message: 'Error on updating.',
              title: 'Error',
              status: 'Fail',
            },
          });
          setTimeout(() => {
            dialog.close({ success: false });
          }, 1000);
        }
      });
  }

  editSubscriber(item: any) {
    const dialogRef = this.dialog.open(MapSubscriberPopupComponent, {
      data: item,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.fetchData();
    });
  }

  changeInvestment(item: any) {
    const dialogRef = this.dialog.open(ChangeInvestmentPopupComponent, {
      data: item,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.fetchData();
    });
  }

  onScrollDown() {
    const start = this.sum;
    this.sum += 10;
    const body = {
      limit: this.sum,
      stock_price_date: this.uploadedDate.split('T')[0],
      pouch_id: this.pouchDetail.id,
    };
    this.fetchPouchSubscribers(body);
    this.direction = 'down';
  }

  onUp() {}
}
