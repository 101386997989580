import { Component, Input, OnInit } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  @Input() isLeft: boolean;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    interval: { timing: 1500, initialDelay: 1500 },
    loop: true,
    touch: true,
    velocity: 0.9,
  };
  testimonialList: any = [
    {
      title: 'Systematic',
      text: 'Smartvalues understands that investing in the stock markets requires not only extensive knowledge about the economy, global political and economic developments and specific company information. It also requires consistent methods and the discipline to execute them. Emotional and behavioural biases come in the way of rational decision making.  It therefore has developed a systematic approach that synthesizes all of this and assists in selection and execution of an appropriate portfolio.',
    },
    {
      title: 'Flexible',
      text: 'Smartvalues is a flexible tool which can be used to construct a plethora of portfolios customized to your needs across sectors and segments.',
    },
    {
      title: 'Customisable',
      text: 'Smartvalues enables the creation of portfolios customizable for size, duration and risk.',
    },
  ];
  testimonialList1: any = [
    {
      title: 'Convenient',
      text: 'Smartvalues is a convenient easy to use tool that assists in the creation of portfolios as per your specifications.',
    },
    {
      title: 'Rule Based',
      text: 'Smartvalues runs on a rules based algorithm that ensures that emotional biases don’t come in the way.',
    },
    {
      title: 'Trends',
      text: 'Smartvalues’s algorithms are designed to identify and invest in trends, which can be one of the most rewarding investment experiences.',
    },
  ];
  constructor() {}

  ngOnInit(): void {
    this.getTestimonial();
  }

  getTestimonial() {
    /* this._apiGraphQlService.getTestimonial().subscribe(
      (response) => {
        const resultData = response.data.testimonials;
        
        this.testimonialList = resultData;
      },
      (err) => {
        
      }
    );*/
  }
}
