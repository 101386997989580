import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.scss'],
})
export class FailureComponent implements OnInit {
  showAborted = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FailureComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    if (this.data === 'aborted' || this.data === 'aborteds') {
      this.showAborted = true;
    } else {
      this.showAborted = false;
    }
  }
  close(val: any) {
    this.dialogRef.close(val);
  }
  subscribe(val?: any) {
    this.dialogRef.close('reIntiate');
  }
}
