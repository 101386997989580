<div class="pouchWrapper">
  <div class="container-lg white-bg public-bg-wrapper mt-10 mb-60 pd-30">
    <ng-container *ngIf="index === 0">
      <div class="d-flex">
        <div *ngIf="questionSets.universe.q1">
          <img src="../../../../../assets/images/icons/question.svg" />
        </div>
        <div class="f-14 fw-400 mx-3">
          <span *ngIf="questionSets.universe.q1" class="qText p-3">Hello {{ getValueFromUser("name").first }}, its me
            Smarty.
            <span *ngIf="switchDateLeft">Let me help you discover few stocks</span>
            <span *ngIf="!switchDateLeft">Your Subscription is expiring <span *ngIf="totalDaysLeft>0"> in {{totalDaysLeft}} days.</span> <span *ngIf="totalDaysLeft==0"> today.</span></span>
          </span>
          <span *ngIf="questionSets.universe.q2" class="qText p-3">You have {{allowedCount-usedCount}} of
            {{allowedCount}} daily stock run attempts left for today.
          </span>
          <span *ngIf="questionSets.universe.q3" class="qText p-3">Lets start with <strong>list of
              stocks</strong></span>
          <span *ngIf="false" class="qText p-3">Select pre defined indices or create your own</span>
        </div>
      </div>

      <div *ngIf="questionSets.universe.a2" class="d-flex justify-end">
        <button mat-button *ngIf="!showRenewLater" (click)="showRenewLater = true" (click)="openRenew()"
          class="theme-btn primary-btn next_btn mt-10">
          Renew Now
        </button>
        <button *ngIf="!showRenewLater" (click)="showRenewLater = true" mat-button (click)="renewLater()"
          class="theme-btn primary-btn next_btn mt-10 ml-10" >
          Renew Later
        </button>
      </div>







      <div *ngIf="questionSets.universe.a1" class="d-flex justify-end">
        <div class="col-md-4 indices">
          <p class="f-14 text-grey mb-05 fw-500">List of Stocks</p>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select (selectionChange)="getPerformance()" [(ngModel)]="selectedUniverse" placeholder="--Select--">
              <mat-option *ngFor="let item of universeList" [value]="item.name">{{ item.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="graph mt-4">
            <app-chart *ngIf="chart" [pouchPerformance]="true" [chartData]="chart" [datesData]="dates"
              [pouchName]="'Pouch'" [indexName]="indexName" [minIndex]="minPercent" [maxIndex]="maxPercent"
              [duration]="selectedDuration"></app-chart>
          </div>
          <div class="f-16 f-600 mt-1" *ngIf="getdescription(selectedUniverse)">
            <p class="f-14 text-grey mb-05 fw-500">Description</p>
          </div>
          <div class="mt-1">{{ getdescription(selectedUniverse) }}</div>

          <button *ngIf="!showOptionForStocksnumber" [disabled]="!selectedUniverse "
            (click)="showOptionForStocksnumber = true " mat-button class="theme-btn primary-btn next_btn mt-10 spinnerBtn">
            <div >
            </div>
            Next
            <mat-icon class="mat-18">chevron_right</mat-icon>
            <!-- <ng-container *ngIf="selectedUniverse" >
              <mat-icon ><mat-spinner color="primary" diameter="20">
                </mat-spinner></mat-icon>
            </ng-container> -->
          </button>
        </div>
      </div>
      <div *ngIf="showOptionForStocksnumber">
        <div class="d-flex">
          <div>
            <img src="../../../../../assets/images/icons/question.svg" />
          </div>
          <div class="f-14 fw-400 mx-3">
            <span class="qText p-3">Please select no of stocks. </span>
          </div>
        </div>
        <div class="d-flex justify-end">
          <div class="col-md-4 indices">
            <p class="f-14 text-grey mb-05 fw-500">Select stock count</p>
            <mat-radio-group [(ngModel)]="noOfStocks" aria-label="Select an option">
              <mat-radio-button class="px-1" [value]="2">2</mat-radio-button>
              <mat-radio-button class="px-1" [value]="3">3</mat-radio-button>
              <mat-radio-button class="px-1" [value]="4">4</mat-radio-button>
              <mat-radio-button class="px-1" [value]="5">5</mat-radio-button>
            </mat-radio-group>
            <div>
              <button [disabled]="!selectedUniverse && disableshowOptionForStocksnumberBtn" (click)="generateTimeFrame()" mat-button
                class="theme-btn primary-btn next_btn mt-10 spinnerBtn">

                <ng-container *ngIf="selectedUniverse && !disableshowOptionForStocksnumberBtn" >
                  Next
                  <mat-icon class="mat-18">chevron_right</mat-icon>
                </ng-container>

                <ng-container *ngIf="!selectedUniverse && disableshowOptionForStocksnumberBtn" >
                  <mat-icon ><mat-spinner color="primary" diameter="20">
                    </mat-spinner></mat-icon>
                </ng-container>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="showOptionForStocksnumber" class="d-flex justify-end">
        <div class="col-md-4 indices">

         <div class="mt-2">
          <p class="f-14 text-grey mb-05 fw-500"> Select number of stocks </p>
<div class='m-2'>
  <mat-radio-group [(ngModel)]="noOfStocks" aria-label="Select an option">
    <mat-radio-button  class="px-1" [value]="2">2</mat-radio-button>
    <mat-radio-button class="px-1" [value]="3">3</mat-radio-button>
    <mat-radio-button  class="px-1" [value]="4">4</mat-radio-button>
    <mat-radio-button class="px-1" [value]="5">5</mat-radio-button>
  </mat-radio-group>
</div>

         </div>
          <button [disabled]="!selectedUniverse" (click)="generateTimeFrame()" mat-button
            class="theme-btn primary-btn next_btn mt-10">
            Next
            <mat-icon class="mat-18">chevron_right</mat-icon>
          </button>
        </div>
      </div> -->
    </ng-container>
    <ng-container *ngIf="index === 1">
      <div class="d-flex">
        <div>
          <img src="../../../../../assets/images/icons/question.svg" />
        </div>
        <div class="f-14 fw-400 mx-3">
          <span class="qText p-3">Here’s your smart stocks for today</span>
        </div>
      </div>
      <div class="pouch_tab w-80">
        <mat-tab-group (selectedTabChange)="TabIndex = $event.index" [selectedIndex]="TabIndex">
          <!-- <mat-tab>
            <ng-template mat-tab-label>
              <p>Performance</p>
            </ng-template>
            <div>
              <app-performance
                [since]="since"
                [public]="false"
                [pouchId]="newPouch.pouch_id"
                [parent]="'createPouch'"
                [duration]="'3y'"
                (changeIndex)="changeTab($event)"
                [startDate]="newPouch.start_date"
                [selectedBenchmarkIndex]="selectedBenchMarkIndex"
              >
              </app-performance>
            </div>
          </mat-tab> -->
          <mat-tab>
            <ng-template mat-tab-label>
              <p>Pouch Composition</p>
            </ng-template>
            <div style="position: relative; height: 25em">
              <table mat-table [dataSource]="tableDataSource">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Stock Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.stock_name }}
                  </td>
                </ng-container>

                <!-- quantity Column -->
                <ng-container matColumnDef="weightage">
                  <th mat-header-cell *matHeaderCellDef>Quantity</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.stock_tf_count }}
                  </td>
                </ng-container>
                <!-- stopLoss -->
                <ng-container matColumnDef="stopLoss">
                  <th mat-header-cell *matHeaderCellDef>Stop Loss (Rs.)</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.stop_loss | number:'1.0-0'}}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr [ngStyle]="{
                    filter:
                      i !== 0 && !checkUserSubscription() ? 'blur(4px)' : 'none'
                  }" mat-row *matRowDef="let row; columns: displayColumns; let i = index"></tr>
              </table>
              <!-- deleted table -->
              <div class="note mt-2" [ngStyle]="{
                  filter: !checkUserSubscription() ? 'blur(4px)' : 'blur(0px)'
                }">
                <strong>Note:</strong> Past pouch composition includes changes
                due to rebalance, events like stock splits & mergers.Also, past
                performance of pouch doesn't guarantee future returns.
              </div>

              <ng-container *ngIf="!checkUserSubscription()">
                <div class="popup">
                  <div style="margin-top: 15%">
                    <img src="../../../../../assets/images//icons/fi-rr-lock.svg" alt="" />
                  </div>
                  <p style="margin-top: 1.5rem; cursor: pointer">
                    <span class="f-blue" (click)="subscribe()">Subscribe</span>
                    to this feature to view the stocks
                  </p>
                </div>
              </ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div *ngIf="checkUserSubscription()" class="m_left mt-3">
        <!-- <button
          mat-button
          class="theme-btn primary-btn next_btn mt-10"
          (click)="proceed('restart')"
        >
          I Want to Change Indices
        </button> -->
        <button (click)="proceed('continue')" mat-button class="theme-btn primary-btn next_btn mt-10 mx-2">
          Proceed To Invest
        </button>
        <button (click)="gotoInvestmentPouches()" mat-button
          class="theme-btn btn_style primary-btn next_btn mt-10 mx-2">
          I ll Do It Later
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="index === 2">
      <div *ngIf="currentqnGroup !== 1" class="row text-end">
        <div class="col">
          <img src="../../../../../assets/images/icons/arrow_up.png" alt="" />
          Scroll up to go back
        </div>
      </div>
      <div (wheel)="scroll($event)" #scrollcontainer class="scroll_container">
        <section *ngIf="1 >= currentqnGroup" class="step_container">
          <!-- <div *ngIf="questionSets.pouch_info.q1" class="d-flex">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">Great, now lets save your pouch </span>
            </div>
          </div> -->
          <div *ngIf="questionSets.pouch_info.q1" class="d-flex">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">Minimum Investment for the stocks selected is
                <span class="fw-600">Rs.{{ this.editNewPouch.minimum_investment | number:'1.0-0'}}</span>
              </span>
            </div>
          </div>
          <div *ngIf="questionSets.pouch_info.q2" class="d-flex mt-2">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">Please enter your investment amount.</span>
            </div>
          </div>

          <!-- <div *ngIf="questionSets.pouch_info.q3" class="d-flex mt-2">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3"
                >What would you like to call your pouch</span
              >
            </div>
          </div> -->

          <div *ngIf="questionSets.pouch_info.a1" class="d-flex justify-end">
            <div class="col-md-6 indices">
              <div class="row">
                <!-- <div class="col-6">
                  <p class="f-14 text-grey mb-05 fw-500">Pouch Name</p>
                  <mat-form-field appearance="outline" class="w-100">
                    <input
                      matInput
                      type="text"
                      [(ngModel)]="editNewPouch.pouch_name"
                    />
                  </mat-form-field>
                  <mat-error *ngIf="editNewPouch.error.pouch_name">
                    Enter pouch name
                  </mat-error>
                </div> -->
                <div class="col-6">
                  <p class="f-14 text-grey mb-05 fw-500">Investment Amount</p>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="number" [(ngModel)]="editNewPouch.investment_amount" />
                  </mat-form-field>
                  <mat-error *ngIf="editNewPouch.error.investment_amount">
                    Investment amount cannot be lower then Min. Investment
                  </mat-error>
                </div>
              </div>

              <button (click)="proceedInvest()" mat-button class="theme-btn primary-btn next_btn mt-10" [disabled]="buttonDisable">
                <!-- (click)="next(2)" -->
                Proceed
                <mat-icon class="mat-18">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </section>

        <section *ngIf="2 === currentqnGroup">
          <div *ngIf="questionSets.pouch_configuration.q1" class="d-flex">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">Nice choice for the pouch name</span>
            </div>
          </div>
          <div *ngIf="questionSets.pouch_configuration.q2" class="d-flex">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">
                <span class="fw-600">No stop loss</span> is configured for this
                pouch</span>
            </div>
          </div>

          <div *ngIf="questionSets.pouch_configuration.q3" class="d-flex">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">You can change the defaults in advance configuration or just
                save and move on.</span>
            </div>
          </div>

          <div *ngIf="questionSets.pouch_configuration.a1" class="d-flex justify-end mt-3">
            <div class="col-md-6 indices text-right">
              <p class="f-14 text-grey mb-05 fw-500"></p>
              <button mat-button class="theme-btn primary-btn next_btn mt-10 mx-1" (click)="next('configure')">
                Yes, advance configuration
                <img height="15px" width="15px" src="../../../../../assets/images/icons/next_arrow.svg" />
              </button>
              <button (click)="next('save')" mat-button class="theme-btn primary-btn next_btn mt-10 mx-1">
                No, just save
                <img height="15px" width="15px" src="../../../../../assets/images/icons/next_arrow.svg" />
              </button>
            </div>
          </div>
        </section>

        <section *ngIf="3 === currentqnGroup">
          <div *ngIf="questionSets.pouch_description.q1" class="d-flex">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">Okay, describe your pouch a little bit</span>
            </div>
          </div>

          <div *ngIf="questionSets.pouch_description.a1" class="d-flex justify-end">
            <div class="col-md-4 indices">
              <p class="f-14 text-grey mb-05 fw-500">Pouch Description</p>
              <mat-form-field appearance="outline" class="w-100">
                <textarea style="height: 80px" matInput [(ngModel)]="editNewPouch.pouch_description"
                  placeholder=""></textarea>
              </mat-form-field>
              <button mat-button class="theme-btn primary-btn next_btn mt-10" (click)="next(4)">
                Next
                <mat-icon class="mat-18">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </section>
        <section *ngIf="4 === currentqnGroup">
          <div *ngIf="questionSets.pouch_icon.q1" class="d-flex">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">Make your pouch more appealing by adding some icon
              </span>
            </div>
          </div>

          <div *ngIf="questionSets.pouch_icon.a1" class="d-flex justify-end">
            <div class="col-md-5 indices">
              <p class="f-14 text-grey mb-05 fw-500">
                Select from below or <span style="color: #001aff">Upload</span>
              </p>
              <div style="height: 140px" class="border">
                <ul class="d-flex justify-content-between">
                  <li *ngFor="let ele of iconArr1">
                    <img [src]="
                        '../../../../../assets/images/uploadIcons/' +
                        ele +
                        '.png'
                      " alt="" />
                  </li>
                </ul>
                <ul class="d-flex justify-content-between">
                  <li *ngFor="let ele of iconArr2">
                    <img [src]="
                        '../../../../../assets/images/uploadIcons/' +
                        ele +
                        '.png'
                      " alt="" />
                  </li>
                </ul>
              </div>
              <button mat-button class="theme-btn primary-btn next_btn mt-10" (click)="next(5)">
                Next
                <mat-icon class="mat-18">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </section>

        <section *ngIf="5 === currentqnGroup">
          <div *ngIf="questionSets.pouch_stoploss.q1" class="d-flex">
            <div>
              <img src="../../../../../assets/images/icons/question.svg" />
            </div>
            <div class="f-14 fw-400 mx-3">
              <span class="qText p-3">Last question. At what loss percentage would you like to
                <br />stop trading and we should inform you to sell the stocks?
              </span>
            </div>
          </div>
          <div *ngIf="questionSets.pouch_stoploss.a1" class="d-flex justify-end">
            <div class="col-md-4 indices">
              <p class="f-14 text-grey mb-05 fw-500">Stop Loss (in %)</p>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="number" [(ngModel)]="editNewPouch.stop_loss" />
              </mat-form-field>
              <mat-error *ngIf="editNewPouch.error.stop_loss">
                Stop loss is required
              </mat-error>
              <button mat-button class="theme-btn primary-btn next_btn mt-10" (click)="next('advanced_save')">
                Save
                <mat-icon class="mat-18">chevron_right</mat-icon>
              </button>
            </div>
          </div>
        </section>
      </div>
    </ng-container>
    <ng-container *ngIf="index === 3">
      <div class="d-flex">
        <div>
          <img src="../../../../../assets/images/icons/question.svg" />
        </div>
        <div class="f-14 fw-400 mx-3">
          <span class="qText p-3">Great!. Your pouch has been saved. Take the next step <br />
            and invest the amount in the pouch.
          </span>
        </div>
      </div>
      <div class="d-flex justify-end">
        <div class="col-md-4 indices">
          <div class="m_left">
            <button (click)="goTo('invest')" mat-button class="theme-btn primary-btn next_btn mt-10">
              Proceed to Invest
            </button>
            <button (click)="goTo('later')" mat-button class="theme-btn primary-btn next_btn mt-10 mx-2">
              I il do it later
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
