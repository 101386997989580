import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import {
  PouchState,
  selectPouchState,
} from 'src/app/modules/investment-pouches/store/pouch.state';

import { PublicService } from '../../services/public.service';
import { PublicFacadeService } from '../../state/public.facade.services';

@Component({
  selector: 'app-pouchlist-details',
  templateUrl: './pouchlist-details.component.html',
  styleUrls: ['./pouchlist-details.component.scss'],
})
export class PouchlistDetailsComponent implements OnInit {
  pouches: any;
  pouchDetail: any;
  bannerDetails: any;
  radioSelected = 1;
  userSubscribed: boolean = false;
  min_investment: any = 100000;
  subScriptionData: any = {
    amount: 10000,
    frequency: '1-month',
  };
  @Input() userInfo: any;
  getState: Observable<any>;
  defaultBody: { sort: any; current_date: any };
  selectedSort: any;
  isLoggedin: boolean = false;
  isSubscribed: boolean = false;
  uploadedDate: any;
  pouchId: any;
  pouchData: any;
  annualizedReturns: any;
  selected: any;
  subscriptionsList: any;
  tabIndex: number = 0;
  since: any;
  constructor(
    public publicService: PublicService,
    private router: Router,
    private publicFacadeServices: PublicFacadeService,
    private route: ActivatedRoute,
    private pouchService: PouchService,
    private store: Store<PouchState>
  ) {
    this.pouchId = this.route.snapshot.paramMap.get('pouchId');
  }
  ngOnInit(): void {
    if (this.publicService.fromCreatingPouch === true) {
      (this.tabIndex = 1), (this.publicService.fromCreatingPouch = false);
    }
    this.getUserData();
    this.publicFacadeServices.getPouchListfromStore({
      current_date: this.uploadedDate,
      limit: 10,
      sort: -18,
    });
    this.since = new Date(this.uploadedDate);
    this.publicFacadeServices.LoadPouchListfromState$.subscribe((list) => {
      this.pouchDetail = list.find((ele: any) => ele.id == this.pouchId);
      if (this.pouchDetail) {
      }
    });
  }

  goSubscription() {
    this.router.navigateByUrl('/public/subscription-plan');
  }

  clickOnRegister() {
    this.router.navigate(['/register']);
  }
  getUserData() {
    let user: any = localStorage.getItem('user');
    if (user) {
      this.isLoggedin = true;
    }
  }

  clickOnLogin() {
    this.router.navigateByUrl('/auth/login');
  }
}
