import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { LoggerService } from 'src/app/common/services/logger.service';
import {
  Load,
  Reset,
  AddEdit,
  AddEditSuccess,
  AddEditFailure,
  LoadStocks,
  CreateUniverse,
  LoadSearchResult,
  UpdateStocks,
  AddSelectedStocks,
} from '../store/actions/universe.action';
import { selectUniverseState, UniverseState } from '../store/universe.state';
import { UtilsService } from '../../../common/services/utils.service';
import { DeletePopupComponent } from 'src/app/common/components/delete-popup/delete-popup.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UniverseService } from 'src/app/modules/investment-pouches/services/universe.service';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { WarningPopupComponent } from 'src/app/common/components/warning-popup/warning-popup.component';
import { TEXTS } from '../../../common/constants/text-constants';
export interface tableElement {
  position: string;
  name: string;
  marketCap: string;
  currentValue: string;
  peRatio: string;
}

@Component({
  selector: 'app-create-universe',
  templateUrl: './create-universe.component.html',
  styleUrls: ['./create-universe.component.scss'],
})
export class CreateUniverseComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'position',
    'name',
    'marketCap',
    'currentValue',
    'peRatio',
    'actions',
  ];
  //dataSource = ELEMENT_DATA;
  dataSource: tableElement[];
  getState: Observable<any>;
  universes: any[];
  sectors: any[];
  stocks: any[];
  selectedSearchStocks: any[];
  selectedUniverses: any = [];
  selectedSectors: any = [];
  form: FormGroup;
  prevPage: string | any;
  searchResults: any[];
  searchControl = new FormControl();
  uploadedDate: any;
  retailCheck: boolean = false;
  hiddenCheck: boolean = false;
  showErr = false;
  loggedInUserRole = '';
  constructor(
    private store: Store<UniverseState>,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private universeService: UniverseService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.getState = this.store.select(selectUniverseState);
    const storageUser: any = localStorage.getItem('user');
    const user = JSON.parse(storageUser);
    this.loggedInUserRole = user.attributes.role;
  }

  ngOnInit(): void {
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.getState.subscribe((state) => {
      this.universes = state && state.universes ? state.universes : [];
      this.sectors = state && state.sectors ? state.sectors : [];
      this.stocks = state && state.stocks ? state.stocks : [];
      this.selectedSearchStocks =
        state && state.selectedStocks ? state.selectedStocks : [];
      this.stocks =
        state && state.selectedStocks
          ? [...state.selectedStocks, ...this.stocks]
          : this.stocks;
      this.searchResults =
        state && state.searchResults ? state.searchResults : [];
      this.dataSource = this.utilsService.RemoveDuplicates(
        this.stocks,
        'stock_code'
      );
      LoggerService.log(' this.dataSource 11.  ', this.dataSource);
    });
    this.store.dispatch(new Load({}));

    this.form = new FormGroup({
      universeName: new FormControl(null, {
        validators: [Validators.required],
      }),
      description: new FormControl(null, { validators: [Validators.required] }),
      universes: new FormControl(null),
      sectors: new FormControl(null),
      search: new FormControl(null),
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.prevPage = params.get('previousPage');
    });
    /*     this.searchControl.valueChanges
    .pipe(
    startWith(''),
    map(value => typeof value === 'string' ? value : value.name),
    map(name => (name.length >= 3)? this.store.dispatch(new LoadSearchResult())  )
     );  */
  }

  public closeDropdown(event: any, type: string) {
    console.log('close dropdown');
    let objData: any;
    objData = {
      data: {
        stock_price_date: this.uploadedDate.split('T')[0],
        universe_name: this.selectedUniverses,
        sector: this.selectedSectors,
      },
    };

    if (this.selectedUniverses.length == 0) {
      delete objData.data.universe_name;
    }

    if (this.selectedSectors.length == 0) {
      delete objData.data.sector;
    }

    this.store.dispatch(new LoadStocks(objData));

    // switch(type){
    //   case 'universe':
    //     if(this.selectedUniverses.length==0){return}
    //     //  this.form.controls['sectors'].reset();
    //     //  this.form.controls['search'].reset();

    //     objData = {
    //       data:{
    //         stock_price_date: (this.uploadedDate.split('T'))[0],
    //         universe_name : this.selectedUniverses
    //       },
    //       // type:'universe'
    //     }
    //   this.store.dispatch(new LoadStocks(objData));
    //   break;
    //   case 'sector':
    //     if(this.selectedSectors.length==0){return}
    //     // this.form.controls['universes'].reset();
    //     // this.form.controls['search'].reset();
    //     objData = {
    //     data:{
    //         stock_price_date: (this.uploadedDate.split('T'))[0],
    //         sector : this.selectedSectors
    //     },
    //     // type:'sector'
    //   }
    //   this.store.dispatch(new LoadStocks(objData));
    //   break;
    // }
  }

  public selectionChange(event: any, type: string) {
    console.log('selectionChange');
    LoggerService.log('selectionChange', event);
    switch (type) {
      case 'universe':
        this.selectedUniverses = event.value;
        // this.form.controls['sectors'].reset();
        break;
      case 'sector':
        this.selectedSectors = event.value;
        // this.form.controls['universes'].reset();
        break;
    }
  }
  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  };

  retail(e: any) {
    if (e.checked) {
      this.retailCheck = true;
    } else {
      this.retailCheck = false;
    }
  }

  hiddenCheckEvent(e: any) {
    if (e.checked) {
      this.hiddenCheck = true;
    } else {
      this.hiddenCheck = false;
    }
  }

  public onSubmit() {
    if (this.form.valid) {
      const universeName: string = this.form.controls['universeName'].value;
      LoggerService.log('universeName ', universeName);
      const stocks: any = this.dataSource.map((stock: any) => {
        return {
          custom_universe_name: universeName,
          custom_universe_desc: this.form.controls['description'].value,
          stock_code: stock.stock_code,
          stock_name: stock.stock_name,
          stock_series: stock.stock_series,
          stock_sector: stock.stock_sector,
          stock_isin: stock.stock_isin,
          is_active: 'Y',
          show_retail: this.retailCheck,
          is_hidden: this.hiddenCheck,
        };
      });
      LoggerService.log('data ', stocks);
      // const data:any = {body:{docs:stocks},navigateTo:this.prevPage}
      const data: any = { docs: stocks };
      let userRole = JSON.parse(localStorage.getItem('user') || '{}').attributes
        .role;

      if (userRole === 'ria' || userRole === 'partner') {
        if (data.docs.length < 10) {
          this.showErr = true;
          return;
        } else {
          this.create(data);
        }
      } else {
        this.create(data);
      }
    }
  }
  create(data: any) {
    this.universeService.createUniverse(data).subscribe((res: any) => {
      if (res[0].insert_universe && res[0].insert_universe.err == '-') {
        const dialog: any = this.dialog.open(CommonDialogComponent, {
          data: {
            message: 'Universe saved successfully.',
            title: 'Success',
            status: 'Success',
          },
        });
        setTimeout(() => {
          dialog.close();
          if (this.prevPage == 'universe') {
            this.router.navigateByUrl('/more/universe');
          } else {
            this.router.navigateByUrl('/investment/create-pouch');
          }
        }, 1000);

        //this.store.dispatch(new Load({}));
      } else {
        const dialog: any = this.dialog.open(CommonDialogComponent, {
          data: {
            message: 'Error on saving.',
            title: 'Error',
            status: 'Fail',
          },
        });
        setTimeout(() => {
          dialog.close();
        }, 1000);
      }
    });
  }
  public deleteRow(event: any, row: any) {
    const Dialogue: any = this.utilsService.openDialog(
      DeletePopupComponent,
      { message: TEXTS.DELETE_TEXT },
      500
    );
    Dialogue.afterClosed().subscribe((result: any) => {
      if (result && result.type === 'yes') {
        this.dataSource = this.dataSource.filter(
          (item: any) => item.stock_code != row.stock_code
        );
        this.store.dispatch(new UpdateStocks(this.dataSource));
        this.store.dispatch(
          new AddSelectedStocks({
            result: this.selectedSearchStocks.filter(
              (item: any) => item.stock_code != row.stock_code
            ),
            isAppend: false,
          })
        );
        LoggerService.log(' this.dataSource => ', this.dataSource);
      }
    });
  }

  public searchHandler(event: any) {
    const isLength: boolean = event.target.value.toString().length >= 3;

    if (isLength) {
      const objData = {
        data: {
          stock_price_date: this.uploadedDate.split('T')[0],
          stock_code: event.target.value.toUpperCase(),
        },
        type: 'sector',
        appendResult: true,
      };
      this.store.dispatch(new LoadSearchResult(objData));
    }
  }
  public optionSelected(event: any) {
    LoggerService.log('optionSelected  == ', event);
    const isItemFound: any = this.dataSource.find(
      (item: any) => item.stock_code === event.option.value.stock_code
    );
    if (!isItemFound) {
      this.dataSource = [event.option.value, ...this.dataSource];
      this.store.dispatch(
        new AddSelectedStocks({ result: [event.option.value], isAppend: true })
      );
      //this.store.dispatch(new UpdateStocks(this.dataSource));
      this.cdr.detectChanges();
    } else {
      const dialog: any = this.utilsService.openDialog(
        WarningPopupComponent,
        { message: TEXTS.ALREADY_EXIST_TEXT },
        500
      );
      setTimeout(() => {
        dialog.close({ success: true });
      }, 5000);
    }
    this.form.controls['search'].reset();
    LoggerService.log(' this.dataSource. 22  ', this.dataSource);
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.stocks.filter((stock) =>
      stock.stock_name.toLowerCase().includes(filterValue)
    );
  }
  displayFn(stock: any): string {
    return stock && stock.stock_name ? stock.stock_name : '';
  }

  onCancelClick() {
    if (this.prevPage == 'universe') {
      this.router.navigateByUrl('/more/universe');
    } else {
      this.router.navigateByUrl('/investment/create-pouch');
    }
  }

  ngOnDestroy() {
    console.log('on destroy');
    this.store.dispatch(new Reset({}));
  }
}
