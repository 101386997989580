<div class="row">
    <div class="col-md-12 titleStyle">Contact Us</div>
    <mat-divider class="divider mt-3"></mat-divider>
    <div class="row">

        <div class="pr"></div>
        <form [formGroup]="contactUsForm" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-md-4 mt-3 ">
                    <div>
                        <p class="f-12 text-grey mb-05">Name*</p>
                    </div>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="name" />
                        <mat-error *ngIf="contactUsForm.get('name')?.invalid">
                            name is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 mt-3">
                    <div>
                        <p class="f-12 text-grey mb-05">Contact No*</p>
                    </div>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="contactNumber" (keypress)="isNumberKey($event)"/>
                        <mat-error>
                            <mat-error *ngIf="contactUsForm.get('contactNumber')?.hasError('required')">
                                contact Number is required
                            </mat-error>
                            <mat-error
                                *ngIf="contactUsForm.get('contactNumber')?.hasError('pattern') || contactUsForm.get('contactNumber')?.hasError('minlength') || contactUsForm.get('contactNumber')?.hasError('maxlength')" >
                                Invalid contact Number
                            </mat-error>
                            
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 mt-3">
                    <div>
                        <p class="f-12 text-grey mb-05">Email ID*</p>
                    </div>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="email" 
                        oninput="this.value = this.value.replace(/[A-Z]/g, '');"
                        (input)="convertToLowercase($event)"
                        />
                        <mat-error>
                            <mat-error *ngIf="contactUsForm.get('email')?.hasError('required')">
                                Email Id required
                            </mat-error>
                            <mat-error *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Invalid Email
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-12  mt-3 discusContainer">
                    <div>
                        <p class="f-12 text-grey mb-05">What would you like to discuss*</p>
                    </div>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="comment" />
                        <mat-error *ngIf="contactUsForm.get('comment')?.invalid">
                            message is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-12 mt-3">
                    <div class="btn-group mx-auto d-block text-center text-sm-end">
                        <button mat-button class="theme-btn border-btn" type="button" (click)="close()">
                            Cancel
                        </button>
                        <button mat-button type="submit" class="theme-btn primary-btn btn-color"
                        >Submit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>