import { PreloaderComponent } from './preloader/preloader.component';
import { MatDialog } from '@angular/material/dialog';
import { PublicFacadeService } from 'src/app/modules/public/state/public.facade.services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { SharedService } from './common/services/shared.service';
import { ThankyouComponent } from './modules/public/components/thankyou/thankyou.component';
import { Subscription, fromEvent } from 'rxjs';
import { TimePopupComponent } from 'src/app/common/components/time-popup/time-popup.component';
import { environment } from 'src/environments/environment';

declare var gtag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading = false;
  title = 'smartValue';

  hideElement = false;
  isLogin: boolean;
  isPublic: boolean;
  private dialogRef: any;
  isPublicUI: boolean = false;
  isLoginTrue: boolean = false;
  isRegister: boolean = false;
  landingPage: boolean = false;
  isIdleTimerRequired = false;
  subscription: Subscription;
  idleTimerStartSubscription: Subscription;
  idleTimerStopSubscription: Subscription;

  constructor(
    public router: Router,
    private userIdle: UserIdleService,
    public facadeService: PublicFacadeService,
    public sharedService: SharedService,
    public dialog: MatDialog
  ) {
    this.router.events.subscribe((event) => {
      let routes = [
        '/',
        '/login',
        '/auth',
        '/new-password',
        '/register',
        '/otp',
        '/multiplechoice',
        '/declaration',
        'questionnaire',
      ];
      if (environment.enableAnalytics) {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.gtag, {
            page_path: event.urlAfterRedirects,
          });
        }
      }
      if (event instanceof NavigationEnd) {
        // this.hideElement=(event.url==='/'||event.url==='/login'|| event.url==='/auth')?true:false
        this.hideElement = routes.includes(event.url) ? true : false;

        this.isLogin = this.isIdleTimerRequired =
          event.url == '/auth/login' ||
          event.url == '/auth/new-password' ||
          event.url == '/auth/register' ||
          event.url == '/auth/otp' ||
          event.url == '/register';

        // this.isPublic = (event.url == '/public' || event.url == '/public/landing-page' || event.url == '/public/pouchlist');
        console.log('')
        if (
          event.url == '/onboarding/questionnaire' ||
          event.url == '/onboarding/multiplechoice' ||
          event.url == '/onboarding/declaration' ||
          event.url == '/onboarding' ||
          event.url == '/onboarding/kyc_upload' ||
          event.url == '/dashboard' ||
          event.url == '/investment' ||
          event.url == '/more' ||
          event.url == '/more/stock' ||
          event.url == '/reports' ||
          event.url == '/more/universe' ||
          event.url == '/more/subscriber' ||
          event.url == '/more/subscriber/kyc_docs' ||
          event.url == '/more/holiday' ||
          event.url == '/more/promocode' ||
          event.url == '/investment/create-universe/universe' ||
          event.url == '/more/holiday/add-holiday' ||
          event.url == '/investment/create-pouch' ||
          event.url == '/investment/create-universe/pouch' ||
          event.url !== '/investment/create-universe/pouch'
        ) {
          this.isPublicUI = true;
        } else {
          this.isPublicUI = false;
        }
        if (
          event.url == '/public/landing-page' ||
          event.url == '/public/terms-and-conditions' ||
          event.url == '/public/privacy-policy' ||
          event.url == '/public' ||
          event.url == '/'
        ) {
          this.isPublicUI = false;
          this.landingPage = this.isIdleTimerRequired = true;
        }
        if (event.url == '/auth/login') {
          this.isLoginTrue = true;
        } else {
          this.isLoginTrue = false;
        }
        if (event.url == '/isRegister') {
          this.isRegister = true;
        }
        if (event.url.includes('/register')) {
          this.nohide = false;
        }

        if (!this.isIdleTimerRequired) {
          this.clearSubscription();
          const down$ = fromEvent(document, 'mousedown');
          this.subscription = down$.subscribe((e) => {
            this.restart();
          });
          this.restart();
          this.startWatching();
        } else {
          this.stop();
          this.stopWatching();
          this.clearSubscription();
        }
      }
    });
  }
  nohide = true;
  loadingDialog: any = [];
  loadGoogleAnalytics() {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtag}`;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.gtag}');
    `;
    document.head.appendChild(script2);
  }
  ngOnInit() {
    if (environment.enableAnalytics) {
      this.loadGoogleAnalytics();
    }


    //Start watching for user inactivity.
    // this.userIdle.startWatching();
    // this.facadeService.setUploadDateToStore();

    // Start watching when user idle is starting.
    this.idleTimerStartSubscription = this.userIdle
      .onTimerStart()
      .subscribe((count: any) => {
        console.log('onTimerStart');
        this.showTimerPopup(count);
      });
    // Start watch when time is up.
    this.idleTimerStopSubscription = this.userIdle.onTimeout().subscribe(() => {
      console.log('onTimeout');
      this.stop();
      this.stopWatching();
      this.showAlertPopup();
    });
  }

  showTimerPopup(count: any) {
    if (
      count != null &&
      (this.dialogRef === undefined || this.dialogRef == null)
    ) {
      this.dialogRef = this.dialog.open(TimePopupComponent, {
        width: '450px',
        data: {
          title: 'Timeout',
          message: '',
          type: 'popup',
          disableCancelBtn: false,
          disableOkBtn: false,
        },
        disableClose: true,
        backdropClass: 'loader-backfrop',
        panelClass: 'custom_popup',
      });
    }
    if (
      count != null &&
      (this.dialogRef !== undefined || this.dialogRef !== null) &&
      this.dialogRef.componentInstance
    ) {
      let configValue: any = this.userIdle.getConfigValue();
      if (configValue.timeout == count) {
        this.stop();
        this.stopWatching();
        this.clearSubscription();
      }
      this.dialogRef.componentInstance.count = configValue.timeout - count;
      this.dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.clickedCancel) {
          this.dialogRef = null;
        }
      });
    }
  }

  // Clear storage and navigate to login page
  showAlertPopup() {
    console.log('showAlertPopup', this.dialogRef);
    if (this.dialogRef === undefined || this.dialogRef == null) {
      this.dialog.closeAll();
      localStorage.clear();
      this.router.navigateByUrl('/auth/login');
    }

    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe((result: any) => {
      //if (result && result.clickedOkay) {
      localStorage.clear();
      this.router.navigateByUrl('/auth/login');
      this.dialogRef = null;
      // }
    });
  }
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  ngOnDestroy() {
    this.nohide = true;
    this.idleTimerStartSubscription.unsubscribe();
    this.idleTimerStopSubscription.unsubscribe();
  }

  clearSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  domain = () => {
    const host = window.location.host;
    const subdomain = host.split('.')[0];

    if (subdomain === 'richmee') {
        return true
      }else{
        return false;
        console.log('')
      }
  }
}
