<div>
  <!-- <ng-container *ngIf="questions.length == 0; else editQns">
    <div class="">
      <img src="../../../../../assets/images/noRisk.svg" alt="" />
    </div>
    <div class="mt-4 text-center">
      You have not answered the risk tolerance questionnaire
    </div>
    <a
      class="mt-1 pointer"
      style="text-decoration: none; color: #065dbc"
      (click)="go()"
      >Take risk tolarance assessment</a
    >
  </ng-container> -->
  <!-- <ng-template #editQns>
    <div class="mx-5 mt-4 editqn_container" style="flex-direction: column">
      <section class="upper">
        <div>
          Your Risk Profile : <span class="fw-600">{{ risk }}</span>
        </div>
        <div class="mx-5">
          <a style="text-decoration: none; color: #065dbc"
            >Update your risk profile</a
          >
        </div>
      </section>
      <section class="mt-2 section-wrapper">
        <div class="qns_box">
          <ng-container *ngFor="let q of questions; let i = index">
            <div class="qns d-flex align-items-center justify-content-center">
              <div>
                <div class="f-14 fw-400 w-100 d-flex">
                  Q{{ i + 1 }}.&nbsp;
                  <span class="mx- qn_wrap">{{ q.qn }}</span>
                </div>
                <div class="mx-3" style="color: #666666">{{ q.ans }}</div>
              </div>

              <img
                src="../../../../../assets/images/icons/edit_black.svg"
                style="margin-left: auto; visibility: hidden"
              />
            </div>
          </ng-container>
        </div>
      </section>
    </div>
  </ng-template> -->

  <div [ngSwitch]="true">
    <div *ngSwitchCase="questions.length > 0">
      <div class="mx-5 mt-4 editqn_container" style="flex-direction: column">
        <section class="upper">
          <div *ngIf="!from_subTable">
            Your Risk Profile : <span class="fw-600">{{ risk }}</span>
          </div>
          <div *ngIf="from_subTable">
            Risk Profile of {{ name }}: <span class="fw-600">{{ risk }}</span>
          </div>
          <div class="mx-5" *ngIf="!from_subTable">
            <a
              style="text-decoration: none; color: #065dbc; cursor: pointer"
              (click)="updateRP()"
              >Update your risk profile</a
            >
          </div>
        </section>
        <section class="mt-2 section-wrapper">
          <div class="qns_box">
            <ng-container *ngFor="let q of questions; let i = index">
              <div class="qns d-flex align-items-center justify-content-center">
                <div>
                  <div class="f-14 fw-400 w-100 d-flex">
                    Q{{ i + 1 }}.&nbsp;
                    <span class="mx- qn_wrap">{{ q.qn }}</span>
                  </div>
                  <div
                    style="color: #666666; padding-left: 24px; margin-top: 5px"
                  >
                    {{ q.ans }}
                  </div>
                </div>

                <img
                  src="../../../../../assets/images/icons/edit_black.svg"
                  style="margin-left: auto; visibility: hidden"
                />
              </div>
            </ng-container>
          </div>
        </section>
      </div>
    </div>
    <div *ngSwitchDefault>
      <div class="empty row justify-content-center" style="margin-top: 10%">
        <div class="text-center">
          <img src="../../../../../assets/images/noRisk.svg" alt="" />
        </div>
        <div class="mt-4 text-center">
          You have not answered the risk tolerance questionnaire
        </div>
        <a
          class="mt-1 pointer text-center"
          style="text-decoration: none; color: #065dbc"
          (click)="go()"
          >Take risk tolarance assessment</a
        >
      </div>
    </div>
  </div>
</div>
