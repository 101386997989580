<div class="popup-section pouch-width" style="position: relative">
  <div class="popup-header d-flex">
    <!-- <p class="f-16 fw-600">Create Your pouch</p> -->
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>

  <div class="popup-body text-center">
    <img
      src="../../../../../assets/images/icons/charm_circle-tick.png"
      alt=""
    />
    <p class="" style="margin-top: 27px">Thank you for choosing SmartBeta</p>
  </div>

  <div class="popup-footer text-center d-flex justify-center">
    <!-- <div class="col-md-3" style="margin-right: 10px;">
            <button mat-button class="theme-btn border-btn w-100" type="button">Cancel</button>
        </div> -->
  </div>
</div>
