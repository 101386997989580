import { Router } from '@angular/router';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-profile',
  templateUrl: './risk-profile.component.html',
  styleUrls: ['./risk-profile.component.scss'],
})
export class RiskProfileComponent implements OnInit {
  showQns = false;
  @Input() sid: any;
  @Input() rp: any;
  @Input() name: any;
  @Input() from_subTable: any;
  risk: any;
  user_id: any;
  questions: any = [];

  constructor(public subService: SubsciberService, public router: Router) {
    /*  if (this.rp) {
      this.risk = this.rp;
    } else {
      this.risk = JSON.parse(
        localStorage.getItem('user') || '{}'
      )?.attributes?.risk_profile;
    } */
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('user') || '{}');
    switch (user.attributes.role) {
      case 'subscriber':
        this.intiate(user.id);
        this.risk = user.attributes.risk_profile;
        break;
      case 'admin':
        if (!this.sid && !this.rp) {
          break;
        }
        this.risk = this.rp;
        this.intiate(this.sid);
        break;
    }
  }
  intiate(user: any) {
    this.user_id = user;
    this.subService
      .fetchRiskProfile({
        subscriber_id: user,
      })
      .subscribe((res: any) => {
        if (res[0].subscriber_response) {
          let response = res[0].subscriber_response[0].public___rp_subscribers;
          console.log(response);
          this.questions = [];
          for (let qn of response) {
            this.questions.push({
              qn: qn.rp_question,
              ans: qn.rp_answer,
            });
          }
        }
      });
  }
  go() {
    this.router.navigateByUrl('/onboarding/questionaire');
  }
  updateRP() {
    this.router.navigateByUrl('/onboarding/questionaire');
  }
}
