import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from 'src/app/modules/navigation/navigation.component';
import { AuthGuard } from 'src/app/core/auth.guard';
import { LandingPageComponent } from './modules/public/components/landing-page/landing-page.component';
import { RegisterComponent } from './modules/onbording/components/register/register.component';
import { PromocodeComponent } from './modules/more/components/promocode/promocode.component';
import { SubscriptionPlanComponent } from 'src/app/modules/public/components/subscription-plan/subscription-plan.component';
import { AgreementComponent } from 'src/app/modules/public/components/agreement/agreement.component';
import { PouchCreationComponent } from './modules/public/components/pouch-creation/pouch-creation.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { AgreementSubscribeComponent } from './modules/public/components/agreement-subscribe/agreement-subscribe.component';
import { RichmeeLandingPageComponent } from './modules/public/partners/richmee-landing-page/richmee-landing-page.component';
//import {QuestionResolver} from './core/resolver/question.resolver';

const domain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];

  if (subdomain === 'richmee') {

    return [{
      path: '',
      component: RichmeeLandingPageComponent
    }]

  } else {
    return [{
      path: '',
      redirectTo: '/public/landing-page',
      pathMatch: 'full'
    }]
  }
}
const domainWildCard = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];

  if (subdomain === 'richmee') {

    return [{
      path: '**',
      redirectTo: '/',
    }]

  } else {
    return [{
      path: '**',
      redirectTo: '/public',
    }]
  }
}

const routes: Routes = [
  ...domain(),

  {
    path: 'richmee-landing-page',

    component: RichmeeLandingPageComponent,
  },
  {
    path: 'register',

    component: RegisterComponent,
  },
  {
    path: 'preloader',
    component: PreloaderComponent,
  },
  {
    path: 'register/:product',
    component: RegisterComponent,
  },
  {
    path: 'subscription-plan',
    component: SubscriptionPlanComponent,
  },
  {
    path: 'subscription-plan/:pouchId',
    component: SubscriptionPlanComponent,
  },
  {
    path: 'agreement',
    component: AgreementComponent,

    canActivate: [AuthGuard],
  },
  {
    path: 'agreement-subscribe',
    component: AgreementSubscribeComponent,
  },
  {
    path: 'agreement/:product',
    component: AgreementComponent,

    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'pouch-creation',
    component: PouchCreationComponent,
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'portfolio',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/investment/investment.module').then(
        (m) => m.InvestmentModule
      ),
  },
  {
    path: 'investment',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/investment-pouches/investment-pouches.module'
      ).then((m) => m.InvestmentPouchesModule),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
  },
  {
    path: 'more',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/more/more.module').then((m) => m.MoreModule),
  },
  {
    path: 'onboarding',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/onbording/onbording.module').then(
        (m) => m.OnbordingModule
      ),
    /*  resolve: {
       questions: QuestionResolver,
     }, */
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('../app/modules/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'public',
    loadChildren: () =>
      import('../app/modules/public/public.module').then((m) => m.PublicModule),
  },

  {
    path: 'smart-value',
    component: NavigationComponent,
    loadChildren: () =>
      import('./modules/navigation/navigation.module').then(
        (m) => m.NavigationModule
      ),
  },
  ...domainWildCard()
  ,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],

})
export class AppRoutingModule { }
