import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PublicService } from '../../public/services/public.service';
import { PlansService } from '../../investment-pouches/services/plans.service';

@Component({
  selector: 'app-payment-loader',
  templateUrl: './payment-loader.component.html',
  styleUrls: ['./payment-loader.component.scss']
})
export class PaymentLoaderComponent implements OnInit ,OnDestroy {
  paymentUrl=''
  paymentData: any = {
    access_code: '',
    encRequest: '',
    order_id: '',
  };
  constructor(@Inject(MAT_DIALOG_DATA)private data:any,public publicService:PublicService,public dialogRef:MatDialogRef<PaymentLoaderComponent>,public planService:PlansService) {
   this.paymentUrl = environment.paymentGatewayUrl;
  }
  ngOnDestroy(): void {
    this.dialogRef.close()
  }
    billingDetails:any={}
  @ViewChild('myForm', { static: true }) public ngForm: ElementRef =
    {} as ElementRef;
  ngOnInit(): void {
    let  userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    this.planService.getBillingDetails({subscriber_id:userInfo.id}).subscribe((res:any)=>{
      this.billingDetails=res[0]?.sub_bill_addrs[0]||{}
      delete this.billingDetails.subscriber_id
      delete this.billingDetails.rowIndex
      sessionStorage.setItem('billings', JSON.stringify( this.billingDetails))
      this.paymentData=this.data
      let encRequest = document.getElementById('encRequest') as HTMLInputElement;
      let access_code = document.getElementById(
        'access_code'
      ) as HTMLInputElement;
      encRequest.value = this.paymentData.encRequest;
      access_code.value = this.paymentData.access_code;
      const interval = setInterval(() => {
        if (this.paymentData.access_code && this.paymentData.encRequest) {
          if(this.data.isRenew){
            this.ngForm.nativeElement.submit();
          }else{
            sessionStorage.setItem(
              'pouch',
              JSON.stringify(this.publicService.agreementDetailsNew)
            );
            this.ngForm.nativeElement.submit();
          }




          clearInterval(interval);
        }
      }, 100);
    })


  }

}
