<div>
    <div class="">
        <mat-form-field class="col-md-3" appearance="outline">
            <mat-label>Pouch </mat-label>
            <mat-select [(ngModel)]="selectedStock" placeholder="--Select--" (selectionChange)="onChangeSelection($event)" >
                <mat-option [value]="item" *ngFor="let item of dropDownList">{{item.stock_name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <canvas id="myChart" width="800" height="350"></canvas>
</div>