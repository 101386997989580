// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version,
  type: 'UAT',
  enableAnalytics: false,
  bypassEsign: false,
  production: true,
  idleTime: 600,
  timeout: 30,
  gtag: 'G-TR4J8S4WW5',
  hotjar: '3618307',
  url: 'https://eruauth.uat.smartvalues.co.in/',
  login_base_url: 'https://eruauth.uat.smartvalues.co.in/',
  baseUrl: 'https://eruql.uat.smartvalues.co.in/',
  baseUrl_8081: 'https://model.uat.smartvalues.co.in/',
  baseUrl_8082: 'https://erufiles.uat.smartvalues.co.in/',
  baseUrl_8083: 'https://erufunc.uat.smartvalues.co.in/',
  baseUrl_8085: 'https://eruauth.uat.smartvalues.co.in/',
  paymentGatewayUrl:
    'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction',
  smallcaseAuthToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJndWVzdCI6dHJ1ZX0.XgUwz34-QeFGMY9gcypSfALRrbXuQqzk6LOC9KAgNgQ',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
