import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-popup',
  templateUrl: './confirm-delete-popup.component.html',
  styleUrls: ['./confirm-delete-popup.component.scss']
})
export class ConfirmDeletePopupComponent implements OnInit {

  constructor(
    private _dialogRef:MatDialogRef<ConfirmDeletePopupComponent>
  ) { }

  ngOnInit(): void {
  }
  closeDialog(key:any){
    this._dialogRef.close(key)
  }
}
