<div class="popup-section pouch-width">

<ng-container *ngIf="agreementSigned==='No'|| agreementSigned===undefined">
  <div *ngIf="!ShowRiskPopup">
    <div class="popup-header d-flex">
      <p class="f-16 fw-600">Smart Stocks</p>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="popup-body" style="padding-top: 0!important;">
      <div class="text-center ">
        <img src="../../../../../assets/images/icons/warning.svg" style="width: 50px;" />
      </div>
      <div class="f-16 fw-400 text-darkgrey text-center mt-30">
       <strong>Smart Stocks</strong> carry a high risk profile
      </div>
      <div class="f-16 fw-400 text-darkgrey text-center mt-20">
        <span class="fw-600"> Do you still want to continue?</span>
      </div>
      <div class="row justify-center mt-20">
        <div class="col-4 col-sm-3" style="margin-left: 20px;">
          <button class="theme-btn border-btn mb-20 f-12 w-100"  mat-dialog-close>
            No
          </button>
        </div>
        <div class="col-4 col-sm-3">
          <button class="theme-btn primary-btn mb-20 f-12 w-100" (click)="ShowRiskPopup=true">
            Yes
          </button>
        </div>
    
      </div>
    </div>


  </div>
  <div *ngIf="ShowRiskPopup">
    <div class="popup-header d-flex">
      <p class="f-16 fw-600">Smart Stocks</p>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="popup-body">
      <p>
        You can discover smart stocks from the index of your choice.
      </p>
      <p>
        Our platform analyzes the stock performance of the stocks in the index that you choose and recommends few stocks for your investment.
      </p>
      <form #myForm method="post" action="{{ paymentUrl }}" ngNoForm hidden>
        <input name="encRequest" id="encRequest" [(ngModel)]="paymentData.encRequest" />
        <input name="access_code" id="access_code" [(ngModel)]="paymentData.access_code" />
      </form>
      <div class="text-greyf-14 fw-500 sb-text">
        <div class="row">
          <div class="col-md-6" style="padding-right: 3%;">
            <div>
              <p class="f-16 fw-600" style="margin-bottom: 0 !important;">Billing Details</p>
              <div class="address">
                <form [formGroup]="form">
                  <div>
                    <mat-label>Address</mat-label>
                    <mat-form-field appearance="outline" class="w-100">         
                      <textarea [ngClass]="{'border_red':form.get('address')?.invalid}" matInput placeholder="Address" formControlName="address" ></textarea>
                    </mat-form-field>
                    <mat-error class="f-12" *ngIf="form.get('address')?.invalid">
                      Invalid Address
                  </mat-error>
                  </div>
                  <div class="row"  style="margin-top: 7px;">
                    <div class="col-md-6">
                      <div>
                        <mat-label>Country</mat-label>
                        <mat-form-field appearance="outline" class="w-100">          
                          <mat-select [ngClass]="{'select_error':form.get('country')?.invalid}" placeholder="Country" formControlName="country">
                            <mat-option *ngFor="let c of getCountry" [value]="c.country_name">{{c.country_name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="f-12" *ngIf="form.get('country')?.invalid">
                          Invalid Country
                      </mat-error>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <mat-label>State</mat-label>
                        <mat-form-field appearance="outline" class="w-100">          
                          <mat-select [ngClass]="{'select_error':form.get('state')?.invalid}" placeholder="Select" formControlName="state">
                            <mat-option *ngFor="let s of getStateName" [value]="s.state_name">{{s.state_name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="f-12" *ngIf="form.get('state')?.invalid">
                          Invalid State
                      </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row"  style="margin-top: 5px;">
                    <div class="col-md-6">
                      <div>
                        <mat-label>City</mat-label>
                        <mat-form-field appearance="outline" class="w-100">          
                          <input [ngClass]="{'border_red':form.get('city')?.invalid}"  matInput placeholder="City" formControlName="city">
                        </mat-form-field>
                        <mat-error class="f-12" *ngIf="form.get('city')?.invalid">
                          Invalid City
                      </mat-error>
                      </div>
                    </div>
                  <div class="col-md-6">
                    <div>
                      <mat-label>Pincode</mat-label>
                      <mat-form-field appearance="outline" class="w-100">        
                        <input [ngClass]="{'border_red':form.get('pincode')?.invalid}" (keypress)="numberOnly($event);allowNumber($event)" matInput placeholder="Pincode" formControlName="pincode"  maxlength="6" type="tel" (paste)="onPasteAllowNumber($event)" (input)="allowNumber($event)">
                      </mat-form-field>
                      <mat-error class="f-12" *ngIf="form.get('pincode')?.invalid">
                        Invalid Pincode
                    </mat-error>
                    </div>
                  </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-6" style="padding-left: 4%">
            <div class="text-start">
              <div>Subscribe to this feature at Rs:</div>
              <div class="py-1" *ngFor="let s of subscriptionList; let i = index">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="selected">
                  <mat-radio-button id="{{ 'radio-sub' + s.rowIndex }}" (change)="selected = s.rowIndex"
                    [value]="s.rowIndex - 1">
                    {{ s.subscription_amount|number }} {{ s.subscription_freq === 'MONTHLY' ? 'for a' : 'for' }}
                    {{ getText(s.subscription_freq) }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>

    <div>
      <div class="row justify-content-end">
        <div class="col-1 col-md-1">
          <mat-icon *ngIf="disabled" (click)="clear()">close</mat-icon>
        </div>
      </div>
    </div>

    <div class=" text-center d-flex justify-center">
      <div class="col-md-3" style="margin-left: 10px">
        <!-- <button (click)="openAgreement('subscriber')" mat-button class="theme-btn primary-btn w-100">
          Subscribe Now
        </button> -->
        <button (click)="subscribe()" mat-button class="theme-btn primary-btn w-100">
          Subscribe Now
        </button>
      </div>
    </div>
    <div class="row" style="padding:10px 0;">
      <div class="col-12 d-flex justify-content-center f-12 text-center">
        <div><img src="../../../../../assets/images/icons/watchVideoIcon.svg" > want some help ? <span (click)="openVideo()" class="cPointer text-dark-blue f-13"> watch this video</span>  on how to smartstocks</div> 

        </div>
    </div>
  </div>
</ng-container>


  <div *ngIf="agreementSigned==='Yes'">
    <div class="popup-header d-flex">
      <p class="f-16 fw-600">Discover Smart Stocks</p>
      <!-- <mat-icon mat-dialog-close>close</mat-icon> -->
    </div>
    <div class="popup-body">
      <p>
        You have successfully signed the contract on <b>{{this.data?.registerDate| date :'dd MMMM yyyy'}}</b>. Redirecting you to complete the subscription payment.

        <!-- Your contract was successfully signed on  -->
      </p>
    </div>
    <div class="popup-footer text-center d-flex justify-center">
      <div class="col-md-3" style="margin-left: 10px">
        <button hidden (click)="makePAyment('makePayment')" mat-button class="theme-btn primary-btn w-100">
          Make Payment
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="agreementSigned==='exhausted'">
    <div class="popup-header d-flex">
      <p class="f-16 fw-600">Discover Stocks</p>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="popup-body">
      <p>You have used your daily limit to use this feature. You can try again tomorrow.
      </p>
    </div>
    <div class="popup-footer text-center d-flex justify-center">
      <div class="col-md-3" style="margin-left: 10px">
        <button (click)="closed()" mat-button class="theme-btn primary-btn w-100">
          Close
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="agreementSigned==='createPouchExpired'">
    <div class="popup-header d-flex">
      <p class="f-16 fw-600">Smart Stocks</p>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="popup-body">
      <p>
        Your Smart Stock subscription has expired on {{expiredDate| date :'dd MMMM yyyy'}}
      </p>
      <form #myForm method="post" action="{{ paymentUrl }}" ngNoForm hidden>
        <input name="encRequest" id="encRequest" [(ngModel)]="paymentData.encRequest" />
        <input name="access_code" id="access_code" [(ngModel)]="paymentData.access_code" />
      </form>
      <div class="text-greyf-14 fw-500 sb-text">
        <div class="row">
          <div class="col-md-6" style="padding-right: 3%">
            <div>
              <p class="f-16 fw-600" style="margin-bottom: 0 !important;">Billing Details</p>
              <div class="address">
                <form [formGroup]="form">
                  <div>
                    <mat-label>Address</mat-label>
                    <mat-form-field appearance="outline" class="w-100">         
                      <textarea [ngClass]="{'border_red':form.get('address')?.invalid}" matInput placeholder="Address" formControlName="address" ></textarea>
                    </mat-form-field>
                    <mat-error class="f-12" *ngIf="form.get('address')?.invalid">
                      Invalid Address
                  </mat-error>
                  </div>
                  <div class="row"  style="margin-top: 7px;">
                    <div class="col-md-6">
                      <div>
                        <mat-label>Country</mat-label>
                        <mat-form-field appearance="outline" class="w-100">          
                          <mat-select [ngClass]="{'select_error':form.get('country')?.invalid}" placeholder="Country" formControlName="country">
                            <mat-option *ngFor="let c of getCountry" [value]="c.country_name">{{c.country_name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="f-12" *ngIf="form.get('country')?.invalid">
                          Invalid Country
                      </mat-error>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <mat-label>State</mat-label>
                        <mat-form-field appearance="outline" class="w-100">          
                          <mat-select [ngClass]="{'select_error':form.get('state')?.invalid}" placeholder="Select" formControlName="state">
                            <mat-option *ngFor="let s of getStateName" [value]="s.state_name">{{s.state_name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="f-12" *ngIf="form.get('state')?.invalid">
                          Invalid State
                      </mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="row"  style="margin-top: 5px;">
                    <div class="col-md-6">
                      <div>
                        <mat-label>City</mat-label>
                        <mat-form-field appearance="outline" class="w-100">          
                          <input [ngClass]="{'border_red':form.get('city')?.invalid}" matInput placeholder="City" formControlName="city">
                        </mat-form-field>
                        <mat-error class="f-12" *ngIf="form.get('city')?.invalid">
                          Invalid City
                      </mat-error>
                      </div>
                    </div>
                  <div class="col-md-6">
                    <div>
                      <mat-label>Pincode</mat-label>
                      <mat-form-field appearance="outline" class="w-100">        
                        <input  [ngClass]="{'border_red':form.get('pincode')?.invalid}" (keypress)="numberOnly($event);allowNumber($event)" matInput placeholder="Pincode" formControlName="pincode"  maxlength="6" type="tel" (paste)="onPasteAllowNumber($event)" (input)="allowNumber($event)">
                      </mat-form-field>
                      <mat-error class="f-12" *ngIf="form.get('pincode')?.invalid">
                        Invalid Pincode
                    </mat-error>
                    </div>
                  </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-6" style="padding-left: 4%">
            <div class="text-start">
              <div>Renew this feature at Rs: </div>
              <div class="py-1" *ngFor="let s of subscriptionList; let i = index">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="selected">
                  <mat-radio-button id="{{ 'radio-sub' + s.rowIndex }}" (change)="selected = s.rowIndex"
                    [value]="s.rowIndex - 1">
                    {{ s.subscription_amount|number }} {{ s.subscription_freq === 'MONTHLY' ? 'for a' : 'for' }}
                    {{ getText(s.subscription_freq) }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    
    </div>

    <div>
      <div class="row justify-content-end">
        <div class="col-1 col-md-1">
          <mat-icon *ngIf="disabled" (click)="clear()">close</mat-icon>
        </div>
      </div>
    </div>

    <div class="popup-footer text-center d-flex justify-center">
      <div class="col-md-3" style="margin-left: 10px">
        <button (click)="renewNow('renewNow')" mat-button class="theme-btn primary-btn w-100">
          Renew Now
        </button>
      </div>
    </div>
  </div>
</div>
