<ng-container *ngIf="logginUser">
  <ng-container *ngIf="logginUser.role !== 'subscriber'">
    <div class="dashboard-component p2update mt-20">
      <div class="container">
        <h1 class="f_white text-center">
          Welcome
          <span class="f-l_green"
            >{{ logginUser.name.first | titlecase }}
            {{ logginUser.name.last | titlecase }}</span
          >
        </h1>

        <div style="margin-top: 14px">
          <div class="welcome_hud">
            <div class="row hud_details_mobView addPD">
              <div class="col-md-3 col-6 hud_details">
                <div class="img-icon">
                  <img
                    src="assets/images/icons/subscriber_updated.svg"
                    alt=""
                  />
                </div>
                <!--  -->
                <div class="ghost">
                  <h3 *ngIf="totalSubscriber">{{ totalSubscriber }}</h3>
                </div>
                <!--  -->
                <span class="sub-text">Total Subscribers</span><br />
                <span
                  class="f-l_green view-text cursor"
                  (click)="goSubscriber()"
                  >VIEW ALL</span
                >
              </div>

              <div class="col-md-3 col-6 hud_details">
                <div class="img-icon">
                  <img src="assets/images/icons/pouches_updated.svg" alt="" />
                </div>
                <!--  -->
                <div class="ghost">
                 <h3 *ngIf="totalPouch">{{ totalPouch }}</h3>
               </div>
               <!--  -->
                <span class="sub-text">Total Pouches</span><br />
                <span
                  class="f-l_green view-text cursor"
                  (click)="goInvestment()"
                  >VIEW ALL</span
                >
              </div>

              <div class="col-md-3 col-6 hud_details">
                <div class="img-icon">
                  <img
                    src="assets/images/icons/investments_updated.svg"
                    alt=""
                  />
                </div>
                <!--  -->
                <div class="ghost">
                  <h3  *ngIf="totalInvestment">
                    Rs.
                    {{
                      totalInvestment | currency : "INR" : "" : "1.2-2" : "en-IN"
                    }}L
                  </h3>
               </div>
                <span class="sub-text">Total Investment</span>
              </div>

              <div class="col-md-3 col-6 hud_details">
                <div class="img-icon">
                  <img src="assets/images/icons/returns_updated.svg" alt="" />
                </div>
                <!--  -->
                <div class="ghost">
                 <h3  *ngIf="totalReturn >= 0 ">{{ totalReturn || 0 }}%</h3>
                 <h3  *ngIf="totalReturn < 0 ">({{ totalReturn || 0 }})%</h3>
               </div>
              <!--  -->
                <span class="sub-text">Total Returns</span>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 90%; color: white" class="container mt-26 mb-10">
          <div class="row">
            <div class="col-md-3">
              <div class="text-left">
                <span class="f-12 fw-400"> Stock Price Last Uploaded On </span>
                <br />
                <!--  -->
                <div class="ghost">
                 <span style="padding-top: 6px" class="fw-600 f-14" *ngIf="uploadedDate">
                  {{ uploadedDate | date : "longDate" || '-'}}
                 </span>
              </div>
              <!--  -->
              </div>
            </div>
            <div class="col-md-9 text-left mpt-20">
              <span class="f-12">
                Stocks with major price change.<span
                  class="f-l_green cursor view-text fw-500"
                  (click)="goStockList()"
                  >&nbsp;VIEW ALL</span
                ></span
              >
              <br />
              <!--  -->
              <div class="ghost ghosttopGainersLosers">
                <ul
                  *ngIf="topGainersLosers?.length > 0"
                  style="padding-top: 6px"
                  class="ulStyle p-0 f-14"
                >
                  <li class="border_right">
                    <div class="d-flexM">
                      <div class="ams">
                        <strong>{{
                          topGainersLosers[0].gainers[0].stock_code
                        }}</strong>
                      </div>
                      <div class="imgStyle">
                        <img
                          src="../../../../../assets/images/icons/up.png"
                          alt=""
                        />
                      </div>
                      <div>{{ topGainersLosers[0].gainers[0].roc }}%</div>
                    </div>
                  </li>
                  <li class="border_right">
                    <div class="d-flexM">
                      <div class="ams">
                        <strong>{{
                          topGainersLosers[0].gainers[1].stock_code
                        }}</strong>
                      </div>
                      <div class="imgStyle">
                        <img
                          src="../../../../../assets/images/icons/up.png"
                          alt=""
                        />
                      </div>
                      <div>{{ topGainersLosers[0].gainers[1].roc }}%</div>
                    </div>
                  </li>
                  <li class="border_right">
                    <div class="d-flexM">
                      <div class="ams">
                        <strong>
                          {{ topGainersLosers[1].losers[0].stock_code }}</strong
                        >
                      </div>
                      <div class="imgStyle">
                        <img
                          src="../../../../../assets/images/icons/down.png"
                          alt=""
                        />
                      </div>
                      <div>{{ topGainersLosers[1].losers[0].roc }}%</div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flexM">
                      <div class="ams">
                        <strong>
                          {{ topGainersLosers[1].losers[1].stock_code }}</strong
                        >
                      </div>
                      <div class="imgStyle">
                        <img
                          src="../../../../../assets/images/icons/down.png"
                          alt=""
                        />
                      </div>
                      <div>{{ topGainersLosers[1].losers[1].roc }}%</div>
                    </div>
                  </li>
                </ul>
              </div>
              <!--  -->
            </div>
          </div>
        </div>
      </div>
      <div class="container-lg white-bg public-bg-wrapper mt-10 mb-60 pd-30">
        <div class="tab-gp" class="tableSec">
          <div class="leftSec">
            <mat-tab-group animationDuration="0ms">
              <mat-tab label="Pouches">
                <div class="pouches">
                  <app-pouches
                    *ngIf="pouches"
                    [pouches]="pouches"
                    [tableStructure]="pouches"
                    [definedData]="pouchList"
                    [sortingFilter]="showFilter"
                    [dashboardFilter]="pouchesFilter"
                    (sortEvent)="sortData($event)"
                    [selectedSort]="defaultSubscriberSort"
                  ></app-pouches>
                </div>
              </mat-tab>
              <mat-tab label="Subscribers">
                <div class="pouches">
                  <app-pouches
                    *ngIf="pouches"
                    [pouches]="pouches"
                    [tableStructure]="subscribers"
                    [definedData]="subscriberList"
                    [sortingFilter]="showFilter"
                    [dashboardFilter]="subscribersFilter"
                    (sortEvent)="sortData($event)"
                    [selectedSort]="defaultPouchSort"
                  ></app-pouches>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div class="rightSec">
            <div>
              <div class="border-bottom f-14 heading">
                Pouch Rebalance Dates
              </div>
              <div class="rightStyle">
                <app-pouches
                  *ngIf="pouches"
                  [pouches]="pouches"
                  [tableStructure]="pouchesRebalances"
                  [definedData]="pouchRebalanceList"
                >
                </app-pouches>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="logginUser.role === 'subscriber'">
    <app-dashboard-details [pouches]="pouches"></app-dashboard-details>
  </ng-container>
</ng-container>
