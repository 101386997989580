import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from 'src/app/common/services/shared.service';
import { Agreement, MoreService } from '../../services/more.service';

@Component({
  selector: 'app-agreement-grid-view',
  templateUrl: './agreement-grid-view.component.html',
  styleUrls: ['./agreement-grid-view.component.scss'],
})
export class AgreementGridViewComponent implements OnInit {
  @Input() sid: string;
  constructor(
    private moreService: MoreService,
    private sharedService: SharedService
  ) {}
  AgreementList: Array<string> = [
    'registration_date',
    'investment_amount',
    'agreement_id',
    'product_name',
    'view_icon',
  ];
  dataSource: MatTableDataSource<Agreement>;
  ngOnInit(): void {
    console.log(this.sid);
    this.moreService
      .fetch_Agreement_list({
        subscriber_id: this.sid,
      })
      .subscribe((response) => {
        console.log(response);
        this.dataSource = new MatTableDataSource(response);
      });
  }
  openPDF(fileName: string) {
    console.log(fileName);
    this.moreService
      .fetch_Agreement_Doc({
        folder_path: 'AGR',
        file_name: fileName,
      })
      .subscribe(
        async (response: any) => {
          const file = new Blob([await response], {
            type: 'application/pdf',
          });

          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
        (e) => {},
        () => {}
      );
  }
}
