<div class="subscription-plan">
  <div class="container-lg white-bg public-bg-wrapper  mb-60">
    <div style="padding:30px">
      <app-banner-pouch-detail [tabIndex]="0" [pouchDetail]="pouches">
      </app-banner-pouch-detail>
      <div class="mt-30 w-90 mauto">
        <app-plans [pouches]="pouches"></app-plans>
      </div>
    </div>
  </div>
</div>
