

<div class="agreement-wrapper">
  <div class="container-lg white-bg3 public-bg-wrapper mt-85 mb-60">
    <div class="container">
      <p class="f-12 fw-600 text-center mt-10" style="visibility: hidden">
        Investment Advisory Agreement for {{ agreementInfo?.heading_text }}.
      </p>
      <ng-container *ngIf="this.product == 'smartbeta'">
        <div id="agAgreement" style="overflow-y: scroll; height: 24rem" class="agreement">
          <div>
            <div class="pdf_container" #agreement style="size: letter">
              <header class="header" style="margin: auto; padding-bottom: 1%; width: 80%">
                <h3 style="text-align: center; font-size: 16px">
                  Investment Advisory Agreement
                </h3>
                <p style="
                    color: #81818c;
                    font-size: 14px;
                    text-align: center;
                    margin-bottom: 0 !important;
                  ">
                  {{ today | date : "dd MMM yyyy" }}
                </p>
                <div style="text-align: center; height: 36px">
                  <img src="../../../../../assets/images/smartbeta.svg" alt="" style="height: 36px" />
                </div>
              </header>

              <section class="box" style="
                  margin: 5%;
                  border: 1px solid rgb(236, 236, 236);
                  transform: scale(0.9);
                  margin-top: -3rem !important;
                ">
                <h5 style="margin-left: 2%; margin-top: 2%; font-size: 16px">
                  ADVISER DETAILS
                </h5>
                <div style="margin: 0 2% 0 2%"></div>

                <div class="advice-details" style="justify-content: space-between; margin: 0 2% 0 2%">
                  <div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Name
                      </p>
                      <span>Lamron Analysts Pvt Ltd</span>
                    </div>
                    <br />
                    <h5 style="
                        margin-left: 2%;
                        white-space: nowrap;
                        font-size: 16px;
                      ">
                      CLIENT DETAILS
                    </h5>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Name
                      </p>
                      <span>{{ userInfo.attributes.name.first || " " }}
                        {{ userInfo.attributes.name.last || " " }}</span>
                    </div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Email
                      </p>
                      <span style="letter-spacing: 0.2px !important">{{
                        userInfo.attributes.email || "-"
                        }}</span>
                    </div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        PAN
                      </p>
                      <span>{{ userInfo.attributes.pan }}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Email
                      </p>
                      <span style="letter-spacing: 0.2px !important">info@lamronanalysts.com</span>
                    </div>
                    <br />
                    <h5 style="
                        margin-left: 2%;
                        white-space: nowrap;
                        font-size: 16px;
                      ">
                      SUBSCRIPTION DETAILS
                    </h5>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Portfolio Name
                      </p>
                      <span>Smartbeta-{{ userInfo.attributes.name.first || " " }}
                        {{ userInfo.attributes.name.last || " " }}</span>
                    </div>

                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Subscription Fee
                      </p>
                      <!-- <span>{{
                        publicService.agreementDetailsNew?.agreementValues
                        ?.subscription_desc
                        }}
                      </span> -->
                      <span>
                        {{description_sub?.agg_text ? description_sub?.agg_text:('Fixed Fee of Rs.'+ (publicService?.agreementDetailsNew?.agreementValues
                        ?.fee_amount)) }} 
                         <!-- {{
                        publicService?.agreementDetailsNew?.agreementValues
                        ?.fee_amount
                        }} -->
                        <!-- {{subscriptionFee}} -->
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        SEBI Registration
                      </p>
                      <span>INA300003772</span>
                    </div>
                    <br />
                    <h5 style="margin-left: 2%; visibility: hidden">A</h5>

                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Subscription Start Date
                      </p>
                      <span> {{ today | date : "dd MMM yyyy" }}</span>
                    </div>

                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Subscription End Date
                      </p>
                      <span>Until cancelled</span>
                    </div>

                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Advance Fee (6-months)
                      </p>
                      <span>
                        <!-- ₹ -->
                        {{
                        publicService?.agreementDetailsNew?.agreementValues
                        ?.fee_amount
                        }}/-</span>
                    </div>
                  </div>
                </div>
                <p style="text-align: end; margin-top: 2%; margin-right: 2%">
                  <span style="color: #81818c; font-size: 12px !important">
                  </span>
                </p>
                <section style="
                    margin-top: 2%;
                    margin-left: 2%;
                    margin-right: 2%;
                    padding-bottom: 3%;
                    border-top: 1px solid rgb(236, 236, 236);
                  ">
                  <h5 style="margin-left: 0%; margin-top: 2%; font-size: 16px">
                    RISK PROFILING
                  </h5>
                  <h5 style="margin-left: 0%; font-size: 16px">
                    {{ user?.attributes?.risk_profile }}
                  </h5>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      Age
                    </p>
                    <span>{{ questions[0]?.ans || "-" }} years</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      What is your investment objective?
                    </p>
                    <span>{{ questions[1]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      What is your annual income?
                    </p>
                    <span>{{ questions[2]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      What percentage of your income goes in repayment of
                      existing liabilities like bank loans etc. ?
                    </p>
                    <span>{{ questions[3]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      Pick a possible outcome for your investment
                    </p>
                    <span>{{ questions[4]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      What percentage of your total wealth are you planning to
                      invest?
                    </p>
                    <span>{{ questions[5]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      Select all the investments you currently hold
                    </p>
                    <span>{{ questions[6]?.ans || "-" }}</span>
                  </div>
                </section>
              </section>
              <br />
              <br />

              <section style="margin-left: 5%; margin-right: 5%">
                <div>
                  <p>
                    In this Agreement, unless the context otherwise requires,
                    the Client and the Investment Adviser shall each be
                    individually referred to as a “Party” and shall be
                    collectively referred to as the “Parties”.
                  </p>
                </div>

                <br />
                <br />
                <div>
                  <ul style="margin-top: -2% !important;padding-left: 0!important;">
                    WHEREAS:

                    <li style="margin-left: 32px;">
                      The Client seeks to appoint advisor(s) to provide certain
                      investment advisory and other related services in relation
                      to the curated stock portfolios of securities and has
                      requested the Investment Adviser to render investment
                      advisory services to it at his/her/ its risk.
                    </li>
                    <li style="margin-left: 32px;">
                      The Investment Adviser has agreed to be appointed as the
                      Investment Adviser to the Client in accordance with the
                      terms of this Agreement and SEBI (Investment Advisers)
                      Regulations, 2013 as amended from time to time (“IA
                      Regulations”) to provide with investment advisory services
                      on a non-binding and non-exclusive basis and in a manner
                      solely determined by itself.
                    </li>
                  </ul>
                </div>
                <br />
                <div>
                  <p>
                    <span>1. REPRESENTATIONS AND WARRANTIES BY THE PARTIES</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">The Parties hereto represent, warrant, and covenant to
                      each other that:</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.1 Each of the Parties are duly formed and validly
                      existing under the respective laws that they are subject
                      to with full power and authority to conduct the business
                      as contemplated in this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.2 Each Party has full power, capacity and authority to
                      execute, deliver and perform this Agreement and has taken
                      all necessary action (corporate, statutory or otherwise)
                      to authorize the execution, delivery and performance of
                      this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.3 This Agreement and each other agreement executed in
                      connection herewith, if any, have been duly executed and
                      delivered by each Party and constitute legal, valid and
                      binding obligations of such Party, enforceable against the
                      other Party in accordance with the terms.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.4 Each Party has obtained and complied with all
                      clearances, permissions, approvals, conditions and
                      notices, that are or have been required, for the due
                      execution and delivery of, and performance under this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p><span class="c2">1.5 Client Undertaking</span></p>
                  <p>
                    <span class="c2">The Client understands and consents that it/he/she:</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.5.1 have read and understood the terms and conditions
                      of investment advisory services provided by the Investment
                      Adviser and also understood the fee structure and
                      mechanism for charging and payment of fees as under this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.5.2 wants to avail the investment advisory services
                      only for himself / herself and not for any other
                      person.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.5.3 have, based on its written request to the
                      Investment Adviser, been provided the opportunity by the
                      Investment Adviser to ask questions and interact with
                      &lsquo;person(s) associated with the investment
                      advice.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">1.5.4 has read the terms and conditions of Investment
                      Advisory services provided by the Investment Adviser along
                      with the fee structure and mechanism for charging and
                      payment of fee. Further, the Investment Adviser based on
                      the Client&rsquo;s request in writing provided the Client
                      an opportunity to ask questions and interact with
                      person(s) associated with the investment advice.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.5.5 shall furnish any and all information as reasonably
                      requested by the Investment Adviser for the purpose of
                      risk profiling process. The risk profile as created by the
                      Investment Adviser shall be final and binding on the
                      Client and the Client consents to the Investment Adviser
                      utilizing such information for the purpose of rendering
                      investment advice services to the Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6 Representations by the Investment Adviser</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.1 The Investment Adviser shall ensure that it has
                      appointed personnel of appropriate qualifications and
                      experience to perform the services in order to fulfil its
                      obligations under this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.2 Investment Adviser shall neither render any
                      investment advice nor charge any fee until the Client has
                      signed this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.3 The Investment Adviser represents and warrants that
                      it shall only recommend direct implementation of advice
                      i.e. through direct schemes/direct codes where no
                      consideration (including any embedded/indirect/in kind
                      commission or referral fees by any name) is received
                      directly or indirectly by the Investment Adviser or his
                      /her family.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.4 Investment Adviser shall not manage funds and
                      securities on behalf of the client and that it shall only
                      receive such sums of monies from the client as are
                      necessary to discharge the client&rsquo;s liability
                      towards fees owed to the Investment Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.5 Investment Adviser shall not, in the course of
                      performing its services to the Client, hold out any
                      investment advice implying any assured returns or minimum
                      returns or target return or percentage accuracy or service
                      provision till achievement of target returns or any other
                      nomenclature that gives the impression to the Client that
                      the investment advice is risk free and/or not susceptible
                      to market risks and or that it can generate returns with
                      any level of assurance.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.6 The Investment Adviser represents and warrants that
                      it is carrying on its activities on an arms-length
                      relationship between its activities as an Investment
                      Adviser and other activities and such arm&rsquo;s length
                      relationship shall be maintained while the existence of
                      this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.7 The Investment Adviser represents and warrant that
                      it is carrying on its activities independently, at an
                      arms-length basis with its related parties. Disclosure of
                      conflicts of interests, if any, shall be made by the
                      Investment Adviser to the Client in a prompt manner.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.8 The Investment Adviser represents and warrants that
                      all appropriate registrations permissions and approvals
                      which are statutorily required, have been validly
                      maintained and shall continue to be in force as required
                      for the performance of the Investment Adviser&rsquo;s
                      obligations under this Agreement.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">1.6.9 The Investment Adviser shall not derive any direct
                      or indirect benefit out the Client&rsquo;s securities
                      and/or investment products.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">1.6.10 The Investment Adviser shall ensure that it will
                      take all consents and permissions from the Client prior to
                      undertaking any actions, including but not limited to
                      implementation services in relation to the securities or
                      investment products advised by the Investment Adviser, in
                      a form and manner as under the IA Regulations.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.11 The Investment Adviser represents and warrants
                      that it shall not provide any distribution services to the
                      Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.12 The Investment Adviser represents and warrants
                      that its family/group companies shall not provide
                      distribution services to the Client advised by the
                      Investment Adviser, for securities and investment
                      products.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.13 The Investment Adviser represents and warrants
                      that its family/ group shall not provide investment
                      advisory services to the Client who receives distribution
                      services from the other family members of the Investment
                      Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.14 The Investment Adviser represents and warrants
                      that it shall not provide investment advisory services,
                      for securities and investment products, to a Client who is
                      receiving distribution services from its family members/
                      group.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.15 The investment adviser represents and warrants
                      that it shall maintain client records and data as mandated
                      under the securities and exchange board of India
                      (Investment Adviser) Regulations 2013.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>For the purposes of this Agreement, </span><span class="c4 c7">&ldquo;family members and
                      group&rdquo;shall have the meaning ascribed to it under the IA
                      Regulations.></span>
                  </p>
                  <p></p>
                  <p>
                    <span>2. SCOPE OF SERVICES</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.1 The Client hereby engages the services of the
                      Investment Adviser and the Investment Adviser hereby
                      agrees, as an independent contractor and on a principal to
                      principal basis, to provide the curated portfolio
                      services.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.2 The services rendered by the Investment Adviser shall
                      take into account the risk capacity and risk aversion
                      determined through a proper risk profiling process and
                      accepted by the Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.3 Notwithstanding anything herein contained to the
                      contrary, the Parties hereby agree that the services to be
                      rendered by the Investment Adviser to the Client are
                      merely recommendatory, non-binding in nature.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.4 The Parties acknowledge that the Investment Adviser
                      will not assume any management responsibilities in
                      connection with the services. Further, the Investment
                      Adviser will not be responsible for the use or
                      implementation of the output of the services provided
                      pursuant to this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.5 The Investment Adviser shall use its best judgment
                      and efforts in rendering advice to the Client under this
                      Agreement and in the performance of all its powers and
                      duties under this Agreement.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">2.6 It is hereby expressly understood and confirmed by
                      the Client and the Investment Adviser that notwithstanding
                      any other provision of this Agreement, neither the
                      Investment Adviser nor any of its directors or employees
                      shall have the power or authority whatsoever to:</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.6.1 Bind or commit the Client in relation to any
                      contract or any trade or other preliminary or ancillary
                      agreement relating thereto.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.6.2 Represent the Client in any way, including without
                      limitation, in any negotiations relating to the purchase,
                      acquisition, sale or transfer of any investments.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.6.3 Buy or sell any securities on behalf of the
                      Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.7 The services to be provided by the Investment Adviser
                      shall be subject to the activities permitted under the
                      Securities and Exchange Board of India (Investment
                      Advisers) Regulations, 2013. The Investment Adviser shall
                      act in a fiduciary capacity towards the Client at all
                      times.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>3. FUNCTIONS AND DUTIES OF THE INVESTMENT ADVISER</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.1 The Investment Adviser, in relation to providing
                      services to the Client, undertakes to always abide by the
                      IA Regulations (including compliance requirements under
                      the IA Regulations) and rules, circulars and notifications
                      issued there under from time to time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.2 The Investment Adviser undertakes to always abide by
                      the eligibility criteria as under the IA
                      Regulations.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.3 The Investment Adviser shall provide a risk
                      assessment procedure to client including determination of
                      risk capacity and risk aversion levels</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"> 3.4 The Investment Adviser shall provide reports in
                      relation to potential and current investments.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.5 The Investment Adviser shall, in relation to
                      each Client maintain, know your client, advice, risk
                      assessment, analysis reports of investment advice and suitability,
                      terms and conditions document, rationale of advice,
                      related books of accounts and a register containing list
                      of clients along with dated investment advice in
                      compliance with the IA Regulations.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.6 The Investment Adviser shall conduct compliance
                      audits with respect to itself to ensure that it is in
                      compliance with the IA Regulations in a form and manner as
                      may be prescribed under the IA Regulations from time to
                      time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.7 The Investment Adviser shall ensure there is adequate
                      compliance and monitoring processes in place for the
                      purposes of client segregation in a form and manner as may
                      prescribed under the IA Regulations from to time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.8 The Investor Advisor undertakes to abide by the code
                      of conduct as specified under the IA Regulations and such
                      shall be deemed to incorporated within this Agreement by
                      reference.</span>
                  </p>

                  <p></p>
                  <p><span>4. ADVISORY FEE</span></p>

                  <p></p>
                  <p>
                    <span class="c2">4.1 The Client shall pay the Investment Adviser by way of
                      remuneration for its services such fees as specified at
                      the beginning of the agreement in accordance with the IA
                      Regulations and relevant circulars issued there
                      under</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.2 In case, the Investment adviser is charging the fee
                      on a percentage of AUA basis, the Investment Adviser shall
                      refer the Client&rsquo;s holding statement as referred to
                      in point 4.3 below for the calculation of the fee</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.3 In case, the Investment Adviser is charging on a
                      percentage of AUA basis, the Client shall bring to the
                      notice of the Investment Adviser any discrepancy in the
                      holding statement shared by the Investment Adviser within
                      7 days from the date of the statement, failing which it
                      shall be presumed that the Client has confirmed its
                      completeness and correctness. The Client agrees to provide
                      the Investment Adviser with necessary supporting documents
                      related to the AUA, as and when required</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.4 The client shall pay the fee within 7 days from
                      receiving the invoice from the Investment Adviser on the
                      registered email id.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.5 The Investment Adviser shall send a receipt
                      evidencing payment of Advisory Fees by the Client to the
                      Client&rsquo;s registered email address.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.6 The Advisory Fee shall be paid by the Client to the
                      Investment Adviser by depositing the same in the bank
                      account of the Investment Adviser as may be notified by
                      the Investment Adviser. The Advisory Fee shall not be
                      accepted in cash by the Investment Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.7 The Investment Adviser confirms that the fees shall
                      at all times be calculated and charged in accordance with
                      IA Regulations and circulars issued thereunder</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.8 The Client shall be additionally charged all taxes as
                      may be applicable or as may be levied in relation to the
                      consideration payable to the Investment Adviser. It is
                      hereby clarified that the Investment Adviser shall be
                      responsible for payment of income tax and similar levies
                      payable by it.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.9 A sample illustration for calculation of fee is given
                      below</span>
                  </p>
                  <p>
                    <span class="c2">Formula for the calculating the fee on percentage of AUA
                      basis has been explained below:</span>
                  </p>
                  <p>
                    <span class="c2">(Average daily AuA in the month) * (Number of invested
                      days in the month/360) * Subscription fee (in %) Sample
                      calculation: 100,000 (Avg. daily AuA) * (30/360) *.02
                      (subscription fee as %) = INR 166.67 Fixed fee will be charged as a fixed amount during the
                      subscription at the beginning of the subscription</span>
                  </p>
                  <p></p>
                  <p><span class="c2">4.10 Below
                      are the SEBI guidelines for advisory fees as specified in
                      the SEBI circular dated 23rd Sep 2020.</span></p>


                  <p></p>
                  <p>
                    <span class="c2">Fees Regulation 15 A of the amended IA Regulations
                      provide that Investment Advisers shall be entitled
                      to</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">charge fees from a client in the manner as specified by
                      SEBI, accordingly Investment Advisers shall charge fees
                      from the clients in either of the two modes:</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">(A) Assets under Advice (AUA) mode</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">a. The maximum fees that may be charged under this mode
                      shall not exceed 2.5 percent of AUA per annum per client
                      across all services offered by IA.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">b. IA shall be required to demonstrate AUA with
                      supporting documents like demat statements, unit
                      statements etc. of the client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">c. Any portion of AUA held by the client under any
                      pre-existing distribution arrangement with any entity
                      shall be deducted from AUA for the purpose of charging fee
                      by the IA.</span>
                  </p>
                  <p></p>
                  <p><span class="c2">(B) Fixed fee mode</span></p>
                  <p></p>
                  <p>
                    <span class="c2">The maximum fees that may be charged under this mode
                      shall not exceed INR 1,25,000 per annum per client across
                      all services offered by IA.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">General conditions under both modes</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">a. IA shall charge fees from a client under any one mode
                      i.e. (A) or (B) on an annual basis. The change of mode
                      shall be effected only after 12 months of on boarding/last
                      change of mode.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">b. If agreed by the client, IA may charge fees in
                      advance. However, such advance shall not exceed fees for 2
                      quarters.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">c. In the event of pre-mature termination of the IA
                      services in terms of agreement, the client shall be
                      refunded the fees for unexpired period. However, IA may
                      retain a maximum breakage fee of not greater than one
                      quarter fee.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>5. INVESTMENT OBJECTIVES AND GUIDELINES AND RISK
                      FACTORS</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">5.1 Type of securities &ndash; curated portfolio services
                      provided by the investment adviser under this agreement
                      would be based on stocks and ETFs listed on the Indian
                      Stock Exchanges.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">5.2 Strategy &ndash; recommendation follows a curated
                      portfolio approach where the portfolio composition is
                      reviewed at a fixed frequency based on various
                      quantitative, technical or fundamental factors to
                      determine the portfolio composition for the next
                      period.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">5.3 Tax Aspects &ndash; equity component of the portfolio
                      would be subjected to short term/long term capital gains
                      tax depending on the holding period of the security. If
                      the holding period is less than one year, a 15% short term
                      capital gains tax would be applicable. If the holdings
                      period is more than one year, a 10% long term capital
                      gains tax would be applicable on portfolio gains. These
                      rates can be revised on a time to time basis by the
                      government of India. Tax liability on the ETF component of
                      the portfolio, if any, would depend on the underlying
                      asset class of the ETF. When in doubt, client shall reach
                      out to the Investment Advisor on the mentioned email id
                      for a detailed evaluation based on the trades placed by
                      the client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">5.4 Investment Objectives and Guidelines and risk factors
                      - The Client hereby agrees and understands that investment
                      advice made by the Investment Adviser are subject to
                      various market, currency, economic, political and business risks including but not limited to price and
                      volume volatility in the stock markets, interest rates,
                      currency exchange rates, foreign investments, changes in
                      government policies, taxation, political, economic,
                      pandemic, or other developments and closure of the stock
                      exchange</span>
                  </p>

                  
                  <p></p>
                  <p>
                    <span>6. NO RIGHT TO SEEK POWER OF ATTORNEY</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">The Investment Adviser hereby declares and confirms that
                      it shall not seek any power of attorney or authorizations
                      from the Client for implementation of investment
                      advice.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>7. SERVICES NOT EXCLUSIVE</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">The services of the Investment Advisor are not exclusive
                      to the Client. The Investment Advisor and any shareholder,
                      employee, director or agent of the Investment Advisor may
                      render similar services to others and engage in additional
                      activities, without any intimation to, or consent of the
                      Client. Provided however that the Investment Advisor as
                      well as any of its directors, employees of associate
                      concerns shall avoid any conflict of interest in relation
                      to the advisory services provided.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">In the event that such a conflict of interest does arise,
                      the Investment Adviser shall declare such conflict and, if
                      reasonably possible, ensure that fair treatment on an
                      arm&rsquo;s length basis as reasonably determined by the
                      Investment Adviser in its sole discretion shall be
                      accorded to the Client. For the avoidance of doubt, the
                      Investment Advisor may, from time to time have business
                      relationships with companies or corporations in relation
                      to which advisory services have been provided to the
                      Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>8. DURATION AND TERMINATION</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.1 The Agreement shall remain in force for the
                      period mentioned at the beginning of this agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.2 If the client is on an auto-renew plan, the
                      agreement will remain in force until the subscription is
                      canceled by the client or advisor in accordance with this
                      agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.3 This Agreement may be terminated by mutual agreement
                      of the Client and the Investment Adviser by giving a 30
                      days prior written notice.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.4 The Agreement may be immediately terminated by the
                      Investment Adviser: (i) if the Client breaches any
                      material term of this Agreement; or (ii)if the Client is
                      admitted into liquidation (except a voluntary liquidation
                      for the purpose of reconstruction or amalgamation) or
                      commits any act of bankruptcy or if a receiver is
                      appointed in respect of any assets of the Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.5 If the Investment Adviser ceases to hold statutory
                      licenses and/or registrations required to provide services
                      as contemplated under this Agreement then the Client shall
                      have the ability to terminate this Agreement without any
                      further consequences.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">8.6 If the Government or any regulatory body has taken
                      any action on the Investment Adviser then the Client shall
                      have the right to immediately terminate this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"
                      > 8.7 The agreement will be terminated automatically
                      upon the death of the Client.</span
                    >
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"
                      >8.8 Upon the termination of the Agreement due to
                      reasons under Clause 8.3, 8.4, 8.5,8.6 and 8.7, the
                      Investment Adviser shall refund any balance of the advisory fees for
                      which services have not been provided within 30 business
                      days from the date of termination of this agreement.</span
                    >
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.9 Any Advisory Fees that have accrued in the manner set
                      out in this Agreement up to the date of the termination
                      shall be paid by the Client to the Investment Adviser
                      within Thirty (30) business days from the date of
                      termination of this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>9. LIMITATION OF LIABILITY OF THE INVESTMENT
                      ADVISER</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">9.1 The Investment Adviser shall not be liable towards
                      the Client by reason of any loss, which a Client may
                      suffer by reason of any depletion in the value of the
                      investment and/or &lsquo;assets under advice&rsquo;, which
                      may result by reason of fluctuation in asset value, or by
                      reason of non-performance or under-performance of the
                      securities/funds or any other market conditions.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>For the purposes of this Agreement, </span><span class="c4 c7">&ldquo;Assets Under
                      Advice&rdquo; </span><span class="c2">shall have the meaning ascribed to under the IA
                      Regulations.</span>
                  </p>
                  <p></p>
                  <p><span>10. NOTICE</span></p>
                  <p></p>
                  <p>
                    <span class="c2">10.1 Any notice, instruction, recommendation or other
                      communication to be given hereunder shall be in writing
                      and delivered by e-mail addressed to the Party for which
                      it is intended A communication sent by e-mail shall be
                      deemed to have been received at the close of business on
                      the day on which it is sent In providing service by
                      e-mail, it shall be sufficient to show that the email was
                      properly addressed to the intended recipient.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">10.2 Whenever, pursuant to any provision of this
                      Agreement, any notice, instruction, recommendation or
                      other communication is given to either Party, the Client
                      or the Investment Adviser (as the case may be) may accept
                      as sufficient evidence thereof a document signed or
                      purporting to be signed by such person or persons as shall
                      be authorised from time to time in that behalf by the
                      Client or the Investment Adviser (as the case may
                      be).</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">10.3 Whenever, pursuant to any provision of this
                      Agreement, any notice, instruction, recommendation or
                      other communication is given to either Party, the Client
                      or the Investment Adviser (as the case may be) may accept
                      as sufficient evidence thereof a document signed or
                      purporting to be signed by such person or persons as shall
                      be authorised from time to time in that behalf by the
                      Client or the Investment Adviser (as the case may
                      be).</span>
                  </p>
                  <p></p>
                  <p><span>11. ASSIGNMENT</span></p>

                  <p></p>
                  <p>
                    <span class="c2">This Agreement may not be assigned by either Party
                      without the written consent of the other Party.</span>
                  </p>

                  <p></p>
                  <p><span>12. WHOLE AGREEMENT</span></p>

                  <p></p>
                  <p>
                    <span class="c2">This Agreement together with any document annexed hereto
                      or referred to herein constitutes the entire Agreement
                      between the Parties in relation to the subject matter
                      hereof and supersedes all prior understandings,
                      arrangements, representations, proposals or communications
                      between the Parties whether written or oral.</span>
                  </p>
                  <p></p>
                  <p><span>13. SEVERABILITY</span></p>
                  <p></p>
                  <p>
                    <span class="c2">In the event that any term, condition or provision of
                      this Agreement is held to be in a violation of any
                      applicable law, statute or regulation the same shall be
                      deemed to be deleted from this Agreement and shall be of
                      no force and effect and this Agreement shall remain in
                      full force and effect as if such term, condition or
                      provision had not originally been contained in this
                      Agreement. Notwithstanding theforegoing in the event of
                      such deletion the Parties shall negotiate in good faith in
                      order to agree on the terms of a mutually acceptable and
                      satisfactory alternative provision in place of the
                      provision so deleted.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>14. GRIEVANCE REDRESSAL AND DISPUTE RESOLUTION</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">14.1 In the event any Client has any grievances in
                      relation to the provision of investment advice and related
                      services by the Investment Adviser, it shall be the
                      responsibility of the Investment Adviser to resolve the
                      grievances promptly but not later than a period of thirty
                      (30) days from the date such grievance or such time period
                      as may be prescribed by SEBI from time to time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">14.2 In the event of any dispute arising between the
                      Parties in relation to this Agreement, the Parties shall
                      in the first instance attempt to resolve such dispute by
                      mutual discussions. If the dispute is not resolved through
                      mutual consultations within thirty (30) calendar days
                      after one Party has served written notice on the other
                      Party requesting the commencement of such discussions,
                      either Party may in writing demand that the dispute be
                      finally settled by the arbitration of one (1) arbitrator,
                      who shall be appointed based on the mutual agreement of
                      the Parties. The arbitration shall be conducted in
                      accordance with the Arbitration and Conciliation Act, 1996
                      and the rules thereunder, as may be amended from time to
                      time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"> 14.3 &nbsp;The language of arbitration shall be
                      English.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"> 14.4 &nbsp;The arbitration award shall be final and
                      binding upon the Parties. Each Party shall co-operate in
                      good faith to expedite (to the maximum extent practicable) the conduct
                      of any arbitral proceedings commenced under this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">14.5 The costs and expenses of the arbitration,
                      including, the fees of the third arbitrator, shall be
                      borne equally by each Party to the dispute or claim and
                      each Party shall pay its own fees, disbursements and other
                      charges of its counsel and the arbitrators nominated by
                      it.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">14.6 The arbitrator would have the power to award
                      interest on any sum awarded pursuant to the arbitration
                      proceedings and such sum would carry interest, if awarded,
                      until the actual payment of such amounts. Any award made by the arbitrators shall be final and
                      binding on
                      each of the Parties that were parties to the
                      dispute.</span>
                  </p>
                  <p></p>
                  <p><span>15. FORCE MAJEURE</span></p>
                  <p></p>
                  <p>
                    <span class="c2">The Investment Adviser shall not be liable for delays or
                      errors occurring by reason of circumstances beyond its
                      control, including but not limited to acts of civil or
                      military authority, national emergencies, work stoppages,
                      fire, flood, catastrophe, acts of God, insurrection, war,
                      riot, or failure of communication or power supply. In the
                      event of equipment breakdowns beyond its control, the
                      Advisor shall take reasonable steps to minimize service
                      interruptions but shall have no liability with respect
                      thereto.</span>
                  </p>
                  <p></p>
                  <p><span>16. MISCELLANEOUS</span></p>
                  <p></p>
                  <p>
                    <span class="c2">16.1 The Investment Adviser shall not seek any power of
                      attorney or authorizations from the Client for automatic
                      implementation of investment advice and nothing in this
                      Agreement shall be construed as conferring a power of
                      attorney or such rights by the Client on the Investment
                      Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.2 Any amendment to the terms hereof shall be effective
                      only if agreed to in writing between the Client and the
                      Investment Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.3 No failure on the part of any Party to exercise, and
                      no delay in exercising, any right or remedy under this
                      Agreement will operate as a waiver thereof nor will any
                      single or partial exercise of any right or remedy preclude
                      any other or further exercise thereof or the exercise of
                      any other right or remedy. The rights and remedies
                      provided in this Agreement are cumulative and not
                      exclusive of any rights or remedies provided by law.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.4 The illegality, invalidity or unenforceability of
                      any provision of this Agreement under the laws of any
                      jurisdiction shall not affect its legality, validity or
                      enforceability under the laws of any other jurisdiction
                      nor the legality, validity or enforceability of any other
                      provision.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.5 Other than as specifically permitted under this
                      Agreement, the Client shall not publish, disseminate or
                      broadcast advertisements, circulars or other publicity
                      material referring to the other Party without the prior
                      consent of such Party, which shall not be unreasonably
                      withheld.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.6 Nothing herein contained shall be deemed to create
                      or constitute a partnership between the Parties hereto.
                      This Agreement may only be varied with the written
                      agreement of both Parties. This Agreement may be entered
                      into in any number of counterparts, each of which when
                      executed and delivered shall be an original.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.7 No Person who is not a party to this Agreement
                      shall have any right to enforce the terms of this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"> 16.8 &nbsp;Each party agrees to perform such further
                      actions and execute such further agreements as are
                      necessary to effectuate the purposes hereof.</span>
                  </p>
                  <p></p>

                  <p></p>
                  <p>
                    <span class="c4 c7">17. DISCLOSURES BY THE INVESTMENT ADVISER</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">17.1 The Client agrees and confirms that the Service
                      Provider may appoint agents and or third-party vendors for
                      carrying out the acts mentioned in or in relation to
                      rendering its services. The Client consents to sharing of
                      his/its account related information to such authorised
                      agents / third party vendors appointed by the Service
                      Provide.</span>
                  </p>
                  <p>
                    <span class="c2">17.2 Investment in securities market are subject to
                      market risks. Read all the related documents carefully
                      before investing. Registration granted by SEBI, membership
                      of BASL (in case of IAs), and certification from NISM in
                      no way guarantee performance of the intermediary or
                      provide any assurance of returns to investors.</span>
                  </p>
                  <p></p>
                  <p><span class="c2">18. ARBITRATION</span></p>
                  <p></p>
                  <p>
                    <span class="c2">In the event of any dispute or differences relating to,
                      arising from, or connected with this Agreement, the
                      Parties may refer such dispute or difference for
                      resolution by arbitration in accordance with the
                      provisions as may be specified under the Arbitration and
                      Conciliation Act, 1996 and the rules there under as may be
                      amended from time to time and the award of such arbitrator
                      shall be final and binding on both the Parties. The
                      arbitration shall be conducted in English. The venue of
                      arbitration shall be Kolkata, India.</span>
                  </p>
                  <p></p>
                  <p><span class="c2">19. INDEMNITY</span></p>
                  <p></p>
                  <p>
                    <span class="c2">The Investment Adviser shall provide Services to the best
                      of its knowledge and ability and shall not, under any
                      circumstances, be liable or responsible for any damage,
                      loss or other consequences, if any, arising from any act,
                      deed, matter or thing done or caused to be done by the
                      Investor upon the advice or recommendations of the
                      Investment Adviser, or upon any implementation of the
                      Models as instructed by the Investor, except in the case
                      of gross misconduct, gross negligence, fraud, willful
                      misconduct, or acting in without appropriate authority or
                      acting without any prudent and diligent manner.</span>
                  </p>

                  <p></p>
                  <p><span class="c2">20. RISK FACTOR RISK FACTOR</span></p>

                  <p></p>
                  <p>
                    <span class="c2">1. The Investor has the financial ability to bear the
                      economic risk of the investments that it makes, including
                      any investments made as a result of the Services rendered
                      by the Investment Adviser hereunder, has adequate means
                      for providing for its current requirements and possible
                      contingencies, and possesses such degree of financial
                      sophistication so as to understand the investment
                      recommendations, advice and Models provided by the
                      Investment Adviser hereunder.</span>
                  </p>

                  <p></p>
                  <p>
                    <span>21. DETAILS OF INVESTMENT ADVISER</span>
                  </p>
                  <p>
                    <span>Name of Investment Adviser as registered with SEBI: </span><span class="c4 c7"><strong>Lamron
                        Analysts Pvt. Ltd.</strong></span><br /><br /><span> Type of Registration- </span><span
                      class="c4 c5"><strong>Non-Individual</strong></span>
                  </p>
                  <p>
                    <span>Registration number: </span><span class="c4 c7"><strong>INA300003772</strong></span><span>,
                      validity of registration: </span><span class="c4 c7"><strong>Until Cancelled</strong>
                    </span><br /><br /><span>Complete address with telephone numbers: </span><span
                      class="c4 c7">109/40B, Hazra Road, Kolkata - 700026 </span><br /><br /><span>Telephone No.
                    </span><span>033-24740657</span>
                    <br /><br /><span>CIN:</span><span>U74140WB2000PTC091710</span>
                    <br /><br /><span>BASL ID: </span><span>1322</span>
                  </p>
                  <p>
                    <span>Contact details of the Principal Officer &ndash; Name: </span><span>Soumitra Sengupta</span>
                  </p>
                  <p>
                    <span>Contact no: </span><span class="c4 c7">9831657426, </span>
                    <span>Email id : </span><span class="c4 c7">soumitra.sengupta@lamronanalysts.com </span>
                    <br>
                    <span>Contact details of the Compliance Officer - Name: </span><span class="c4 c7">Prabhas Chandra
                      Ray</span>
                    <br>
                    <span>Contact no: </span><span class="c4 c7">7003362380, &nbsp; </span>

                    <span>Email id : &nbsp; </span><span class="c4 c7">prabhas.rsay@lamronanalysts.com</span>
                    <br>
                    <span>Contact details of the Grievance Officer - Name: </span><span class="c4 c7"> Prabhas Chandra
                      Ray</span> <br>
                    <span>Contact no: </span><span class="c4 c7">7003362380, &nbsp; </span>

                    <span>Email id : &nbsp; </span><span class="c4 c7"> prabhas.ray@lamronanalysts.com</span>
                    <br>
                    <span>Corresponding SEBI regional/local office address: </span><span class="c4 c7">L&amp;T Chambers,
                      3rd Floor</span><span class="c2">,</span>
                  </p>
                  <p>
                    <span><strong>16 Camac Street, Kolkata </strong> - 700017</span>
                  </p>

                  <p class="c0 c10"><span></span></p>
                </div>
              </section>
            </div>
          </div>
          <div style="text-align: center">
            <button class="theme-btn primary-btn mb-20 mt-10 text-center" (click)="submit()">
              Accept Agreement
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="this.product !== 'smartbeta'">
        <div id="agAgreement" style="overflow-y: scroll; height: 24rem" class="agreement">
          <div>
            <div class="pdf_container" #agreement style="size: letter">
              <header class="header" style="margin: auto; padding-bottom: 1%; width: 80%">
                <h3 style="text-align: center; font-size: 16px">
                  Investment Advisory Agreement
                </h3>
                <p style="
                    color: #81818c;
                    font-size: 14px;
                    text-align: center;
                    margin-bottom: 0 !important;
                  ">
                  {{ today | date : "dd MMM yyyy" }}
                </p>
                <div style="text-align: center; height: 36px">
                  <img src="../../../../../assets/images/logo_1.svg" alt="" style="height: 36px" />
                </div>
              </header>

              <section class="box" style="
                  margin: 5%;
                  border: 1px solid rgb(236, 236, 236);
                  transform: scale(0.9);
                  margin-top: -3rem !important;
                ">
                <h5 style="margin-left: 2%; margin-top: 2%; font-size: 16px">
                  ADVISER DETAILS
                </h5>
                <div style="margin: 0 2% 0 2%"></div>

                <div class="advice-details" style="justify-content: space-between; margin: 0 2% 0 2%">
                  <div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Name
                      </p>
                      <span>Lamron Analysts Pvt Ltd</span>
                    </div>
                    <br />
                    <h5 style="
                        margin-left: 2%;
                        white-space: nowrap;
                        font-size: 16px;
                      ">
                      CLIENT DETAILS
                    </h5>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Name
                      </p>
                      <span>{{ userInfo.attributes.name.first || " " }}
                        {{ userInfo.attributes.name.last || " " }}</span>
                    </div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Email
                      </p>
                      <span style="letter-spacing: 0.2px !important">{{
                        userInfo.attributes.email || "-"
                        }}</span>
                    </div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        PAN
                      </p>
                      <span>{{ userInfo.attributes.pan }}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Email
                      </p>
                      <span style="letter-spacing: 0.2px !important">info@lamronanalysts.com</span>
                    </div>
                    <br />
                    <h5 style="
                        margin-left: 2%;
                        white-space: nowrap;
                        font-size: 16px;
                      ">
                      SUBSCRIPTION DETAILS
                    </h5>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Portfolio Name
                      </p>
                      <span>{{ agreementInfo?.heading_text }}</span>
                    </div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Subscription Frequency
                      </p>
                      <span>{{
                        subscriptionData?.subscriberObj[0]?.subscription_type ||
                        "-"
                        }}</span>
                    </div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Subscription Fee
                      </p>
                      <!-- <span>{{ description_sub?.subscription_desc }} </span> -->
                      <span>
                        {{description_sub?.agg_text ?description_sub?.agg_text:('Fixed Fee of Rs.' + (publicService.mapSubscriberData?.subscription
                        ?.subscription_amount_final)) }} 
                         <!-- {{
                        publicService.mapSubscriberData?.subscription
                        ?.subscription_amount_final
                        }} -->
                        <!-- {{subscriptionFee}} -->
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        SEBI Registration
                      </p>
                      <span>INA300003772</span>
                    </div>
                    <br />
                    <h5 style="margin-left: 2%; visibility: hidden">A</h5>

                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Subscription Start Date
                      </p>
                      <span>{{
                        subscriptionData?.subscriberObj[0]?.start_date
                        | date : "dd MMM yyyy"
                        }}</span>
                    </div>

                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Subscription End Date
                      </p>
                      <span>Until cancelled</span>
                    </div>

                    <div>
                      <p style="color: #81818c; font-size: 14px" class="grey">
                        Advance Fee ({{
                        this.getMonths(
                        subscriptionData?.subscriberObj[0]
                        ?.subscription_type || "-"
                        )
                        }})
                      </p>
                      <span>
                        <!-- ₹ -->
                        {{
                        publicService.mapSubscriberData?.subscription
                        ?.subscription_amount_final
                        }}/-</span>
                    </div>
                  </div>
                </div>
                <p style="text-align: end; margin-top: 2%; margin-right: 2%">
                  <span style="color: #81818c; font-size: 12px !important">
                  </span>
                </p>
                <section style="
                    margin-top: 2%;
                    margin-left: 2%;
                    margin-right: 2%;
                    padding-bottom: 3%;
                    border-top: 1px solid rgb(236, 236, 236);
                  ">
                  <h5 style="margin-left: 0%; margin-top: 2%; font-size: 16px">
                    RISK PROFILING
                  </h5>
                  <h5 style="margin-left: 0%; font-size: 16px">
                    {{ user?.attributes?.risk_profile }}
                  </h5>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      Age
                    </p>
                    <span>{{ questions[0]?.ans || "-" }} years</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      What is your investment objective?
                    </p>
                    <span>{{ questions[1]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      What is your annual income?
                    </p>
                    <span>{{ questions[2]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      What percentage of your income goes in repayment of
                      existing liabilities like bank loans etc. ?
                    </p>
                    <span>{{ questions[3]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      Pick a possible outcome for your investment
                    </p>
                    <span>{{ questions[4]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      What percentage of your total wealth are you planning to
                      invest?
                    </p>
                    <span>{{ questions[5]?.ans || "-" }}</span>
                  </div>
                  <div>
                    <p style="color: #81818c; font-size: 14px" class="grey">
                      Select all the investments you currently hold
                    </p>
                    <span>{{ questions[6]?.ans || "-" }}</span>
                  </div>
                </section>
              </section>
              <br />
              <br />

              <section style="margin-left: 5%; margin-right: 5%">
                <div>
                  <p>
                    In this Agreement, unless the context otherwise requires,
                    the Client and the Investment Adviser shall each be
                    individually referred to as a “Party” and shall be
                    collectively referred to as the “Parties”.
                  </p>
                </div>

                <br />
                <br />
                <div>
                  <ul style="margin-top: -2% !important;padding-left: 0!important;">
                    WHEREAS:

                    <li style="margin-left: 32px;">
                      The Client seeks to appoint advisor(s) to provide certain
                      investment advisory and other related services in relation
                      to the curated stock portfolios of securities and has
                      requested the Investment Adviser to render investment
                      advisory services to it at his/her/ its risk.
                    </li>
                    <li style="margin-left: 32px;">
                      The Investment Adviser has agreed to be appointed as the
                      Investment Adviser to the Client in accordance with the
                      terms of this Agreement and SEBI (Investment Advisers)
                      Regulations, 2013 as amended from time to time (“IA
                      Regulations”) to provide with investment advisory services
                      on a non-binding and non-exclusive basis and in a manner
                      solely determined by itself.
                    </li>
                  </ul>
                </div>
                <br />
                <div>
                  <p>
                    <span>1. REPRESENTATIONS AND WARRANTIES BY THE PARTIES</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">The Parties hereto represent, warrant, and covenant to
                      each other that:</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.1 Each of the Parties are duly formed and validly
                      existing under the respective laws that they are subject
                      to with full power and authority to conduct the business
                      as contemplated in this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.2 Each Party has full power, capacity and authority to
                      execute, deliver and perform this Agreement and has taken
                      all necessary action (corporate, statutory or otherwise)
                      to authorize the execution, delivery and performance of
                      this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.3 This Agreement and each other agreement executed in
                      connection herewith, if any, have been duly executed and
                      delivered by each Party and constitute legal, valid and
                      binding obligations of such Party, enforceable against the
                      other Party in accordance with the terms.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.4 Each Party has obtained and complied with all
                      clearances, permissions, approvals, conditions and
                      notices, that are or have been required, for the due
                      execution and delivery of, and performance under this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p><span class="c2">1.5 Client Undertaking</span></p>
                  <p>
                    <span class="c2">The Client understands and consents that it/he/she:</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.5.1 have read and understood the terms and conditions
                      of investment advisory services provided by the Investment
                      Adviser and also understood the fee structure and
                      mechanism for charging and payment of fees as under this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.5.2 wants to avail the investment advisory services
                      only for himself / herself and not for any other
                      person.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.5.3 have, based on its written request to the
                      Investment Adviser, been provided the opportunity by the
                      Investment Adviser to ask questions and interact with
                      &lsquo;person(s) associated with the investment
                      advice.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">1.5.4 has read the terms and conditions of Investment
                      Advisory services provided by the Investment Adviser along
                      with the fee structure and mechanism for charging and
                      payment of fee. Further, the Investment Adviser based on
                      the Client&rsquo;s request in writing provided the Client
                      an opportunity to ask questions and interact with
                      person(s) associated with the investment advice.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.5.5 shall furnish any and all information as reasonably
                      requested by the Investment Adviser for the purpose of
                      risk profiling process. The risk profile as created by the
                      Investment Adviser shall be final and binding on the
                      Client and the Client consents to the Investment Adviser
                      utilizing such information for the purpose of rendering
                      investment advice services to the Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6 Representations by the Investment Adviser</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.1 The Investment Adviser shall ensure that it has
                      appointed personnel of appropriate qualifications and
                      experience to perform the services in order to fulfil its
                      obligations under this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.2 Investment Adviser shall neither render any
                      investment advice nor charge any fee until the Client has
                      signed this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.3 The Investment Adviser represents and warrants that
                      it shall only recommend direct implementation of advice
                      i.e. through direct schemes/direct codes where no
                      consideration (including any embedded/indirect/in kind
                      commission or referral fees by any name) is received
                      directly or indirectly by the Investment Adviser or his
                      /her family.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.4 Investment Adviser shall not manage funds and
                      securities on behalf of the client and that it shall only
                      receive such sums of monies from the client as are
                      necessary to discharge the client&rsquo;s liability
                      towards fees owed to the Investment Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.5 Investment Adviser shall not, in the course of
                      performing its services to the Client, hold out any
                      investment advice implying any assured returns or minimum
                      returns or target return or percentage accuracy or service
                      provision till achievement of target returns or any other
                      nomenclature that gives the impression to the Client that
                      the investment advice is risk free and/or not susceptible
                      to market risks and or that it can generate returns with
                      any level of assurance.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.6 The Investment Adviser represents and warrants that
                      it is carrying on its activities on an arms-length
                      relationship between its activities as an Investment
                      Adviser and other activities and such arm&rsquo;s length
                      relationship shall be maintained while the existence of
                      this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.7 The Investment Adviser represents and warrant that
                      it is carrying on its activities independently, at an
                      arms-length basis with its related parties. Disclosure of
                      conflicts of interests, if any, shall be made by the
                      Investment Adviser to the Client in a prompt manner.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.8 The Investment Adviser represents and warrants that
                      all appropriate registrations permissions and approvals
                      which are statutorily required, have been validly
                      maintained and shall continue to be in force as required
                      for the performance of the Investment Adviser&rsquo;s
                      obligations under this Agreement.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">1.6.9 The Investment Adviser shall not derive any direct
                      or indirect benefit out the Client&rsquo;s securities
                      and/or investment products.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">1.6.10 The Investment Adviser shall ensure that it will
                      take all consents and permissions from the Client prior to
                      undertaking any actions, including but not limited to
                      implementation services in relation to the securities or
                      investment products advised by the Investment Adviser, in
                      a form and manner as under the IA Regulations.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.11 The Investment Adviser represents and warrants
                      that it shall not provide any distribution services to the
                      Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.12 The Investment Adviser represents and warrants
                      that its family/group companies shall not provide
                      distribution services to the Client advised by the
                      Investment Adviser, for securities and investment
                      products.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.13 The Investment Adviser represents and warrants
                      that its family/ group shall not provide investment
                      advisory services to the Client who receives distribution
                      services from the other family members of the Investment
                      Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.14 The Investment Adviser represents and warrants
                      that it shall not provide investment advisory services,
                      for securities and investment products, to a Client who is
                      receiving distribution services from its family members/
                      group.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">1.6.15 The investment adviser represents and warrants
                      that it shall maintain client records and data as mandated
                      under the securities and exchange board of India
                      (Investment Adviser) Regulations 2013.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>For the purposes of this Agreement, </span><span class="c4 c7">&ldquo;family members and
                      group&rdquo; shall have the meaning ascribed to it under the IA
                      Regulations.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>2. SCOPE OF SERVICES</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.1 The Client hereby engages the services of the
                      Investment Adviser and the Investment Adviser hereby
                      agrees, as an independent contractor and on a principal to
                      principal basis, to provide the curated portfolio
                      services.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.2 The services rendered by the Investment Adviser shall
                      take into account the risk capacity and risk aversion
                      determined through a proper risk profiling process and
                      accepted by the Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.3 Notwithstanding anything herein contained to the
                      contrary, the Parties hereby agree that the services to be
                      rendered by the Investment Adviser to the Client are
                      merely recommendatory, non-binding in nature.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.4 The Parties acknowledge that the Investment Adviser
                      will not assume any management responsibilities in
                      connection with the services. Further, the Investment
                      Adviser will not be responsible for the use or
                      implementation of the output of the services provided
                      pursuant to this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.5 The Investment Adviser shall use its best judgment
                      and efforts in rendering advice to the Client under this
                      Agreement and in the performance of all its powers and
                      duties under this Agreement.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">2.6 It is hereby expressly understood and confirmed by
                      the Client and the Investment Adviser that notwithstanding
                      any other provision of this Agreement, neither the
                      Investment Adviser nor any of its directors or employees
                      shall have the power or authority whatsoever to:</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.6.1 Bind or commit the Client in relation to any
                      contract or any trade or other preliminary or ancillary
                      agreement relating thereto.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.6.2 Represent the Client in any way, including without
                      limitation, in any negotiations relating to the purchase,
                      acquisition, sale or transfer of any investments.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.6.3 Buy or sell any securities on behalf of the
                      Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">2.7 The services to be provided by the Investment Adviser
                      shall be subject to the activities permitted under the
                      Securities and Exchange Board of India (Investment
                      Advisers) Regulations, 2013. The Investment Adviser shall
                      act in a fiduciary capacity towards the Client at all
                      times.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>3. FUNCTIONS AND DUTIES OF THE INVESTMENT ADVISER</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.1 The Investment Adviser, in relation to providing
                      services to the Client, undertakes to always abide by the
                      IA Regulations (including compliance requirements under
                      the IA Regulations) and rules, circulars and notifications
                      issued there under from time to time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.2 The Investment Adviser undertakes to always abide by
                      the eligibility criteria as under the IA
                      Regulations.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.3 The Investment Adviser shall provide a risk
                      assessment procedure to client including determination of
                      risk capacity and risk aversion levels</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"> 3.4 The Investment Adviser shall provide reports in
                      relation to potential and current investments.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.5 The Investment Adviser shall, in relation to
                      each Client maintain, know your client, advice, risk
                      assessment, analysis reports of investment advice and suitability,
                      terms and conditions document, rationale of advice,
                      related books of accounts and a register containing list
                      of clients along with dated investment advice in
                      compliance with the IA Regulations.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.6 The Investment Adviser shall conduct compliance
                      audits with respect to itself to ensure that it is in
                      compliance with the IA Regulations in a form and manner as
                      may be prescribed under the IA Regulations from time to
                      time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.7 The Investment Adviser shall ensure there is adequate
                      compliance and monitoring processes in place for the
                      purposes of client segregation in a form and manner as may
                      prescribed under the IA Regulations from to time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">3.8 The Investor Advisor undertakes to abide by the code
                      of conduct as specified under the IA Regulations and such
                      shall be deemed to incorporated within this Agreement by
                      reference.</span>
                  </p>

                  <p></p>
                  <p><span>4. ADVISORY FEE</span></p>

                  <p></p>
                  <p>
                    <span class="c2">4.1 The Client shall pay the Investment Adviser by way of
                      remuneration for its services such fees as specified at
                      the beginning of the agreement in accordance with the IA
                      Regulations and relevant circulars issued there
                      under</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.2 In case, the Investment adviser is charging the fee
                      on a percentage of AUA basis, the Investment Adviser shall
                      refer the Client&rsquo;s holding statement as referred to
                      in point 4.3 below for the calculation of the fee</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.3 In case, the Investment Adviser is charging on a
                      percentage of AUA basis, the Client shall bring to the
                      notice of the Investment Adviser any discrepancy in the
                      holding statement shared by the Investment Adviser within
                      7 days from the date of the statement, failing which it
                      shall be presumed that the Client has confirmed its
                      completeness and correctness. The Client agrees to provide
                      the Investment Adviser with necessary supporting documents
                      related to the AUA, as and when required</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.4 The client shall pay the fee within 7 days from
                      receiving the invoice from the Investment Adviser on the
                      registered email id.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.5 The Investment Adviser shall send a receipt
                      evidencing payment of Advisory Fees by the Client to the
                      Client&rsquo;s registered email address.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.6 The Advisory Fee shall be paid by the Client to the
                      Investment Adviser by depositing the same in the bank
                      account of the Investment Adviser as may be notified by
                      the Investment Adviser. The Advisory Fee shall not be
                      accepted in cash by the Investment Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.7 The Investment Adviser confirms that the fees shall
                      at all times be calculated and charged in accordance with
                      IA Regulations and circulars issued thereunder</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.8 The Client shall be additionally charged all taxes as
                      may be applicable or as may be levied in relation to the
                      consideration payable to the Investment Adviser. It is
                      hereby clarified that the Investment Adviser shall be
                      responsible for payment of income tax and similar levies
                      payable by it.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">4.9 A sample illustration for calculation of fee is given
                      below</span>
                  </p>
                  <p>
                    <span class="c2">Formula for the calculating the fee on percentage of AUA
                      basis has been explained below:</span>
                  </p>
                  <p>
                    <span class="c2">(Average daily AuA in the month) * (Number of invested
                      days in the month/360) * Subscription fee (in %) Sample
                      calculation: 100,000 (Avg. daily AuA) * (30/360) *.02
                      (subscription fee as %) = INR 166.67 Fixed fee will be charged as a fixed amount during the
                      subscription at the beginning of the subscription</span>
                  </p>
                  <p></p>
                  <p><span class="c2">4.10 Below
                      are the SEBI guidelines for advisory fees as specified in
                      the SEBI circular dated 23rd Sep 2020.</span></p>

                  <p></p>
                  <p>
                    <span class="c2">Fees Regulation 15 A of the amended IA Regulations
                      provide that Investment Advisers shall be entitled
                      to</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">charge fees from a client in the manner as specified by
                      SEBI, accordingly Investment Advisers shall charge fees
                      from the clients in either of the two modes:</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">(A) Assets under Advice (AUA) mode</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">a. The maximum fees that may be charged under this mode
                      shall not exceed 2.5 percent of AUA per annum per client
                      across all services offered by IA.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">b. IA shall be required to demonstrate AUA with
                      supporting documents like demat statements, unit
                      statements etc. of the client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">c. Any portion of AUA held by the client under any
                      pre-existing distribution arrangement with any entity
                      shall be deducted from AUA for the purpose of charging fee
                      by the IA.</span>
                  </p>
                  <p></p>
                  <p><span class="c2">(B) Fixed fee mode</span></p>
                  <p></p>
                  <p>
                    <span class="c2">The maximum fees that may be charged under this mode
                      shall not exceed INR 1,25,000 per annum per client across
                      all services offered by IA.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">General conditions under both modes</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">a. IA shall charge fees from a client under any one mode
                      i.e. (A) or (B) on an annual basis. The change of mode
                      shall be effected only after 12 months of on boarding/last
                      change of mode.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">b. If agreed by the client, IA may charge fees in
                      advance. However, such advance shall not exceed fees for 2
                      quarters.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">c. In the event of pre-mature termination of the IA
                      services in terms of agreement, the client shall be
                      refunded the fees for unexpired period. However, IA may
                      retain a maximum breakage fee of not greater than one
                      quarter fee.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>5. INVESTMENT OBJECTIVES AND GUIDELINES AND RISK
                      FACTORS</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">5.1 Type of securities &ndash; curated portfolio services
                      provided by the investment adviser under this agreement
                      would be based on stocks and ETFs listed on the Indian
                      Stock Exchanges.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">5.2 Strategy &ndash; recommendation follows a curated
                      portfolio approach where the portfolio composition is
                      reviewed at a fixed frequency based on various
                      quantitative, technical or fundamental factors to
                      determine the portfolio composition for the next
                      period.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">5.3 Tax Aspects &ndash; equity component of the portfolio
                      would be subjected to short term/long term capital gains
                      tax depending on the holding period of the security. If
                      the holding period is less than one year, a 15% short term
                      capital gains tax would be applicable. If the holdings
                      period is more than one year, a 10% long term capital
                      gains tax would be applicable on portfolio gains. These
                      rates can be revised on a time to time basis by the
                      government of India. Tax liability on the ETF component of
                      the portfolio, if any, would depend on the underlying
                      asset class of the ETF. When in doubt, client shall reach
                      out to the Investment Advisor on the mentioned email id
                      for a detailed evaluation based on the trades placed by
                      the client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">5.4 Investment Objectives and Guidelines and risk factors
                      - The Client hereby agrees and understands that investment
                      advice made by the Investment Adviser are subject to
                      various market, currency, economic, political and business risks including but not limited to price and
                      volume volatility in the stock markets, interest rates,
                      currency exchange rates, foreign investments, changes in
                      government policies, taxation, political, economic,
                      pandemic, or other developments and closure of the stock
                      exchange</span>
                  </p>

                  
                  <p></p>
                  <p>
                    <span>6. NO RIGHT TO SEEK POWER OF ATTORNEY</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">The Investment Adviser hereby declares and confirms that
                      it shall not seek any power of attorney or authorizations
                      from the Client for implementation of investment
                      advice.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>7. SERVICES NOT EXCLUSIVE</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">The services of the Investment Advisor are not exclusive
                      to the Client. The Investment Advisor and any shareholder,
                      employee, director or agent of the Investment Advisor may
                      render similar services to others and engage in additional
                      activities, without any intimation to, or consent of the
                      Client. Provided however that the Investment Advisor as
                      well as any of its directors, employees of associate
                      concerns shall avoid any conflict of interest in relation
                      to the advisory services provided.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">In the event that such a conflict of interest does arise,
                      the Investment Adviser shall declare such conflict and, if
                      reasonably possible, ensure that fair treatment on an
                      arm&rsquo;s length basis as reasonably determined by the
                      Investment Adviser in its sole discretion shall be
                      accorded to the Client. For the avoidance of doubt, the
                      Investment Advisor may, from time to time have business
                      relationships with companies or corporations in relation
                      to which advisory services have been provided to the
                      Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>8. DURATION AND TERMINATION</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.1 The Agreement shall remain in force for the
                      period mentioned at the beginning of this agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.2 If the client is on an auto-renew plan, the
                      agreement will remain in force until the subscription is
                      canceled by the client or advisor in accordance with this
                      agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.3 This Agreement may be terminated by mutual agreement
                      of the Client and the Investment Adviser by giving a 30
                      days prior written notice.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.4 The Agreement may be immediately terminated by the
                      Investment Adviser: (i) if the Client breaches any
                      material term of this Agreement; or (ii)if the Client is
                      admitted into liquidation (except a voluntary liquidation
                      for the purpose of reconstruction or amalgamation) or
                      commits any act of bankruptcy or if a receiver is
                      appointed in respect of any assets of the Client.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.5 If the Investment Adviser ceases to hold statutory
                      licenses and/or registrations required to provide services
                      as contemplated under this Agreement then the Client shall
                      have the ability to terminate this Agreement without any
                      further consequences.</span>
                  </p>

                  <p></p>
                  <p>
                    <span class="c2">8.6 If the Government or any regulatory body has taken
                      any action on the Investment Adviser then the Client shall
                      have the right to immediately terminate this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"
                      > 8.7 The agreement will be terminated automatically
                      upon the death of the Client.</span
                    >
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"
                      >8.8 Upon the termination of the Agreement due to
                      reasons under Clause 8.3, 8.4, 8.5,8.6 and 8.7, the
                      Investment Adviser shall refund any balance of the advisory fees for
                      which services have not been provided within 30 business
                      days from the date of termination of this agreement.</span
                    >
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">8.9 Any Advisory Fees that have accrued in the manner set
                      out in this Agreement up to the date of the termination
                      shall be paid by the Client to the Investment Adviser
                      within Thirty (30) business days from the date of
                      termination of this Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>9. LIMITATION OF LIABILITY OF THE INVESTMENT
                      ADVISER</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">9.1 The Investment Adviser shall not be liable towards
                      the Client by reason of any loss, which a Client may
                      suffer by reason of any depletion in the value of the
                      investment and/or &lsquo;assets under advice&rsquo;, which
                      may result by reason of fluctuation in asset value, or by
                      reason of non-performance or under-performance of the
                      securities/funds or any other market conditions.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>For the purposes of this Agreement, </span><span class="c4 c7">&ldquo;Assets Under
                      Advice&rdquo; </span><span class="c2">shall have the meaning ascribed to under the IA
                      Regulations.</span>
                  </p>
                  <p></p>
                  <p><span>10. NOTICE</span></p>
                  <p></p>
                  <p>
                    <span class="c2">10.1 Any notice, instruction, recommendation or other
                      communication to be given hereunder shall be in writing
                      and delivered by e-mail addressed to the Party for which
                      it is intended A communication sent by e-mail shall be
                      deemed to have been received at the close of business on
                      the day on which it is sent In providing service by
                      e-mail, it shall be sufficient to show that the email was
                      properly addressed to the intended recipient.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">10.2 Whenever, pursuant to any provision of this
                      Agreement, any notice, instruction, recommendation or
                      other communication is given to either Party, the Client
                      or the Investment Adviser (as the case may be) may accept
                      as sufficient evidence thereof a document signed or
                      purporting to be signed by such person or persons as shall
                      be authorised from time to time in that behalf by the
                      Client or the Investment Adviser (as the case may
                      be).</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">10.3 Whenever, pursuant to any provision of this
                      Agreement, any notice, instruction, recommendation or
                      other communication is given to either Party, the Client
                      or the Investment Adviser (as the case may be) may accept
                      as sufficient evidence thereof a document signed or
                      purporting to be signed by such person or persons as shall
                      be authorised from time to time in that behalf by the
                      Client or the Investment Adviser (as the case may
                      be).</span>
                  </p>
                  <p></p>
                  <p><span>11. ASSIGNMENT</span></p>

                  <p></p>
                  <p>
                    <span class="c2">This Agreement may not be assigned by either Party
                      without the written consent of the other Party.</span>
                  </p>

                  <p></p>
                  <p><span>12. WHOLE AGREEMENT</span></p>

                  <p></p>
                  <p>
                    <span class="c2">This Agreement together with any document annexed hereto
                      or referred to herein constitutes the entire Agreement
                      between the Parties in relation to the subject matter
                      hereof and supersedes all prior understandings,
                      arrangements, representations, proposals or communications
                      between the Parties whether written or oral.</span>
                  </p>
                  <p></p>
                  <p><span>13. SEVERABILITY</span></p>
                  <p></p>
                  <p>
                    <span class="c2">In the event that any term, condition or provision of
                      this Agreement is held to be in a violation of any
                      applicable law, statute or regulation the same shall be
                      deemed to be deleted from this Agreement and shall be of
                      no force and effect and this Agreement shall remain in
                      full force and effect as if such term, condition or
                      provision had not originally been contained in this
                      Agreement. Notwithstanding theforegoing in the event of
                      such deletion the Parties shall negotiate in good faith in
                      order to agree on the terms of a mutually acceptable and
                      satisfactory alternative provision in place of the
                      provision so deleted.</span>
                  </p>
                  <p></p>
                  <p>
                    <span>14. GRIEVANCE REDRESSAL AND DISPUTE RESOLUTION</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">14.1 In the event any Client has any grievances in
                      relation to the provision of investment advice and related
                      services by the Investment Adviser, it shall be the
                      responsibility of the Investment Adviser to resolve the
                      grievances promptly but not later than a period of thirty
                      (30) days from the date such grievance or such time period
                      as may be prescribed by SEBI from time to time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">14.2 In the event of any dispute arising between the
                      Parties in relation to this Agreement, the Parties shall
                      in the first instance attempt to resolve such dispute by
                      mutual discussions. If the dispute is not resolved through
                      mutual consultations within thirty (30) calendar days
                      after one Party has served written notice on the other
                      Party requesting the commencement of such discussions,
                      either Party may in writing demand that the dispute be
                      finally settled by the arbitration of one (1) arbitrator,
                      who shall be appointed based on the mutual agreement of
                      the Parties. The arbitration shall be conducted in
                      accordance with the Arbitration and Conciliation Act, 1996
                      and the rules thereunder, as may be amended from time to
                      time.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"> 14.3 &nbsp;The language of arbitration shall be
                      English.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"> 14.4 &nbsp;The arbitration award shall be final and
                      binding upon the Parties. Each Party shall co-operate in
                      good faith to expedite (to the maximum extent practicable) the conduct
                      of any arbitral proceedings commenced under this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">14.5 The costs and expenses of the arbitration,
                      including, the fees of the third arbitrator, shall be
                      borne equally by each Party to the dispute or claim and
                      each Party shall pay its own fees, disbursements and other
                      charges of its counsel and the arbitrators nominated by
                      it.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">14.6 The arbitrator would have the power to award
                      interest on any sum awarded pursuant to the arbitration
                      proceedings and such sum would carry interest, if awarded,
                      until the actual payment of such amounts. Any award made by the arbitrators shall be final and
                      binding on
                      each of the Parties that were parties to the
                      dispute.</span>
                  </p>

                  <p></p>
                  <p><span>15. FORCE MAJEURE</span></p>
                  <p></p>
                  <p>
                    <span class="c2">The Investment Adviser shall not be liable for delays or
                      errors occurring by reason of circumstances beyond its
                      control, including but not limited to acts of civil or
                      military authority, national emergencies, work stoppages,
                      fire, flood, catastrophe, acts of God, insurrection, war,
                      riot, or failure of communication or power supply. In the
                      event of equipment breakdowns beyond its control, the
                      Advisor shall take reasonable steps to minimize service
                      interruptions but shall have no liability with respect
                      thereto.</span>
                  </p>
                  <p></p>
                  <p><span>16. MISCELLANEOUS</span></p>
                  <p></p>
                  <p>
                    <span class="c2">16.1 The Investment Adviser shall not seek any power of
                      attorney or authorizations from the Client for automatic
                      implementation of investment advice and nothing in this
                      Agreement shall be construed as conferring a power of
                      attorney or such rights by the Client on the Investment
                      Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.2 Any amendment to the terms hereof shall be effective
                      only if agreed to in writing between the Client and the
                      Investment Adviser.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.3 No failure on the part of any Party to exercise, and
                      no delay in exercising, any right or remedy under this
                      Agreement will operate as a waiver thereof nor will any
                      single or partial exercise of any right or remedy preclude
                      any other or further exercise thereof or the exercise of
                      any other right or remedy. The rights and remedies
                      provided in this Agreement are cumulative and not
                      exclusive of any rights or remedies provided by law.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.4 The illegality, invalidity or unenforceability of
                      any provision of this Agreement under the laws of any
                      jurisdiction shall not affect its legality, validity or
                      enforceability under the laws of any other jurisdiction
                      nor the legality, validity or enforceability of any other
                      provision.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.5 Other than as specifically permitted under this
                      Agreement, the Client shall not publish, disseminate or
                      broadcast advertisements, circulars or other publicity
                      material referring to the other Party without the prior
                      consent of such Party, which shall not be unreasonably
                      withheld.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.6 Nothing herein contained shall be deemed to create
                      or constitute a partnership between the Parties hereto.
                      This Agreement may only be varied with the written
                      agreement of both Parties. This Agreement may be entered
                      into in any number of counterparts, each of which when
                      executed and delivered shall be an original.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">16.7 No Person who is not a party to this Agreement
                      shall have any right to enforce the terms of this
                      Agreement.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2"> 16.8 &nbsp;Each party agrees to perform such further
                      actions and execute such further agreements as are
                      necessary to effectuate the purposes hereof.</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c4 c7">17. DISCLOSURES BY THE INVESTMENT ADVISER</span>
                  </p>
                  <p></p>
                  <p>
                    <span class="c2">17.1 The Client agrees and confirms that the Service
                      Provider may appoint agents and or third-party vendors for
                      carrying out the acts mentioned in or in relation to
                      rendering its services. The Client consents to sharing of
                      his/its account related information to such authorised
                      agents / third party vendors appointed by the Service
                      Provide.</span>
                  </p>
                  <p>
                    <span class="c2">17.2 Investment in securities market are subject to
                      market risks. Read all the related documents carefully
                      before investing. Registration granted by SEBI, membership
                      of BASL (in case of IAs), and certification from NISM in
                      no way guarantee performance of the intermediary or
                      provide any assurance of returns to investors.</span>
                  </p>
                  <p></p>
                  <p><span class="c2">18. ARBITRATION</span></p>
                  <p></p>
                  <p>
                    <span class="c2">In the event of any dispute or differences relating to,
                      arising from, or connected with this Agreement, the
                      Parties may refer such dispute or difference for
                      resolution by arbitration in accordance with the
                      provisions as may be specified under the Arbitration and
                      Conciliation Act, 1996 and the rules there under as may be
                      amended from time to time and the award of such arbitrator
                      shall be final and binding on both the Parties. The
                      arbitration shall be conducted in English. The venue of
                      arbitration shall be Kolkata, India.</span>
                  </p>
                  <p></p>
                  <p><span class="c2">19. INDEMNITY</span></p>
                  <p></p>
                  <p>
                    <span class="c2">The Investment Adviser shall provide Services to the best
                      of its knowledge and ability and shall not, under any
                      circumstances, be liable or responsible for any damage,
                      loss or other consequences, if any, arising from any act,
                      deed, matter or thing done or caused to be done by the
                      Investor upon the advice or recommendations of the
                      Investment Adviser, or upon any implementation of the
                      Models as instructed by the Investor, except in the case
                      of gross misconduct, gross negligence, fraud, willful
                      misconduct, or acting in without appropriate authority or
                      acting without any prudent and diligent manner.</span>
                  </p>

                  <p></p>
                  <p><span class="c2">20. RISK FACTOR</span></p>
                  <p></p>
                  <p>
                    <span class="c2">1. The Investor has the financial ability to bear the
                      economic risk of the investments that it makes, including
                      any investments made as a result of the Services rendered
                      by the Investment Adviser hereunder, has adequate means
                      for providing for its current requirements and possible
                      contingencies, and possesses such degree of financial
                      sophistication so as to understand the investment
                      recommendations, advice and Models provided by the
                      Investment Adviser hereunder.</span>
                  </p>

                  <p></p>
                  <p>
                    <span>21. DETAILS OF INVESTMENT ADVISER</span>
                  </p>
                  <p>
                    <span>Name of Investment Adviser as registered with SEBI: </span><span class="c4 c7"><strong>Lamron
                        Analysts Pvt. Ltd.</strong></span><br /><br /><span> Type of Registration- </span><span
                      class="c4 c5"><strong>Non-Individual</strong></span>
                  </p>
                  <p>
                    <span>Registration number: </span><span class="c4 c7"><strong>INA300003772</strong></span><span>,
                      validity of registration: </span><span class="c4 c7"><strong>Until Cancelled</strong>
                    </span><br /><br /><span>Complete address with telephone numbers: </span><span
                      class="c4 c7">109/40B, Hazra Road, Kolkata - 700026 </span><br /><br /><span>Telephone No.
                    </span><span>033-24740657</span>
                    <br /><br /><span>CIN:</span><span>U74140WB2000PTC091710</span>
                    <br /><br /><span>BASL ID: </span><span>1322</span>
                  </p>
                  <p>
                    <span>Contact details of the Principal Officer &ndash; Name: </span><span>Soumitra Sengupta</span>
                  </p>
                  <p>
                    <span>Contact no: </span><span class="c4 c7">9831657426, </span>
                    <span>Email id : </span><span class="c4 c7">soumitra.sengupta@lamronanalysts.com </span>
                    <br>
                    <span>Contact details of the Compliance Officer - Name:&nbsp; </span><span class="c4 c7">Prabhas
                      Chandra Ray</span>
                    <br>
                    <span>Contact no: </span><span class="c4 c7">7003362380, &nbsp; </span>

                    <span>Email id : &nbsp; </span><span class="c4 c7"> prabhas.rsay@lamronanalysts.com</span>
                    <br>
                    <span>Contact details of the Grievance Officer - Name:&nbsp; </span><span class="c4 c7">Prabhas
                      Chandra Ray</span> <br>
                    <span>Contact no: </span><span class="c4 c7">7003362380, &nbsp; </span>

                    <span>Email id : &nbsp; </span><span class="c4 c7"> prabhas.ray@lamronanalysts.com</span>
                    <br>
                    <span>Corresponding SEBI regional/local office address: </span><span class="c4 c7">L&amp;T Chambers,
                      3rd Floor</span><span class="c2">,</span>
                  </p>
                  <p>
                    <span><strong>16 Camac Street, Kolkata </strong> - 700017</span>
                  </p>

                  <p class="c0 c10"><span></span></p>
                </div>
              </section>
            </div>
          </div>
          <div style="text-align: center">
            <button class="theme-btn primary-btn mb-20 mt-10 text-center" (click)="submit()">
              Accept Agreement
            </button>
          </div>
        </div>
      </ng-container>

      <div class="scroll_down">* Scroll down to accept the agreement.</div>
      <form #myForm method="post" [action]="paymentUrl" ngNoForm hidden="true">
        <input name="encRequest" [(ngModel)]="paymentData.encRequest" />
        <input name="access_code" [(ngModel)]="paymentData.access_code" />
        <button type="submit"></button>
      </form>
    </div>
  </div>
</div>