import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Load } from 'src/app/modules/dashboard/store/actions/dashboard.action';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import {
  PouchState,
  selectPouchState,
} from 'src/app/modules/investment-pouches/store/pouch.state';
import { PublicFacadeService } from '../../state/public.facade.services';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
  pouches: any;

  getState: Observable<any>;
  defaultBody: { sort: any; current_date: any };
  selectedSort: any;
  uploadedDate: any;
  pouchId: any;
  subscriptionsList: any;
  subScriptionData: { amount: any; frequency: string };
  min_investment: any;
  bannerDetails: {
    pouch_name: any;
    pouch_description: any;
    pouch_icon: any;
    total_stocks: any;
    pouch_review_freq: any;
    pouch_start_date: any;
    pouch_last_review_date: any;
    pouch_next_review_date: any;
    pouch_riskProfile: any;
    min_investment: any;
    subsription_fee: { amount: any; frequency: string };
    annualizized_returns: number;
  };

  constructor(
    private store: Store<PouchState>,
    public route: ActivatedRoute,
    public publicFacadeServices: PublicFacadeService,
    public pouchService: PouchService
  ) {
    this.getState = this.store.select(selectPouchState);
    this.pouchId = this.route.snapshot.paramMap.get('pouchId');
  }
  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.getState.subscribe((state) => {
        let statePouches = state.pouches;

        if (
          statePouches &&
          !(statePouches.error && statePouches.error.message != '')
        ) {
          this.pouches = [...statePouches]?.find(
            (ele: any) => ele.id == Number(this.pouchId)
          );
        }
      });
      this.defaultBody = {
        sort: this.selectedSort,
        current_date: localStorage
          .getItem('stock_price_uploaded_date')
          ?.split('T')[0],
      };
      if (!this.pouches) {
        this.store.dispatch(new Load(this.defaultBody));
      }
    } else {
      this.publicFacadeServices.LoadPouchListfromState$.subscribe((list) => {
        this.pouches = list.find((ele: any) => ele.id == Number(this.pouchId));
      });
    }
  }
}
