<div class="timer">    
    <div class="close">
        <img (click)="cancelClickHandle($event)" src="../../../../assets/images/icons/close-icon.png" />
    </div>
    <div class="timer-container">
        <img  src="../../../../assets/images/icons/timer.png" />
        <p>
            There has been no activity for a long time. <br/>
            In <span [innerHtml]="count"></span> seconds, your session will end and you will be logged out
        </p>
    </div>
    <div mat-dialog-actions class="col-md-6 m-auto">
        <button class="ml-20" mat-button cdkFocusInitial mat-button color="primary" 
        class="mat-focus-indicator theme-btn primary-btn w-100 mat-button mat-button-base"
        (click)="cancelClickHandle($event)">Keep me logged in</button>
    </div>
</div>