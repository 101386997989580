import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-sucess-popup',
  templateUrl: './sucess-popup.component.html',
  styleUrls: ['./sucess-popup.component.scss'],
})
export class SucessPopupComponent {
  getState: Observable<any>;
  userInfo: any;
  pouchState: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SucessPopupComponent>,
    public router: Router
  ) {}



  close(val: any) {
    console.log(this.data);
    if (val) {
      this.dialogRef.close();
      this.router
        .navigateByUrl(`/investment/map-subscriber/${this.data.id}`);
    } else {
      this.router.navigateByUrl(`/investment`);
      this.dialogRef.close();
    }
  }
}
