<div class="digi-popup-container">
  <ng-container [ngSwitch]="viewType">
    <ng-container *ngSwitchDefault>
      <div
        mat-dialog-title
        class="d-flex justify-content-between align-items-center"
      >
        <p class="darktext" style="margin-bottom: 0">
          Please enter Digilocker Access code
        </p>
        <img
          class="imageSize"
          src="../../../../../../../assets/images/icons/close-icon.png"
          alt=""
          (click)="closeDialog()"
          style="cursor: pointer"
        />
      </div>
      <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'fetchingDocuments'">
      <div mat-dialog-title class="d-flex justify-content-end">
        <img
          src="../../../../../../../assets/images/icons/close-icon.png"
          alt=""
          (click)="closeDialog()"
          style="cursor: pointer"
        />
      </div>
      <ng-container *ngTemplateOutlet="fetchingDocuments"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'unableFetchDocuments'">
      <div mat-dialog-title class="d-flex justify-content-end">
        <img
          src="../../../../../../../assets/images/icons/close-icon.png"
          alt=""
          (click)="closeDialog()"
          style="cursor: pointer"
        />
      </div>
      <ng-container *ngTemplateOutlet="unableFetchDocuments"></ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-template #default>
  <div class="flexProperty padding-property-default">
    <div class="displayPropertyDefaultField">
      <div>
        <p class="subText">Digilocker Access Code</p>
        <form [formGroup]="Form">
          <mat-form-field class="w100" appearance="outline">
            <input type="text" matInput formControlName="accessCode" />
          </mat-form-field>
        </form>
      </div>
      <div></div>
      <div class="margin-lefts">
        <button mat-button class="submitButton darktext f-16" (click)="submitAccess()">
          Submit <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #fetchingDocuments>
  <div class="flexProperty padding-property">
    <div class="mb-23">
      <img
        src="../../../../../../../assets/images/icons/fetchingDocuments.png"
        alt=""
      />
    </div>
    <div>
      <p style="width: 296px" class="darktext">
        We are fetching your documents. Please be patient...
      </p>
    </div>
  </div>
</ng-template>
<ng-template #unableFetchDocuments>
  <div class="flexProperty padding-property">
    <div class="mb-23">
      <img
        src="../../../../../../../assets/images/icons/unableFetchDocuments.png"
        alt=""
      />
    </div>
    <div>
      <p style="width: 343px" class="darktext">
        We are unable to fetch your {{getText()}}. Please proceed for
        <span style="color: #065dbc; cursor: pointer" (click)="manualUpload()">manual upload > ></span>
      </p>
    </div>
  </div>
</ng-template>
