<ng-container>
  <div class="investment-component">
    <div class="container">
      <div class="section-heading">
        <h1 class="mb-20">Investment</h1>
      </div>
      <div class="mg-10">
        <mat-form-field appearance="fill" class="formfil">
          <select matNativeControl id="mySelectId">
            <option value="value1">YTD</option>
          </select>
        </mat-form-field>
      </div>
      <div class="text-center">
        <div class="d-flex">
          <div class="col-md-4 bright">
            <div>
              <img src="assets/images/icons/clarity_briefcase-line.svg" />
              <span class="s-text">37.24 L</span>
            </div>
            <div>
              <div class="subtext">Current Investment</div>
              <div class="subset">(YTD)</div>
            </div>
          </div>
          <div class="col-md-3 bright img-style">
            <div>
              <img src="assets/images/icons/growth.svg" />
              <span class="s-text">42.24 L</span>
            </div>
            <div>
              <div class="subtext subtext2">Current Returns</div>
              <div class="subset subset3">(YTD)</div>
            </div>
          </div>
          <div class="col-md-3 img-style">
            <div>
              <img src="assets/images/icons/business_profit_icon.svg" />
              <span class="s-text s-text1">12.38%</span>
            </div>
            <div>
              <div class="subtext">Current Returns</div>
              <div class="subset">(YTD)</div>
            </div>
          </div>
        </div>
      </div>
      <div class="spac">
        <div
          class="d-flex border-top add-shadow"
          *ngFor="let item of dataSource"
        >
          <div class="w60">
            <!-- <img src="../../../../../assets/images/icons/electric_mobility.png" /> -->
            <img [src]="item.src" />
            {{ item.name }}
          </div>
          <div class="display-grid w32">
            <span class="f-16">{{ item.heading_text }}</span>
            <span class="f-12">{{ item.description }}</span>
          </div>
          <div class="display-grid f-12 w12">
            <span>Total Stocks</span>
            <span>{{ item.total_stocks }}</span>
          </div>
          <div class="display-grid f-12 w12">
            <span>Total Subscribers</span>
            <span>{{ item.total_subscribers }}</span>
          </div>
          <div class="display-grid f-12 w12">
            <span>Current investment</span>
            <span>{{ item.current_investment }}</span>
          </div>
          <div class="display-grid f-12 w12">
            <span>Curent Returns</span>
            <span>{{ item.current_returns }}</span>
          </div>
          <div class="display-grid f-12 w12">
            <span>Return Percentage</span>
            <span>{{ item.return_percent }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
