<form
  class="timeframeForm mt-20"
  [formGroup]="timeframeForm"
  (ngSubmit)="generateTimeframe()"
>
  <div class="row">
    <div class="col-lg-2 col-md-2">
      <div class="label sm-mt-15">Stock Universe</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-select formControlName="universe_name" placeholder="--Select--"   (selectionChange)="universeChange()">
          <mat-option
            value="value1"
            style="color: rgba(0, 26, 255, 1)"
            (click)="createNew()"

          >
            Create New
          </mat-option>
          <mat-divider></mat-divider>
          <mat-option [value]="n.name" *ngFor="let n of universeList">
            {{ n.name }}
            <span
              (click)="displayUniverseStockList(n.name)"
              style="float: right"
              ><img
                src="../../../.././../../assets/images/icons/eye.svg"
                alt=""
            /></span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-lg-2 col-md-2">
      <div class="label sm-mt-15">Start Date</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <input
          matInput
          [matDatepicker]="picker"
          placeholder=""
          [max]="todayDate"
          formControlName="start_date"
          (dateChange)="dateChangeHandler($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-2">
      <div class="label sm-mt-15">Timeframe Size</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <input
          type="number"
          formControlName="timeframe_size"
          min="1"
          autocomplete="off"
          matInput
          placeholder=""
          value=""
          (input)="changeTimeFrameEvent($event)"
        />
      </mat-form-field>
      <mat-error *ngIf="this.timeframeForm.controls['timeframe_size'].hasError('incorrect')">Selected universe size is {{no_of_stocks}}</mat-error>
    </div>
    <div class="col-md-2" *ngIf="!isItermediary">
      <div class="label">Lookback Type</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-select formControlName="lookback_type" placeholder="--Select--">
          <mat-option [value]="l.value" *ngFor="let l of lookBack_type">
            {{ l.name }}
          </mat-option>
          <mat-divider></mat-divider>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2" *ngIf="!isItermediary">
      <div class="label">RT</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <input
          matInput
          type="number"
          placeholder=""
          min="0"
          autocomplete="off"
          formControlName="rank_tolerance"
          value=""
        />
      </mat-form-field>
    </div>
    <div class="col-md-2" *ngIf="isPartnerAdvConfig">
      <div class="label">Timeframe Weightage</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-select formControlName="tf_wt">
          <mat-option value="DISTINCT"> Distinct </mat-option>
          <mat-option value="AVERAGE"> Average </mat-option>
          <mat-divider></mat-divider>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col-lg-2 col-md-4" *ngIf="logginUserRole !== 'subscriber'">
      <div class="label sm-mt-20">Reset Years</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-select formControlName="reset_years">
          <mat-option [value]="f" *ngFor="let f of ryears">
            {{ f }}
          </mat-option>
          <mat-divider></mat-divider>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-4">
      <div class="label sm-mt-20">Frequency of Review</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-select
          formControlName="review_freq"
          (selectionChange)="onReviewFeqChange()"
        >
          <mat-option
            [hidden]="f.name === 'DAILY' && logginUserRole !== 'admin'"
            [value]="f.name"
            *ngFor="let f of frequency_review"
          >
            {{ f.name }}
          </mat-option>
          <mat-divider></mat-divider>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-4" *ngIf="isPartnerAdvConfig">
      <div class="label sm-mt-20">Stock Selection</div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-select
          formControlName="pouch_type"
          (selectionChange)="stockSelectionChange($event)"
        >
          <mat-option value="PRICE"> Smartvalue </mat-option>
          <mat-option value="ZSCORE"> Zscore </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <div class="d-flex">
        <div
          class="mt-30"
          [ngClass]="
            isReviewFreqValue
              ? 'cursor error'
              : timeframeForm.controls['review_freq'].value !== 'DAILY'
              ? 'cursor text-dark-blue'
              : ''
          "
          (click)="openBox()"
        >
          Select Review
          {{
            timeframeForm.controls["review_freq"].value !== "WEEKLY"
              ? "Date"
              : "Day"
          }}
        </div>
        <div class="mt-30 px-2 f-14">
          {{
            timeframeForm.controls["review_freq"].value !== "DAILY"
              ? this.timeframeForm.value["review_freq_day"]
              : ""
          }}
        </div>

        <section
          class="trendedStocks_c mt-30 mx-3"
          *ngIf="logginUserRole === 'admin'"

        >
          Retain Trended Stocks
          <mat-checkbox
            [checked]="true"
            (change)="trendStockChangeEvent($event)"
          ></mat-checkbox>
        </section>
        <section
         *ngIf="isPartnerAdvConfig"
          class="trendedStocks_c mt-30 mx-3"
          [style.opacity]="logginUserRole === 'admin'||logginUserRole === 'partner'||logginUserRole === 'ria' ? 1 : 0"
        >
          Exclude non FNO stocks
          <mat-checkbox
            [checked]="false"
            (change)="excludeFNO($event)"
          ></mat-checkbox>
        </section>

      </div>
    </div>
    <!-- <div class="col-md-2">
        <div class="mt-30 f-14">{{reviewDate}}
        </div>
      </div> -->
  </div>
  <div class="row">
    <ng-container *ngIf="!isItermediary">
      <div class="col-md-12">
        <div class="label mt-20">
          Lookback periods ( Use comma to seperate multiple values)
        </div>
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-chip-list
            #chipList
            aria-label="Period selection"
            formControlName="lookback_periods"
          >
            <mat-chip
              *ngFor="let period of periods"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(period)"
            >
              {{ period.label }}
              <!-- {{ period.label }} - from <span class="text-blue" (click)="openDateDialog($event,period)">
                &nbsp;{{period.date | date:'mediumDate'}}</span> -->

              <mat-icon matChipRemove *ngIf="removable"
                ><img
                  src="../../../../../../assets/images/icons/cancel.svg"
                  alt=""
              /></mat-icon>
            </mat-chip>
            <input
              #periodInput
              formControlName="lookback_periods"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-list>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selected($event)"
          >
            <mat-option
              *ngFor="let period of filteredPeriods | async"
              [value]="period"
            >
              {{ period }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container [(ngModel)]="intermediatryCheckBox" *ngIf="isItermediary">
      <ul class="d-flex my-2">
        <li>
          <mat-checkbox
            [checked]="short"
            (change)="selectPeriod($event, 6)"
            class="example-margin px-2"
            >Long</mat-checkbox
          >
        </li>

        <li>
          <mat-checkbox
            [checked]="medium"
            (change)="selectPeriod($event, 9)"
            class="example-margin px-2"
            >Medium</mat-checkbox
          >
        </li>

        <li>
          <mat-checkbox
            [checked]="long"
            (change)="selectPeriod($event, 12)"
            class="example-margin px-2"
            >Short</mat-checkbox
          >
        </li>
      </ul>
    </ng-container>
  </div>
  <div class="row pd-5">
    <div class="col-md-3">
      <button mat-button type="submit" class="theme-btn primary-btn px-3 f-14">
        Generate Timeframe
      </button>
    </div>
  </div>
</form>
<div
  class="pt-20"
  *ngIf="recommendedStocks && !isItermediary"
  style="overflow-x: auto"
>
  <table class="table table-bordered table-responsive f-14">
    <tbody>
      <tr>
        <td scope="col" colspan="4" rowspan="2">
          <span>Recommended Stocks</span>
          <a
            (click)="onViewAll()"
            class="f-12 px-4 text-decoration-no cursor"
            >{{ viewAll ? "view selected" : "view all" }}</a
          >
          <!-- <div class="d-flex mt-2">
            <div class="radio">
              <label class="f-14 text-grey d-flex align-items-center">
                <input
                  class="mr-10"
                  type="radio"
                  name="category"
                  value="Price"
                  (change)="setradio('Price')"
                  [checked]="true"
                />
                By Price
              </label>
            </div>
            <div class="radio ml-10">
              <label class="f-14 text-grey d-flex align-items-center">
                <input
                  class="mr-10"
                  type="radio"
                  name="category"
                  value="Volume"
                  (change)="setradio('Volume')"
                />
                By Volume
              </label>
            </div>
          </div> -->
        </td>
        <td class="text-grey w-130px" colspan="4">
          <div *ngIf="pouchType === 'ZSCORE'">
            <span class="f-14 text-grey d-flex align-items-center">
              <input
                class="mx-2"
                type="checkbox"
                name=""
                id=""
                (click)="showZscore()"
              />Rank by Price
            </span>
          </div>

          <!-- <div *ngIf="isSelected('Volume')">
            <span class="f-14 text-grey d-flex align-items-center">
              <input
                class="mx-2"
                type="checkbox"
                name=""
                id=""
                (click)="showPrice()"
              />Rank by Price
            </span>
          </div> -->
        </td>
      </tr>

      <tr style="text-align: center" *ngIf="!(isValue || isZscore)">
        <td
          *ngFor="let t of sortLookBackDates(recommendedStocks?.lookback_dates)"
        >
          {{ t | date : "mediumDate" }}
        </td>
        <td class="f-14 text-grey w-130px" colspan="2" *ngIf="isAverage">
          Average
        </td>
      </tr>
      <tr style="text-align: center" *ngIf="isValue || isZscore">
        <td
          scope="col"
          colspan="2"
          *ngFor="let t of sortLookBackDates(recommendedStocks?.lookback_dates)"
        >
          {{ t | date : "mediumDate" }}
        </td>
        <td colspan="100%" class="f-14 text-grey w-130px" *ngIf="isAverage">
          Average
        </td>
      </tr>

      <tr style="text-align: center">
        <td style="text-align: start" colspan="3" scope="col">Stocks</td>

        <td class="f-14 text-grey">Weightage</td>
        <ng-container *ngFor="let t of recommendedStocks?.lookback_dates">
          <td
            class="f-14 text-grey w-130px"
            *ngIf="isValue || isSelected('Price')"
          >
            Rank by Price
          </td>

          <td
            class="f-14 text-grey w-130px"
            *ngIf="isZscore || isSelected('ZSCORE')"
          >
            Rank by ZScore
          </td>
        </ng-container>
        <td
          class="f-14 text-grey"
          *ngIf="(isValue || isSelected('Price')) && isAverage"
        >
          Rank by Price
        </td>
        <td
          class="f-14 text-grey"
          *ngIf="isAverage && (isZscore || isSelected('ZSCORE'))"
        >
          Rank by ZScore
        </td>
      </tr>

      <tr
        class="cell_value"
        style="white-space: nowrap"
        *ngFor="let t of recommendedStocks.timeframe_stocks_display"
      >
        <ng-container *ngIf="isSelected('Price') && t.stock_wt_price > 0">
          <td scope="col" colspan="3">{{ t.stock_name }}</td>

          <td class="viewData text-center">
            {{ roundOfWeightage(t.stock_wt_price) }}%
          </td>
          <!-- <td class="viewData text-center" >{{t.stock_wt_volume}}%</td> -->

          <ng-container
            *ngFor="
              let x of sortLookBackDates(recommendedStocks.lookback_dates)
            "
          >
            <td class="text-center" *ngIf="isValue || isSelected('Price')">
              {{ t.stock_ranks[x] ? t.stock_ranks[x].price_rank : "0" }}
            </td>
            <td class="text-center" *ngIf="isZscore || isSelected('ZSCORE')">
              {{ t.stock_ranks[x] ? t.stock_ranks[x].zscore_rank : "0" }}
            </td>
          </ng-container>
          <td
            class="viewData text-center"
            *ngIf="isAverage && (isValue || isSelected('Price'))"
          >
            {{
              getAverage("Price", { t: t, x: recommendedStocks.lookback_dates })
            }}
          </td>
          <td
            class="viewData text-center"
            *ngIf="isAverage && (isZscore || isSelected('ZSCORE'))"
          >
            {{
              getAverage("ZSCORE", {
                t: t,
                x: recommendedStocks.lookback_dates
              })
            }}
          </td>
        </ng-container>
        <ng-container *ngIf="isSelected('ZSCORE') && t.stock_wt_zscore > 0">
          <td scope="col" colspan="3">{{ t.stock_name }}</td>

          <td class="viewData text-center">
            {{ roundOfWeightage(t.stock_wt_zscore) }}%
          </td>
          <!-- <td class="viewData text-center" >{{t.stock_wt_volume}}%</td> -->

          <ng-container
            *ngFor="
              let x of sortLookBackDates(recommendedStocks?.lookback_dates)
            "
          >
            <td class="text-center" *ngIf="isValue || isSelected('Price')">
              {{ t.stock_ranks[x] ? t.stock_ranks[x].price_rank : "0" }}
            </td>
            <td class="text-center" *ngIf="isZscore || isSelected('ZSCORE')">
              {{ t.stock_ranks[x] ? t.stock_ranks[x].zscore_rank : "0" }}
            </td>
          </ng-container>
          <td
            class="viewData text-center"
            *ngIf="isAverage && (isValue || isSelected('Price'))"
          >
            {{
              getAverage("Price", {
                t: t,
                x: recommendedStocks.lookback_dates
              })
            }}
          </td>
          <td
            class="viewData text-center"
            *ngIf="isAverage && (isZscore || isSelected('ZSCORE'))"
          >
            {{
              getAverage("ZSCORE", {
                t: t,
                x: recommendedStocks.lookback_dates
              })
            }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="isSelected('Price') && t.stock_wt_price == 0 && viewAll"
        >
          <td scope="col" colspan="3">{{ t.stock_name }}</td>

          <td class="viewData text-center">
            {{ roundOfWeightage(t.stock_wt_price) }}%
          </td>
          <!-- <td class="viewData text-center" >{{t.stock_wt_volume}}%</td> -->

          <ng-container
            *ngFor="
              let x of sortLookBackDates(recommendedStocks.lookback_dates)
            "
          >
            <td class="text-center" *ngIf="isValue || isSelected('Price')">
              {{ t.stock_ranks[x] ? t.stock_ranks[x].price_rank : "0" }}
            </td>
            <td class="text-center" *ngIf="isZscore || isSelected('ZSCORE')">
              {{ t.stock_ranks[x] ? t.stock_ranks[x].zscore_rank : "0" }}
            </td>
          </ng-container>
          <td
            class="viewData text-center"
            *ngIf="isAverage && (isValue || isSelected('Price'))"
          >
            {{
              getAverage("Price", {
                t: t,
                x: recommendedStocks.lookback_dates
              })
            }}
          </td>
          <td
            class="viewData text-center"
            *ngIf="isAverage && (isZscore || isSelected('ZSCORE'))"
          >
            {{
              getAverage("ZSCORE", {
                t: t,
                x: recommendedStocks.lookback_dates
              })
            }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="isSelected('ZSCORE') && t.stock_wt_zscore == 0 && viewAll"
        >
          <td scope="col" colspan="3">{{ t.stock_name }}</td>

          <td class="viewData text-center">
            {{ roundOfWeightage(t.stock_wt_zscore) }}%
          </td>
          <!-- <td class="viewData text-center" >{{t.stock_wt_volume}}%</td> -->

          <ng-container
            *ngFor="
              let x of sortLookBackDates(recommendedStocks.lookback_dates)
            "
          >
            <td class="text-center" *ngIf="isValue || isSelected('Price')">
              {{ t.stock_ranks[x] ? t.stock_ranks[x].price_rank : "0" }}
            </td>
            <td class="text-center" *ngIf="isZscore || isSelected('ZSCORE')">
              {{ t.stock_ranks[x] ? t.stock_ranks[x].zscore_rank : "0" }}
            </td>
          </ng-container>
          <td
            class="viewData text-center"
            *ngIf="isAverage && (isValue || isSelected('Price'))"
          >
            {{
              getAverage("Price", {
                t: t,
                x: recommendedStocks.lookback_dates
              })
            }}
          </td>
          <td
            class="viewData text-center"
            *ngIf="isAverage && (isZscore || isSelected('ZSCORE'))"
          >
            {{
              getAverage("ZSCORE", {
                t: t,
                x: recommendedStocks.lookback_dates
              })
            }}
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
<div
  class="d-flex justify-content-end"
  *ngIf="recommendedStocks && !isItermediary"
>
  <div class="col-1 text-end">
    <button mat-button class="theme-btn primary-btn" (click)="onNextClick()">
      Next
    </button>
  </div>
</div>
