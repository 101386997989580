import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import {
  PouchActionTypes,
  AddEdit,
  AddEditSuccess,
  AddEditFailure,
  Load,
  EmptyStateLoad,
  CreateAddEditSuccess,
} from '../actions/pouch.action';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';

@Injectable()
export class PouchEffects {
  /**
   *
   */
  constructor(
    private actions: Actions,
    private router: Router,
    private pouchService: PouchService
  ) {}

  @Effect()
  Load: Observable<any> = this.actions.pipe(
    ofType(PouchActionTypes.LOAD),
    mergeMap((action: Load) => {
      const result$ = this.pouchService
        .getPouchList(action.payload)
        .pipe(
          map((data: any) => {
            if (data.length > 0 && data[0].pouches) {
              return data[0].pouches.map((element: any) => {
                return {
                  benchmark_index: element['benchmark_index'],
                  created_by: element['created_by'],
                  created_by_role: element['created_by_role'],
                  subscribed: element['subscribed'],
                  subscription_date:element['subscription_date'],
                  min_amt: element['min_amt'],
                  stop_sub:element['stop_sub'],
                  id: element['id'],
                  src: '/assets/images/icons/electric_mobility.png',
                  heading_text: element['name'],
                  description: element['description'],
                  universe: element['universe_name'],
                  total_stocks: element['stock_count']?.toLocaleString(
                    'en-IN',
                    {
                      useGrouping: true,
                    }
                  ),
                  total_subscribers: element[
                    'subscriber_count'
                  ]?.toLocaleString('en-IN', {
                    useGrouping: true,
                  }),
                  min_investment: element['minimum_investment']?.toLocaleString(
                    'en-IN',
                    {
                      useGrouping: true,
                    }
                  ),
                  min_investment_number: element['minimum_investment'],
                  risk_profile: element['risk_profile'],
                  annual_subscriptions: element[
                    'annual_subscriptions'
                  ]?.toLocaleString('en-IN', {
                    useGrouping: true,
                  }),
                  cagr: element['cagr'],
                  investment_strategy: element['investment_strategy'],
                  last_review_date: element['last_review_date'],
                  monthly_subscriptions: element[
                    'monthly_subscriptions'
                  ]?.toLocaleString('en-IN', {
                    useGrouping: true,
                  }),
                  next_review_date: element['next_review_date'],
                  rank_tolerance: element['rank_tolerance'],
                  public___pouch_subscriptions:
                    element['public___pouch_subscriptions'],
                  return_perc: element['return_perc'],
                  review_freq: element['review_freq'],
                  review_freq_day: element['review_freq_day'],
                  start_date: element['start_date'],
                  reset_date: element['reset_date'],
                  reset_years: element['reset_years'],
                  tf_wt:element['tf_wt'],
                  stock_count: element['stock_count']?.toLocaleString('en-IN', {
                    useGrouping: true,
                  }),
                  subscriber_count: element['subscriber_count']?.toLocaleString(
                    'en-IN',
                    {
                      useGrouping: true,
                    }
                  ),
                  lookback_periods: element['lookback_periods'],
                  inactive_subscriber: element['inactive_subscriber'],
                };
              });
            } else {
              return [];
            }
          }),
          catchError((error) => {
            return observableOf({ error: error });
          })
        )
        .pipe(
          map((result) => {
            return new AddEditSuccess(result);
          })
        );
      return result$;
    }),
    catchError((error) => {
      return observableOf(new AddEditFailure({ error: error }));
    })
  );

  @Effect()
  EmptyStateLoad: Observable<any> = this.actions.pipe(
    ofType(PouchActionTypes.EMPTYSTATELOAD),
    mergeMap((action: EmptyStateLoad) => {
      return observableOf(new CreateAddEditSuccess([]));
    }),
    catchError((error) => {
      return observableOf(new AddEditFailure({ error: error }));
    })
  );

  @Effect()
  AddEdit: Observable<any> = this.actions.pipe(
    ofType(PouchActionTypes.ADDEDIT),
    map((action: AddEdit) => action.payload),
    switchMap((payload) => {
      try {
        return observableOf(new CreateAddEditSuccess(payload));
      } catch (error) {
        return observableOf(new AddEditFailure({ error: error }));
      }
    })
  );

  @Effect({ dispatch: false })
  AddEditSuccess: Observable<any> = this.actions.pipe(
    ofType(PouchActionTypes.ADDEDIT_SUCCESS),
    tap((response) => {
      // const data: any = response.payload;
      const data: any = response;
    })
  );

  @Effect({ dispatch: false })
  AddEditFailure: Observable<any> = this.actions.pipe(
    ofType(PouchActionTypes.ADDEDIT_FAILURE),
    tap((error) => {})
  );
}
