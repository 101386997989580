import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  links = [
    {
      name: 'Risk Profile',
      path: '/profile',
    },
    {
      name: 'KYC',
      path: '/profile/kyc',
    },
    // {
    //   name: 'Settings',
    //   path: '/profile/settings',
    // },
    {
      name: 'Agreement',
      path: '/profile/agreement',
    },
  ];
  activeLink = this.links[0].name;
  constructor(private router: Router) {}
  goTo(arg0: any) {
    this.activeLink = arg0.name;
    this.router.navigateByUrl(`${arg0.path}`);
  }
}
