<div class="popup-section">
  <form [formGroup]="mapSubscriberForm" (ngSubmit)="mapSubscriber($event)">
    <div class="popup-header">
      <h3>Map subscribers</h3>
    </div>
    <div class="popup-body">
      <div class="row">
        <div class="col-md-7">
          <div class="col-md-11 mb-20 sm-mb-15">
            <p class="f-12 text-grey mb-05">Select Subscriber</p>
            <mat-form-field appearance="outline">
              <mat-select placeholder="--Select--" formControlName="investment_id"
                (selectionChange)="changeSub($event)">
                <mat-option [value]="item.subscriber_id" *ngFor="let item of subscriberList">
                  {{ item.first_name }}
                  {{ item.last_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-11 row wrap">
            <div class="col-md-6 mb-20 sm-mb-15">
              <p class="f-12 text-grey mb-05">Subscription Start Date</p>
              <mat-form-field appearance="outline" style="width: 100%">
                <input matInput [matDatepicker]="picker" placeholder="" formControlName="start_date" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-6 mb-20 sm-mb-15">
              <p class="f-12 text-grey mb-05">Subscription End Date</p>
              <mat-form-field appearance="outline" style="width: 100%">
                <input matInput [matDatepicker]="picker1" placeholder="" formControlName="end_date" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div> -->
               <div class="col-md-6 sm-mb-15">
              <p class="f-12 text-grey mb-05">Stop loss - Stock level</p>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="number" autocomplete="off" min="0" name="stockLevel"
                  formControlName="stoploss_stock" />
              </mat-form-field>
            </div>
            <div class="col-md-6 sm-mb-15">
              <p class="f-12 text-grey mb-05">Stop loss - Portfolio level</p>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="number" autocomplete="off" min="0" name="portfolioLevel"
                  formControlName="stoploss_portfolio" />
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-11 row">
            <!-- <div class="col-md-6 sm-mb-15">
              <p class="f-12 text-grey mb-05">Stop loss - Stock level</p>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="number" autocomplete="off" min="0" name="stockLevel"
                  formControlName="stoploss_stock" />
              </mat-form-field>
            </div>
            <div class="col-md-6 sm-mb-15">
              <p class="f-12 text-grey mb-05">Stop loss - Portfolio level</p>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="number" autocomplete="off" min="0" name="portfolioLevel"
                  formControlName="stoploss_portfolio" />
              </mat-form-field>
            </div> -->
          </div>
        </div>
        <div class="col-md-5">
          <!-- <div class="col-md-12 mb-50 sm-mb-15">
            <p class="f-12 text-grey mb-05">Subscription Type</p> -->
          <!-- <div class="d-flex mgt-5">
                            <div class="radio">
                                <label>
                                    <input type="radio" formControlName="subscription_type" name="subscription_type"
                                        value="MONTHLY" [checked]='true'>
                                    Monthly
                                </label>
                            </div>
                            <div class="radio ml-10">
                                <label>
                                    <input type="radio" formControlName="subscription_type" name="subscription_type"
                                        value="YEARLY">
                                    Yearly
                                </label>
                            </div>
                        </div> -->
          <!-- <mat-radio-group [(ngModel)]="selected" [ngModelOptions]="{standalone: true}">
              <ul>
                <li *ngFor=" let ele of subscriptionsList" class="mt-2">
                  <mat-radio-button color="primary" [value]="ele.subscription_freq" (change)="radioChanged($event)">{{
                    getOptionsText(ele.subscription_freq) }}</mat-radio-button><br />
                </li>
              </ul>
            </mat-radio-group> -->
          <!-- </div> -->
          <div class="col-md-12">
            <p class="f-12 text-grey mb-05">Investment Amount</p>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="number" autocomplete="off" min="0" name="investmentAmount"
                (input)="investmentAmountChange($event)" formControlName="investment_amount" />
            </mat-form-field>
          </div>
          <!-- <p class="f-12 text-grey mt-4 mb-05">Joining Date</p>
          <mat-form-field appearance="outline" style="width: 100%">
            <input matInput [matDatepicker]="picker4" placeholder="" formControlName="joinDate" />
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </mat-form-field> -->
        </div>
      </div>
    </div>

    <div class="popup-footer">
      <div class="btn-group">
        <span class="error">{{ errorMessage }}</span>
        <button mat-button class="theme-btn border-btn" type="button" (click)="onCancelClick()">
          Cancel
        </button>
        <button mat-button class="theme-btn primary-btn ml-20" type="submit">
          Map Subscriber
        </button>
      </div>
    </div>
  </form>
</div>