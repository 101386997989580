import { createAction, props } from "@ngrx/store";

const PUBLIC :string ="PUBLIC"

export const getCurrentPublicState = createAction(`${PUBLIC}CURRENT STATE FROM STORE`);

export const setUploadDate = createAction(`${PUBLIC} SET UPLOAD DATE TO STORE`,)
export const setUploadDateSuccess = createAction(`${PUBLIC} SET UPLOAD DATE TO STORE SUCCESS`,props<{date:any}>())
export const setUploadDateFailure = createAction(`${PUBLIC} SET UPLOAD DATE TO STORE FAILURE`,props<{statusCode:number}>());

export const loadPouches = createAction (`${PUBLIC} LOAD POUCHES FROM STORE`,(payload:any)=>payload)
export const loadPouchesSuccess = createAction (`${PUBLIC} LOAD POUCHES FROM STORE SUCCESS`,props<{pouchList:any}>())
export const loadPouchesFailure = createAction (`${PUBLIC} LOAD POUCHES FROM STORE FAILURE`,props<{statusCode:number}>())

export const loadAnnualizedReturns = createAction (`${PUBLIC} LOAD ANNUALIZED RETURNS FROM STORE`,(payload:any)=>payload)
export const loadAnnualizedReturnsSuccess = createAction (`${PUBLIC} LOAD ANNUALIZED RETURNS FROM STORE SUCCESS`,props<{annualizedReturns:any}>())
export const loadAnnualizedReturnsFailure = createAction (`${PUBLIC} LOAD ANNUALIZED RETURNS FROM STORE FAILURE`,props<{statusCode:number}>())


export const addNewPouchToState = createAction(`${PUBLIC}   ADD NEW POUCH TO STORE`,props<{data:any}>())