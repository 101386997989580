import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/common/services/shared.service';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { NotificationService } from 'src/app/services/notification.services';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { PublicService } from '../../services/public.service';
import { PublicFacadeService } from '../../state/public.facade.services';
import { PaymentLoaderComponent } from 'src/app/modules/navigation/payment-loader/payment-loader.component';
import { MatDialog } from '@angular/material/dialog';
import { PlansService } from 'src/app/modules/investment-pouches/services/plans.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit {
  plans: any = null;
  minimumInvestmentAmount: number = 0;
  minimumInvestmentAmountCopy: number = 0;
  selected: any;
  pouchId: any;
  stopLoss: number = 0;
  error: boolean;
  showInv_error: boolean = false;
  selectedSubscription: string = '';
  token: any = null;
  discountAmount: any = 0;
  totalAmount: any = 0.0;
  showTokenError: any = {
    error: false,
    errorText: '',
  };
  selectedSubscriptionData: any = {
    amount: 0.0,
    frequency: '',
    descriprion: '',
  };
  investAmount: number = 0;
  subscriptionsList: any[];
  @Input() pouches: any;
  subscriberID: any;
  renewChange:boolean=true;
  totalReturns:number= 0;
  // country = [
  //   {
  //     name: 'India',
  //     value: 'India',
  //   },
  //   {
  //     name: 'USA',
  //     value: 'USA'
  //   }
  // ];
  state = [
    {
      name: 'Maharashtra',
      value: 'Maharashtra'
    },
    {
      name: 'California',
      value: 'California'
    }
  ]
  getCountry: any;
  getStateName: any;
  form!: FormGroup;
  getDetails: any;
  constructor(
    private router: Router,
    private _router: ActivatedRoute,
    private publicService: PublicService,
    public publicFacadeService: PublicFacadeService,
    private pouchService: PouchService,
    private subscriberService: SubsciberService,
    private sharedService: SharedService,
    private notifyService: NotificationService,
    private dialog : MatDialog,
    private plansService: PlansService,
  ) {
    this.pouchId = this._router.snapshot.paramMap.get('pouchId');
    const stateData = this.router.getCurrentNavigation()?.extras.state;
    if (stateData) {
      this.totalReturns =stateData.pouchReturn[0].pouch_subscriber_returns[0].total_returns;
      if(stateData.Renew === 'false'){
        this.renewChange=false;
      }else{
        this.renewChange=true;
      }
    }


  }
  
  ngOnInit(): void {
    this.form = new FormGroup({
      address: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required, Validators.pattern('^[1-9][0-9]{5}$')]),
    });
    const data = {
      "subscriber_id":  JSON.parse(localStorage.getItem('user') || '{}').id
    }
    this.plansService.getBillingDetails(data).subscribe((res: any) => {
      this.getDetails = res[0].sub_bill_addrs;
      console.log(this.getDetails[0].billing_address)
      this.form.patchValue({
        "address": this.getDetails[0].billing_address,
        "country": this.getDetails[0].billing_country,
        "state": this.getDetails[0].billing_state,
        "city": this.getDetails[0].billing_city,
        "pincode": this.getDetails[0].billing_pincode,
      });
    })
    this.plansService.getCountry().subscribe((res: any) => {
      this.getCountry = res[0].country_name;
    })
    this.plansService.getState().subscribe((res: any) => {
      this.getStateName = res[0].country_name;
    })
    if (this.pouches) {
      this.subscriberID = JSON.parse(localStorage.getItem('user') || '{}').id;
      // this.getminInvestmentAmount();
      this.minimumInvestmentAmount = Number(Math.round( this.pouches.min_amt).toFixed(2));
      this.minimumInvestmentAmountCopy =Number(Math.round( this.pouches.min_amt).toFixed(2));
      this.getSubscriptionPlanList();
      this.selected = this.subscriptionsList[0]?.subscription_freq;
      this.plans = this.subscriptionsList[0];
    } else {
      this.router.navigateByUrl('investment');
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  };
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  allowNumber(event: any) {
    const input = event.key;
    const isDigit = /^\d$/.test(input);
    if (!isDigit) {
      event.preventDefault();
    }
  }
  
  onPasteAllowNumber(event: any) {
    const isAllDigits = /^\d+$/.test(event.clipboardData.getData('Text'));
    if (!isAllDigits) {
      event.preventDefault();
    }
  }
  getminInvestmentAmount() {
    let data: any = {
      pouch_id: this.pouches.id,
      stock_price_date: localStorage
        .getItem('stock_price_uploaded_date')
        ?.split('T')[0],
    };
    this.getSubscriptionPlanList();
    this.selected = this.subscriptionsList[0]?.subscription_freq;
    this.plans = this.subscriptionsList[0];
    this.pouchService.getPouchStocksReturns(data).subscribe(
      (response: any) => {
        let returns: any = response[0].Results[0].return_data.pouch_stocks_returns;
        let sumArr: any[] = [];
        if (returns) {
          for (let data of returns) {
            if (!data.sell_date) {
              sumArr.push(data.close_price * data.stock_tf_count);
            }
          }
          let sum = 0;
          for (let i = 0; i < sumArr.length; i++) {
            sum += sumArr[i];
          }
          this.minimumInvestmentAmount = Number(sum.toFixed(2));
          this.minimumInvestmentAmountCopy = Number(sum.toFixed(2));

        }
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );
  }


  onSubmit() {
    this.form.markAllAsTouched();
    if(this.form.value != '') {
      let stateCode = this.getStateName.find((e:any)=>e.state_name === this.form.controls['state'].value).state_code
      const data = {
        "subscriber_id": JSON.parse(localStorage.getItem('user') || '{}')?.id,
        "docs": {
          "billing_address": this.form.controls['address'].value,
          "billing_country": this.form.controls['country'].value,
          "billing_state": this.form.controls['state'].value,
          "billing_city": this.form.controls['city'].value,
          "billing_pincode": this.form.controls['pincode'].value,
          'billing_state_code':stateCode,
        }
       }
       if(this.form.valid){
        this.plansService.updateBillingDetails(data).subscribe((res: any) => {
          this.agreement(this.minimumInvestmentAmountCopy,data?.docs);
         })
       }

    }

  }

  agreement(ele: any ,billDetails:any) {
    if(this.renewChange===true){
      if (!ele) {
        this.showInv_error = true;
        return;
      }
      // this.showInv_error = false;
      let body: any = {
        subscriberObj: [
          {
            pouch_id: this.pouches.id,
            subscriber_id: this.subscriberID,
            subscription_type: this.selected,
            start_date: this.formatDate(new Date()),
            end_date: this.getEndDate(new Date()),
            stoploss_stock: this.stopLoss,
            stoploss_portfolio: this.stopLoss,
            investment_amount: this.minimumInvestmentAmountCopy,
            is_active: 'Y',
          },
        ],
        docsi: [
          {
            pouch_id: this.pouches.id,
            subscriber_id: this.subscriberID,
            investment_amount: this.minimumInvestmentAmountCopy,
            change_amount: this.minimumInvestmentAmountCopy,
            from_date: new Date(
              localStorage.getItem('stock_price_uploaded_date') || ''
            )
              .toISOString()
              .split('T')[0],
          },
        ],
        pouch_id: this.pouches.id,
        subscriber_id: this.subscriberID,
        review_date: localStorage
          .getItem('stock_price_uploaded_date')
          ?.split('T')[0],

        investment_amount: this.minimumInvestmentAmountCopy,
        minimum_investment: this.minimumInvestmentAmount,
      };
      if (this.stopLoss >= 0) {
        this.publicService.agreementFrom = 'from-plans';
        this.publicService.mapSubscriberData.data = body;
        this.publicService.mapSubscriberData.agreementDetails = this.pouches;
        this.publicService.mapSubscriberData.subscription = {
          pouch_id: this.pouches.id,
          subscriber_id: this.subscriberID,
          subscription_type: this.plans?.subscription_type,
          subscription_perc: this.plans?.subscription_perc,
          subscription_amount: this.plans?.subscription_amount,
          subscription_amount_final: Number(this.getamount(this.selected)),
          discount_amount: this.discountAmount,
          promo_code: this.token,
          subscription_freq: this.selected,
          start_date: new Date().toISOString().split('T')[0],
          end_date: this.getEndDate(new Date()),
          order_id: '',
          bank_ref_no: '',
          investment_amount:body.investment_amount,
          ...billDetails
        };
    this.router.navigateByUrl('/agreement');
      } else {
        this.error = true;
      }
    }else{
      // this.goSubscribe()
    }

  }
  getEndDate(date: any) {
    let value =
      this.selected === 'MONTHLY' ? 1 : this.selected === 'QUARTERLY' ? 3 : 6;
    return this.formatDate(
      new Date(new Date(date).setMonth(new Date(date).getMonth() + value))
    );

    new Date(
      new Date(
        localStorage.getItem('stock_price_uploaded_date') || ''
      ).setMonth(
        new Date(
          localStorage.getItem('stock_price_uploaded_date') || ''
        ).getMonth() + value
      )
    );
  }
  getSubscriptionPlanList() {
    console.log(
      this.pouches?.public___pouch_subscriptions,
      'check subscriptions'
    );
    this.subscriptionsList = this.pouches.public___pouch_subscriptions[0]
      .subscription_freq
      ? this.pouches.public___pouch_subscriptions
      : [];
  }

  getOptionsText(type: string) {
    if (type === 'MONTHLY') {
      return 'Monthly subscription : ' + this.getamount(type);
    } else if (type === 'QUARTERLY') {
      return '3 months subscription : ' + this.getamount(type);
    } else {
      return '6 months subscription : ' + this.getamount(type);
    }
  }
  getamount(type: any) {
    let getsubscriptiondata = this.subscriptionsList?.find(
      (ele: any) => ele.subscription_freq === type
    );
    if (getsubscriptiondata?.subscription_type === 'MAX') {
      let value = Math.max(
        (this.minimumInvestmentAmountCopy *
          getsubscriptiondata?.subscription_perc) /
          (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)),
        getsubscriptiondata?.subscription_amount
      );
      return Math.round(value).toFixed(2) || 0;
    }
    if (getsubscriptiondata?.subscription_type === 'MIN') {
      let value = Math.min(
        (this.minimumInvestmentAmountCopy *
          getsubscriptiondata?.subscription_perc) /
          (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)),
        getsubscriptiondata?.subscription_amount
      );

      return  Math.round(value).toFixed(2) || 0;
    }
    if (getsubscriptiondata?.subscription_type === 'BOTH') {
      let value =
        (this.minimumInvestmentAmountCopy *
          getsubscriptiondata?.subscription_perc) /
          (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)) +
        getsubscriptiondata?.subscription_amount;
      return  Math.round(value).toFixed(2) || 0;
    }
    return this.minimumInvestmentAmountCopy;
  }
  radioChanged(event: any, ele: any) {
    this.selected = event.value;
  }
  getPouchName() {
    return this.pouches?.heading_text;
  }

  getFrequencyPlan() {
    let value = '';
    switch (this.selected) {
      case 'MONTHLY':
        value = '1 month';

        break;
      case 'QUARTERLY':
        value = '3 months';

        break;
      case 'HALF-YEARLY':
        value = '6 months';

        break;
    }
    return value;
  }
  getSubscriptionAmount() {
    return this.getamount(this.selected);
  }
  validateToken() {
    let body = {
      subscription_amount: this.getamount(this.selected),
      promo_code: this.token,
    };
    this.publicService.verifyPromoCodes(body).subscribe(
      (result: any) => {
        let res = result[0].Results[0];
        if (res.promo_status) {
          if (res.promo_status === 'Active') {
            this.showTokenError.error = false;
            this.showTokenError.errorText = '';
            this.totalAmount = res.subscription_amount_final.toFixed(2);
            this.discountAmount = res.discount_amount.toFixed(2);
          } else {
            this.showTokenError.error = true;
            this.showTokenError.errorText = 'Promo code is expired';
            this.totalAmount = this.getamount(this.selected);
          }
        } else {
          this.showTokenError.error = true;
          this.showTokenError.errorText = 'Invalid promo code';
          this.totalAmount = this.getamount(this.selected);
        }
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );
  }

  formatDate(date: any) {
    const d = new Date(date);
    // This will return an ISO string matching your local time.
    return new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes() - d.getTimezoneOffset()
    )
      .toISOString()
      .split('T')[0];
  }
  subscriptionStatus:any
  goSubscribe(){
    this.checkSubscriptionStatus()
      let details = JSON.parse(sessionStorage.getItem('pouch') || '{}');
      this.publicService
        .intiatePaymentGateway({
          investment_amount:this.minimumInvestmentAmountCopy,
          pouch_id: this.pouches.id,
          frequency: "MONTHLY",
          subscription_amount: 2,
          subscriber_id:JSON.parse(localStorage.getItem('user') || '{}')?.id,
          redirect_url:`${window.location.host}/investment/map-subscriber/${this.pouches.id}`,
        })
        .subscribe(
          (res1: any) => {
            this.redirectToPayment(res1);
          },
          (e) => {
            this.notifyService.showError('', e.error.error.message);
          }
        );
  }
  checkSubscriptionStatus(){
    this.pouchService
      .get_pouch_subscriber_status({
        pouch_id: this.pouchId,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      }).subscribe((res:any)=>{
        if(res && res.length > 1 && res[1]?.subscription_status){
          this.subscriptionStatus = res[1].subscription_status;
        }
      })
  }
  redirectToPayment(data: any) {
    this.dialog.open(PaymentLoaderComponent, {
      data: data,
      panelClass: 'custom-modalbox',
    });
  }
}
