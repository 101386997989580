import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  Component,
  OnInit,
  DoCheck,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SharedService } from '../common/services/shared.service';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent implements OnInit {
  routeShow = false;
  rp = false;
  rp_exp = false;
  kyc = false;
  kyc_exp = false;
  closeNot: boolean = true;
  closeNot1: boolean = true;
  closeNot2: boolean = true;
  closeNot3: boolean = true;
  constructor(
    public _router: ActivatedRoute,
    public router: Router,
    private shared_service: SharedService
  ) {
    this.Notify_trigger();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.Notify_trigger();
      }
    });
  }

  ngOnInit() {
    this.shared_service.onUserUpdate.subscribe((res) => this.Notify_trigger());
  }
  Notify_trigger() {
    this.closeNot = true;
    this.closeNot1 = true;
    this.closeNot2 = true;
    this.closeNot3 = true;
    if (this.router.url === '/dashboard') {
      this.routeShow = true;
    } else {
      this.routeShow = false;
    }
    let userInfo = JSON.parse(localStorage.getItem('user') || '{}').attributes;
    if (userInfo?.role === 'subscriber') {
      this.checkNotification();
    } else {
      this.booleanSwitcher(false);
    }
  }
  checkNotification() {
    let userInfo = JSON.parse(localStorage.getItem('user') || '{}').attributes;

    if (userInfo?.risk_profile === 'PENDING'||userInfo?.risk_profile==='') {
      this.rp = true;
    } else {
      this.rp = false;
      this.rp_exp = this.checkExpiry(new Date(userInfo?.risk_profile_date));
    }
    if (userInfo?.kyc_date === ''||userInfo?.kyc_date === null) {
      this.kyc = true;
    } else {
      this.kyc = false;
      this.kyc_exp = this.checkExpiry(new Date(userInfo?.kyc_date));
    }
  }

  checkExpiry(Cdate: any) {
    let quoteExpiry = new Date(Cdate.setFullYear(Cdate.getFullYear() + 1));
    let now = new Date();

    if (now.getTime() > quoteExpiry.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  go(v: any) {
    if (v === 'risk') {
      this.router.navigateByUrl('/onboarding/questionaire');
    }
    if (v === 'kyc') {
      this.router.navigateByUrl('/onboarding/kyc_upload');
    }
  }

  goto(v: any) {
    if (v === 'firstClick') {
      this.closeNot = false;
    }

    if (v === 'firstClick2') {
      this.closeNot1 = false;
    }

    if (v === 'firstClick3') {
      this.closeNot2 = false;
    }

    if (v === 'firstClick4') {
      this.closeNot3 = false;
    }
  }

  booleanSwitcher(value: boolean) {
    this.routeShow = value;
    this.rp = value;
    this.rp_exp = value;
    this.kyc = value;
    this.kyc_exp = value;
  }
}
