import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Elements } from '../common/interfaces/table';
import { UtilsService } from 'src/app/common/services/utils.service';
import {
  PouchState,
  selectPouchState,
} from '../modules/investment-pouches/store/pouch.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Load } from '../modules/investment-pouches/store/actions/pouch.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pouches',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
})
export class DashboardTableComponent implements OnInit,OnChanges {
  //
  isLoading:boolean=true;
  //
  @Input() public tableStructure = {
    border: false,
    box: false,
    heading: false,
    content: false,
    subscriber: false,
    pouch: false,
    lastReview: false,
    nextReview: false,
  };
  getState: Observable<any>;

  @Input() public sortingFilter = false;

  @Input() dashboardFilter: any = [];

  @Input() definedData: Elements[] = [];

  @Input() selectedSort: any;
  @Input() pouches: any;

  @Output() sortEvent = new EventEmitter();
  uploadedDate: any;
  // defaultBody: { sort: any; current_date: string; };
  defaultBody: any;

  /**
   *
   */
  constructor(
    private utilSerivce: UtilsService,
    private store: Store<PouchState>,
    private router: Router
  ) {
    this.getState = this.store.select(selectPouchState);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.isLoading=true;
    this.definedData = []
    this.definedData= changes?.definedData?.currentValue
    if(this.definedData){
      this.isLoading=false;
     }
  }
  ngOnInit(): void {
    // this.selectedSort = -13;
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.defaultBody = {
      sort: this.selectedSort,
      current_date: this.uploadedDate?.split('T')[0],
    };
    this.getState.subscribe((state) => {
      let statePouches = state.pouches;
      if (
        statePouches &&
        !(statePouches.error && statePouches.error.message != '')
      ) {
        this.pouches = [...statePouches];
      }

    });
    if (!this.pouches) {
      this.store.dispatch(new Load(this.defaultBody));
    }
// setTimeout(()=>{
//   this.isLoading=false;
// },50000);
// setTimeout(()=>{
//   this.isLoading=false;
// },5000);
  }
  //selectedFilter = 'All';

  //definedColumns: string[] = ['blank', 'content', 'border', 'subscribers', 'lastReview', 'nextReview', 'stocks', 'investment', 'profile'];

  sortData(evt: any, tableStructure: any) {
    let sortData: any = {
      subscriber: tableStructure.subscriber,
      sortValue: evt.value,
    };
    this.isLoading = true;
    this.sortEvent.emit(sortData);
  }

  getNextReviewDisplayDate(date: any, review_frequency: any) {
    let reviewDate = new Date(date);
    let nextReviewDate = this.utilSerivce.appendDateByTimeframe(
      reviewDate,
      review_frequency
    );
    return nextReviewDate;
  }
  onPouchClick(val: any) {
    let pouchObj = this.definedData[val];

    let id = this.filterPouchName(pouchObj);
    this.router.navigateByUrl(`investment/map-subscriber/${id}`);
  }

  filterPouchName(obj: any) {
    let find = this.pouches.find(
      (ele: any) => ele.heading_text === obj.heading
    );
    return find.id;
  }
}
