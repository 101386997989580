import { createFeatureSelector } from '@ngrx/store';
import * as dashboard from './reducers/dashboard.reducer';

export interface DashboardState {
    entityState: dashboard.State;  
}

export const reducers = {
    dashboard: dashboard.reducer,
};

export const selectDashboardState = createFeatureSelector<DashboardState>('dashboard');