<ng-container *ngIf="!domain() else richmee">
  <app-preloader *ngIf="loading"></app-preloader>

<div *ngIf="!isPublicUI" class="oldUI">
  <!-- <app-toast-notification></app-toast-notification> -->
  <app-header></app-header>
  <div [ngClass]="isLogin ? 'sectionHeight' : 'mainSection'">
    <router-outlet></router-outlet>
    <div *ngIf="!landingPage">
      <app-footer> </app-footer>
    </div>
    <div *ngIf="landingPage">
      <app-public-footer></app-public-footer>
    </div>
  </div>
</div>

<div *ngIf="isPublicUI">
  <div class="tnotification">
    <app-toast-notification></app-toast-notification>
  </div>
  <app-header-public ></app-header-public>
  <div>
    <app-background></app-background>
  </div>
  <div [ngClass]="isLoginTrue ? 'removeHeight' : 'p2-height'">
    <div style="min-height: 68vh">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!isLoginTrue">
      <app-public-footer></app-public-footer>
    </div>
  </div>
</div>

</ng-container>

<ng-template #richmee>
  <div class="tnotification">
    <app-toast-notification></app-toast-notification>
  </div>
  <app-header-public  *ngIf="router?.url !=='/' "></app-header-public>
  <app-background *ngIf="router?.url !=='/' "></app-background>
  <div [ngClass]="isLogin ? 'sectionHeight' : router.url ==='/'? 'mainSection':'mainSection p2-height'">
    <div style="min-height: 68vh">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!isLoginTrue">
      <app-public-footer></app-public-footer>
    </div>
  </div>

</ng-template>
