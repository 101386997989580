<!-- <div class="opt-wrapper">
    <div class="f-18 fw-600 text-center">
        Please enter the 4 digit OTP we have sent on your<br /> mobile and email.        
    </div>
    <div>
        <p>Mobile OTP</p>
        <div class="text-center d-flex">
            <ng-otp-input (onInputChange)="onMobileOtpChange($event)"  [config]="{length:4}"></ng-otp-input> 
            <p>Resend</p>
        </div>        
    </div>
    <div class="mt-30">
        <p>Email OTP</p>
        <div class="text-center">
            <ng-otp-input  (onInputChange)="onEmailOtpChange($event)"[config]="{length:4}"></ng-otp-input> 
            <p>Resend</p>
        </div>        
    </div>
</div> -->

<div class="opt-wrapper">
  <!-- <div class="f-18 fw-600 text-center heading">
    Please enter the 4 digit OTP we have sent on your mobile and email.
  </div> -->
  <!-- need to remove when email api is completed -->
  <div class="f-16 fw-600 text-center heading">
    Please enter the 6 digit OTP we have sent on your mobile.
  </div>
  <!-- need to remove when email api is completed -->
  <div class="mobileOtp p-96 mt-32">
    <p>Mobile OTP</p>
    <div class="text-center d-flex">
      <ng-otp-input
        #ngMobileOtpInput
        (onInputChange)="onMobileOtpChange($event)"
        [config]="config"
      ></ng-otp-input>
      <a
        (click)="resendOTP()"
        class="resendLink"
        [ngStyle]="{ visibility: showResendLink ? 'visible' : 'hidden' }"
        >Resend</a
      >
    </div>
  </div>
  <!-- <div class="emailOtp p-96 mt-40">
    <p>Email OTP</p>
    <div class="text-center d-flex">
      <ng-otp-input
        #ngEmailOtpInput
        (onInputChange)="onEmailOtpChange($event)"
        [config]="config"
      ></ng-otp-input>
      <p class="resendLink">Resend</p>
    </div>
  </div> -->
  <mat-error class="text-center mt-3" [hidden]="!otpError">
    Try Again
  </mat-error>
  <!-- <p class="errorText text-center mt-2">Try Again</p> -->
  <div class="btn-footer">
    <button (click)="verify()" mat-flat-button class="btn-properties">
      Verify
    </button>
  </div>
</div>
