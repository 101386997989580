import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPouchesTable]'
})
export class DashboardTableDirective implements OnInit {

 @Input('round-box') public box: boolean = false;
 @Input('border-v') public border: boolean = false;
 @Input() public heading: boolean = false;
 @Input('table-border') public tableBorder: boolean = false;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    const nativeEle = this.el.nativeElement;
    if(this.box){
      nativeEle.setAttribute('style', 'width: 40px; height: 40px; border-radius: 5px; background: rgb(184, 184, 184);');
    }

    if(this.border){
      nativeEle.setAttribute('style', 'border-right: 1px solid rgb(184, 184, 184); padding: 0; height: 38px;');
      nativeEle.setAttribute('class', 'line');
    }

    if(this.heading){
      nativeEle.style.fontWeight = "500";
    }

    if(this.tableBorder){
      nativeEle.style.border = "1px solid rgb(200, 200, 200)";
    }
  }
}
