import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
  styles: [
    `
      .header,
      .dialog-message {
        text-transform: lowercase;
      }
      .header::first-letter,
      .dialog-message::first-letter {
        text-transform: uppercase;
      }
      .btn-cancel {
        background-color: var(--red);
        color: var(--white);
      }
    `,
  ],
})
export class CommonDialogComponent implements OnInit {
  status: string;
  title: string;
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cancelText: string;
      confirmText: string;
      message: string;
      title: string;
      status: string;
    },
    private mdDialogRef: MatDialogRef<CommonDialogComponent>
  ) {}
  ngOnInit(): void {
    this.showMsg();
    // throw new Error('Method not implemented.');
  }
  public cancel() {
    this.close(false);
  }
  public close(value: boolean) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }
  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }
  showMsg() {
    //
    // if(this.data.status == 'Sucess') {
    //   this.data.title = 'Sucess';
    //   this.data.message = 'Sucess Message';
    // }
    // else {
    //   this.data.title = 'Fail';
    //   this.data.message = 'Failure Message';
    // }
  }
}
