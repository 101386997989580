import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { UniverseService } from 'src/app/modules/investment-pouches/services/universe.service';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { PublicService } from '../../public/services/public.service';
import * as moment from 'moment';
import { SharedService } from 'src/app/common/services/shared.service';
import { NotificationService } from 'src/app/services/notification.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
})
export class PerformanceComponent implements OnChanges {
  public myMath = Math;
  environment=environment
  @Input()PouchSubscriberStocksReturns:any
  @Input()subList:any=[]
  @Input() public: any;
  @Input() userType: any;
  @Input() selectedBenchmarkIndex: any;
  sliderDisabled: boolean = true;
  @Input() radioSelected: any;
  @Input() duration: any;
  sliderIntial: boolean = false;
  performanceData: any;
  pouchData: any;
  indexData: any;
  dates: any;
  chart: any;
  pouchName: any;
  indexName: any;
  maxPercent: any;
  minPercent: any;
  pouchSince: any = 0;
  indexSince: any = 0;
  disableOutline: any = false;
  datesForComparison: any;
  intialLoad = false;
  selectedDuration: any = '1m';
  @Input() since: any;
  @Input() pouchDetail: any;
  @Input() parent: string;
  @Input() pouchId: any;
  @Input() startDate: any;
  @Input() cagr: any;

  @Output() subscriberChange = new EventEmitter();
  @Output() changeTabEvent = new EventEmitter();
  @Output() bannerChangeEvent = new EventEmitter();
  @Output() changeIndex = new EventEmitter();
  @Output() radioChange = new EventEmitter();
  displayedColumns: string[] = [
    'name',
    'pe_ratio',
    'pb_ratio',
    'sharpe_ratio',
    'div_yield',
  ];
  dataSource: any = [];
  universeList: any = [];
  subscriberList: any = [];
  selectedUniverse: any = 'Smartvalues Main Large Cap';
  uploadedDate: any;
  selectedSubscriber: any = '';
  chartUsingDate: any;
  isSinceDate: boolean = false;

  userData: any;
  radioSelectedSubscriber:any=0
  constructor(
    private universeService: UniverseService,
    private pouchService: PouchService,
    private publicServices: PublicService,
    private subscriberService: SubsciberService,
    private sharedService: SharedService,
    private notifyService: NotificationService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {

    // this.since = changes.since.currentValue;
    //
  }

  ngOnInit(): void {
    
    this.fetchUniverseList();
    this.userData = JSON.parse(localStorage.getItem('user') || '{}');
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.isSinceDate = false;

    this.selectedUniverse = this.pouchDetail?.benchmark_index;

    this.chartUsingDate = new Date(
      new Date(this.since).getFullYear(),
      new Date(this.since).getMonth(),
      new Date(this.since).getDate(),
      new Date(this.since).getHours(),
      new Date(this.since).getMinutes() - new Date(this.since).getTimezoneOffset()
    );

    // this is where public see performance
    if (this.userType === 'public') {
      this.radioSelected = 1;
      this.callPouchData();
    }
    if (this.parent === 'createPouch') {
      this.selectedUniverse = this.selectedBenchmarkIndex || 'Smartvalues Main Large Cap';
      console.log(this.selectedUniverse);
      if (this.duration) {
        this.selectedDuration = '3y';
      }

      this.callPouchData();
      this.selectedUniverse = this.selectedBenchmarkIndex || 'Smartvalues Main Large Cap';
      this.since = new Date(this.since);
      this.getChartData(this.selectedDuration);
    }
    if (
      this.userType === 'admin' ||
      this.userType === 'ria' ||
      this.userType === 'partner'
    ) {
      if (this.radioSelected === 0) {
        this.selectedSubscriber = this.pouchService.selectedSubscriber;
        this.callSubscriberData();
      } else {
        this.callPouchData();
      }
    }
    if (this.userType === 'subscriber') {
      if (this.radioSelected === 1) {
        this.callPouchData();
      }
      if (this.radioSelected === 0) {
        this.callSubscriberData();
      }
    }
    this.subList.forEach((item:any)=>{
      this.radioSelectedSubscriber = item.active_flag==='ACTIVE'?0:1
  });
  }

  callPouchData(callcompare=true) {
    const pouchName = this.pouchDetail
      ? this.pouchDetail.heading_text
      : 'Pouch';

    this.subscriberList = [{ name: pouchName }];
    // this.cagr = this.pouchDetail

    //   ? Number(this.pouchDetail.cagr * 100).toFixed(2)
    //   : 0;
    this.selectedSubscriber = pouchName;
    if (this.pouchId) {
      this.getOneMonthDatesForChart();
      if(callcompare){
        this.getComparePouchUniversePerformance();
        this.getPouchIndexRatio();
      }

    }
    console.log(callcompare,'ccc')
    this.createNewCagr();
  }
  callSubscriberData(callcompare=true) {

    let subscriber: any;
    if (this.userType === 'subscriber') {
      subscriber = this.userData.id;
    } else {
      if (this.pouchService.selectedSubscriber) {
        subscriber = this.pouchService.selectedSubscriber;
      } else {
        let subscribersearch = this.subList.find(
          (x: any) => x.subscriber_id == this.selectedSubscriber
        );
        subscriber = subscribersearch?.id;
      }
    }
    if(this.userType !== 'subscriber'){
  if(!subscriber){
    subscriber= this.subList[0]?.subscriber_id || []
  }
}

    let subscriberPayload: any = {
      limit: 100,
      stock_price_date: moment(new Date(this.since)).format('YYYY-MM-DD'),//this.uploadedDate.split('T')[0],
      pouch_id: this.pouchDetail ? this.pouchDetail.id : this.pouchId,
      subscriber_id:subscriber
    };
    if (this.userType === 'subscriber') {
      subscriberPayload.subscriber_id = JSON.parse(
        localStorage.getItem('user') || '{}'
      ).id;
    }
    this.subscriberService
      .getPouchSubscriberReturns(subscriberPayload)
      .subscribe(
        (res: any) => {
          if (res && res[0]?.pouch_subscribers) {
            this.subscriberList = [];
            res[0]?.pouch_subscribers.map((element: any) => {
              if (element['is_active'] == true) {
                this.subscriberList.push({
                  id: element['subscriber_id'],
                  name: `${element['subscriber_first_name']} ${element['subscriber__last_name']}`,
                  cagr: element['cagr'],
                });
              }
            });
          }
          this.sendCagr(res?.[0]?.pouch_subscriber_returns?.[0]);
          const pouchName = this.pouchDetail
            ? this.pouchDetail.heading_text
            : 'Pouch';
          // this.cagr = this.pouchDetail
          //   ? Number(this.pouchDetail.cagr * 100).toFixed(2)
          //   : 0;
          // this.subscriberList.unshift({ name: pouchName });
          // this.selectedSubscriber = pouchName;
          if( (this.userType === 'admin' ||
          this.userType === 'ria' ||
          this.userType === 'partner' ||
          this.userType === 'subscriber') &&
          this.radioSelected === 0)
          {
            this.startDate = res?.[0]?.pouch_subscriber_returns?.[0]?.public___pouch_subscribers?.[0]?.start_date;
          }
          else{
            this.startDate = this.pouchDetail
              ? this.pouchDetail.start_date
              : this.startDate;
          }

          // if (!this.intialLoad) {
          this.selectedSubscriber = this.pouchService.selectedSubscriber
            ? this.pouchService.selectedSubscriber
            : this.subscriberList[0]?.id;
          this.pouchService.selectedSubscriber = this.selectedSubscriber;
          this.intialLoad = true;
          // this.sendCagr();
          // }

          this.getOneMonthDatesForChart();
          if(callcompare){
            this.getComparePouchSubscriberUniversePerformance();
            this.getPouchIndexRatio();
          }


        },
        (e) => {
          this.notifyService.showError('', e.error.error.message);
        }
      );

    // this.getOneMonthDatesForChart();
    // this.getComparePouchSubscriberUniversePerformance();
    // this.getPouchIndexRatio();
  }

  onIndexChange() {
    if (this.selectedUniverse) {
      this.onChangeSelection();
      this.getPouchIndexRatio();
    }
  }
  fetchUniverseList() {
    if (this.public) {
      this.publicServices
        .fetchUniverseList({
          limit: 1000,
          universe_type: 'SYSTEM',
        })
        .subscribe((res: any) => {
          this.universeList = res[0].universes;
        });
    } else {
      this.universeService
        .getUniverseList({ universe_type: 'SYSTEM' })
        .subscribe((res: any) => {
          this.universeList = res[0].universes;
        });
    }
  }
  getPouchIndexRatio() {
    let data = {
      pouch_id: this.pouchId,
      universe_name: this.selectedUniverse,
      // stock_price_date: this.uploadedDate.split('T')[0],
      stock_price_date: this.since
    };
    this.pouchService.getPouchIndexRatio(data).subscribe(
      (res: any) => {
        if (res && res.length > 0) {
          let responseSource: any = [];
          if (res[0]?.pouch) {
            responseSource.push({
              name: res[0].pouch[0].pouch_name,
              pe_ratio: res[0].pouch[0].pe_ratio,
              pb_ratio: res[0].pouch[0].pb_ratio,
              sharpe_ratio: res[0].pouch[0].sharpe_ratio,
              div_yield: res[0].pouch[0].div_yield,
            });
          }
          if (res[1]?.index) {
            responseSource.push({
              name: res[1].index[0].index_name,
              pe_ratio: res[1].index[0].pe_ratio,
              pb_ratio: res[1].index[0].pb_ratio,
              sharpe_ratio: res[1].index[0].sharpe_ratio,
              div_yield: res[1].index[0].div_yield,
            });
          }

          this.dataSource = new MatTableDataSource(responseSource);
        }
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );
  }

  getChartData(duration: any) {
    this.selectedDuration = duration;

    let Difference_In_Time =
      this.chartUsingDate.getTime() - new Date(this.startDate).getTime();
    let daysDiff = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    let isMoreThanYear = daysDiff > 365;

    switch (duration) {
      case '1m':
        {
          this.getOneMonthDatesForChart();
          this.onChangeSelection();
        }
        break;
      case '6m':
        {
          this.getLastMonthsDates(duration);
          this.onChangeSelection();
        }
        break;
      case '1y':
        {
          this.getLastMonthsDates(duration);
          this.onChangeSelection();
        }
        break;
      case '3y':
        {
          if (isMoreThanYear) {
            this.getLastYearsDates(duration);
          } else {
            this.getLastMonthsDates(duration);
          }
          this.onChangeSelection();
        }
        break;
      case '5y':
        {
          if (isMoreThanYear) {
            this.getLastYearsDates(duration);
          } else {
            this.getLastMonthsDates(duration);
          }
          this.onChangeSelection();
        }
        break;
      case 'MAX':
        {
          if (isMoreThanYear) {
            this.getLastYearsDates(duration);
          } else {
            this.getLastMonthsDates(duration);
          }
          this.onChangeSelection();
        }
        break;
      default:
        {
          this.getOneMonthDatesForChart();
          this.onChangeSelection();
        }
        break;
    }
  }

  getComparePouchUniversePerformance() {
    let newDate = this.datesForComparison.slice(
      this.datesForComparison.indexOf('{') + 1,
      this.datesForComparison.lastIndexOf('}')
    );
    let newDateArr: any = [];
    for (let date of newDate.split(',')) {
      if (new Date(date) >= new Date(this.startDate)) {
        newDateArr.push(date);
      }
    }

    let newDateforComparison = `{${newDateArr.join(',')}}`;

    let data = {
      pouch_id: this.pouchId,
      universe_name: this.selectedUniverse,
      stock_price_date: newDateforComparison,
    };
    if (this.userType === 'public') {
      this.publicServices.fetchPouchComparison(data).subscribe(
        (res: any) => {
          if (res && res[0].Results) {
            this.performanceData = res[0].Results;
            this.pouchData = this.performanceData.filter(function (item: any) {
              if (item.record_type == 'pouch') return item;
            });
            this.indexData = this.performanceData.filter(function (item: any) {
              if (item.record_type == 'index') return item;
            });
            if (this.pouchData.length > 0 && this.indexData.length > 0) {
              this.arrangeChartData();
            }
          }
        },
        (e) => {
          this.notifyService.showError('', e.error.error.message);
        }
      );
    } else {
      this.pouchService.getComparePouchUniversePerformance(data).subscribe(
        (res: any) => {
          if (res && res[0].Results) {
            this.performanceData = res[0].Results;

            this.pouchData = this.performanceData.filter(function (item: any) {
              if (item.record_type == 'pouch') return item;
            });
            this.indexData = this.performanceData.filter(function (item: any) {
              if (item.record_type == 'index') return item;
            });
            if (this.pouchData.length > 0 && this.indexData.length > 0) {
              this.arrangeChartData();
            }
          }
        },
        (e) => {
          this.notifyService.showError('', e.error.error.message);
        }
      );
    }
  }

  getComparePouchSubscriberUniversePerformance() {
    this.chart = {};
    let data: any;
    let subscriber: any;
    if (this.userType === 'subscriber') {
      subscriber = this.userData.id;
    } else {
      if (this.pouchService.selectedSubscriber) {
        subscriber = this.pouchService.selectedSubscriber;
      } else {
        let subscribersearch = this.subList.find(
          (x: any) => x.subscriber_id == this.selectedSubscriber
        );
        subscriber = subscribersearch?.id;
      }
    }
    if (this.userData.attributes.role === 'subscriber') {
      data = {
        pouch_id: this.pouchId,
        subscriber_id: this.userData.id,
        universe_name: this.selectedUniverse,
        stock_price_date: this.datesForComparison,
      };
    } else {
      let subscriber = this.subList.find(
        (x: any) => x.subscriber_id == this.selectedSubscriber
      );
      data = {
        pouch_id: this.pouchId,
        subscriber_id: subscriber?.subscriber_id || '',
        universe_name: this.selectedUniverse,
        stock_price_date: this.datesForComparison,
      };
    }
//! date changes code
    // console.log(this.PouchSubscriberStocksReturns)
    // let subscriptionStartDate= this.PouchSubscriberStocksReturns?.public___pouch_subscribers[0]?.start_date?.split('T')[0]

    // if(subscriptionStartDate){
    //   const dateArrayString = data.stock_price_date;

    //   const dateArray = dateArrayString.slice(1, -1).split(',');

    //   const pouchSubscriberStartDate = subscriptionStartDate;

    //   if (this.selectedDuration === "MAX") {
    //     dateArray.unshift(pouchSubscriberStartDate);
    // } else {
    //     const durationMapping:any = {
    //         "5y":
    //         {
    //           value:5,
    //           type:'year'
    //         } ,
    //         "3y": {
    //           value:3,
    //           type:'year'
    //         } ,
    //         "1y": {
    //           value:1,
    //           type:'year'
    //         } ,
    //         "6m":{
    //           value:6,
    //           type:'month'
    //         } ,
    //         "1m": {
    //           value:1,
    //           type:'month'
    //         } ,
    //     };

    //     const selectedDurationYears = durationMapping[this.selectedDuration].value;

    //     if (selectedDurationYears) {
    //         const yearsAgo = moment(this.since).subtract(selectedDurationYears, durationMapping[this.selectedDuration].type);

    //         if (moment(pouchSubscriberStartDate) < yearsAgo) {
    //             dateArray.unshift(pouchSubscriberStartDate);
    //         }
    //     }
    // }

    //   data.stock_price_date=`{${dateArray.join(',')}}`
    //   let newDate =data.stock_price_date.slice(
    //     data.stock_price_date.indexOf('{') + 1,
    //     data.stock_price_date.lastIndexOf('}')
    //   );
    //   let newDateArr: any = [];
    //   for (let date of newDate.split(',')) {
    //     if (new Date(date) >= new Date(pouchSubscriberStartDate)) {
    //       newDateArr.push(date);
    //     }
    //   }

    //   let newDateforComparison = `{${newDateArr.join(',')}}`;
    //   data.stock_price_date=   newDateforComparison
    //   console.table( [data.stock_price_date,subscriptionStartDate]);
    // }
//!
    this.pouchService
      .getComparePouchSubscriberUniversePerformance(data)
      .subscribe(
        (res: any) => {
          if (res && res[0].Results) {
            this.performanceData = res[0].Results;

            this.pouchData = this.performanceData.filter(function (item: any) {
              if (item.record_type == 'pouch') return item;
            });
            this.indexData = this.performanceData.filter(function (item: any) {
              if (item.record_type == 'index') return item;
            });
            if (this.pouchData.length > 0 && this.indexData.length > 0) {
              this.arrangeChartData();
            }
            if (this.indexData.length > 0) {
              this.arrangeChartData();
            }
          }
        },
        (e) => {
          this.notifyService.showError('', e?.error?.error?.message);
        }
      );
  }

  arrangeChartData() {
    const maxPercent = Math.round(
      Math.max.apply(
        Math,
        this.performanceData.map(function (item: any) {
          return item.change_perc;
        })
      )
    );
    const minPercent = Math.round(
      Math.min.apply(
        Math,
        this.performanceData.map(function (item: any) {
          return item.change_perc;
        })
      )
    );
    this.pouchData = this.performanceData.filter(function (item: any) {
      if (item.record_type == 'pouch') return item;
    });
    this.indexData = this.performanceData.filter(function (item: any) {
      if (item.record_type == 'index') return item;
    });
    this.dates = this.indexData.map(function (item: any) {
      return item.index_date.split('T')[0];
    });
    const pouchChangePercents = this.pouchData.map(function (item: any) {
      return item.change_perc;
    });
    const indexChangePercents = this.indexData.map(function (item: any) {
      return item.change_perc;
    });
    this.chart = {
      chart1: pouchChangePercents,
      chart2: indexChangePercents,
    };
    this.pouchName = this.pouchData[0]?.index_name;
    if (!this.pouchName) {
      this.pouchName =
        this.userType === 'admin' ? 'Subscriber' : 'My Subscription';
    }
    this.indexName = this.indexData[0]?.index_name;
    this.pouchSince = Math.round(
      this.pouchData[this.pouchData.length - 1]?.change_perc + 100
    ).toLocaleString('en-IN', {
      useGrouping: true,
    });

    this.indexSince = Math.round(
      this.indexData[this.indexData.length - 1].change_perc + 100
    ).toLocaleString('en-IN', {
      useGrouping: true,
    });
    this.minPercent = minPercent - 2;
    this.maxPercent = maxPercent + 2;
  }

  getOneMonthDatesForChart() {
    let uploadedDate = this.chartUsingDate;
    let date1: any = this.getSevenDaysAgoDate(uploadedDate);
    let date2: any = this.getSevenDaysAgoDate(date1);
    let date3: any = this.getSevenDaysAgoDate(date2);
    let date4: any = this.getSevenDaysAgoDate(date3);
    date4 = new Date(this.startDate) > new Date(date4) ? this.startDate : date4;
    this.datesForComparison = `{${this.getSplitDate(
      uploadedDate
    )},${this.getSplitDate(date1)},${this.getSplitDate(
      date2
    )},${this.getSplitDate(date3)},${this.getSplitDate(date4)}}`;
  }

  getSevenDaysAgoDate(uploadedDate: any) {
    const date = new Date(uploadedDate);
    date.setDate(date.getDate() - 7);
    return date;
  }

  getLastMonthsDates(duration: any) {
    let uploadedDate = this.chartUsingDate;
    let date1: any = this.getOneMonthAgoDate(uploadedDate);
    let date2: any = this.getOneMonthAgoDate(date1);
    let date3: any = this.getOneMonthAgoDate(date2);
    let date4: any = this.getOneMonthAgoDate(date3);
    let date5: any = this.getOneMonthAgoDate(date4);
    let date6: any = this.getOneMonthAgoDate(date5);
    date6 = new Date(this.startDate) > new Date(date6) ? this.startDate : date6;
    if (duration == '6m') {
      this.datesForComparison = `{${this.getSplitDate(
        uploadedDate
      )},${this.getSplitDate(date1)},${this.getSplitDate(
        date2
      )},${this.getSplitDate(date3)},${this.getSplitDate(
        date4
      )},${this.getSplitDate(date5)},${this.getSplitDate(date6)}}`;
    } else {
      let date6: any = this.getOneMonthAgoDate(date5);
      let date7: any = this.getOneMonthAgoDate(date6);
      let date8: any = this.getOneMonthAgoDate(date7);
      let date9: any = this.getOneMonthAgoDate(date8);
      let date10: any = this.getOneMonthAgoDate(date9);
      let date11: any = this.getOneMonthAgoDate(date10);
      let date12: any = this.getOneMonthAgoDate(date11);
      date12 =
        new Date(this.startDate) > new Date(date12) ? this.startDate : date12;
      this.datesForComparison = `{${this.getSplitDate(
        uploadedDate
      )},${this.getSplitDate(date1)},${this.getSplitDate(
        date2
      )},${this.getSplitDate(date3)},${this.getSplitDate(
        date4
      )},${this.getSplitDate(date5)},${this.getSplitDate(
        date6
      )},${this.getSplitDate(date7)},${this.getSplitDate(
        date8
      )},${this.getSplitDate(date9)},${this.getSplitDate(
        date10
      )},${this.getSplitDate(date11)},${this.getSplitDate(date12)}}`;
    }
  }

  getLastYearsDates(duration: any) {
    let uploadedDate = this.chartUsingDate;
    let date1: any = this.getOneYearAgoDate(uploadedDate);
    let date2: any = this.getOneYearAgoDate(date1);
    let date2a: any = this.getOneYearAgoDate(date2);
    if (duration == '3y') {
      date2a =
        new Date(this.startDate) > new Date(date2a) ? this.startDate : date2a;
      this.datesForComparison = `{${this.getSplitDate(
        uploadedDate
      )},${this.getSplitDate(date1)},${this.getSplitDate(
        date2
      )},${this.getSplitDate(date2a)}}`;
      return;
    }
    let date3: any = this.getOneYearAgoDate(date2);
    let date4: any = this.getOneYearAgoDate(date3);
    let date4a: any = this.getOneYearAgoDate(date4);
    if (duration == '5y') {
      date4a =
        new Date(this.startDate) > new Date(date4a) ? this.startDate : date4a;
      this.datesForComparison = `{${this.getSplitDate(
        uploadedDate
      )},${this.getSplitDate(date1)},${this.getSplitDate(
        date2
      )},${this.getSplitDate(date3)},${this.getSplitDate(
        date4
      )},${this.getSplitDate(date4a)}}`;
      return;
    }
    ///
    let previousDatesArr: any = [uploadedDate, date1, date2, date3, date4];
    let fromDate4 = new Date(date4).getFullYear()
     let lastDate = new Date(this.startDate).getFullYear();
     let date = this.getOneYearAgoDate(date4)
    for (let i= fromDate4; i>=lastDate;i-- ){
      if(new Date(this.startDate) < new Date(date)){
        previousDatesArr.push(date)
      }else{
        previousDatesArr.push(this.startDate)
        break;
      }
      date = this.getOneYearAgoDate(date)
     }
    let dateArr: any = [];
    for (let d of previousDatesArr) {
      if (new Date(this.startDate) > new Date(d)) {
        dateArr.push(this.startDate);
        break;
      } else {
        dateArr.push(d);
      }
    }

    let splitedDates = dateArr.map((ev: any) => this.getSplitDate(ev));

    this.datesForComparison = `{${splitedDates.join(',')}}`;

    // let date5: any = this.getOneYearAgoDate(date4);
    // let date6: any = this.getOneYearAgoDate(date5);
    // let date7: any = this.getOneYearAgoDate(date6);
    // let date8: any = this.getOneYearAgoDate(date7);
    // let date9: any = this.getOneYearAgoDate(date8);
    // date9 = new Date(this.startDate) > new Date(date9) ? this.startDate : date9;
    // this.datesForComparison = `{${this.getSplitDate(
    //   uploadedDate
    // )},${this.getSplitDate(date1)},${this.getSplitDate(
    //   date2
    // )},${this.getSplitDate(date3)},${this.getSplitDate(
    //   date4
    // )},${this.getSplitDate(date5)},${this.getSplitDate(
    //   date6
    // )},${this.getSplitDate(date7)},${this.getSplitDate(
    //   date8
    // )},${this.getSplitDate(date9)}}`;
  }

  onChangeSelection() {
    if (this.parent === 'createPouch') {
      // checks whether it is from admin create pouch journey
      this.getComparePouchUniversePerformance();
    }

    if (this.userType === 'subscriber') {
      if (this.radioSelected === 1) {
        this.getComparePouchUniversePerformance();
        // this.callPouchData(false);
      } else {
        this.getComparePouchSubscriberUniversePerformance();
        this.getOneMonthDatesForChart();
        // this.getPouchIndexRatio();
        // this.callSubscriberData(false);
      }
    }
    if (
      this.userType === 'admin' ||
      this.userType === 'ria' ||
      this.userType === 'partner'
    ) {
      if (this.radioSelected === 0) {
        this.getComparePouchSubscriberUniversePerformance();
        this.getOneMonthDatesForChart();
        this.getPouchIndexRatio();
      } else {
        this.getComparePouchUniversePerformance();
        this.getPouchIndexRatio()
      }
    }
    if (this.userType === 'public') {
      this.getComparePouchUniversePerformance();
    }
  }

  sinceDateChangeHandler(event: any) {
    this.isSinceDate = true;
    this.since= moment(new Date(event.value)).format('YYYY-MM-DD')
    if (this.pouchId) {
      this.chartUsingDate = event.value;
      this.getChartData(this.selectedDuration);
    }
    if (this.radioSelected == 0) {
      this.callSubscriberData(false);
    } else {
      this.callPouchData(false);
    }
  }

  getOneMonthAgoDate(uploadedDate: any) {
    const date = new Date(uploadedDate);
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  getOneYearAgoDate(uploadedDate: any) {
    const date = new Date(uploadedDate);
    date.setFullYear(date.getFullYear() - 1);
    return date;
  }

  getSplitDate(date: any) {
    date = new Date(date);
    const splitDate = moment(new Date(date)).format('YYYY-MM-DD');
    return splitDate;
  }

  onNextClick(event: any) {
    this.changeIndex.emit(1);
    this.changeTabEvent.emit({
      tabId: '2',
      pouchId: this.pouchId,
      startDate: this.startDate,
    });
  }

  valueChange(e: any) {
    if (e.value === 1) {
      /*    if (this.userData.attributes.role !== 'subscriber') {
        this.callSubscriberData();
      } else {
        this.callPouchData();
      } */
      this.callPouchData();
    } else {
      console.log(e);
      this.callSubscriberData();

      /*   if (this.userData.attributes.role !== 'subscriber') {
        this.callPouchData();
      } else {
        this.callSubscriberData();
      } */
    }
  }
  showDropDown() {
    if (localStorage.getItem('user')) {
      if (this.userData?.attributes.role === 'subscriber') {
        if (this.sliderIntial === false) {
          return false;
        } else {
          this.disableOutline = true;
          return true;
        }
      }
    }

    return true;
  }

  changeSubscriber() {
    this.pouchService.selectedSubscriber = this.selectedSubscriber;
    this.callSubscriberData();
  }

  getNameOfSubscriber(val: any) {
    let subscriber = this.subList?.find((ele: any) => ele.subscriber_id === val);
    if (this.userType !== 'subscriber') {
      return subscriber?.name;
    } else {
      return 'My Subscription';
    }
  }
  sendCagr(res: any) {
    console.log(res);
    this.PouchSubscriberStocksReturns=res
    if (this.selectedSubscriber) {
      this.cagr =  (res?.cagr * 100)?.toFixed(2) ||0;
      this.subscriberChange.emit( (res?.cagr ) ||0);
    }else{
      if(this.userData.attributes.role === 'subscriber'){
        this.cagr = (res?.cagr * 100)?.toFixed(2) ||0;
      }
    }

  }

  createNewCagr() {
    let data = {
      pouch_id: this.pouchId,
      stock_price_date: moment(new Date(this.since)).format('YYYY-MM-DD'),
    };
    this.pouchService.getPouchStocksReturns(data).subscribe((res: any) => {
      // let resArr: any = res[0].pouch_stocks_returns;
      this.cagr = (res[0].Results[0].return_data?.pouch_returns[0].cagr * 100)?.toFixed(2);
    });
  }
  transformtValue(value: any) {
    return Math.abs(value);
  }

  valueChangeSubscriber(event:any){
    if (this.radioSelectedSubscriber !== undefined) {
      this.radioChange.emit(this.radioSelectedSubscriber===1?'N':'Y')
    }
  }
}
