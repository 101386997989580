import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-banner-pouch-detail',
  templateUrl: './banner-pouch-detail.component.html',
  styleUrls: ['./banner-pouch-detail.component.scss'],
})
export class BannerPouchDetailComponent implements OnInit ,OnChanges {
  @Input() pouchDetail: any;
  @Input() tabIndex: any;
  min_investment: any;
  subScriptionData: any;
  banner: any;
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
  console.log(changes)
  this.setBanner();
  }
  ngOnInit(): void {

    this.setBanner();
  }

  setBanner() {
    this.banner = {
      pouch_name: this.pouchDetail?.heading_text,
      pouch_description: this.pouchDetail?.description,
      pouch_icon: this.pouchDetail?.src,
      total_stocks: this.pouchDetail?.total_stocks,
      pouch_review_freq: `${this.pouchDetail?.review_freq} (${this.pouchDetail?.review_freq_day})`,
      pouch_start_date: this.pouchDetail?.start_date,
      pouch_last_review_date: this.pouchDetail?.last_review_date,
      pouch_next_review_date: this.pouchDetail?.next_review_date,
      pouch_riskProfile: this.pouchDetail?.risk_profile,
      min_investment: this.pouchDetail?.min_amt || 0,
      subsription_fee: this.subScriptionData,
      annualizized_returns: this.pouchDetail?.cagr * 100,
      universe_name: this.pouchDetail?.universe,
    };
  }
}
