<div class="success-popup" style="padding: 20px;padding-top:0!important">
    <span class="closeIcon row justify-content-end" (click)="close()"><mat-icon >close</mat-icon></span>
    <div class="popup-sub-container">
      <div class="checkicon">
        <mat-icon class="my-icon">check_circle_outline</mat-icon>
      </div>
      <h3 class="titleStyle">
        Success
      </h3>
      <div class="discriptionTextStyle">
        Your eKyc process is completed succesfully.
      </div>
    </div>
  </div>
