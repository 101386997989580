<div [hidden]="hideElement" class="header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row">
          <div class="col-lg-2 col-7 d-flex align-end">
            <!-- <div type="button" [ngStyle]="{'background':isPublic ? 'white':'transparent'}" class="hamburger"
              [ngClass]="{'active': istoggleActive}" (click)="togglesideBar()"> -->
            <div
              type="button"
              class="hamburger"
              [ngClass]="{ active: istoggleActive }"
              (click)="togglesideBar()"
            >
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
            </div>
            <div class="logo-wrapper">
              <!-- <img *ngIf="isPublic" src="../../assets/images/logo-update.svg" /> -->
              <img src="assets/images/logo-blue.png" />
            </div>
          </div>
          <div
            class="col-lg-10 col-5 d-flex navigation align-item-center justify-content-end"
          >
            <div class="sidebar-nav" [ngClass]="{ open: isSideBarActive }">
              <app-navigation
                (closeMenu)="closeMenu1($event)"
                *ngIf="role"
                [role]="role"
              >
              </app-navigation>
            </div>
            <div class="d-flex justify-content-end align-end removeMargin">
              <div class="header-profile row align-item-center">
                <ng-container>
                  <div class="profile_divider">
                    <img
                      src="assets/images/dummy-user.png"
                      alt=""
                      srcset=""
                      class="profile-img"
                    />
                  </div>
                  <div class="profile_name">
                    <span class="username">{{ logginUser }}</span>
                    <div class="dropdown ud">
                      <button class="dropbtn">
                        <img
                          src="assets/images/icons/down-arrow.svg"
                          alt=""
                          srcset=""
                        />
                      </button>
                      <div class="dropdown-content f-14">
                        <a href="javascript:void(0)" (click)="changePassword()"
                          >Change Password</a
                        >
                        <a href="javascript:void(0)" (click)="onLogout()"
                          >Logout</a
                        >
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
