import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ButtonSwitchService {

  constructor() { }
  private isButtonDisabledSubject = new BehaviorSubject<boolean>(false);
  isButtonDisabled$: Observable<boolean> = this.isButtonDisabledSubject.asObservable();

  setButton(Value: boolean) {
    this.isButtonDisabledSubject.next(Value);
  }
}
