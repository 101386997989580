import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  dataSource: any = [
    {
      src: '/assets/images/icons/electric_mobility.png',
      heading_text: 'Electric Mobility',
      description: 'Companies driving Indias transition to electric vehicles. Transports new era',
      total_stocks: '23',
      total_subscribers: '56',
      current_investment: '13,80,750.00',
      current_returns: '15,10,750',
      return_percent: '10%'
    },
    {
      src: '/assets/images/icons/solar.png',
      heading_text: 'Pharma Majors',
      description: 'Companies to efficintly trackand invest in the pharma sector.',
      total_stocks: '18',
      total_subscribers: '48',
      current_investment: '8,50,000.00',
      current_returns: '8,70,000.00',
      return_percent: '03%'
    },
    {
      src: '/assets/images/icons/medication_pills.png',
      heading_text: 'Solar Superheros',
      description: 'Companies driving India s transition to Solar energy.',
      total_stocks: '21',
      total_subscribers: '33',
      current_investment: '6,50,000.00',
      current_returns: '7,12,000.00',
      return_percent: '08%'
    },
    {
      src: '/assets/images/icons/nature_peak.png',
      heading_text: 'Rural Rockstars',
      description: 'Companies that stand to benefit from increasing rural consumption',
      total_stocks: '16',
      total_subscribers: '33',
      current_investment: '10,50,000.00',
      current_returns: '11,70,00.00',
      return_percent: '10%'
    },
    {
      src: '/assets/images/icons/desktop_monitor.png',
      heading_text: 'IT Giants',
      description: 'India s most powerful IT ompanies in one portfolio. Solid stability',
      total_stocks: '31',
      total_subscribers: '23',
      current_investment: '5,80,000.00',
      current_returns: '8,70,000.00',
      return_percent: '40%'
    },
    {
      src: '/assets/images/icons/bank_building.png',
      heading_text: 'Bank Bharosa',
      description: 'Companies to efficiently track and invest in the banking sector',
      total_stocks: '14',
      total_subscribers: '18',
      current_investment: '9,50,000.00',
      current_returns: '9,70,000.00',
      return_percent: '21%'
    },
  ];

}
