import { PublicService } from 'src/app/modules/public/services/public.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.services';
import { OnBoardingService } from '../../services/onboarding.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  @ViewChild('ngMobileOtpInput') ngMobileOtpInputRef: any;
  @ViewChild('ngEmailOtpInput') ngEmailOtpInputRef: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private router: Router,
    public dialogRef: MatDialogRef<OtpComponent>,
    public onboardingServices: OnBoardingService,
    private notifyService: NotificationService,
    private authService: AuthService,
    private publicService: PublicService,
    private sharedService: SharedService
  ) {}
  resendCounter: number = 0;
  showResendLink: boolean = false;
  mobileOtp: string;
  emailOtp: string;
  otpError: boolean;
  config = {
    allowNumbersOnly: true,
    length: 6,
    inputStyles: {
      color: 'rgba(0, 0, 0, 0.3)',
      fontSize: '25px',
    },
  };
  ngOnInit(): void {
    localStorage.clear();
    this.otpError = false;
    this.timer();
  }

  timer() {
    setTimeout(() => {
      this.showResendLink = true;
    }, 300000);
  }
  onMobileOtpChange(otp: any) {
    this.mobileOtp = otp;
  }
  onEmailOtpChange(otp: any) {
    this.emailOtp = otp;
  }
  resendOTP() {
    this.resendCounter++;
    if (this.resendCounter <= 3) {
      this.onboardingServices
        .generateOTP(this.data.mobile)
        .subscribe((res: any) => {
          if (res.status === 'success') {
            this.notifyService.showSuccess(
              `SUCCESS : New OTP has been sent to your mobile number`,
              ''
            );
          }
        });
    }
  }
  verify() {
    let body = {
      otp: this.mobileOtp,
      mobile_no: this.data.mobile
        .toString()
        .substring(this.data.mobile.toString().length - 10),
    };

    if (this.data.product === 'smartbeta') {
      if (this.data.type === 'old') {
        this.onboardingServices.verifyOTP(body).subscribe((res: any) => {
          try {
            if (res[0]['otp'][0]['otp_id']) {
              this.smartBetaLoad(res);
            } else {
              this.otpError = true;
            }
          } catch (error) {
            this.otpError = true;
          }
        });
      }
      if (this.data.type === 'new') {
        this.onboardingServices.verifyOTP(body).subscribe((res: any) => {
          this.onboardingServices
            .registerSubscriber(this.onboardingServices.createSubscriberData)
            .subscribe((res: any) => {
              if (res[0]['otp'][0]['otp_id']) {
                this.smartBetaLoad(res);
              } else {
                this.otpError = true;
              }
            });
        });
      }
    } else {
      this.onboardingServices.verifyOTP(body).subscribe(
        (res: any) => {
          let isValidOTP;

          try {
            isValidOTP = res[0]['otp'][0]['otp_id'];
          } catch (error) {
            isValidOTP = false;
          }

          if (isValidOTP) {
            this.dialogRef.close();
            if (this.data.product === 'smartbeta') {
              this.router.navigateByUrl(
                `/onboarding/questionaire/${this.data.product}`
              );
            } else {
              console.log(this.onboardingServices.createSubscriberData, '<===');
              this.onboardingServices
                .registerSubscriber(
                  this.onboardingServices.createSubscriberData
                )
                .subscribe(
                  (res: any) => {
                    this.authService.setTokens(res);
                    this.authService
                      .fetchUser(res.access_token , res.id_token )
                      .subscribe((res) => {
                        localStorage.setItem('user', JSON.stringify(res));
                        this.authService.setUser(res);

                        this.router.navigateByUrl('/dashboard');
                      });
                  },
                  (e) => {
                    this.notifyService.showError('', e.error.error.message);
                  }
                );
            }
          } else {
            this.otpError = true;
          }
        },
        (error) => {
          this.notifyService.showError('Error', 'Something went wrong');
        }
      );
    }
  }
  checkExpiry(Cdate: any) {
    let quoteExpiry = new Date(Cdate.setFullYear(Cdate.getFullYear() + 1));
    let now = new Date();

    if (now.getTime() > quoteExpiry.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  smartBetaLoad(res1: any) {
    this.onboardingServices
      .save_subscriber_pan({
       id:this.data.detail.id,
       subscriber_first_name:this.data.detail.subscriber_first_name.value,
       subscriber_last_name:this.data.detail.subscriber_last_name.value,
       subscriber_middle_name:this.data.detail.subscriber_middle_name.value,
       subscriber_pan:this.data.detail.subscriber_pan,
        subscriber_email:this.data.detail.subscriber_email,
      })
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.authService.setTokens(res);
          this.authService
            .fetchUser(res.access_token , res.id_token )
            .subscribe((res) => {
              let resCopy: any = cloneDeep(res);
              resCopy.attributes.pan = this.data.detail.subscriber_pan;
              localStorage.setItem('user', JSON.stringify(resCopy));
              this.authService.setUser(resCopy);
              let userInfo = JSON.parse(
                localStorage.getItem('user') || '{}'
              ).attributes;
              this.publicService.agreementDetailsNew = {
                investment_amount: this.onboardingServices.betaInvestment,
                registration_date: new Date().toISOString().split('T')[0],
                product_name: 'smartbeta',
                agreementValues: this.data.agreementValues,
              };
              sessionStorage.setItem(
                'agreementSmartBeta',
                JSON.stringify(this.publicService.agreementDetailsNew)
              );
              if (
                userInfo?.risk_profile === 'PENDING' ||
                this.checkExpiry(new Date(userInfo?.risk_profile_date))
              ) {
                this.dialogRef.close();
                this.router.navigateByUrl(
                  `/onboarding/questionaire/${this.data.product}`
                );
                return;
              } else {
                if (
                  userInfo?.kyc_date === '' ||
                  this.checkExpiry(new Date(userInfo?.kyc_date))
                ) {
                  this.dialogRef.close();
                  this.router.navigateByUrl('/onboarding/kyc_upload/smartbeta');
                  return;
                } else {
                  this.publicService.agreementFrom = 'from-create';
                  this.publicService.agreementDetailsNew = {
                    investment_amount: this.onboardingServices.betaInvestment,
                    registration_date: new Date().toISOString().split('T')[0],
                    product_name: 'smartbeta',
                    agreementValues: this.data.agreementValues,
                  };
                  sessionStorage.setItem(
                    'agreementSmartBeta',
                    JSON.stringify(this.publicService.agreementDetailsNew)
                  );
                  this.dialogRef.close();
                  this.router.navigateByUrl('/agreement/smartbeta');
                }
              }
            });
        },
        (e: any) => {
          this.notifyService.showError(`Something went wrong :Try Again`, '');
        }
      );
  }
}
