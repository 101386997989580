<div>
  <div [hidden]="hideElement" class="header_public">
    <div style="padding-left: 4%;padding-right: 3%;">
      <div class="row justify-content-center" style="width: 100%;">
        <div class="col-12" style="width: 100%;">
          <div class="row" style="width: 100%;">
            <div class="col-5 col-xxl-2 col-xl-2 col-lg-2  col-md-2 col-sm-3  d-flex align-end">
              <!-- <div type="button" [ngStyle]="{'background':isPublic ? 'white':'transparent'}" class="hamburger"
                [ngClass]="{'active': istoggleActive}" (click)="togglesideBar()"> -->
              <div
                type="button"
                class="hamburger"
                [ngClass]="{ active: istoggleActive }"
                (click)="togglesideBar()"
              >
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
              </div>
              <div class="logo-wrapper" (click)="goTo()">
                <img src="../../assets/images/logo-update.svg" />
              </div>
            </div>
            <div class="col-2 col-xxl-7 col-xl-7 col-lg-8 col-md-8 col-sm-7  d-flex navigation align-item-center justify-content-center "
            >
              <div class="sidebar-nav" [ngClass]="{ open: isSideBarActive }" style="width: 100%;">
                <ng-container style="width: 100%;">
                  <app-navigation
                    (closeMenu)="closeMenu1($event)"
                    *ngIf="role"
                    [role]="role"
                    style="width: 100%;"
                  >
                  </app-navigation>
                </ng-container>
              </div>

            </div>
            <div class="col-5 col-xxl-3 col-xl-3  col-lg-2  col-md-2 col-sm-2  d-flex align-end justify-content-end">
              <div class="d-flex justify-content-end align-end addMargin">
                <div class="header-profile d-flex align-item-center">
                  <ng-container class="d-flex">
                    <div class="notification-icon_public">
                      <img src="assets/images/icons/bell_white.svg" alt="" />
                      <!-- <span matBadge="12" matBadgeSize="small"></span> -->
                    </div>
                    <div class="profile_name px-2">
                      <span class="username">{{ logginUser }}</span>
                      <!-- <div class="dropdown ud">
                        <button
                          class="dropbtn"
                          style="background-color: var(--blue2) !important"
                        >
                          <img
                            src="assets/images/icons/down-arrow.svg"
                            alt=""
                            srcset=""
                          />
                        </button>
                        <div
                          class="dropdown-content f-14"
                          style="padding-top: 0"
                        >
                        <a
                        href="javascript:void(0)"
                        (click)="viewProfile()"
                        >My Profile</a
                      >
                          <a
                            href="javascript:void(0)"
                            (click)="changePassword()"
                            >Change Password</a
                          >
                          <a href="javascript:void(0)" (click)="onLogout()"
                            >Logout</a
                          >
                        </div>
                      </div> -->
                    </div>
                    <div class="dropdown ud pl-1">
                        <img
                        style="width: 30px;"
                        src="assets/images/dummy-user.png"
                          alt=""
                          srcset=""
                        />
                      <div
                        class="dropdown-content f-14"
                        style="padding-top: 0"
                      >
                      <a
                      *ngIf="role == 'subscriber'"
                      href="javascript:void(0)"
                      (click)="goToProfile()"
                      >My Profile</a
                    >
                        <a
                          href="javascript:void(0)"
                          (click)="changePassword()"
                          >Change Password</a
                        >
                        <a href="javascript:void(0)" (click)="onLogout()"
                          >Logout</a
                        >
                      </div>
                    </div>
                    <!-- <div
                      class="profile_divider_public pointer"
                      (click)="goToProfile()"
                    >
                      <img
                        src="assets/images/dummy-user.png"
                        alt=""
                        srcset=""
                        class="profile-img"
                      />
                    </div> -->
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="smartbeta">
    <div class="profile_name px-2 smartbeta" [hidden]="!hideElement">
      <span class="username">{{ logginUser }}123</span>
      <div class="dropdown ud">
        <button
          class="dropbtn"
          style="background-color: var(--blue2) !important"
        >
          <img src="assets/images/icons/down-arrow.svg" alt="" srcset="" />
        </button>
        <div class="dropdown-content f-14" style="padding-top: 0">
          <a href="javascript:void(0)" (click)="changePassword()"
            >Change Password</a
          >
          <a href="javascript:void(0)" (click)="onLogout()">Logout</a>
        </div>
      </div>
    </div>
  </div>
</div>
