<div class="custom-popup">
  <div *ngIf="rp && kyc">
    <div class="text-center kyc" >
      <div class="close">
        <img (click)="close(true)" src="../../../../assets/images/icons/close-icon.png" />
    </div>
      <div class="sucess-popup">
        
        <div class="text-center">
          <img src="../../../../../assets/images/icons/sucess.png" />
        </div>
        <div class="f-16 fw-600 text-darkgrey text-center mt-30 text-wrap">
          You have not updated your Risk Profile and your kyc
        </div>
        <br />
        <div class="f-16 fw-500 text-darkgrey text-center mt-10">          
          To update the Risk Profile, please
          <span (click)="go('rp')" style="color: #065dbc" class="cursor">answer these basic questions.</span> and to update kyc  <span (click)="go('kyc')" style="color: #065dbc" class="cursor"
          >upload documents.</span>
        
        </div>
        
      </div>     
    </div>
  </div>
  <br/>
  <div *ngIf="rp && !kyc">
    <div class="text-center kyc" >
      <div class="close">
        <img (click)="close(true)" src="../../../../assets/images/icons/close-icon.png" />
    </div>
      <div class="sucess-popup">
        
        <div class="text-center">
          <img src="../../../../../assets/images/icons/sucess.png" />
        </div>
        <div class="f-16 fw-600 text-darkgrey text-center mt-30 text-wrap">
          You have not updated your Risk Profile yet.
        </div>
        <br />
        <div class="f-16 fw-500 text-darkgrey text-center mt-10">          
          To update the same, please
          <span (click)="go('rp')" style="color: #065dbc" class="cursor">answer these basic questions.</span>      
        </div>
        
      </div>     
    </div>
  </div>
 
    <br />
  <div style="padding: none !important;" *ngIf="kyc && !rp">
    <div class="text-center kyc" >
      <div class="close">
        <img (click)="close(true)" src="../../../../assets/images/icons/close-icon.png" />
    </div>
      <div class="sucess-popup">
        
        <div class="text-center">
          <img src="../../../../../assets/images/icons/sucess.png" />
        </div>
        <div class="f-16 fw-600 text-darkgrey text-center mt-30 text-wrap">
          You have not updated your KYC yet. 
        </div>
        <br />
        <div class="f-16 fw-500 text-darkgrey text-center mt-10">          
        To update the same, please
        <span (click)="go('kyc')" style="color: #065dbc" class="cursor"
          >upload documents.</span>
        </div>
        
      </div>
      <!-- <div class="text-darkgrey f-18 fw-400 ml-10">
        You have not updated your KYC yet. <br />
        To update the same, please
        <span (click)="go('kyc')" style="color: #065dbc" class="cursor"
          >upload documents.</span
        >
      </div> -->
    </div>
  </div>
    <br />
  <div *ngIf="!rp && rp_exp">
    <div class="text-center kyc" >
      <div class="close">
        <img (click)="close(true)" src="../../../../assets/images/icons/close-icon.png" />
    </div>
      <div class="sucess-popup">
        <div class="text-center">
          <img src="../../../../../assets/images/icons/sucess.png" />
        </div>
        <div class="f-16 fw-600 text-darkgrey text-center mt-30 text-wrap">
          Your Risk Profile has Expired. 
        </div>
        <br />
        <div class="f-16 fw-500 text-darkgrey text-center mt-10">          
          Please
          <span style="color: #065dbc" (click)="go('risk')" class="cursor"
            >update.</span
          >
        </div>
        
      </div>
    </div>
  </div>
    <br />
  <div  *ngIf="!kyc && kyc_exp">
    <div class="text-center kyc" >
      <div class="close">
        <img (click)="close(true)" src="../../../../assets/images/icons/close-icon.png" />
    </div>
      <div class="sucess-popup">
       
        <div class="text-center">
          <img src="../../../../../assets/images/icons/sucess.png" />
        </div>
        <div class="f-16 fw-600 text-darkgrey text-center mt-30 text-wrap">
          Your KYC has Expired.
        </div>
        <br />
        <div class="f-16 fw-500 text-darkgrey text-center mt-10">          
          <br />Please
        <span style="color: #065dbc" (click)="go('kyc')" class="cursor"
          >update.</span>
        </div>
        
      </div>
    </div>
  </div>
</div>
