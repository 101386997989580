<div class="row container sidebar h-100 align-items-center">
    <div class="col-md-12 text-center testimonial-carousel f-black2">
      <ng-container *ngIf="testimonialList.length > 0">
        <ngu-carousel
          #myCarousel
          [inputs]="carouselConfig"
          [dataSource]="isLeft ? testimonialList1 : testimonialList"
        >
          <div *nguCarouselDef="let item" class="item">
            <div class="tile">
              <div>
                <div class="title-text">
                    {{ item?.title }}
                </div>
                <div class="testimonial-text" style="padding-top: 10px; padding-bottom: 45px;">
                  {{ item?.text }}
                </div>
                <!-- <div class="user-name">
                 - {{ item?.author }}
                </div> -->
              </div>
            </div>
          </div>
  
          <div
            class="myPoint mt-4 d-flex justify-content-left"
            NguCarouselPoint *ngIf="myCarousel"
          >
            <div
              class="testimonial-dot-item mx-1"
              *ngFor="let i of myCarousel.pointNumbers"
              [class.active]="i === myCarousel.activePoint"
              (click)="myCarousel.moveTo(i)"
            ></div>
          </div>
        </ngu-carousel>
      </ng-container>
    </div>
  </div>
  