import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {jwtDecode} from 'jwt-decode';

export interface loginInterface {
  USER_ID_FOR_SRP: string;
  requiredAttributes: string;
  userAttributes: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private AUTH = 'auth';
  private USERMGMT = 'usermgmt';
  private LOGIN = 'smartvalues/ory/login';
  private USER_INFO = 'smartvalues/ory/userinfo';
  private FETCH_TOKEN = 'smartvalues/ory/fetchtokens';
  private LOGOUT = 'smartvalues/ory/logout';
  private CHANGE_PASSWORD_URL = 'smartvalues/ory/changepassword';
  private USER_FETCH = 'USER_FETCH';
  private FETCH_ME = 'FETCH_ME';
  private CHANGE_PASSWORD = 'CHANGE_PASSWORD';
  private SET_NEW_USER_PASSWORD = 'SET_NEW_USER_PASSWORD';
  private GET_RECOVERY_CODE = 'smartvalues/ory/getrecoverycode';
  private VERIFY_RECOVERY_CODE = 'smartvalues/ory/verifyrecoverycode';
  private COMPLETE_RECOVERY = 'smartvalues/ory/completerecovery';
  private Token: string;
  private Access_Token: string;
  private readonly REFRESH_TOKEN: string = 'refreshToken';
  private User: any;
  onUserUpdate: Subject<any> = new Subject();

  constructor(private http: HttpClient, public router: Router) {}

  validateUser(email: string, password: string, action: string) {
    const data = {
      action: action,
      username: email,
      password: password,
    };
    return this.http.post(environment.url + this.AUTH, data);
  }

  loginUser(email: string, password: string) {
    const data = {
      username: email,
      password: password,
    };
    return this.http.post(environment.login_base_url + this.LOGIN, data).pipe(tap((res:any)=>{
      let {id_token ,refresh_token } = res
      let decodedToken:any = jwtDecode(id_token );
      localStorage.setItem('id',decodedToken?.identity.id||'')
      // localStorage.setItem('refreshToken',RefreshToken||'')

    }));
  }

  fetchUser(access_token: string, idToken: any) {
    const headers = { Authorization: idToken };
    const data = {
      // action: this.FETCH_ME,
      // username: username
      access_token: access_token,
    };
    return this.http.post(
      `${environment.login_base_url}${this.USER_INFO}`,
      data,
      { headers }
    );
  }

  public get user() {
    return this.User;
  }

  public setUser(user: any) {
    this.User = user;
    this.onUserUpdate.next(user);
    this.router.navigateByUrl('/dashboard');
  }

  public get token() {
    return this.Token;
  }

  public setTokens(data: any) {
    //
    //const refreshToken = data.RefreshToken;
    // localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
    //
    this.Token = data.id_token ;
    this.Access_Token = data.access_token ;
    localStorage.setItem('refreshToken', data.refresh_token );
    //
  }

  getTokens(refresh_token: string,id:string): Observable<any> {
      const data = {
        refresh_token: refresh_token,
        id:id
      };
      return this.http.post(environment.login_base_url + this.FETCH_TOKEN, data).pipe(tap(
        (response) => {
          this.setTokens(response);
        }
      ));

  }

  public refreshToken(minutes: number) {
    return new Observable<any>((subscriber) => {
      interval(1000 * 60 * minutes).subscribe((x) => {
        const refreshToken: any = localStorage.getItem(this.REFRESH_TOKEN);
        const id: any = localStorage.getItem('id');
        //
        if(refreshToken){
          this.getTokens(refreshToken,id).subscribe((response) => {
            subscriber.next(response);
          });
        }
      });
    });
  }

  public startTokenUpdateTimer(time: number) {
    this.refreshToken(time).subscribe((data) => {
      // const token = {
      //           IdToken: data.id_token,
      //           RefreshToken: data.RefreshToken
      //       }
      //
      this.setTokens(data);
    });
  }

  public chnagePassword(oldPassword: any, newPassword: any) {
    const data = {
      // action: this.CHANGE_PASSWORD,
      old_password: oldPassword,
      new_password: newPassword,
      // access_token: this.Access_Token,
    };
    const headers = { Authorization: this.Token };
    return this.http.post(
      environment.login_base_url + this.CHANGE_PASSWORD_URL,
      data,
      { headers: headers }
    );
  }

  public setNewUserPassword(
    username: any,
    newpassword: any,
    newusersession: any
  ) {
    const data = {
      action: this.SET_NEW_USER_PASSWORD,
      username: username,
      password: newpassword,
      session: newusersession,
    };
    return this.http.post(environment.url + this.AUTH, data);
  }

  logoutUser() {
    const refreshToken: any = localStorage.getItem('refreshToken');
    const data = {
      refresh_token: refreshToken,
    };
    const headers = { authorization: this.Token };
    return this.http.delete(`${environment.login_base_url}${this.LOGOUT}`, {
      headers: headers,
      body: data,
    });
  }
  getrecoverycode(userEmail: any) {
    return this.http.post(
      `${environment.login_base_url}${this.GET_RECOVERY_CODE}`,
      {
        username: userEmail,
      }
    );
  }
  verifyrecoverycode(id: any, code: any) {
    return this.http.post(
      `${environment.login_base_url}${this.VERIFY_RECOVERY_CODE}`,
      {
        id: id,
        code: code,
      }
    );
  }
  completeRecovery(id: any, password: any) {
    return this.http.post(
      `${environment.login_base_url}${this.COMPLETE_RECOVERY}`,
      {
        id: id,
        password: password,
      }
    );
  }
}
