<div class="sucess-popup">
  <div class="text-center mt-30">
    <img src="../../../../../assets/images/icons/sucess.png" />
  </div>
  <div class="f-16 fw-600 text-darkgrey text-center mt-30 text-wrap">
    <span *ngIf="data.isRenew !=='updateAmount'&&data.isRenew !=='sellAmount'"> Great, you have successfully subscribed
      to the pouch.</span>
    <span *ngIf="data.isRenew ==='updateAmount' || data.isRenew ==='sellAmount'"> Great, you have successfully updated
      your investment amount.</span>
      <!-- <span *ngIf="data.isRenew ==='sellAmount'"> Great, you have successfully updated
        your sell down amount.</span> -->

    <!-- <br />Your bank
    reference no. is {{ data.no }} and amount paid is {{data.amount}} against order number {{data.order}}<br /><span *ngIf="!data.isRenew">Make, the next SMART move and invest
      in the pouch.</span> -->
  </div>
  <ng-container *ngIf="data.isRenew !== 'sellAmount'">
    <div class="f-17 fw-600 text-darkgrey text-start mt-30 col-md-10 offset-md-1 offfset-sm-0">
      Payment summary:
    </div>
    <div class="f-16 text-darkgrey text-start mt-10">

      <div class="row " style="align-items: baseline;">

        <div class="col-md-3 offset-md-1 offfset-sm-0  fw-600 ">Order Number</div>
        <div class="col-md-8">{{data.order}}</div>
        <div class="col-md-3 offset-md-1 offfset-sm-0  fw-600">Bank reference no</div>
        <div class="col-md-8">{{ data.no }}</div>
        <div class="col-md-3 offset-md-1 offfset-sm-0  fw-600">Amount paid</div>
        <div class="col-md-8">Rs. {{data.amount |number:"1.0-0"}}</div>
      </div>
    </div>
  </ng-container>
  <div class="row justify-center mt-30">
    <ng-container *ngIf="!data.isRenew ||data.isRenew ==='updateAmount'||data.isRenew ==='sellAmount' ">
      <div class="col-md-5">
        <button (click)="close(false)" class="theme-btn border-btn mb-20 f-12 w-100">
          I will Invest Later
        </button>
      </div>
      <div class="col-md-5">
        <button (click)="close(true)" class="theme-btn primary-btn mb-20 f-12 w-100">
          Proceed for Investment
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="data.isRenew == true">
      <div class="col-md-5">
        <button (click)="close(true)" class="theme-btn primary-btn mb-20 f-12 w-100">
          Okay
        </button>
      </div>
    </ng-container>
  </div>
</div>