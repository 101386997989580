<!-- <div class="create-pouch-component">
    <div class="container">
        <div>
            <h1>{{isEdit ? 'Edit' : 'Create new'}} pouch</h1>
            <div>
                <div class="common-form-div">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="common-form-scrolling-div scrollbar-style reconn-tab">
                                <div class="row">
                                    <div class="f-12 col-md-12 home-tabs-section">
                                        <mat-tab-group [selectedIndex]="selected"
                                            (selectedIndexChange)="tabChanged($event)" animationDuration="0ms">
                                            <mat-tab tabindex="0" label="Timeframe" [disabled]="isDisabled">
                                                <app-timeframe (changeTabEvent)="changeTab($event)"></app-timeframe>
                                            </mat-tab>
                                            <mat-tab tabindex="1" label="Performance">
                                                <ng-template matTabContent>
                                                <app-performance [pouchId]="pouchId" [parent]="createPouch" [startDate]="startDate" (changeTabEvent)="changeTab($event)"></app-performance>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab tabindex="2" label="Pouch Composition">
                                                <ng-template matTabContent>
                                                <app-pouch-composition [pouchId]="pouchId" [parent]="createPouch" (changeTabEvent)="changeTab($event)"></app-pouch-composition>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab tabindex="3" label="Pouch Detail">
                                                <app-pouch-details [pouchId]="pouchId"></app-pouch-details>
                                            </mat-tab>
                                        </mat-tab-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="create-pouch-component ui-update">
  <div class="container">
    <div class="container-lg white-bg public-bg-wrapper mt-60 mb-60 pd-30">
      <div>
        <h1>{{isEdit ? 'Edit' : 'Create new'}} pouch</h1>
        <div>
          <div class="common-form-div">
            <div class="row">
              <div class="col-md-12">
                <div class="common-form-scrolling-div scrollbar-style reconn-tab">
                  <div class="row">
                    <div class="f-12 col-md-12 home-tabs-section">
                      <mat-tab-group [selectedIndex]="selected" (selectedIndexChange)="tabChanged($event)"
                        animationDuration="0ms">
                        <mat-tab tabindex="0" label="Timeframe" [disabled]="isDisabled" *ngIf="!isEdit">
                          <app-timeframe (changeTabEvent)="changeTab($event)"></app-timeframe>
                        </mat-tab>
                        <mat-tab tabindex="1" label="Performance"  *ngIf="!isEdit">
                          <ng-template matTabContent>
                            <app-performance [pouchId]="pouchId" [parent]="createPouch" [startDate]="startDate"
                              [since]="since" (changeTabEvent)="changeTab($event)"></app-performance>
                          </ng-template>
                        </mat-tab>
                        <mat-tab tabindex="2" label="Pouch Composition" *ngIf="!isEdit">
                          <ng-template matTabContent>
                            <app-pouch-composition [since]="since" [pouchId]="pouchId" [parent]="createPouch"
                              (changeTabEvent)="changeTab($event)"></app-pouch-composition>
                          </ng-template>
                        </mat-tab>
                        <mat-tab tabindex="3" label="Pouch Detail">

                          <app-pouch-details [minInvestment]="this.minInvestment" [pouchId]="pouchId">
                          </app-pouch-details>


                        </mat-tab>
                      </mat-tab-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
