import { PublicService } from 'src/app/modules/public/services/public.service';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PouchState, selectPouchState } from './store/pouch.state';
import { Store } from '@ngrx/store';
import { Load, AddEdit } from './store/actions/pouch.action';
import { Observable } from 'rxjs/internal/Observable';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CreatePouchPopupComponent } from '../public/components/create-pouch-popup/create-pouch-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/common/services/shared.service';
import { NotificationService } from 'src/app/services/notification.services';
import { environment } from 'src/environments/environment';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { FailureComponent } from './failure/failure.component';
import { MatTableDataSource } from '@angular/material/table';
import { PouchService } from './services/pouch.service';
import { SuccessComponent } from './success/success.component';
import * as _moment from 'moment';
import { InvalidComponent } from './create-pouch/components/pouch-details/invalid/invalid.component';
@Component({
  selector: 'app-investment-pouches',
  templateUrl: './investment-pouches.component.html',
  styleUrls: ['./investment-pouches.component.scss'],
})
export class InvestmentPouchesComponent implements OnInit, OnDestroy {
  showGhost: boolean = true;
  pouches: any;
  userInfo: any;
  getState: Observable<any>;
  showFilter = false;
  defaultBody: any;
  searchValue: any = '';
  selectedStrategies: any;
  selectedRiskProfiles: any;
  objData: any;
  selectedSort: any = -13;
  selectedAmountOperator: any;
  selectedCAGROperator: any;
  // invAmount : number;
  // cagrValue : number;
  filterCount: number = 0;
  uploadedDate: any;
  pouchFilterForm: FormGroup;

  risk_profiles: any = [
    {
      name: 'Low',
      value: 'LOW',
    },
    {
      name: 'Medium',
      value: 'MEDIUM',
    },
    {
      name: 'High',
      value: 'HIGH',
    },
  ];

  investment_strategies: any = [
    {
      name: 'Sector',
      value: 'SECTOR',
    },
    {
      name: 'Theme',
      value: 'THEME',
    },
    {
      name: 'Value',
      value: 'VALUE',
    },
    {
      name: 'Technology',
      value: 'TECHNOLOGY',
    },
  ];

  operators: any = [
    {
      name: '<',
      value: '$lt',
    },
    {
      name: '<=',
      value: '$lte',
    },
    {
      name: '=',
      value: '$eq',
    },
    {
      name: '>',
      value: '$gt',
    },
    {
      name: '>=',
      value: '$gte',
    },
  ];

  sortData: any = [];
  userInfos: any;
  constructor(
    private router: Router,
    private store: Store<PouchState>,
    public dialog: MatDialog,
    public publicService: PublicService,
    private sharedService: SharedService,
    private notifyService: NotificationService,
    private subService: SubsciberService,
    private route: ActivatedRoute,
    public pouchService: PouchService
  ) {
    this.getState = this.store.select(selectPouchState);
    let storage: any = localStorage.getItem('user');
    this.userInfo = JSON.parse(storage).attributes;
    this.paymentUrl = environment.paymentGatewayUrl;
  }
  ngOnDestroy(): void {}
  newPouch: any;
  index: number = 0;
  TabIndex = 0;
  tableDataSource: any;

  ngOnInit(): void {
    let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    this.route.queryParams.subscribe((result: any) => {
      if (result.order_id) {
        this.publicService
          .checkTransactionStatus({ order_id: result.order_id })
          .subscribe((res: any) => {
            let status = res[0].Results[0].order_status;

            if (status === 'Success') {
              let d = JSON.parse(
                sessionStorage.getItem('pouchCreation') || '{}'
              );
              let u = JSON.parse(localStorage.getItem('user') || '{}');
              let ba =   JSON.parse(sessionStorage.getItem('billings') || '{}')
              let payLoad: any = {
                subscription: {
                  sv_feature_id: d.sv_feature_id,
                  sv_feature_name: d.sv_feature_name,
                  subscriber_id: u.id,
                  subscription_amount: d.subscription_amount,
                  subscription_amount_final: Number(d.subscription_amount),
                  discount_amount: 0,
                  promo_code: null,
                  subscription_freq: d.subscription_freq,
                  start_date: new Date().toISOString().split('T')[0],
                  order_id: result.order_id,
                  bank_ref_no: res[0].Results[0].bank_ref_no,
                  end_date: new Date(
                    this.getEndDateSubscription(d.subscription_freq)
                  )
                    .toISOString()
                    .split('T')[0],
                    ...ba
                },

              };
              const getStartDate = () => {
                let currentExpiryDate = _moment(
                  u?.attributes.createpouchexpiry
                );
                let today = _moment(new Date());
                return currentExpiryDate.isAfter(today)
                  ? currentExpiryDate.add(1, 'days').format('YYYY-MM-DD')
                  : today.format('YYYY-MM-DD');
              };

              const getEndDate = (startDate: string, freq: any) => {
                const dt = _moment(startDate);

                switch (freq) {
                  case 'QUARTERLY':
                    dt.add(3, 'months');
                    break;
                  case 'MONTHLY':
                    dt.add(1, 'months');
                    break;
                  case 'YEARLY':
                    dt.add(1, 'years');
                    break;
                  case 'HALF-YEARLY':
                    dt.add(6, 'months');
                    break;
                  default:
                    return '';
                }

                return dt.format('YYYY-MM-DD');
              };

              payLoad.subscription.start_date = getStartDate();
              payLoad.subscription.end_date = getEndDate(
                payLoad.subscription.start_date,
                d.subscription_freq
              );

              this.publicService.saveSubscriptionPouch(payLoad).subscribe(
                (res: any) => {
                  if (
                    (res?.[0]?.old_sv_feature_subscriptions?.error === '-' &&
                      res?.[1]?.sv_feature_subscriptions?.error === '-') ||
                    res?.event_logs?.err == '-'
                  ) {
                    this.updateLocalStorage(u, d, payLoad);
                    if (sessionStorage.getItem('pouchData')) {
                      this.newPouch = JSON.parse(
                        sessionStorage.getItem('pouchData') || '{}'
                      );
                      if (this.newPouch) {
                        this.index = 1;
                        this.TabIndex = 1;
                        this.calculateMinimumInvestment(this.newPouch);
                        this.tableDataSource = new MatTableDataSource(
                          this.newPouch.public___pouch_stocks
                        );
                      } else {
                        this.index = 0;
                        this.TabIndex = 0;
                      }
                    }
                  }
                },
                () => {
                  this.notifyService.showError(
                    'Error',
                    'Something went wrong.'
                  );
                  this.router.navigateByUrl('/investment');
                },
                () => {
                  this.router.navigateByUrl('/investment');
                  window.history.replaceState(
                    {},
                    `${window.location.hostname}/investment?order_id=${result.order_id}`,
                    `${window.location.hostname}/investment`
                  );
                  console.log(window.history);
                }
              );

              const dialogRef = this.dialog.open(SuccessComponent, {
                data: res[0].Results[0],
              });
              dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                  this.router.navigateByUrl(`/pouch-creation`);
                }
              });
            } else if (status === 'Failure') {
              const dialogRef = this.dialog.open(FailureComponent, {
                data: res[0].Results[0].bank_ref_no,
              });
              dialogRef.afterClosed().subscribe((res: any) => {
                if (res) {
                  this.router.navigateByUrl(`/investment`);
                }
                if (sessionStorage.getItem('pouch')) {
                  sessionStorage.removeItem('pouch');
                }
              });
            } else if (status === 'Aborted') {
              const dialogRef = this.dialog.open(FailureComponent, {
                data: 'aborteds',
              });
              dialogRef.afterClosed().subscribe((res: any) => {
                this.router.navigateByUrl(`/investment`);
              });
            } else {
              const dialogRef = this.dialog.open(InvalidComponent, {
                data: status,
              });
              dialogRef.afterClosed().subscribe((res: any) => {
                this.router.navigateByUrl(`/investment`);
              });
            }
          });
      } else {
        if (sessionStorage.getItem('pouch')) {
          sessionStorage.removeItem('pouch');
        }
      }
    });

    this.selectedSort = -13;
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.getState.subscribe((state) => {
      let statePouches = state.pouches;
      if (
        statePouches &&
        !(statePouches.error && statePouches.error.message != '')
      ) {
        this.pouches = [...statePouches];

        this.showGhost = false;
      }
    });
    this.defaultBody = {
      sort: this.selectedSort,
      limit: 10,
      current_date: localStorage
        .getItem('stock_price_uploaded_date')
        ?.split('T')[0],
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
    };

    if (
      this.userInfo.role === 'admin' ||
      this.userInfo.role === 'ria' ||
      this.userInfo.role === 'partner'
    ) {
      this.createPouchFilterForm();
      this.sortData = [
        {
          name: 'Subscribers - High to Low',
          value: -13,
        },
        {
          name: 'Subscribers - Low to High',
          value: 13,
        },
        {
          name: 'Pouch Name - Decending',
          value: -2,
        },
        {
          name: 'Pouch Name - Ascending',
          value: 2,
        },
      ];
    } else {
      this.sortData = [
        {
          name: 'Annualized Returns',
          value: -13,
        },
        {
          name: 'No of Stocks',
          value: 13,
        },
        {
          name: 'Risk Profile',
          value: -2,
        },
      ];
      this.createPouchFilterForm();
    }

    if (!this.pouches || this.searchValue == '') {
      this.store.dispatch(new Load(this.defaultBody));
    }
  }
  editNewPouch: any = {
    pouch_name: '',
    investment_amount: '',
    pouch_description: '',
    icon: '',
    end_date: '',
    stop_loss: 0,
    minimum_investment: '',
    error: {
      pouch_name: false,
      end_date: false,
      stop_loss: false,
      investment_amount: false,
    },
  };
  calculateMinimumInvestment(pouches: any) {
    this.pouchService
      .getPouchStocksReturns({
        pouch_id: pouches.pouch_id,
        stock_price_date: this.newPouch.stock_price_date.split('T')[0],
      })
      .subscribe((response: any) => {
        let pouch_stocks_returns =
          response[0].Results[0].return_data.pouch_stocks_returns;

        let sumArr = [];
        for (let data of pouch_stocks_returns) {
          if (data.min_amt) {
            sumArr.push(data.min_amt);
          }
        }
        this.editNewPouch.minimum_investment = Number(
          sumArr.reduce((a, b) => a + b, 0)
        ).toFixed(2);
        /*  this.editNewPouch.investment_amount = Number(
          sumArr.reduce((a, b) => a + b, 0)
        ).toFixed(2); */
      });
  }
  getEndDateSubscription(freq: any) {
    if (freq === 'MONTHLY') {
      var dt = new Date();
      return dt.setMonth(dt.getMonth() + 1);
    }
    if (freq === 'YEARLY') {
      var dt = new Date();
      return dt.setMonth(dt.getMonth() + 12);
    }
    if (freq === 'HALF-YEARLY') {
      var dt = new Date();
      return dt.setMonth(dt.getMonth() + 6);
    }
    return '';
  }
  updateLocalStorage(userInfo: any, d: any, payload?: any) {
    userInfo.attributes.createpouch = true;
    if (d?.isRenew) {
      userInfo.attributes.createpouchexpiry = payload.subscription.end_date;
    } else {
      userInfo.attributes.createpouchexpiry = new Date(
        this.getEndDateSubscription(d.subscription_freq)
      )
        .toISOString()
        .split('T')[0];
    }

    localStorage.setItem('user', JSON.stringify(userInfo));
    if (sessionStorage.getItem('pouch')) {
      sessionStorage.removeItem('pouch');
    }
  }

  createPouchFilterForm() {
    if (
      this.userInfo.role === 'admin' ||
      this.userInfo.role === 'ria' ||
      this.userInfo.role === 'partner'
    ) {
      this.pouchFilterForm = new FormGroup({
        amountOperator: new FormControl('', [Validators.required]),
        amount: new FormControl('', [Validators.required]),
        cagrOperator: new FormControl('', [Validators.required]),
        cagr: new FormControl('', [Validators.required]),
        strategies: new FormControl([], []), //  [Validators.required]),
        riskProfiles: new FormControl([], [Validators.required]),
      });
    } else {
      this.pouchFilterForm = new FormGroup({
        amountOperatorInvestment: new FormControl('', [Validators.required]),
        amountOperatorAnnualized: new FormControl('', [Validators.required]),
        min_investment: new FormControl('', [Validators.required]),
        annualized_returns: new FormControl('', [Validators.required]),
        // no_of_stocks: new FormControl("", [Validators.required]),
        riskProfiles: new FormControl([], [Validators.required]),
      });
    }
  }

  isshowFilter() {
    this.showFilter = !this.showFilter;
  }

  public selectionChange(event: any, type: string) {
    switch (type) {
      case 'invstment_strategy':
        this.selectedStrategies = event.value;
        break;
      case 'risk_profile':
        this.selectedRiskProfiles = event.value;
        break;
    }
  }

  applyFilters() {
    if (this.pouchFilterForm.valid) {
      let body: any = {
        sort: this.selectedSort,
        current_date: this.uploadedDate.split('T')[0],
      };
      if (this.searchValue.length >= 3) {
        body.pouch_name = this.searchValue;
      }

      let investment_amount: any = {};
      investment_amount[this.pouchFilterForm.value.amountOperator] =
        this.pouchFilterForm.value.amount;
      body.investment_amount = investment_amount;

      let cagr: any = {};
      cagr[this.pouchFilterForm.value.cagrOperator] =
        this.pouchFilterForm.value.cagr;
      body.cagr = cagr;

      if (this.pouchFilterForm.value.strategies.length > 0) {
        body.investment_strategy = this.pouchFilterForm.value.strategies;
      }
      if (this.pouchFilterForm.value.riskProfiles.length > 0) {
        body.risk_profile = this.pouchFilterForm.value.riskProfiles;
      }
      this.store.dispatch(new Load(body));
      this.showFilter = !this.showFilter;
    }
  }

  cancelFilters() {
    this.createPouchFilterForm();
    this.filterCount = 0;
    this.store.dispatch(new Load(this.defaultBody));
    this.showFilter = !this.showFilter;
  }

  //  resetFilters(){
  //   this.selectedStrategies = '';
  //   this.selectedRiskProfiles = '';
  //   this.selectedAmountOperator = '';
  //   this.selectedCAGROperator = '';
  //   this.invAmount  = 0;
  //   this.cagrValue  = 0;
  //  }

  //  getFilterCount(){
  //    return this.filterCount;
  //  }

  sortOnChange(event: any) {
    let body: any = {
      sort: this.selectedSort,
      current_date: this.uploadedDate.split('T')[0],
      pouch_name: this.searchValue,
    };
    this.store.dispatch(new Load(body));
  }
  searchHandler(event: any) {
    if (this.userInfo.role === 'subscriber') {
      this.searchHandlerSubscriberAndPublic(event);
    } else {
      this.searchHandlerAdmin(event);
    }
  }

  searchHandlerAdmin(event: any) {
    if (this.searchValue.length >= 3) {
      this.showGhost = true;
      let body: any = {
        sort: this.selectedSort,
        current_date: this.uploadedDate.split('T')[0],
        pouch_name: this.searchValue,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      };
      if (
        this.pouchFilterForm.value.amountOperator &&
        this.pouchFilterForm.value.amount
      ) {
        let investment_amount: any = {};
        investment_amount[this.pouchFilterForm.value.amountOperator] =
          this.pouchFilterForm.value.amount;
        body.investment_amount = investment_amount;
      }
      if (
        this.pouchFilterForm.value.cagrOperator &&
        this.pouchFilterForm.value.cagr
      ) {
        let cagr: any = {};
        cagr[this.pouchFilterForm.value.cagrOperator] =
          this.pouchFilterForm.value.cagr;
        body.cagr = cagr;
      }

      if (this.pouchFilterForm.value.strategies.length > 0) {
        body.investment_strategy = this.pouchFilterForm.value.strategies;
      }
      if (this.pouchFilterForm.value.riskProfiles.length > 0) {
        body.risk_profile = this.pouchFilterForm.value.riskProfiles;
      }

      this.store.dispatch(new Load(body));
    } else {
      this.store.dispatch(new Load(this.defaultBody));
    }
  }
  searchHandlerSubscriberAndPublic(event: any) {
    if (this.searchValue.length >= 3) {
      this.showGhost = true;
      let body: any = {
        sort: 2,
        current_date: this.uploadedDate.split('T')[0],
        pouch_name: this.searchValue,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
      };

      if (
        this.pouchFilterForm.value.amountOperatorInvestment &&
        this.pouchFilterForm.value.min_investment
      ) {
        let minimum_investment: any = {};
        minimum_investment[
          this.pouchFilterForm.value.amountOperatorInvestment
        ] = this.pouchFilterForm.value.min_investment;
        body.minimum_investment = minimum_investment;
      }
      if (
        this.pouchFilterForm.value.amountOperatorAnnualized &&
        this.pouchFilterForm.value.annualized_returns
      ) {
        let annualized_returns: any = {};
        annualized_returns[
          this.pouchFilterForm.value.amountOperatorAnnualized
        ] = this.pouchFilterForm.value.annualized_returns;
        body.annualized_returns = annualized_returns;
      }

      if (this.pouchFilterForm.value.riskProfiles.length > 0) {
        body.risk_profile = this.pouchFilterForm.value.riskProfiles;
      }
      // this.publicFacadeService.getPouchListfromStore({ ...body });
      this.store.dispatch(new Load(body));

      // need to remove once sort value is given
      /*   this.publicPouchList = this.publicPouchListCopy.filter((ele: any) =>
        ele.heading_text.includes(this.searchValue)
      ); */

      //
    } else {
      this.store.dispatch(new Load(this.defaultBody));
    }
  }
  formatDate(date: any) {
    const d = new Date(date);
    // This will return an ISO string matching your local time.
    return new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes() - d.getTimezoneOffset()
    )
      .toISOString()
      .split('T')[0];
  }
  completedAgrement: any;
  createButtonClick() {
    let storage: any = localStorage.getItem('user');
    let expiredDate = this.formatDate(
      JSON.parse(storage).auth_details.expires_at
    );
    let currentDate = this.formatDate(new Date());

    if (this.userInfo.role === 'subscriber') {
      this.publicService.postStockRun({}).subscribe((res: any) => {
        let toCreatePouch = res[0]?.Results[0]?.allow_run;
        if (toCreatePouch === true) {
          let data: any = [];

          if (
            this.userInfo.createpouch === true &&
            expiredDate >= currentDate
          ) {
            this.router.navigateByUrl('/pouch-creation');
            return;
          } else {
            this.publicService
              .postViewAgreements({
                subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')
                  .id,
                product_name: 'create_pouch',
              })
              .subscribe((res: any) => {
                console.log(res);
                if (Object.keys(res[0]).length > 0) {
                  // this.completedAgrement = 'Yes';
                } else {
                  this.completedAgrement = 'No';
                }
                if (Object.keys(res[0]).length > 0) {
                  let value = res[0]?.agreements?.[0];
                  let pouchCreation = {
                    subscription_amount: Number(value.subscription_amount_final),
                    subscription_freq: value.subscription_freq,
                    sv_feature_name: value.product_name,
                    agreement_id: value.agreement_id,
                    subscriber_id: value.subscriber_id,
                    sv_feature_id: value.sv_feature_id,
                  };

                  sessionStorage.setItem(
                    'pouchCreation',
                    JSON.stringify(pouchCreation)
                  );
                  this.publicService.getSubscriptionList().subscribe(
                    (r: any) => {
                      data = r[0].subscriptions;
                    },
                    (e: any) => {
                      this.notifyService.showError('', e.error.error.message);
                    },
                    () => {
                      this.makePayment();
                      // let dialog = this.dialog.open(CreatePouchPopupComponent, {
                      //   data: {
                      //     data: data,
                      //     from: 'button',
                      //     agreementSign: this.completedAgrement,
                      //     registerDate: res[0]?.agreements?.[0].registration_date,
                      //   },
                      // });
                      // dialog.afterClosed().subscribe((val: string) => {
                      //   if (val === 'subscribe') {
                      //     this.router.navigateByUrl('/agreement');
                      //   } else if (val === 'try') {
                      //     this.router.navigateByUrl('/pouch-creation');
                      //   } else if (val === 'subscriber') {
                      //     this.router.navigateByUrl('/agreement-subscribe');
                      //   } else if (val === 'makePayment') {
                      //     // this.makePayment();
                      //   } else {
                      //     this.router.navigateByUrl('/investment');
                      //   }
                      // });
                    }
                  );
                } else {
                  this.publicService.getSubscriptionList().subscribe(
                    (r: any) => {
                      data = r[0].subscriptions;
                    },
                    (e: any) => {
                      this.notifyService.showError('', e.error.error.message);
                    },
                    () => {
                      let dialog = this.dialog.open(CreatePouchPopupComponent, {
                        data: {
                          data: data,
                          from: 'button',
                          agreementSign: this.completedAgrement,
                          registerDate: '',
                        },
                      });
                      dialog.afterClosed().subscribe((val: string) => {
                        if (val === 'subscribe') {
                          this.router.navigateByUrl('/agreement');
                        } else if (val === 'try') {
                          this.router.navigateByUrl('/pouch-creation');
                        } else if (val === 'subscriber') {
                          this.router.navigateByUrl('/agreement-subscribe');
                        } else if (val === 'makePayment') {
                          this.makePayment();
                        } else {
                          this.router.navigateByUrl('/investment');
                        }
                      });
                    }
                  );
                }
              });
          }
        } else {
          this.completedAgrement = 'exhausted';
          this.dialog.open(CreatePouchPopupComponent, {
            data: {
              agreementSign: this.completedAgrement,
            },
          });
        }
      });
    } else {
      this.router.navigateByUrl('/investment/create-pouch');
    }
  }
  paymentData: any = {
    access_code: '',
    encRequest: '',
    order_id: '',
  };
  makePayment() {
    // let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    // this.publicService
    //   .intiatePaymentGatewaysvf({
    //     subscriber_id: this.userInfo.id,
    //     redirect_url: `${window.location.host}/pouch-creation`,
    //     subscription_freq: d.subscription_freq,
    //     sv_feature_name: 'create_pouch',
    //     promo_code: '',
    //   })
    //   .subscribe((res: any) => {
    //     if (res) {
    //       this.paymentData = res;
    //       this.redirectToPayment(res);
    //     }
    //   });
    let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    let data = {
      docs: {
        subscriber_id: d.subscriber_id,
        investment_amount: 0,
        registration_date: new Date().toISOString().split('T')[0],
        product_name: 'create_pouch',
        agreement_id: d.agreement_id,
        subscription_type: '',
        subscription_perc: 0,
        subscription_amount: d.subscription_amount,
        subscription_amount_final: d.subscription_amount,
        subscription_freq: d.subscription_freq,
        promo_code: '',
        discount_amount: 0,
        stoploss: 0,
        sv_feature_id: d.sv_feature_id,
      },
    };
    this.publicService
      .intiatePaymentGatewaysvf({
        subscriber_id: d.subscriber_id,
        redirect_url: `${window.location.host}/investment`,
        subscription_freq: d.subscription_freq,
        sv_feature_name: 'create_pouch',
        promo_code: '',
      })
      .subscribe((res: any) => {
        if (res) {
          this.paymentData = res;
          this.redirectToPayment(res);
        }
      });
    // this.subService.postSaveSubscriberInvestment(data).subscribe((res: any) => {
    //   if (res) {
    //     this.publicService
    //       .intiatePaymentGatewaysvf({
    //         subscriber_id: d.subscriber_id,
    //         redirect_url: `${window.location.host}/pouch-creation`,
    //         subscription_freq: d.subscription_freq,
    //         sv_feature_name: 'create_pouch',
    //         promo_code: '',
    //       })
    //       .subscribe((res: any) => {
    //         if (res) {
    //           this.paymentData = res;
    //           this.redirectToPayment(res);
    //         }
    //       });
    //   }
    // });
  }
  @ViewChild('myForm', { static: true }) public ngForm: ElementRef =
    {} as ElementRef;
  paymentUrl: string = '';
  redirectToPayment(data: any) {
    let encRequest = document.getElementById('encRequest') as HTMLInputElement;
    let access_code = document.getElementById(
      'access_code'
    ) as HTMLInputElement;
    encRequest.value = data.encRequest;
    access_code.value = data.access_code;
    this.paymentData = data;
    const interval = setInterval(() => {
      if (this.paymentData.access_code && this.paymentData.encRequest) {
        sessionStorage.setItem(
          'pouch',
          JSON.stringify(this.publicService.agreementDetailsNew)
        );
        this.ngForm.nativeElement.submit();

        clearInterval(interval);
      }
    }, 100);
  }
}
