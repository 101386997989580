import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
//Components
import { DeletePopupComponent } from '../../common/components/delete-popup/delete-popup.component';
import { WarningPopupComponent } from '../../common/components/warning-popup/warning-popup.component';
import { DatepickerComponent } from '../../common/components/datepicker/datepicker.component';
import { DetailsComponent } from '../investment-pouches/details/details.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ChangeInvestmentPopupComponent } from '../investment-pouches/change-investment-popup/change-investment-popup.component';
import { ChartComponent } from '../investment-pouches/chart/chart.component';
import {
  TimeframeComponent,
  PouchDetailsComponent,
  ReviewdateComponent,
} from '../investment-pouches/create-pouch/components';
import { DialogBoxComponent } from '../investment-pouches/create-pouch/components/dialog-box/dialog-box.component';
import { TimeFrameDialogComponent } from '../investment-pouches/create-pouch/components/timeframe-dialog/timeframe-dialog.component';
import { CreatePouchComponent } from '../investment-pouches/create-pouch/create-pouch.component';
import { CreateUniverseComponent } from '../investment-pouches/create-universe/create-universe.component';
import { HowCreatedComponent } from '../investment-pouches/how-created/how-created.component';
import { MapSubscriberPopupComponent } from '../investment-pouches/map-subscriber-popup/map-subscriber-popup.component';
import { MapSubscriberComponent } from '../investment-pouches/map-subscriber/map-subscriber.component';
import { PerformanceComponent } from '../investment-pouches/performance/performance.component';
import { HorizontalChartComponent } from '../investment-pouches/pouch-composition/horizontal-chart/horizontal-chart.component';
import { PouchCompositionComponent } from '../investment-pouches/pouch-composition/pouch-composition.component';
import { ReviewHistoryComponent } from '../investment-pouches/review-history/review-history.component';
import { SubscriberComponent } from '../investment-pouches/subscriber/subscriber.component';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PublicFooterComponent } from '../public/components/public-footer/public-footer.component';
import { PublicHeaderComponent } from '../public/components/public-header/public-header.component';
import { BackgroundComponent } from '../public/components/background/background.component';
import { MoreComponent } from '../more/more.component';
import { RegisterComponent } from '../onbording/components/register/register.component';
import { DashboardDetailsComponent } from '../public/components/dashboard-details/dashboard-details.component';
import { PouchlistComponent } from '../public/components/pouchlist/pouchlist.component';
import { PouchlistDetailsComponent } from '../public/components/pouchlist-details/pouchlist-details.component';
import { CommonDialogWaitingComponent } from './common-dialog/common-dialog.component';
import { BannerPouchDetailComponent } from './banner-pouch-detail/banner-pouch-detail.component';
import { BannerSubscriptionDetailsComponent } from './banner-subscription-details/banner-subscription-details.component';
import { FileUploadsComponent } from '../onbording/components/questionaire/file-uploads/fileUploads.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { KycComponent } from '../profile/components/kyc/kyc.component';
import { RiskProfileComponent } from '../profile/components/risk-profile/risk-profile.component';
import { AgreementGridViewComponent } from '../more/components/agreement-grid-view/agreement-grid-view.component';
import { RenewSubscriptionPopupComponent } from './renew-subscription-popup/renew-subscription-popup.component';
import { SubscriptionRenewPopupComponent } from './subscription-renew-popup/subscription-renew-popup.component';
import { AmountUpdatePopupComponent } from './banner-subscription-details/amount-update-popup/amount-update-popup.component';
import { PlayVideoPopupComponent } from './play-video-popup/play-video-popup.component';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

const COMPONENTS: any[] = [
  DeletePopupComponent,
  AgreementGridViewComponent,
  WarningPopupComponent,
  DatepickerComponent,
  DetailsComponent,
  CreatePouchComponent,
  TimeframeComponent,
  PouchDetailsComponent,
  CreateUniverseComponent,
  DialogBoxComponent,
  TimeFrameDialogComponent,
  PerformanceComponent,
  PouchCompositionComponent,
  SubscriberComponent,
  MapSubscriberComponent,
  HowCreatedComponent,
  ReviewHistoryComponent,
  ReviewdateComponent,
  MapSubscriberPopupComponent,
  ChartComponent,
  HorizontalChartComponent,
  ChangeInvestmentPopupComponent,
  PublicFooterComponent,
  PublicHeaderComponent,
  BackgroundComponent,
  MoreComponent,
  RegisterComponent,
  DashboardDetailsComponent,
  PouchlistComponent,
  PouchlistDetailsComponent,
  FileUploadsComponent,
  KycComponent,
];
@NgModule({
  declarations: [
    ...COMPONENTS,
    CommonDialogWaitingComponent,
    BannerPouchDetailComponent,
    BannerSubscriptionDetailsComponent,
    RiskProfileComponent,
    RenewSubscriptionPopupComponent,
    SubscriptionRenewPopupComponent,
    AmountUpdatePopupComponent,
    PlayVideoPopupComponent
  ],
  imports: [
    CommonModule,
    // NgxIntlTelInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBadgeModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatBadgeModule,
    MatDividerModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CommonModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    MatFormFieldModule,
    MatMenuModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
  ],
  exports: [
    DeletePopupComponent,
    WarningPopupComponent,
    DatepickerComponent,
    DetailsComponent,
    MatBadgeModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    CreatePouchComponent,
    TimeframeComponent,
    PouchDetailsComponent,
    CreateUniverseComponent,
    DialogBoxComponent,
    TimeFrameDialogComponent,
    PerformanceComponent,
    PouchCompositionComponent,
    SubscriberComponent,
    MapSubscriberComponent,
    HowCreatedComponent,
    ReviewHistoryComponent,
    ReviewdateComponent,
    MapSubscriberPopupComponent,
    ChartComponent,
    HorizontalChartComponent,
    ChangeInvestmentPopupComponent,
    ReactiveFormsModule,
    PublicFooterComponent,
    PublicHeaderComponent,
    BackgroundComponent,
    MoreComponent,
    RegisterComponent,
    DashboardDetailsComponent,
    PouchlistComponent,
    BannerPouchDetailComponent,
    FileUploadsComponent,
    KycComponent,
    RiskProfileComponent,
    AgreementGridViewComponent,
    PlayVideoPopupComponent
  ],
})
export class SharedModule {}
