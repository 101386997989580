import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as Chart from 'chart.js';
declare module 'chart.js' {
  interface ChartTooltipModel {
    title: string[];
    body: ChartTooltipModelBody[];
    labelColors: ChartTooltipLabelColor[];
  }
}

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges {
  public canvas: any;
  public ctx: any;
  public labels: any = [
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
  ];
  chart: any;

  constructor() {}

  uploadedDate: any;
  @Input() chartData: any;
  @Input() datesData: any;
  @Input() pouchName: any;
  @Input() indexName: any;
  @Input() minIndex: any;
  @Input() maxIndex: any;
  @Input() duration: any;
  @Input() pouchPerformance: boolean;

  shortMonthNames: any = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  ngOnInit(): void {
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.uploadedDate = new Date(this.uploadedDate);
    this.createLineChart(this.datesData, this.chartData, 'myChart');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.datesData || this.chartData) {
      if (this.chart) {
        this.removeData(this.chart);
      }
      this.createLineChart(this.datesData, this.chartData, 'myChart');
    }
  }

  removeData(chart: any) {
    chart.data.labels = [];
    chart.data.datasets = [];
    chart.update();
  }

  getDisplayLabels(labels: any) {
    let displayLabels: any = [];
    // if(this.duration =='1m' || this.duration == '6m' || this.duration == '1y')
    // {
    labels?.forEach((label: any) => {
      label = new Date(label);
      displayLabels.push(
        `${label.getDate()}-${
          this.shortMonthNames[label.getMonth()]
        }-${label.getFullYear()}`
      );
    });
    return displayLabels;
    // }
    // else (this.duration == '3y' || this.duration == '5y' || this.duration == 'MAX')
    // {
    //   let displayLabels : any = [];
    //   labels.forEach((label :any) => {
    //     label = new Date(label);
    //     displayLabels.push(`${label.getFullYear()}`)
    //   })
    //   return displayLabels;
    //}
  }

  private createLineChart(
    labels: any,
    dataCases: { chart1: any; chart2: any },
    chartId: string
  ) {
    this.canvas = document.getElementById(chartId);
    this.ctx = this.canvas.getContext('2d');

    let displayLabels = this.getDisplayLabels(labels);

    Chart.defaults.LineWithLine = Chart.defaults.line;

    Chart.controllers.LineWithLine = Chart.controllers.line.extend({
      draw: function (ease: any) {
        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          var activePoint = this.chart.tooltip._active[0],
            ctx = this.chart.ctx,
            x = activePoint.tooltipPosition().x,
            topY = this.chart.legend.bottom,
            bottomY = this.chart.chartArea.bottom;

          // draw line

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 0.3;
          ctx.strokeStyle = '#333333';
          ctx.stroke();
          ctx.restore();
        }

        Chart.controllers.line.prototype.draw.call(this, ease);
      },
    });
    this.chart = new Chart(this.ctx, {
      type: 'LineWithLine',

      data: {
        labels: displayLabels,
        datasets: [
          {
            label: this.pouchName, //"Electric Mobility",
            data: dataCases?.chart1,
            backgroundColor: '#00861D',
            borderColor: '#00861D',
            fill: false,
            borderWidth: 2,
            pointBackgroundColor: '#00861D',
            pointRadius: 0,
            pointBorderColor: '#00861D',
            pointHoverBackgroundColor: '#00861D',
            pointHoverBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBorderColor: '#00861D',
          },
          {
            label: this.indexName, //"Equity Large & Mid Cap",
            data: dataCases?.chart2,
            backgroundColor: '#FA6900',
            borderColor: '#FA6900',
            fill: false,
            borderWidth: 2,
            pointRadius: 0,
            pointBackgroundColor: '#FA6900',
            pointBorderColor: '#FA6900',
            pointHoverBackgroundColor: '#FA6900',
            pointHoverBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBorderColor: '#FA6900',
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0.5,
          },
        },

        legend: {
          display: false,
        },

        // title: {
        //   display: false,
        //   text: "First chart"
        // },

        tooltips: {
          mode: 'index',
          intersect: false,
          enabled: false,

          backgroundColor: 'rgba(255,255,0,1)',

          custom: (tooltipModel) => {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip');
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            // console.log(typeof(tooltipEl.style.opacity))
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            const getBody = (bodyItem: { lines: any }) => {
              return bodyItem.lines;
            };

            // Set Text

            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = '<thead>';

              titleLines.forEach(function (title: any) {
                //] innerHtml += '<tr><th>' + title + '</th></tr>';
              });
              innerHtml += '</thead><tbody class="p-2">';

              bodyLines.forEach(function (body: any, i: any) {
                var label = body[0].split(':');

                var colors = tooltipModel.labelColors[i];

                var style = 'color:' + colors.backgroundColor;
                style += '; border-color:' + colors.borderColor;
                style += '; border-width: 2px';
                style += '; font-family: "Poppins", sans-serif !important';
                innerHtml += `<tr ><td style =${style}> ${label[0]}<span class="px-2" style="color:black">:${label[1]}</span> </td></tr>`;
              });
              innerHtml += `<tr ><td style ="color:#666666"> ${titleLines}</td></tr>`;

              innerHtml += '</tbody>';

              var tableRoot = tooltipEl.querySelector('table');
              tableRoot!.innerHTML = innerHtml;
              // `this` will be the overall tooltip
              var position = document
                .getElementById(chartId)
                ?.getBoundingClientRect();
              tooltipEl.style.zIndex = '3';
              tooltipEl.style.opacity = '1';
              tooltipEl.style.background = 'white';

              // tooltipEl.style.left = position!.left + window.pageXOffset + tooltipModel.caretX + 'px';

              if (this.pouchPerformance) {
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left =
                  position!.left + tooltipModel.caretX + 20 + 'px';
                tooltipEl.style.top =
                  position!.top + tooltipModel.caretY - 10 + 'px';
              } else {
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left =
                  position!.left + tooltipModel.caretX - 200 + 'px';
                tooltipEl.style.top =
                  position!.top + tooltipModel.caretY - 50 + 'px';
              }

              // tooltipEl.style.top = position!.top + window.pageYOffset + tooltipModel.caretY + 'px';

              tooltipEl.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.08)';

              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          },
        },
        hover: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: this.showDisplayLine(),
                borderDash: [8, 4],
              },
              ticks: {
                beginAtZero: true,
                min: this.setMin(),
                max: this.setMax(),
                stepSize: this.calculateStepSize(),
                maxTicksLimit: this.pouchPerformance ? 2 : 0,
              },
            },
          ],
        },
      },
    });
  }

  calculateStepSize() {
    if (this.pouchPerformance) {
      let ticks = this.chart?.config.options.scales.yAxes[0].ticks;
      let min = Math.round(Math.min(...this.chartData.chart2));
      let max = Math.round(Math.max(...this.chartData.chart2));
      return Math.ceil((Math.round((min + max) / 3) / 10) * 10);
    }
    return 2;
  }
  showDisplayLine() {
    if (this.pouchPerformance) {
      return true;
    }
    return false;
  }
  setMin() {
    if (this.pouchPerformance) {
      return Math.round(Math.min(...this.chartData.chart2));
    } else {
      return this.minIndex;
    }
  }
  setMax() {
    if (this.pouchPerformance) {
      return Math.ceil(Math.max(...this.chartData.chart2) / 10) * 10;
    } else {
      return this.maxIndex;
    }
  }
}
