<div class="popup">
    <div class="d-flex pd-15 relative">
        <div class="f-16 dtext-grey">Review History</div>
        <div class="icon">
            <mat-icon [mat-dialog-close]="true">close</mat-icon>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="container">
        <div class="mg-10">
            <a href="#">Download as CSV</a>
        </div>
    </div>
    <div></div>
    <div class="container">
        <div class="d-flex f-16">
            <div>
                <div class="head">Composite</div>
                <div>
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                        <ng-container matColumnDef="stocks">

                            <th mat-header-cell *matHeaderCellDef> Stocks
                                <!-- <tr> Stocks Weightage
                    </tr> -->
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.stocks}} </td>
                        </ng-container>

                        <ng-container matColumnDef="weight">
                            <th mat-header-cell *matHeaderCellDef> Weightage
                                <!-- <tr> Stocks Weightage
                                </tr> -->
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
            <div>
                <h4 class="head">Review - 1 May 2021</h4>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">


                    <ng-container matColumnDef="stocks">
                        <th mat-header-cell *matHeaderCellDef> Stocks
                            <!-- <tr> Stocks Weightage
                            </tr> -->
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.stocks}} </td>
                    </ng-container>

                    <ng-container matColumnDef="weight">
                        <th mat-header-cell *matHeaderCellDef> Weightage
                            <!-- <tr> Stocks Weightage
                                        </tr> -->
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div>
                <h4 class="head">Review - 1 Jan 2021</h4>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="stocks">
                        <th mat-header-cell *matHeaderCellDef> Stocks
                            <!-- <tr> Stocks Weightage
                            </tr> -->
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.stocks}} </td>
                    </ng-container>

                    <ng-container matColumnDef="weight">
                        <th mat-header-cell *matHeaderCellDef> Weightage
                            <!-- <tr> Stocks Weightage
                                        </tr> -->
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>

</div>