import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Injectable({providedIn:"root"})
export class UtilsService{

    constructor( public dialog: MatDialog) {
    }

    public getDisplayDate(date: any) {
        const datetime: any[] = (date && date.indexOf('T') !== -1) ? date.split('T') : [];
        let formattedDate = '';
        if (datetime.length > 1) {
            const spittedDate: any[] = datetime[0].split('-');
            if(spittedDate.length > 2)
            {
                formattedDate = `${spittedDate[2]}-${spittedDate[1]}-${spittedDate[0]}`;
            }
        }
        return formattedDate;
    }

    public appendDateByTimeframe(date : any, timeframe : any ){
        try{
                return date.setDate(date.getDate() + TimeFrame[timeframe]);
        }
        catch(error){
            return 'Error.'
        }
        // switch(timeframe){
        //     case timeframe === TimeFrame.DAILY :
        //     {
        //         return date.set(date + 1);
        //     }
        // }
    }
    public RemoveDuplicates = (array:any[], key:string) => {
        return array.reduce((arr, item) => {
          const removed = arr.filter((i:any) => i[key] !== item[key]);
          return [...removed, item];
        }, []);
      };
    
   public openDialog = (component:any,data:any,width:any)=> {
        let dialogRef = this.dialog.open(component, {
          width: width,
          data: data
        });
        return dialogRef;
    }


}

enum TimeFrame {
    DAILY = 1,
    WEEKLY = 7,
    MONTHLY = 30,
    QUARTERLY = 90,
    YEARLY = 365
}