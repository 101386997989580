<div class="riskProfileContainer">
  <div class="text-center mt-30">
    <img src="../../../../assets/images/icons/warning.svg" style="width: 50px;" />
  </div>
  <div class="f-16 fw-400 text-darkgrey text-center mt-30">
    <strong> {{data[2]}}</strong> does not match your risk profile
  </div>

  <div class="f-16 fw-400 text-darkgrey text-center mt-20">
    <span class="fw-600"> Do you still want to continue?</span>
  </div>
  <div class="row justify-center mt-20">
    <div class="col-4 col-sm-3" style="margin-left: 20px;">
      <button class="theme-btn border-btn mb-20 f-12 w-100" (click)="dialogRef.close(false)">
        No
      </button>
    </div>
    <div class="col-4 col-sm-3">
      <button class="theme-btn primary-btn mb-20 f-12 w-100" (click)="dialogRef.close(true)">
        Yes
      </button>
    </div>

  </div>
</div>