import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  private GET_COUNTRY_NAME = `store/smartvalues/myquery/execute/get_country_name`;
  private GET_STATE_NAME = `store/smartvalues/myquery/execute/get_state_name`;
  private GET_BILLING_DETAILS = `store/smartvalues/myquery/execute/get_subscriber_billing_address`;
  private UPDATE_BILLING_DETAILS = `store/smartvalues/myquery/execute/update_subscriber_billing_address`;

  constructor(private http: HttpClient) {}

  getCountry() {
    return this.http.post(environment.baseUrl + this.GET_COUNTRY_NAME, {});
  }

  getState() {
    return this.http.post(environment.baseUrl + this.GET_STATE_NAME, {});
  }
  getBillingDetails(data: any) {
    return this.http.post(environment.baseUrl + this.GET_BILLING_DETAILS, data);
  }
  updateBillingDetails(data: any) {
    return this.http.post(environment.baseUrl + this.UPDATE_BILLING_DETAILS, data);
  }
}
