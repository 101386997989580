import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  hideElement = false;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      let routes = [
        '/',
        '/login',
        '/auth',
        '/auth/login',
        '/onboarding',
        '/onboarding/questionnaire',
        '/onboarding/multiplechoice',
        '/onboarding/declaration',
        '/auth/register',
        '/auth/otp',
        '/public',
        '/public/landing-page',
        '/public/privacy-policy',
        '/public/terms-and-conditions',
        '/public/pouchlist',
        '/public/pouchlist-details',
        '/public/subscription-plan',
        '/public/agreement',
        '/public/pouch-creation',
        '/register',
        '/public/dashboard',
      ];
      if (event instanceof NavigationEnd) {
        // this.hideElement=(event.url==='/'||event.url==='/login'|| event.url==='/auth')?true:false
        this.hideElement = routes.includes(event.url) ? true : false;
        console.log(event.url);
      }
    });
  }

  ngOnInit(): void {}
}
