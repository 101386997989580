import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PlansService } from '../../investment-pouches/services/plans.service';
@Component({
  selector: 'app-renew-subscription-popup',
  templateUrl: './renew-subscription-popup.component.html',
  styleUrls: ['./renew-subscription-popup.component.scss']
})
export class RenewSubscriptionPopupComponent implements OnInit {
  getDetails: any;
  getCountry: any;
  getStateName: any;
  expiredDate:any;
  paymentUrl: any;
  paymentData: any = {
    access_code: '',
    encRequest: '',
    order_id: '',
  };
  subscriptionList: any = [];
  selected: any=0;
  userInfo: any;
  form!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private plansService: PlansService,
    private DialogRef: MatDialogRef<RenewSubscriptionPopupComponent>,
  ) {
    this.paymentUrl = environment.paymentGatewayUrl;
   }

  ngOnInit(): void {

    this.userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    this.expiredDate = this.data.endDate;
    this.subscriptionList = this.data.optionValueShow;
    this.form = new FormGroup({
      address: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [
        Validators.required,
        Validators.pattern('^[1-9][0-9]{5}$'),
      ]),
    });
    const data = {
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
    };
    this.plansService.getBillingDetails(data).subscribe((res: any) => {
      this.getDetails = res[0].sub_bill_addrs;
      console.log(this.getDetails[0].billing_address);
      this.form.patchValue({
        address: this.getDetails[0].billing_address,
        country: this.getDetails[0].billing_country,
        state: this.getDetails[0].billing_state,
        city: this.getDetails[0].billing_city,
        pincode: this.getDetails[0].billing_pincode,
      });
    });
    this.plansService.getCountry().subscribe((res: any) => {
      this.getCountry = res[0].country_name;
    });
    this.plansService.getState().subscribe((res: any) => {
      this.getStateName = res[0].country_name;
    });
  }
  getText(d: any) {
    if (d === 'HALF-YEARLY') {
      return '6 months';
    }
    if (d === 'YEARLY') {
      return 'year';
    }
    if (d === 'MONTHLY') {
      return 'month';
    }
    return '-';
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  allowNumber(event: any) {
    const input = event.key;
    const isDigit = /^\d$/.test(input);
    if (!isDigit) {
      event.preventDefault();
    }
  }
  
  onPasteAllowNumber(event: any) {
    const isAllDigits = /^\d+$/.test(event.clipboardData.getData('Text'));
    if (!isAllDigits) {
      event.preventDefault();
    }
  }
  renewNow(value: string){
    this.form.markAllAsTouched();
    if(!this.subscriptionList[this.selected]){
      return
    }else{

      let pouchCreation ={
        subscriber_id: this.userInfo.id,
        subscription_amount:this.subscriptionList[this.selected].subscription_amount,
        subscription_freq : this.subscriptionList[this.selected].subscription_freq,
        sv_feature_id: this.subscriptionList[this.selected].sv_feature_id,
        sv_feature_name: this.subscriptionList[this.selected].sv_feature_name,
        isRenew:true
      }
      let stateCode = this.getStateName.find(
        (e: any) => e.state_name === this.form.controls['state'].value
      ).state_code;
      const data = {
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}')?.id,
        docs: {
          billing_address: this.form.controls['address'].value,
          billing_country: this.form.controls['country'].value,
          billing_state: this.form.controls['state'].value,
          billing_city: this.form.controls['city'].value,
          billing_pincode: this.form.controls['pincode'].value,
          billing_state_code: stateCode,
        },
      };
      if(this.form.valid){
        this.plansService.updateBillingDetails(data).subscribe((res: any) => {
          sessionStorage.setItem('pouchCreation', JSON.stringify(pouchCreation))
          sessionStorage.setItem('billings', JSON.stringify(data.docs))
  
          this.DialogRef.close(value);
        })
      }


    }
  }
}
