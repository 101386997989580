import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import {
  UniverseActionTypes,
  AddEdit,
  AddEditSuccess,
  AddEditFailure,
  Load,
  LoadStocks,
  LoadStockSuccess,
  LoadStockFailure,
  CreateUniverse,
  CreateUniverseSuccess,
  CreateUniverseFailure,
  LoadSearchResultSuccess,
  LoadSearchResultFailure,
  UpdateStocks,
  AddSelectedStocks,
  NavigateUniverse,
  NavigateUniverseSuccess,
} from '../actions/universe.action';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { UniverseService } from 'src/app/modules/investment-pouches/services/universe.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { StockService } from 'src/app/modules/more/components/stock/services/stock.service';

@Injectable()
export class UniverseEffects {
  /**
   *
   */
  constructor(
    private actions: Actions,
    private router: Router,
    private universeService: UniverseService,
    private stockService: StockService
  ) {}

  @Effect()
  Load: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.LOAD),
    mergeMap((action: Load) => {
      const result$ = forkJoin([
        this.universeService.getUniverseList().pipe(
          map((data: any) => {
            return data[0].universes.map((element: any) => {
              return { name: element['name'] };
            });
          })
        ),
        this.universeService.getSectorList().pipe(
          map((data: any) => {
            return data[0].sectors
              ? data[0].sectors.map((element: any) => {
                  return { name: element['stock_sector'] };
                })
              : [];
          })
        ),
      ]).pipe(
        mergeMap((joinResult) => {
          console.log('joinResult', joinResult);
          return [new AddEditSuccess(joinResult)];
        })
      );
      return result$;
    }),
    catchError((error) => {
      return observableOf(new AddEditFailure({ error: error }));
    })
  );

  @Effect()
  AddEdit: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.ADDEDIT),
    map((action: AddEdit) => action.payload),
    switchMap((payload) => {
      try {
        return observableOf(new AddEditSuccess(payload));
      } catch (error) {
        return observableOf(new AddEditFailure({ error: error }));
      }
    })
  );

  @Effect({ dispatch: false })
  AddEditSuccess: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.ADDEDIT_SUCCESS),
    tap((response) => {
      // const data: any = response.payload;
      const data: any = response;
    })
  );

  @Effect({ dispatch: false })
  AddEditFailure: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.ADDEDIT_FAILURE),
    tap((error) => {})
  );

  // effects go here
  @Effect()
  LoadStocks: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.LOAD_STOCK),
    mergeMap((action: LoadStocks) => {
      let API$: any;
      let stockList = this.stockService.getStocks(action.payload.data);
      API$ = stockList;
      //let getByUniverse = this.stockService.getStocks(action.payload.data);
      //  if(action.payload.type == 'universe'){
      //      API$ = getByUniverse;
      //  }
      //  else{
      //      API$ = getByIndustry;
      //  }
      const result$ = API$.pipe(
        map((data: any) => {
          return data[0].stocks;
        })
      ).pipe(
        map((result) => {
          //
          if (action.payload.appendResult) {
            return new LoadStockSuccess({ result: result, appendResult: true });
          } else {
            return new LoadStockSuccess({
              result: result,
              appendResult: false,
            });
          }
        })
      );
      return result$;
    }),
    catchError((error) => {
      return observableOf(new LoadStockFailure({ error: error }));
    })
  );

  @Effect()
  UpdateStocks: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.UPDATE_STOCKS),
    map((action: UpdateStocks) => action.payload),
    switchMap((payload: any) => {
      try {
        return observableOf(
          new LoadStockSuccess({ result: payload, appendResult: false })
        );
      } catch (error) {
        return observableOf(new LoadStockFailure({ error: error }));
      }
    })
  );

  @Effect()
  AddSelectedStocks: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.UPDATE_STOCKS),
    map((action: AddSelectedStocks) => action.payload),
    switchMap((payload: any) => {
      try {
        return observableOf(
          new AddSelectedStocks({
            result: payload.result,
            isAppend: payload.isAppend,
          })
        );
      } catch (error) {
        return observableOf(new LoadStockFailure({ error: error }));
      }
    })
  );

  @Effect()
  CreateUniverse: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.CREATE_UNIVERSE),
    mergeMap((action: CreateUniverse) => {
      const result$ = this.universeService
        .createUniverse(action.payload.body)
        .pipe(
          map((data: any) => {
            return data;
          })
        )
        .pipe(
          map((result) => {
            return new CreateUniverseSuccess({
              result: result,
              navigateTo: action.payload.navigateTo,
            });
          })
        );
      return result$;
    }),
    catchError((error) => {
      return observableOf(new CreateUniverseFailure({ error: error }));
    })
  );

  @Effect({ dispatch: false })
  CreateUniverseSuccess: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.CREATE_UNIVERSE_SUCCESS),
    tap((response) => {
      const data: any = response;
      if (data.payload.navigateTo == 'universe') {
        this.router.navigateByUrl('/more/universe');
      } else {
        this.router.navigateByUrl('/investment/create-pouch');
      }
      // LoggerService.log('CreateUniverseSuccess ',data);
    })
  );

  @Effect()
  LoadSearchResults: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.LOAD_SEARCH_RESULTS),
    mergeMap((action: CreateUniverse) => {
      const result$ = this.stockService
        .getStocks(action.payload.data)
        .pipe(
          map((data: any) => {
            return data;
          })
        )
        .pipe(
          map((result) => {
            return new LoadSearchResultSuccess(result[0].stocks);
          })
        );
      return result$;
    }),
    catchError((error) => {
      return observableOf(new LoadSearchResultFailure({ error: error }));
    })
  );

  @Effect()
  NavigateUniverse: Observable<any> = this.actions.pipe(
    ofType(UniverseActionTypes.NAVIGATED_UNIVERSE),
    map((action: NavigateUniverse) => action.payload),
    switchMap((payload: any) => {
      try {
        return observableOf(
          new NavigateUniverseSuccess({ result: payload.result })
        );
      } catch (error) {
        return observableOf(new AddEditFailure({ error: error }));
      }
    })
  );
}
