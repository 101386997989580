<div class="update_transactionContainer">
  <div class="row title">
    <div class="col-12">
      <span class="title_text">Update transaction details</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 stock_name">
      <span>Stock : </span><span>{{ data.stock_code }}</span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4">
      <p>Quantity Purchased</p>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="qty" />
      </mat-form-field>
    </div>
    <div class="col-4">
      <p> Stock Price</p>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="price" />
      </mat-form-field>
    </div>
    <div class="col-4">
      <p>Transaction Date</p>
      <mat-form-field appearance="outline" class="mb-20">
        <input
          matInput
          [matDatepicker]="picker"
          placeholder=""          
          [(ngModel)]="txn_date"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-end">
      <button
        (click)="close(false)"
        mat-button
        class="theme-btn border-btn mx-3"
      >
        cancel
      </button>
      <button [disabled]="btndisable" (click)="close(true)" mat-button class="theme-btn primary-btn spinnerBtn">
        <div *ngIf="!btndisable"> Update</div>
        <ng-container *ngIf="btndisable" >
          <mat-icon ><mat-spinner color="primary" diameter="20">
            </mat-spinner></mat-icon>
        </ng-container>
      </button>
    </div>
  </div>
</div>
